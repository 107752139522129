import React, { memo, forwardRef, RefObject, FC, RefAttributes } from 'react';
import styled from '@emotion/styled';

import { IconNameType } from './IconNamesList';
import { ColorType, ThemeType } from '../ThemeProvider/theme';
import { getThemeColor } from '../../utils';

type IconPropsBase = {
	name: IconNameType;
	color?: ColorType;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
	style?: React.CSSProperties;
	theme?: ThemeType;
	'data-testid'?: string;
	iconTextColor?: ColorType;
};

export type IconProps = IconPropsBase & RefAttributes<HTMLElement>;

export const StyledIcon = styled.i<Pick<IconProps, 'name' | 'color' | 'theme' | 'iconTextColor'>>`
	line-height: 1;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	${({ color, theme }) => `color: ${getThemeColor(color, theme)}`};
	font-size: ${({ name }) => name.match(/\d+$/)[0]}px;
	position: relative;

	:before {
		margin: 0;
		font-size: inherit;
	}

	&:after {
		display: ${({ iconTextColor }) => (iconTextColor ? 'block' : 'none')};
		content: '';
		border-radius: 6px;
		width: 20px;
		height: 20px;
		position: absolute;
		top: -2px;
		left: -2px;
		z-index: -1;
		background: ${({ theme, iconTextColor }) => getThemeColor(iconTextColor, theme)};
	}
`;

export const Icon: FC<IconProps> = forwardRef<HTMLElement, IconPropsBase>(
	(
		{
			name,
			color,
			className,
			onMouseEnter,
			onClick,
			'data-testid': testId,
			...props
		}: IconPropsBase,
		ref: RefObject<HTMLElement>,
	) => (
		<StyledIcon
			{...props}
			ref={ref}
			name={name}
			color={color}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			data-testid={testId}
			className={`icon-${name} ${className ? className : ''}`}
		/>
	),
);

Icon.displayName = 'Icon';

export default memo(Icon);
