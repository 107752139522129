import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, containerID }) => {
	const node = document.getElementById(containerID);
	if (!node) return children;
	return ReactDOM.createPortal(children, node);
};

Portal.propTypes = {
	children: PropTypes.node.isRequired,
	containerID: PropTypes.string.isRequired,
};

export default Portal;
