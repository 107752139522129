import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl';
import withRouter from '~/hoc/withRouter';

import IncreaseTeamSize from './IncreaseTeamSize';
import UpgradePlan from './UpgradePlan';
import query from '~/cache/query';
import PRICE_LIST from '~/cache/queries/user/priceList';

class UpgradeModal extends React.PureComponent {
	getAmount = (value, currency) =>
		this.props.intl.formatNumber(value, {
			currency,
			style: 'currency',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});

	validate(value) {
		let error;
		if (!value) {
			error = true;
		}
		return error;
	}

	render() {
		const { activeService, increaseTeamSize, loading, priceList = {}, data, close } = this.props;
		const planInfo = priceList[data.type.toLowerCase()];

		if (increaseTeamSize) {
			return (
				<IncreaseTeamSize
					close={close}
					planInfo={planInfo}
					getAmount={this.getAmount}
					activeService={activeService}
				/>
			);
		} else {
			return (
				<UpgradePlan
					data={data}
					close={close}
					loading={loading}
					planInfo={planInfo}
					validate={this.validate}
					getAmount={this.getAmount}
					activeService={activeService}
				/>
			);
		}
	}
}

UpgradeModal.propTypes = {
	intl: PropTypes.object,
	activeService: PropTypes.object,
	increaseTeamSize: PropTypes.bool,
	loading: PropTypes.bool,
	priceList: PropTypes.object,
	data: PropTypes.object,
	close: PropTypes.func,
};

export default compose(
	injectIntl,
	withRouter,
	graphql(PRICE_LIST, {
		props: ({ data: { priceList, loading } }) => ({
			priceList,
			loading,
		}),
	}),
	query('account', {
		fragments: ['ActiveService'],
		mapProps: ({ user }) => ({ activeService: user?.activeService }),
	}),
)(UpgradeModal);
