import gql from 'graphql-tag';

export default gql`
	mutation UpdateNotifications($changes: UserEditableInput!, $id: Int) {
		updateUser(changes: $changes, id: $id) {
			newsletter
			systemMessages
		}
	}
`;
