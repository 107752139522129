import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './style.less';

import { noop } from '../utils';

const Checkbox = ({
	checked,
	className,
	color,
	disabled,
	id,
	name,
	onBlur,
	onChange,
	onFocus,
	errors,
	label,
	value,
}) => (
	<label className={classNames(className, styles.label, errors && styles.error)}>
		<label className={styles.checkbox}>
			<input
				value={value}
				checked={checked}
				disabled={disabled}
				id={id}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				type="checkbox"
			/>
			<span className={classNames(styles.checkmark, styles[color], disabled && styles.disabled)} />
		</label>
		{label && <div className={styles.text}>{label}</div>}
	</label>
);

Checkbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'primary',
		'success',
		'dark',
		'light',
		'purple',
		'filters_primary',
		'individual_responses',
	]),
	disabled: PropTypes.bool,
	errors: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
};

Checkbox.defaultProps = {
	color: 'primary',
	disabled: false,
	onBlur: noop,
	onFocus: noop,
};

export default memo(Checkbox);
