import styled from '@emotion/styled';
import { TooltipProps } from './index';

export const TooltipWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const TooltipChildren = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;

export const TooltipContent = styled.div<TooltipProps>`
	${({ theme: { typography }, description }) =>
		description ? typography.textSemibold200 : typography.textMedium200};
	display: inline-flex;
	text-align: left;
	justify-content: center;
	background-color: ${({ theme, description, dark }) =>
		!dark
			? theme.colors.black[10]
			: description
				? theme.colors.grey_shades_with_blue[700]
				: theme.colors.black[90]};
	padding: ${({ description }) => (description ? '4px 8px' : '8px 12px')};
	border-radius: ${({ description }) => (description ? '6px' : '4px')};
	width: max-content;
	max-width: ${({ maxWidth }) =>
		typeof maxWidth === 'string'
			? maxWidth
			: typeof maxWidth === 'number'
				? `${maxWidth}px`
				: 'none'};
	color: ${({ theme, dark }) =>
		!dark ? theme.colors.grey_shades_with_blue[600] : theme.colors.white};
	z-index: 1;
	transition:
		visibility 200ms ease-out,
		opacity 200ms ease-out,
		transform 200ms;

	.tooltip-arrow {
		display: ${({ description }) => (description ? 'none' : 'block')} !important;
	}

	// animation of visible/hidden state of tooltip
	&[data-animation='fade'][data-state='false'] {
		transform: translateY(-4px);
		visibility: hidden;
		opacity: 0;
		// IE11 fix
		position: relative;
	}

	&[data-animation='fade'][data-state='true'] {
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
		// IE11 fix
		position: relative;
	}

	// arrow positioning and rotation
	&[data-placement^='right'] {
		.tooltip-arrow {
			left: -15px;
			.arrow-svg {
				transform: rotate(-90deg);
			}
		}
	}
	&[data-placement^='left'] {
		.tooltip-arrow {
			right: -15px;
			.arrow-svg {
				transform: rotate(90deg);
			}
		}
	}
	&[data-placement^='top'] {
		.tooltip-arrow {
			bottom: -11px;
			.arrow-svg {
				transform: rotate(180deg);
			}
		}
	}
	&[data-placement^='bottom'] {
		.tooltip-arrow {
			top: -11px;
		}
	}

	// arrow color
	path {
		fill: ${({ theme }) => theme.colors.black[90]};
	}
`;
