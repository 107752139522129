import gql from 'graphql-tag';

export default gql`
	fragment Team on User {
		team {
			id
			name
			role
			language
		}
	}
`;
