import gql from 'graphql-tag';

export default gql`
	mutation connectGoogleAnalytics($code: String!, $uid: ID!) {
		connectGoogleAnalytics(code: $code, uid: $uid) {
			active
			activeSince
			code
		}
	}
`;
