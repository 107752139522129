import gql from 'graphql-tag';

export default gql`
	mutation advanceInvoice($data: InvoiceData!, $preview: Boolean) {
		advanceInvoice(data: $data, preview: $preview) {
			number
			invoiceUrl
		}
	}
`;
