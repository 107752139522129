import Table, { Table as T } from './Table';
import TableCell from './TableCell';
import { TableWrapper, Cell, Row, Header } from './Table.styled';
import * as Types from './Table.d';

export { T as Table, TableWrapper, Cell, Row, Header, TableCell };
export type TableProps = Types.TableProps;
export type RowProps = Types.RowProps;
export type CellProps = Types.CellProps;
export default Table;
