import gql from 'graphql-tag';

export default gql`
	mutation createSurveyDashboard($input: CreateSurveyDashboardInput!) {
		createSurveyDashboard(input: $input) {
			id
			title
			createdAt
			sharing {
				enabled
			}
		}
	}
`;
