import { graphql } from '@apollo/client/react/hoc';
import pathOr from 'ramda/es/pathOr';

import QUERY from '~/cache/queries/results/answers';

import tableTransform from '~/pages/Results/table';

export default graphql(QUERY, {
	options: (ownProps) => {
		const params = {
			variables: { uid: ownProps.uid, perPage: ownProps.perPage, page: ownProps.page },
			notifyOnNetworkStatusChange: true,
		};
		// if (ownProps.cursor) params.variables.onlyUntilId = ownProps.cursor;
		if (!ownProps.cache) params.fetchPolicy = 'cache-and-network';
		return params;
	},
	props: ({ ownProps: { loadingMain, cols, total }, data: { loading, responsePage, refetch } }) => {
		if (loading || loadingMain)
			return {
				loadingAnswers: true,
			};
		if (!cols)
			return {
				empty: !(pathOr(0, ['edges', 'length'], responsePage) > 0),
				answers: [],
			};

		let hasEmailRow = false;

		const rows = pathOr([], ['edges'], responsePage).map((row) => {
			let answers = {
				deleted: row.node.deleted,
				discarded: row.node.discarded,
				index: `#${row.node.number}`,
				saved: row.node.submitted,
				id: row.node.id,
				cursor: row.cursor,
				parameterValues: row.node.parameterValues,
			};

			if (row.node.email) {
				answers.email = row.node.email;
				hasEmailRow = true;
			}
			row.node.answers.forEach((answer) => {
				answers = {
					...answers,
					...tableTransform(
						answer.answer.__typename,
						{
							answer,
							cols: cols.filter((col) => col.questionId === answer.questionId),
						},
						false,
					),
				};
			});
			return answers;
		});

		const result = {
			hasEmailRow,
			answers: rows,
			refetchResponsePage: refetch,
		};
		if (!total) result.total = pathOr(0, ['totalCount'], responsePage);
		return result;
	},
});
