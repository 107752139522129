import { v4 as uuid } from 'uuid';
import moment from 'moment';

export const getTrackingToken = () => {
	const token = localStorage.getItem('trckTkn');
	const timestamp = localStorage.getItem('trckTknTmstp');
	return { token, timestamp };
};

const setTrackingToken = () => {
	const token = uuid();
	const timestamp = moment().unix();
	localStorage.setItem('trckTkn', token);
	localStorage.setItem('trckTknTmstp', timestamp);
};

export const removeTrackingToken = () => {
	localStorage.removeItem('trckTkn');
	localStorage.removeItem('trckTknTmstp');
};

const updateTokenTimestamp = () => {
	const timestamp = moment().unix();
	localStorage.setItem('trckTknTmstp', timestamp);
};

export const revalidateTrackingToken = () => {
	const { token, timestamp } = getTrackingToken();

	if (!token || !timestamp) setTrackingToken();
	else {
		const isActual = moment.unix(timestamp).add(1, 'hours').isAfter();
		if (isActual) updateTokenTimestamp();
		else setTrackingToken();
	}
};
