import gql from 'graphql-tag';

export default gql`
	fragment dropBoxList on User {
		integrations {
			dropBox {
				folderNames
			}
		}
	}
`;
