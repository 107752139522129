import * as R from 'ramda';

import ActionTypes from '../constants/actionTypes';

const initModal = {
	data: {},
	contents: null,
	key: null,
	overlayClassName: null,
};

const modalReducer = (state = initModal, action) => {
	switch (action.type) {
		case ActionTypes.OPEN_MODAL:
			return { ...state, ...action.payload };
		case ActionTypes.CLOSE_MODAL:
			return initModal;
		case ActionTypes.SET_MODAL_DATA: {
			return {
				...state,
				data: Object.keys(action.payload)[0] ? { ...state.data, ...action.payload } : {},
			};
		}
		case ActionTypes.SLIDE_MODAL:
			return { ...state, key: action.payload };
		default:
			return state;
	}
};

export default modalReducer;

export const getModal = R.path(['modal']);
