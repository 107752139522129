import React, { FC, forwardRef, memo, useCallback, useRef, useState } from 'react';
import {
	LogicSelectButton,
	LogicSelectContent,
	LogicSelectProps,
	LogicSelectStyled,
	HiddenInput,
} from '.';
import { Menu, useOutsideClick } from 'ui';
import {
	LOGIC_OPTION_FROM_API_TYPES,
	LOGIC_OPTION_TYPES,
} from '~/pages/Builder23_1/constants/constants';
import { useFormContext } from 'react-hook-form';
import { TextFieldError } from '~/ui/components/TextField/TextField.styled';
import { useIntl } from 'react-intl';

export const LogicSelect: FC<LogicSelectProps> = forwardRef(
	(
		{
			dialogId,
			options,
			choiceId,
			logic,
			setChoiceLogic,
			removeLogic,
			logicValue,
			...hookFormProps
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const wrapperRef = useRef(null);
		const [isOpen, setIsOpen] = useState(false);

		const {
			formState: { errors },
		} = useFormContext();
		const error = errors[`${choiceId}`]?.['notify'];

		const handleOnClick = useCallback(() => {
			setIsOpen(!isOpen);
		}, [isOpen]);

		const handleOutsideClick = useCallback(() => {
			setIsOpen(false);
		}, []);

		useOutsideClick(wrapperRef, handleOutsideClick);

		const selectTopPosition = wrapperRef?.current?.getBoundingClientRect()?.top;
		const selectHeightPlusOffset = wrapperRef?.current?.getBoundingClientRect()?.height + 8;
		const dialogTopPosition = document.getElementById(dialogId)?.getBoundingClientRect()?.top;
		const dialogWidth = document.getElementById(dialogId)?.getBoundingClientRect()?.width;
		const dialogHeight = document.getElementById(dialogId)?.getBoundingClientRect()?.height;

		const getLogicValue = useCallback(() => {
			if (!logicValue) {
				return undefined;
			}

			switch (logicValue.type) {
				case LOGIC_OPTION_FROM_API_TYPES.QUESTION:
				case LOGIC_OPTION_FROM_API_TYPES.PAGE:
					return options.find((option) => option.toId === logicValue.value);
				case LOGIC_OPTION_FROM_API_TYPES.REDIRECT:
					return options.find((option) => option.value === LOGIC_OPTION_TYPES.TO_URL);
				case LOGIC_OPTION_FROM_API_TYPES.SUBMIT:
					return options.find((option) => option.value === LOGIC_OPTION_TYPES.TO_SUBMIT);
			}
		}, [logicValue, options]);

		const handleRemoveLogic = useCallback(() => {
			removeLogic(choiceId);
		}, [choiceId, removeLogic]);

		return (
			<>
				<LogicSelectStyled
					ref={wrapperRef}
					topPosition={selectTopPosition - dialogTopPosition + selectHeightPlusOffset}
					dWidth={dialogWidth}
					dHeight={dialogHeight}
				>
					<Menu
						autoHeight={false}
						visible={isOpen}
						anchorWidth="100%"
						content={
							<LogicSelectContent
								options={options}
								closeSelect={handleOutsideClick}
								choiceId={choiceId}
								logic={logic}
								setChoiceLogic={setChoiceLogic}
								logicValue={logicValue}
							/>
						}
						placement="bottom-end"
						offset={[12, 8]}
						popperOptions={{
							strategy: 'fixed',
							modifiers: [
								{
									name: 'preventOverflow',
									options: {
										mainAxis: false,
									},
								},
							],
						}}
					>
						<LogicSelectButton
							onClick={handleOnClick}
							isOpen={isOpen}
							value={getLogicValue()}
							onRemove={handleRemoveLogic}
						/>
					</Menu>
					<HiddenInput ref={ref} {...hookFormProps} />
					{!!error && (
						<TextFieldError error={!!error} style={{ visibility: 'hidden' }}>
							{formatMessage({ id: 'app.account.change-email-invalid-format' })}
						</TextFieldError>
					)}
				</LogicSelectStyled>
			</>
		);
	},
);

LogicSelect.displayName = 'LogicSelect';

export default memo(LogicSelect);
