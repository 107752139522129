import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../components/Modal';

const InAppContext = React.createContext(null);

export const useInAppModal = () => {
	const context = React.useContext(InAppContext);
	if (context === undefined) {
		throw new Error('useModal must be used within a InAppProvider');
	}

	return context;
};

const InAppProvider = (props) => {
	const [modal, setModal] = useState('');

	const openInAppModal = useCallback(
		(name) => {
			setModal(name);
		},
		[setModal],
	);

	const closeInAppModal = useCallback(() => {
		setModal('');
	}, [setModal]);

	return (
		<InAppContext.Provider value={{ closeInAppModal, openInAppModal, modal }} {...props}>
			{props.children}
			{modal && <Modal close={closeInAppModal} name={modal} />}
		</InAppContext.Provider>
	);
};

InAppProvider.propTypes = {
	intl: PropTypes.object,
	children: PropTypes.element,
};

export default memo(InAppProvider);
