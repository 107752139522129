// service keys
const RESULTS = {
	// categories
	SUMMARY: 'summary',
	ANALYZE: 'analyze',
	INDIVIDUAL: 'individual',
	SHARE: 'share',
	DASHBOARDS: 'dashboards',
};

export default RESULTS;
