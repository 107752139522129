import gql from 'graphql-tag';

export default gql`
	fragment PreviousPremiumService on User {
		previousPremiumService {
			name
			plan
			responseLimit
			responseCollectionLimit
			activeTill
			activeSince
			period
			seatsCount
			cardExpirationDate
		}
	}
`;
