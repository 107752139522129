import React from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import WindowSizeListener from 'react-window-size-listener';
import { TweenLite } from 'gsap';

import Content from './Content';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

import ModalContext from './ModalContext';

import Back from '../Back';
import Close from '../Close';
import OnEsc from '../OnEsc';
import Carousel from '../Carousel';
import { animate } from '../utils';
import debounce from '../utils/debounce';

import styles from './style.less';

class Modal extends React.PureComponent {
	static Content = Content;
	static Header = Header;
	static Body = Body;
	static Footer = Footer;

	static propTypes = {};

	static defaultProps = {};

	state = {
		closing: false,
		children: [],
	};

	componentDidMount() {
		this.setMaxSize({ windowHeight: window.innerHeight });
		window.addEventListener('popstate', this.props.close);
	}

	componentDidUpdate() {
		// TODO: breaks height of Textarea in EditableField
		// setTimeout(() =>
		// 	TweenLite.set(this.modalRef.current, {
		// 		transition: 'width, height, 100ms',
		// 	}),
		// );
	}

	componentWillUnmount() {
		this.props.close();
		window.removeEventListener('popstate', this.props.close);
	}

	modalRef = React.createRef();

	clearTimeout = debounce(() => (this.timeout = false), 300);

	isAllowedClose = (allowed, func) => () => {
		allowed && !this.timeout && func();
		this.timeout = true;
		this.clearTimeout();
	};

	handleStopPropagation = (e) => e.stopPropagation();

	setMaxSize = ({ windowHeight, windowWidth }) => {
		const setSize = () => {
			const padding = 80;
			const { maxWidth, minHeight: minH, fullPage } = this.state;
			const maxHeight = windowHeight - padding;
			const minHeight = fullPage ? maxHeight : minH < maxHeight ? minH : maxHeight;
			const modalRef = this.modalRef.current;

			modalRef &&
				TweenLite.set(this.modalRef.current, {
					minHeight,
					maxHeight: `${maxHeight}px`,
					maxWidth: `${fullPage ? windowWidth - (this.state.padding ? padding : 0) : maxWidth}px`,
				});
		};

		if (this.state.fullPageTo && this.state.fullPage !== this.state.fullPageTo >= windowWidth) {
			this.setState({ fullPage: this.state.fullPageTo >= windowWidth }, setSize);
		} else {
			setSize();
		}
	};

	close = () => this.setState({ closing: true });

	setModal = (options) =>
		this.setState(options, () =>
			this.setMaxSize({ windowHeight: window.innerHeight, windowWidth: window.innerWidth }),
		);

	render() {
		const {
			animation,
			back,
			className,
			classNameClose,
			close,
			closing,
			closeOnCrossClick,
			closeOnEsc,
			closeOnOutsideClick,
			padding,
			fullPage,
		} = this.state;

		return (
			<Transition
				appear
				in={!closing}
				mountOnEnter
				// unmountOnExit
				timeout={300}
				onEnter={(node) =>
					animate(node, 'from', 0.3, {
						opacity: '0',
						// transform: 'scale(0.5)',
					})
				}
				onExit={(node) =>
					animate(node, 'to', 0.3, {
						opacity: '0',
						// transform: 'scale(0.5)',
						onComplete: this.props.close,
					})
				}
			>
				<div className={styles.outside} onClick={this.isAllowedClose(closeOnOutsideClick, close)}>
					<OnEsc action={this.isAllowedClose(closeOnEsc, close)} />
					<WindowSizeListener onResize={this.setMaxSize} />
					<div className={styles.modalContainer}>
						<div
							ref={this.modalRef}
							className={classNames(
								'modal',
								styles.modal,
								padding && styles.modalPadding,
								fullPage && styles.fullPage,
								className,
							)}
							onClick={this.handleStopPropagation}
						>
							{back && <Back className={styles.back} onClick={back} />}

							{closeOnCrossClick && (
								<Close
									onClick={this.isAllowedClose(closeOnCrossClick, close)}
									className={classNames('close', styles.close, classNameClose)}
									color="grey"
								/>
							)}

							<ModalContext.Provider
								value={{
									setModal: this.setModal,
								}}
							>
								<Carousel
									animation={animation}
									childrenProps={{
										close: this.close,
										data: this.props.data,
									}}
									contentKey={this.props.contentKey}
									contents={this.props.contents}
									onSlide={this.props.onSlide}
									slide={this.props.slide}
								/>
							</ModalContext.Provider>
						</div>
					</div>
				</div>
			</Transition>
		);
	}
}

export default Modal;
