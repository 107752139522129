import gql from 'graphql-tag';

export default gql`
	query question($uid: String!, $questionId: ID!) {
		survey(uid: $uid) {
			uid
			question(id: $questionId) {
				answered
			}
		}
	}
`;
