// service keys
import { IN_APP_MODAL } from '~/components/InApps';

const SETTINGS = {
	// categories
	BASIC: 'basic',
	ADVANCED: 'advanced',
	SECURITY: 'security',
	INTEGRATIONS: 'integrations',
	// basic
	COLLECT: 'availability',
	DEADLINES: 'deadlines',
	SUBMISSIONS: 'allowRepeat',
	AUTOSCROLL: 'isAutomaticNextQuestionActive',
	PREVIOUS: 'allowReturn',
	NUMBERS: 'numberQuestions',
	LANGUAGE: 'language',
	SERVER: 'server',
	// advanced
	NOTIFICATIONS: 'responseNotifications',
	SLACK: 'responseNotificationsSlack',
	PARAMETER: 'urlParameters',
	REDIRECT: 'exitUrl',
	INDEXING: 'indexing',
	// security
	PASSWORD: 'passwordProtection',
	IP: 'ipRestriction',
	// integrations
	ZAPIER: 'zapier',
	SHEETS: 'sheets',
	ANALYTICS: 'analytics',
	DRIVE: 'drive',
	DROPBOX: 'dropbox',
	ONEDRIVE: 'onedrive',
};
// features info
SETTINGS.FEATURES = {
	// basic
	[SETTINGS.COLLECT]: {
		title: [
			'app.designer.turn-on-off-1',
			'app.designer.turn-on-off-2',
			'app.publish.survey-unpublished',
		],
	},
	[SETTINGS.DEADLINES]: {
		title: 'app.settings.duration',
		description: 'app.designer.availability',
	},
	[SETTINGS.LANGUAGE]: {
		title: 'app.common.language-pref',
		description: 'app.common.language-pref-hint',
		variant: 'language',
	},
	[SETTINGS.SUBMISSIONS]: {
		title: 'app.designer.multiple-submission',
		description: 'app.designer.multiple-submission-on-off',
	},
	[SETTINGS.AUTOSCROLL]: {
		title: 'app.settings.auto-shift',
		description: 'app.settings.auto-shift-desc',
	},
	[SETTINGS.PREVIOUS]: {
		title: 'app.designer.allow-return',
		description: 'app.designer.can-return',
	},
	[SETTINGS.NUMBERS]: {
		title: 'app.designer.display-numbers',
		description: 'app.designer.question-num-auto',
	},
	[SETTINGS.SERVER]: {
		title: 'app.geolocation.settings-title',
		description: 'app.geolocation.settings-desc',
		variant: 'server',
		info: IN_APP_MODAL.SAFE_DATA,
	},

	// advanced
	[SETTINGS.NOTIFICATIONS]: {
		title: 'app.designer.response-notif-email',
		description: 'app.designer.response-notif-email-help',
	},
	[SETTINGS.SLACK]: {
		title: 'app.settings.slack',
		description: 'app.settings.slack-desc',
	},
	[SETTINGS.PARAMETER]: {
		title: 'app.settings.custom-variables',
		description: 'app.settings.custom-variables-desc',
		info: IN_APP_MODAL.URL_PARAMETERS,
	},
	[SETTINGS.REDIRECT]: {
		title: 'app.settings.redirect-url',
		description: 'app.settings.redirect-url-desc',
	},
	[SETTINGS.INDEXING]: {
		title: 'app.distribute.allow-indexing',
		description: 'app.distribute.allow-indexing-help',
	},
	// security
	[SETTINGS.PASSWORD]: {
		title: 'app.designer.pass-protection',
		description: 'app.designer.password-protection',
	},
	[SETTINGS.IP]: {
		title: 'app.designer.ip-protection',
		description: 'app.designer.ip-protection-hint',
	},
	// integrations
	[SETTINGS.ZAPIER]: {
		title: 'app.settings.zapier',
		description: 'app.settings.zapier-desc-3',
		variant: 'integration',
	},
	[SETTINGS.SHEETS]: {
		title: 'app.settings.google-sheets',
		// description: 'app.settings.google-sheets-hint',
		variant: 'integration',
	},
	[SETTINGS.ANALYTICS]: {
		title: 'app.designer.google-analytics',
		// description: 'app.designer.google-analytics-help',
		variant: 'integration',
		infoIcon: IN_APP_MODAL.GA,
	},
	[SETTINGS.DRIVE]: {
		title: 'app.settings.google-drive',
		// description: 'app.settings.google-drive-hint',
		variant: 'integration',
	},
	[SETTINGS.DROPBOX]: {
		title: 'app.settings.dropbox',
		// description: 'app.settings.dropbox-hint',
		variant: 'integration',
	},
	[SETTINGS.ONEDRIVE]: {
		title: 'app.settings.onedrive',
		// description: 'app.settings.onedrive-hint',
		variant: 'integration',
	},
};
SETTINGS.CATEGORY = {
	[SETTINGS.BASIC]: [
		SETTINGS.COLLECT,
		SETTINGS.DEADLINES,
		SETTINGS.LANGUAGE,
		SETTINGS.SUBMISSIONS,
		SETTINGS.AUTOSCROLL,
		SETTINGS.PREVIOUS,
		SETTINGS.NUMBERS,
		/*Storage location section is hidden for now.*/
		/*More details: https://survio.atlassian.net/wiki/x/EACksQ*/
		// SETTINGS.SERVER,
	],
	[SETTINGS.ADVANCED]: [
		SETTINGS.NOTIFICATIONS,
		// SETTINGS.SLACK,
		SETTINGS.PARAMETER,
		SETTINGS.REDIRECT,
		SETTINGS.INDEXING,
	],
	[SETTINGS.SECURITY]: [SETTINGS.PASSWORD, SETTINGS.IP],
	[SETTINGS.INTEGRATIONS]: [
		SETTINGS.ZAPIER,
		SETTINGS.SHEETS,
		SETTINGS.ANALYTICS,
		SETTINGS.DRIVE,
		SETTINGS.DROPBOX,
		SETTINGS.ONEDRIVE,
	],
};

SETTINGS.UPDATE = {
	[SETTINGS.PARAMETER]: (settings, variables, settingsKey, id) => {
		const data = variables[settingsKey];
		let updatedSettingsKey;

		switch (true) {
			case data.hasOwnProperty('add'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					list: [
						...settings[settingsKey].list,
						{
							id,
							name: data.add.name,
							__typename: 'UrlParameter',
						},
					],
				};
				break;
			}
			case data.hasOwnProperty('delete'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					list: settings[settingsKey].list.filter(({ id }) => id !== data.delete),
				};
				break;
			}
			case data.hasOwnProperty('change'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					list: settings[settingsKey].list.map((item) =>
						item.id === data.change.id ? { ...item, name: data.change.name } : item,
					),
				};
				break;
			}
			case data.hasOwnProperty('active'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					active: data.active,
				};
				break;
			}
			default: {
				updatedSettingsKey = { ...settings[settingsKey] };
			}
		}

		const updatedSettings = {
			...settings,
			[settingsKey]: updatedSettingsKey,
		};

		return updatedSettings[settingsKey];
	},
	[SETTINGS.IP]: (settings, variables, settingsKey, id) => {
		const data = variables[settingsKey];
		let updatedSettingsKey;

		switch (true) {
			case data.hasOwnProperty('add'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					list: [
						...settings[settingsKey].list,
						{
							id,
							ip: data.add.address,
							__typename: 'AllowedIp',
						},
					],
				};
				break;
			}
			case data.hasOwnProperty('delete'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					list: settings[settingsKey].list.filter(({ id }) => id !== data.delete),
				};
				break;
			}
			case data.hasOwnProperty('change'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					list: settings[settingsKey].list.map((item) =>
						item.id === data.change.id ? { ...item, ip: data.change.address } : item,
					),
				};
				break;
			}
			case data.hasOwnProperty('active'): {
				updatedSettingsKey = {
					...settings[settingsKey],
					active: data.active,
				};
				break;
			}
			default: {
				updatedSettingsKey = { ...settings[settingsKey] };
			}
		}

		const updatedSettings = {
			...settings,
			[settingsKey]: updatedSettingsKey,
		};

		return updatedSettings[settingsKey];
	},
};

export default SETTINGS;
