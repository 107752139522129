import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Spacer, Typography } from '@survio/ui';

import s from './style';

const Footer = ({ intl, isCardValid }) => (
	<div className={s.footer}>
		<Button type="submit" size="xl">
			{intl.formatMessage({
				id: isCardValid ? 'app.stripe.pay-now' : 'app.common.continue',
			})}
		</Button>

		<Spacer width={24} />

		<Icon name="secured-24" color="grey_shades_with_blue[400]" />

		<Spacer width={8} />

		<Typography variant="textRegular200" color="grey_shades_with_blue[400]">
			{intl.formatMessage({ id: 'app.upgrade.secure-payment' })}
		</Typography>
	</div>
);

Footer.propTypes = {
	intl: PropTypes.object,
	isCardValid: PropTypes.bool,
};

export default Footer;
