import React from 'react';
import { EMPTY_INDIVIDUAL } from '../constants';

import Text from '~/survio-ui/Text';
import { FormattedMessage } from 'react-intl';

const matrix = {
	cols: ({ rows, cols, id, number, type, text }) => {
		if (type === 'MATRIX_SINGLE_CHOICE') {
			const colsText = {};
			cols.map((col) => (colsText[col.id] = col.text));
			return rows.map((row) => ({
				dataIndex: `${id}-${row.id}`,
				// intlID: 'app.results.individual-questioncolumn',
				title: () => <Text maxLine={2}>{`${text} x ${row.text}`}</Text>,
				width: 270,
				questionId: id,
				answerId: row.id,
				colsText,
				render: (grow) => <Text width={240}>{grow[`${id}-${row.id}`]}</Text>,
			}));
		}
		const data = [];
		rows.map((row) =>
			cols.map((col, i) =>
				data.push({
					dataIndex: `${id}-${row.id}-${col.id}`,
					// intlID: 'app.results.individual-questioncolumn',
					title: () => <Text maxLine={2}>{`${text} x ${row.text} x ${col.text}`}</Text>,
					width: 270,
					questionId: id,
					answerId: row.id,
					colId: col.id,
					text: col.text,
					colIndex: i,
					render: (grow) => {
						const value = grow[`${id}-${row.id}-${col.id}`];
						return (
							<Text width={225}>
								{(() => {
									switch (value) {
										case 0:
											return <FormattedMessage id="app.common.no" />;
										case 1:
											return <FormattedMessage id="app.common.yes" />;
										default:
											return value;
									}
								})()}
							</Text>
						);
					},
				}),
			),
		);
		return data;
	},
	rows: ({
		answer: {
			questionId,
			answer: { rows },
		},
		cols,
	}) => {
		const answers = {};
		cols.map(({ answerId, dataIndex, colsText, colId, colIndex }) => {
			let answer = EMPTY_INDIVIDUAL;
			const rowIndex = rows.findIndex((a) => a.rowId === answerId);
			// text matrix
			if (rows[rowIndex] && rows[rowIndex].texts) {
				if (rows[rowIndex].texts[colIndex])
					answer = rows[rowIndex].texts[colIndex]
						? rows[rowIndex].texts[colIndex]
						: EMPTY_INDIVIDUAL;
			}
			// single/multiple choices
			if (rows[rowIndex] && rows[rowIndex].colIds && rows[rowIndex].colIds.length > 0) {
				const row = rows[rowIndex];
				if (rowIndex >= 0)
					answer = !colId
						? colsText[row.colIds[0]] || EMPTY_INDIVIDUAL
						: row.colIds.indexOf(colId) >= 0
						? 1
						: 0;
			}
			answers[dataIndex] = answer;
		});
		return answers;
	},
};
export default matrix;
