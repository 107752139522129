import gql from 'graphql-tag';

export default gql`
	query shareResultsSettings($uid: String!) {
		shareResultsSettings(uid: $uid) {
			url
			published
			showIndividualResponses
			enableResultsAndResponsesExport
			showSurveyStatistics
			showSharing
			password
			passwordActive
			introductoryText
		}
	}
`;
