import React, { FC, memo, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ColorType, ThemeType } from '../ThemeProvider/theme';
import { getThemeColor } from '../../utils';
import { Typography, TypographyVariant } from '../Typography';

export type SectionProps = {
	children: ReactNode;
	className?: string;
	theme?: ThemeType;
	renderRight?: ReactNode;
	label?: string;
	titleColor?: ColorType;
	titleVariant?: TypographyVariant;
	wrapperBackground?: ColorType;
	border?: ColorType | 'none';
};

const SectionWrapper = styled.section<SectionProps>`
	background-color: ${({ wrapperBackground, theme }) =>
		wrapperBackground ? getThemeColor(wrapperBackground, theme) : 'transparent'};
	border-radius: 24px;
	border: ${({ theme, border }) =>
		`1px solid ${border === 'none' ? 'none' : getThemeColor(border, theme)}`};
	display: flex;
	flex-direction: column;
	outline: 0;
	padding: ${({ label }) => (label ? 40 : 16)}px 0;
	width: 100%;
`;

const Title = styled(Typography)<Pick<SectionProps, 'titleColor' | 'theme'>>`
	align-items: center;
	color: ${({ titleColor, theme }) => getThemeColor(titleColor, theme)};
	display: flex;
	margin: 0 40px 24px 40px;
	justify-content: space-between;
`;

export const Section: FC<SectionProps> = ({
	children,
	className,
	label,
	titleColor,
	titleVariant,
	wrapperBackground,
	renderRight,
	border,
}: SectionProps) => (
	<SectionWrapper
		className={className}
		wrapperBackground={wrapperBackground}
		data-testid="ui-section"
		label={label}
		border={border}
	>
		{(label || renderRight) && (
			<Title variant={titleVariant} titleColor={titleColor}>
				{label}
				{renderRight}
			</Title>
		)}
		{children}
	</SectionWrapper>
);

Section.defaultProps = {
	border: 'grey_shades_with_blue[100]',
	titleColor: 'black[100]',
	titleVariant: 'title600',
};

export default memo(Section);
