import React, { FC, forwardRef, memo, RefObject } from 'react';

import { StatusProps, StyledStatus, StatusPropsBase } from './';

export const Status: FC<StatusProps> = forwardRef<HTMLElement, StatusPropsBase>(
	({ children, ...props }: StatusPropsBase, ref: RefObject<HTMLDivElement>) => (
		<StyledStatus {...props} ref={ref}>
			{children || props.state.split('_')[0]}
		</StyledStatus>
	),
);

Status.displayName = 'Status';

export default memo(Status);
