import gql from 'graphql-tag';

export default gql`
	mutation transferSurveys(
		$targetMemberId: ID!
		$surveyIds: [ID!]
		$transferAll: Boolean
		$memberId: ID
	) {
		transferSurveys(
			targetMemberId: $targetMemberId
			surveyIds: $surveyIds
			transferAll: $transferAll
			memberId: $memberId
		) {
			success
		}
	}
`;
