import React, { useState, memo, useCallback } from 'react';
import { compose } from 'redux';
import { useForm } from 'react-hook-form';
import { Button, Dropdown, Input, Modal, Textarea, Typography } from 'ui';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import isValidEmail from '~/utils/isValidEmail';
import cn from 'classnames';
import query from '~/cache/query';
import mutation from '~/cache/mutation';
import Loader from '~/components/Loader';
import Notifications from '~/components/Notifications';
import { isProPlan } from '~/utils';
import { EXPERIMENT_STATE, EXPERIMENTS, PRO_PLANS } from '~/constants';
import { withSendTracking } from '~/hoc';

import styles from './style.less';
import { PRICING_EXPERIMENT } from '~/constants/experiments';

const TeamSizeAdjustmentModal = ({
	intl,
	email,
	close,
	requestTeamSeats,
	prev,
	upgrade,
	increase,
	bulkPrice,
	twoYears,
	serviceName,
	planType,
	seatsDefault,
	seatsCount,
	sendTracking,
	updateExperiment,
	testPrice23A,
}) => {
	const [loading, setLoading] = useState(false);
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		defaultValues: {
			email,
			seatsCount: seatsCount || 3,
		},
	});

	const { name, onChange, onBlur, ref } = register('seatsCount', {
		required: intl.formatMessage({ id: 'app.multiuser.required-field' }),
	});

	const handleEmailValidation = useCallback((address) => {
		const emailAddress = address?.toLowerCase();
		return isValidEmail(emailAddress);
	}, []);

	const onSubmit = useCallback(
		(fields) => {
			if (handleEmailValidation(fields?.email)) {
				setLoading(true);
				if (increase) {
					sendTracking(482, 'team_click_upsell_feature_seats_contact');
				}
				updateExperiment &&
					updateExperiment({
						input: { name: EXPERIMENTS.AB_TRIAL, state: EXPERIMENT_STATE.FINISHED },
					});
				requestTeamSeats({
					input: {
						email: fields?.email,
						message: fields?.message,
						seatsCount: Math.abs(parseInt(fields?.seatsCount)),
					},
				}).then((response) => {
					if (response?.requestTeamSeats?.success) {
						close();
					}
					Notifications.add({
						variant: 'success',
						children: intl.formatMessage({ id: 'app.multiuser.msg-request-sent' }),
					});
					setLoading(false);
				});
			}
		},
		[
			close,
			handleEmailValidation,
			increase,
			intl,
			requestTeamSeats,
			sendTracking,
			updateExperiment,
		],
	);

	const seatsListGenerator = useCallback(() => {
		const resultList = [];
		for (let n = seatsDefault || 3; n <= 50; n++) {
			resultList.push({
				id: `${n}`,
				label: `${n} ${
					n < 5
						? intl.formatMessage({ id: 'app.multiuser.seats-1' })
						: intl.formatMessage({ id: 'app.multiuser.seats-2' })
				}`,
			});
		}

		return resultList;
	}, [seatsDefault, intl]);

	return (
		<>
			<Loader visible={loading} />
			<Modal.Content prev={prev ?? window.location.hash.substr(1)}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Typography variant="title800" className={styles.headline}>
						{(() => {
							switch (true) {
								case upgrade:
									return intl.formatMessage(
										{ id: 'app.upgrade.upgrade-to' },
										{
											PLAN: intl.formatMessage(
												{
													id:
														!testPrice23A && isProPlan(planType || serviceName)
															? 'app.multiuser.plan-types-PRO'
															: 'app.multiuser.plan-types-basic',
												},
												{
													PLAN_TYPE: testPrice23A
														? 'CUSTOM'
														: planType?.toUpperCase() ||
															PRO_PLANS[PRO_PLANS.indexOf(serviceName) + 1]?.toUpperCase(),
												},
											),
										},
									);
								case increase:
									return intl.formatMessage({ id: 'app.multiuser.increase-team-size' });
								case bulkPrice:
									return intl.formatMessage({ id: 'app.pricelist.bulk-price-request' });
								default:
									return intl.formatMessage({ id: 'app.multiuser.manage-seats' });
							}
						})()}
						{twoYears && `, ${intl.formatMessage({ id: 'app.upgrade.two-years-subscription' })}`}
					</Typography>
					<Typography variant="textRegular400" className={styles.subtitle}>
						{intl.formatMessage({ id: 'app.multiuser.team-size-adjust-desc' })}
					</Typography>
					<div className={cn(styles.flex, styles.row)}>
						<div className={styles.flex}>
							<Dropdown
								items={seatsListGenerator()}
								label={intl.formatMessage({
									id: 'app.multiuser.how-many-seats',
								})}
								name={name}
								onChangeHookForm={onChange}
								onBlur={onBlur}
								ref={ref}
								size="md"
								placeholder={intl.formatMessage({ id: 'app.common.select' })}
								width="100%"
								menuMaxHeight={270}
								error={errors.seatsCount?.message}
								required
							/>
						</div>
						<div className={styles.flex}>
							<Input
								required
								label={intl.formatMessage({
									id: 'app.designer.modal.email',
								})}
								placeholder={intl.formatMessage({ id: 'app.common.your-email' })}
								{...register('email', {
									validate: (e) =>
										handleEmailValidation(e) ||
										intl.formatMessage({ id: 'app.multiuser.enter-valid-email' }),
								})}
								size="md"
								width="100%"
								error={errors.email?.message}
							/>
						</div>
						<div className={styles.flex}>
							<Textarea
								label={intl.formatMessage({
									id: 'app.invitation.invitation-text',
								})}
								{...register('message')}
								size="sm"
								width="100%"
								rows={3}
							/>
						</div>
					</div>
					<Modal.Footer>
						<Button size="xl" type="submit" color="primary">
							{intl.formatMessage({ id: 'survey.submit' })}
						</Button>
					</Modal.Footer>
				</form>
			</Modal.Content>
			<Modal.Sidebar bgColor="#fff">
				<div className={styles.sidebarContent}>
					<div className={styles.argumentSection}>
						<Typography
							variant="textSemibold300"
							color="grey_shades_with_blue[500]"
							className={styles.title}
						>
							{intl.formatMessage({ id: 'app.multiuser.prefer-talk' })}
						</Typography>
						<Typography variant="textRegular250" color="grey_shades_with_blue[500]">
							<span className={styles.bullet}>&#8226;</span>
							{intl.formatMessage({ id: 'app.multiuser.PRO-argument-1' })}
						</Typography>
						<Typography variant="textRegular250" color="grey_shades_with_blue[500]">
							<span className={styles.bullet}>&#8226;</span>
							{intl.formatMessage({ id: 'app.multiuser.PRO-argument-2' })}
						</Typography>
						<Typography variant="textRegular250" color="grey_shades_with_blue[500]">
							<span className={styles.bullet}>&#8226;</span>
							{intl.formatMessage({ id: 'app.multiuser.PRO-argument-3' })}
						</Typography>
					</div>
					<div className={styles.customerSuccess}>
						<div className={styles.faces} />

						<div className={styles.description}>
							<Typography variant="textSemibold250" color="grey_shades_with_blue[600]">
								{intl.formatMessage({ id: 'app.multiuser.cs-names' })}
							</Typography>
							<Typography variant="textRegular200" color="grey_shades_with_blue[500]">
								{intl.formatMessage({ id: 'app.multiuser.cs-team' })}
							</Typography>
						</div>
					</div>
					<div className={styles.footer}>
						<Button
							variant="simpleLinkBlue"
							onClick={() => window.open('https://calendly.com/survio-demo', '_blank')}
						>
							{intl.formatMessage({ id: 'app.multiuser.schedule-call' })}
						</Button>
					</div>
				</div>
			</Modal.Sidebar>
		</>
	);
};

TeamSizeAdjustmentModal.propTypes = {
	close: PropTypes.func,
	email: PropTypes.string,
	intl: PropTypes.object,
	loading: PropTypes.bool,
	prev: PropTypes.string,
	publicUrl: PropTypes.string,
	requestTeamSeats: PropTypes.func,
	serviceName: PropTypes.string,
	planType: PropTypes.string,
	upgrade: PropTypes.bool,
	increase: PropTypes.bool,
	bulkPrice: PropTypes.bool,
	twoYears: PropTypes.bool,
	seatsDefault: PropTypes.number,
	seatsCount: PropTypes.number,
	sendTracking: PropTypes.func,
	updateExperiment: PropTypes.func,
	testPrice23A: PropTypes.bool,
};

export default compose(
	memo,
	injectIntl,
	withSendTracking,
	mutation('team/requestTeamSeats', {
		optimisticResponse: {
			requestTeamSeats: {
				success: true,
				message: null,
				__typename: 'MutationResult',
			},
		},
	}),
	query('account', {
		fragments: ['ActiveService', 'UserContact'],
		mapProps: ({ user }) => ({
			email: user?.email,
			serviceName: user?.activeService?.name,
		}),
	}),
	query('stripe', {
		fragments: ['Prices'],
		mapProps: ({ stripePriceList }) => ({
			testPrice23A: stripePriceList?.experiment === PRICING_EXPERIMENT.TEST_PRICE_23_A,
		}),
	}),
)(TeamSizeAdjustmentModal);
