import React, { createRef, FC, memo, useCallback, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Instance, sticky as stickyPlugin } from 'tippy.js';
import classnames from 'classnames';
import { BubbleProps, BubbleContent, BubbleChildren } from './';
import SvgArrow from '!!svg-react-loader!./assets/arrow.svg';
import { controlledTippyFix } from '../../utils';

export const BUBBLE_DELAY = 200;

const Bubble: FC<BubbleProps> = ({
	className,
	contentClassName,
	content,
	children,
	onHide,
	onMount,
	placement,
	sticky,
	variant,
	visible,
	disabled,
	trigger,
	popperOptions,
	withArrow,
	childrenClassName,
	appendTo,
	onClickOutside,
	offset,
	interactive,
	withShadow,
	borderRadius,
	...rest
}: BubbleProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [arrowElement, setArrowElement] = useState(null);
	const ref = createRef<HTMLDivElement>();

	const setArrowPadding = useCallback(() => {
		const isBottomOrTop = RegExp(/(top)+|(bottom)+/).test(placement);
		return isBottomOrTop ? 20 : -4;
	}, [placement]);

	const handleOnMount = useCallback(
		(instance: Instance) => {
			setIsOpen(true);
			onMount && onMount(instance);
		},
		[onMount],
	);

	const handleOnHide = useCallback(
		(instance: Instance) => {
			const unmountInstance = (event) => {
				if (event.elapsedTime >= BUBBLE_DELAY / 1000) {
					instance.unmount();
				}
				// need to control when we remove the listener because transitionend fires multiple times
				instance.popper.firstChild.removeEventListener('transitionend', unmountInstance);
			};

			instance.popper.firstChild.removeEventListener('transitionend', unmountInstance);
			instance.popper.firstChild.addEventListener('transitionend', unmountInstance);
			isOpen && setIsOpen(false);
			onHide && onHide(instance);
		},
		[isOpen, onHide],
	);

	const handleOnClickOutside = useCallback(() => {
		onClickOutside && onClickOutside();
	}, [onClickOutside]);

	const noTriggerWhenMenuIsActive = disabled === 'whenMenuIsActive' && !!rest.active ? '' : trigger;

	// workaround to resolve Tippy.js warning (see ./utils/tippyFix.ts)
	const controlledFix = useCallback(
		() => controlledTippyFix(visible, noTriggerWhenMenuIsActive, true),
		[noTriggerWhenMenuIsActive, visible],
	);

	const isDisabled = disabled === 'whenMenuIsActive' ? false : disabled;

	return (
		<div className={classnames('bubble-wrapper', className)} data-testid="ui-bubble">
			{isDisabled ? (
				<BubbleChildren ref={ref}>{children}</BubbleChildren>
			) : (
				<Tippy
					{...(appendTo && { appendTo })}
					onClickOutside={handleOnClickOutside}
					interactive={interactive}
					animation={true}
					placement={placement}
					{...(sticky && { sticky: true })}
					plugins={sticky ? [stickyPlugin] : []}
					onMount={handleOnMount}
					onHide={handleOnHide}
					{...controlledFix()}
					visible={visible}
					popperOptions={{
						...popperOptions,
						modifiers: [
							{ name: 'arrow', options: { element: arrowElement, padding: setArrowPadding() } },
							{ name: 'offset', options: { offset: offset || [0, 13] } },
							...(popperOptions && popperOptions.modifiers ? popperOptions?.modifiers : []),
						],
					}}
					render={(attrs) => (
						<BubbleContent
							className={contentClassName}
							data-animation="fade"
							data-state={isOpen}
							data-testid="ui-bubble-content"
							variant={variant}
							withArrow={withArrow}
							withShadow={withShadow}
							borderRadius={borderRadius}
							{...attrs}
						>
							{typeof content === 'function' ? content({ isOpen }) : content}
							{withArrow && (
								<div className="bubble-arrow" ref={setArrowElement} data-popper-arrow="">
									<div className="arrow-transform">
										<SvgArrow className="arrow-svg" />
									</div>
								</div>
							)}
						</BubbleContent>
					)}
					{...rest}
					theme={undefined}
				>
					<BubbleChildren ref={ref} className={childrenClassName}>
						{children}
					</BubbleChildren>
				</Tippy>
			)}
		</div>
	);
};

Bubble.defaultProps = {
	placement: 'right',
	trigger: 'click',
	variant: 'light',
	withArrow: true,
	withShadow: true,
	interactive: true,
	borderRadius: 16,
};

export default memo(Bubble) as FC<BubbleProps>;
