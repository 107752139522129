import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'ui';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import query from '~/cache/query';
import mutation from '~/cache/mutation';
import { PERIOD, PLANS, PLANS_NEW, UPGRADE_MODAL_TYPES } from '~/constants';
import CustomPlanSlide from './CustomPlanSlide';
import UpgradeSlide from './UpgradeSlide';
import UpgradeSlideWithQuotes from './UpgradeSlideWithQuotes';
import SalesModal from '~/containers/SalesModal';
import TeamSizeAdjustmentModal from '~/pages/Members/components/TeamSizeAdjustmentModal';
import { OPS } from '~/pages/StripePricing/constants';
import { PLANS_OLD, PLANS_24 } from '~/constants/plans';
import { withBusinessModel01Handling, withSendTracking } from '~/hoc';
import Iframe from '~/components/InAppModal/components/Iframe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SaleSourceContext from './SaleSourceContext';
import withRouter from '~/hoc/withRouter';

const SALES_SLIDE = 'sales';
const IFRAME_SLIDE = 'iframe';

const stripePromise = loadStripe(window.env.STRIPE_PUBLISHABLE_KEY);

const UpgradeModalStripe = ({
	close,
	actualPlan,
	plan,
	stripePriceList,
	actualLimit,
	actualPeriod,
	stripeUpgrade,
	nextBilling,
	period,
	refetchStripePriceList,
	loading: apiLoading,
	salesVariant,
	isTrial,
	subscriptionReference,
	intl,
	isBusinessModel01,
	iframeProps,
	sendTracking,
	saleSource: saleSourceProp,
	location,
}) => {
	/** Get maximum of responses which user can buy for actual package. */
	const packages = stripePriceList?.plans?.find(
		(p) => p.identifier === actualPlan,
	)?.responseCollectionPackages;
	const maxResponsesLimit = packages ? packages[packages.length - 1]?.quantity : 0;

	/** Decide if upgrading responses or plan. */
	const increaseResponses =
		!salesVariant &&
		!isTrial &&
		(!plan ? actualLimit < maxResponsesLimit : plan === actualPlan && period === OPS[actualPeriod]);

	/** Get the plan to upgrade to. Plan from parameter "plan" or next higher plan. */
	let upgradeToPlan = plan ? plan : isTrial ? PLANS_NEW.STANDARD : undefined;
	if (!plan && actualPlan && !isTrial) {
		const planIndex = Object.values(PLANS_NEW).indexOf(actualPlan);
		upgradeToPlan = PLANS_NEW[Object.keys(PLANS_NEW)[planIndex + (increaseResponses ? 0 : 1)]];
	}

	const proforma =
		!subscriptionReference &&
		!isTrial &&
		![PLANS_NEW.FREE_01, PLANS_OLD.FREE, PLANS_24.FREE].includes(actualPlan);

	const [saleSource, setSaleSource] = useState(location?.state?.saleSource || saleSourceProp);

	return (
		<Elements stripe={stripePromise}>
			<SaleSourceContext.Provider value={{ saleSource, setSaleSource }}>
				{salesVariant === IFRAME_SLIDE ? (
					<Iframe {...iframeProps} sendTracking={sendTracking} close={close} />
				) : (
					<Modal slide={salesVariant ? SALES_SLIDE : upgradeToPlan} close={close}>
						{(slide) => {
							switch (slide) {
								case SALES_SLIDE:
									return <SalesModal close={close} variant={salesVariant} proforma={proforma} />;
								case PLANS_NEW.CUSTOM:
									return (
										<CustomPlanSlide
											close={close}
											upgradeToPlan={slide}
											prev={salesVariant ? SALES_SLIDE : undefined}
										/>
									);
								case 'upgradePRO':
									return (
										<TeamSizeAdjustmentModal
											upgrade
											close={close}
											planType={PLANS.ENTERPRISE}
											prev={SALES_SLIDE}
										/>
									);
								default:
									if (proforma) {
										window.open(intl.formatMessage({ id: 'app.common.contact' }), 'blank');
										close();
										return;
									} else {
										const Slide =
											isBusinessModel01 ||
											![PLANS_NEW.FREE_01, PLANS_OLD.FREE, PLANS_24.FREE].includes(actualPlan)
												? UpgradeSlide
												: UpgradeSlideWithQuotes;
										return (
											<Slide
												upgradeToPlan={slide}
												nextBilling={nextBilling}
												actualPlan={actualPlan}
												period={period}
												actualPeriod={actualPeriod}
												stripePriceList={stripePriceList}
												actualLimit={actualLimit}
												stripeUpgrade={stripeUpgrade}
												type={
													increaseResponses
														? UPGRADE_MODAL_TYPES.INCREASE_RESPONSES
														: UPGRADE_MODAL_TYPES.UPGRADE_PLAN
												}
												refetchStripePriceList={refetchStripePriceList}
												apiLoading={apiLoading}
												prev={salesVariant ? SALES_SLIDE : undefined}
												isTrial={isTrial}
												close={close}
											/>
										);
									}
							}
						}}
					</Modal>
				)}
			</SaleSourceContext.Provider>
		</Elements>
	);
};

UpgradeModalStripe.propTypes = {
	actualLimit: PropTypes.number,
	actualPeriod: PropTypes.number,
	actualPlan: PropTypes.string,
	close: PropTypes.func,
	isTrial: PropTypes.bool,
	intl: PropTypes.object,
	loading: PropTypes.bool,
	nextBilling: PropTypes.object,
	period: PropTypes.oneOf([PERIOD.MONTHLY, PERIOD.YEARLY]),
	plan: PropTypes.string,
	refetchStripePriceList: PropTypes.func,
	salesVariant: PropTypes.string,
	stripePriceList: PropTypes.object,
	stripeUpgrade: PropTypes.func,
	subscriptionReference: PropTypes.string,
	type: PropTypes.oneOf([UPGRADE_MODAL_TYPES.UPGRADE_PLAN, UPGRADE_MODAL_TYPES.INCREASE_RESPONSES]),
	isBusinessModel01: PropTypes.bool,
	iframeProps: PropTypes.object,
	sendTracking: PropTypes.func,
	saleSource: PropTypes.string,
	location: PropTypes.object,
};

export default compose(
	memo,
	injectIntl,
	withRouter,
	mutation('stripeUpgrade'),
	withSendTracking,
	withBusinessModel01Handling,
	query('account', {
		fragments: ['UpgradePlanData', 'NextBilling', 'isTrial', 'Language'],
		mapProps: ({ user }) => {
			if (!user) {
				return null;
			}
			return {
				actualLimit: user.activeService?.responseCollectionLimit,
				actualPlan: user.activeService?.identifier,
				actualPeriod: user.activeService?.period,
				isTrial: user.activeService?.isTrial,
				nextBilling: user.activeService?.nextBilling,
				subscriptionReference: user.activeService?.subscriptionReference,
			};
		},
	}),
	query('stripe', {
		fragments: ['Prices'],
		mapProps: ({ stripePriceList, fetchMore }) => ({
			/**
			 * Workaround - using fetchMore instead of refetch to update Apollo cache
			 * read more:
			 * https://github.com/apollographql/apollo-client/issues/6925#issuecomment-762354072
			 */
			refetchStripePriceList: (input) =>
				fetchMore({
					variables: { currencyCode: input.currencyCode },
					updateQuery: (prevResult, { fetchMoreResult }) => {
						if (!fetchMoreResult) {
							return prevResult;
						}
						return {
							...prevResult,
							stripePriceList: fetchMoreResult?.stripePriceList,
						};
					},
				}),
			stripePriceList,
		}),
	}),
)(UpgradeModalStripe);
