import { EMPTY_INDIVIDUAL } from '../constants';
import Text from '~/survio-ui/Text';
import React from 'react';

const nps = {
	cols: ({ id, text }) => [
		{
			dataIndex: `${id}`,
			// intlID: 'app.results.individual-questioncolumn',
			title: () => <Text maxLine={2}>{text}</Text>,
			questionId: id,
			width: 270,
		},
	],
	rows: ({ answer, cols }) =>
		answer && answer.questionId
			? {
					[answer.questionId]: `${answer.answer.rating}`,
			  }
			: {
					[cols[0].dataIndex]: EMPTY_INDIVIDUAL,
			  },
};
export default nps;
