import { connect } from 'react-redux';
import * as R from 'ramda';

import { getModal } from '../reducers';
import { closeModal, setModalData, slideModal } from '../actions/index';
import ModalContainer from '~/survio-ui/Modal/ModalContainer';

export default connect(
	R.applySpec({
		modal: getModal,
	}),
	{
		closeModal,
		setModalData,
		slideModal,
	},
)(ModalContainer);
