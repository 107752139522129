import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './style.less';

const ConditionalLink = ({ disabled, children, external, to, ...rest }) =>
	disabled ? (
		<>{children}</>
	) : external ? (
		<span className={s.link}>
			<a href={to} rel="noopener noreferrer" {...rest}>
				{children}
			</a>
		</span>
	) : (
		<Link to={to} {...rest}>
			{children}
		</Link>
	);

ConditionalLink.propTypes = {
	external: PropTypes.bool,
	disabled: PropTypes.bool,
	children: PropTypes.element,
	to: PropTypes.string,
};

export default memo(ConditionalLink);
