import Input from '../Input';
import styled from '@emotion/styled';

export const RangeStyled = styled(Input)`
	display: flex;
	align-items: flex-start;
	min-width: auto;
	align-self: flex-start;

	&& input {
		min-width: 24px;
		margin: 2px 0;
		text-align: center;
	}

	.textFieldRight {
		margin-right: 8px;
		margin-left: 4px;
		cursor: pointer;
	}

	.textFieldLeft {
		margin-left: 8px;
		margin-right: 4px;
		cursor: pointer;
	}
`;
