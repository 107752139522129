import gql from 'graphql-tag';

export default gql`
	mutation setDashboardTileChartSettings($id: ID!, $input: ChartSettingsInput!) {
		setDashboardTileChartSettings(id: $id, input: $input) {
			customLabels {
				title
				axis
				answers
				rows
				columns
			}
			theme {
				id
				name
				colors
				isSystem
				isLocked
			}
			labels
			type
			order
			percentage
		}
	}
`;
