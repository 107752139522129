import React, { FC, memo, useCallback } from 'react';
import { FilterSelectorProps, Number, StyledSelector } from '.';
import { Typography, Icon, Menu } from '../../index';

export const FilterSelector: FC<FilterSelectorProps> = ({
	label,
	items,
	filters,
	className,
	onItemClick,
	...props
}: FilterSelectorProps) => {
	const getItems = useCallback(
		() => items.map((i) => ({ ...i, onClick: onItemClick })),
		[items, onItemClick],
	);

	return (
		<Menu items={getItems()} placement="bottom-start" minWidth={192} size="sm">
			<StyledSelector
				filters={filters}
				className={className}
				{...props}
				data-testid="ui-filterSelector"
			>
				<Icon name="filter-16" color="grey_shades_with_blue[600]" />
				<Typography color="grey_shades_with_blue[600]" variant="textSemibold300">
					{label}
				</Typography>
				{filters > 0 && (
					<Number variant="textSemibold100" color="white">
						{filters}
					</Number>
				)}
			</StyledSelector>
		</Menu>
	);
};

export default memo(FilterSelector);
