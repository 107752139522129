const colors = {
	active_blue: {
		50: '#F3FCFF',
		100: '#E3F7FF',
		200: '#81D7FC',
		400: '#00C0FD',
		500: '#0098FD',
		550: '#0092F2',
		600: '#0081DE',
		700: '#0065AE',
	},
	yellow: {
		50: '#FFF8EE',
		100: '#FFF2DD',
		200: '#F8EAC0',
		250: '#ECD8BA',
		300: '#FFCC49',
		400: '#FFB800',
		500: '#F7AC00',
		600: '#EB9B00',
		700: '#B77E37',
		800: '#8A6100',
		900: '#6B4B22',
		1000: '#44310C',
	},
	avatar_colors: {
		blue_3: {
			300: '#D6DAE9',
			500: '#5F71CE',
			600: '#36417C',
		},
		blue_2: {
			300: '#DBE7EE',
			500: '#5BADDB',
			600: '#295383',
		},
		blue: {
			300: '#D6EEF3',
			500: '#71CBDF',
			600: '#206D7E',
		},
		turquoise_blue: {
			300: '#DEEBEC',
			500: '#83CACE',
			600: '#37727A',
		},
		turquoise_green: {
			300: '#D6E5E4',
			500: '#6CB1A9',
			600: '#096C60',
		},
		green_yellow: {
			300: '#D9E2D4',
			500: '#9AAF8E',
			600: '#4A653A',
		},
		yellow: {
			300: '#F2ECD6',
			500: '#CAB464',
			600: '#887E58',
		},
		orange: {
			300: '#F3DED3',
			500: '#D38A61',
			600: '#98532D',
		},
		purple: {
			300: '#DFD4E1',
			500: '#9874A0',
			600: '#593062',
		},
		pink: {
			300: '#EED7E0',
			500: '#BF4672',
			600: '#93254C',
		},
		red: {
			300: '#F5D8D6',
			500: '#E27168',
			600: '#E02E23',
		},
	},
	black: {
		100: '#000000',
		90: '#1A1A1A',
		85: '#252525',
		80: '#333333',
		70: '#4D4D4D',
		60: '#666666',
		50: '#808080',
		40: '#999999',
		30: '#B3B3B3',
		20: '#CCCCCC',
		10: '#E0E0E0',
	},
	grey: {
		400: '#EBEBEB',
		300: '#F2F2F2',
		200: '#F8F8F8',
	},
	brand_blue: {
		500: '#056EFC',
		600: '#0035F0',
		700: '#0016DA',
	},
	green_success: {
		700: '#006956',
		600: '#01856D',
		500: '#00B176',
		400: '#00C980',
		100: '#D7FAE5',
	},
	grey_shades_with_blue: {
		800: '#121217',
		700: '#282833',
		600: '#3F3F50',
		550: '#55556D',
		500: '#5F5F7A',
		400: '#6C6C89',
		350: '#8A8AA3',
		300: '#A9A9BC',
		250: '#BABACA',
		200: '#D1D1DB',
		100: '#E4E4EA',
		50: '#EBEBEF',
		25: '#F2F2F5',
		10: '#F7F7F8',
		5: '#FCFCFD',
	},
	red: {
		800: '#880727',
		700: '#AF0932',
		600: '#D50B3E',
		550: '#F3164E',
		500: '#F53D6B',
		400: '#F76489',
		300: '#FFA0B7',
		250: '#E9A5B5',
		200: '#FBB1C4',
		150: '#FDD8E1',
		100: '#FEF0F4',
	},
	white: '#FFFFFF',
	old: {
		dark_blue: '#333D60',
		green: {
			100: '#13d5a7',
			200: '#00B889',
			300: '#00957a',
		},
	},
	ice: {
		100: '#E6F4F9',
	},
	brand_steel: {
		100: '#DCEDF5',
		200: '#D8EAF4',
		250: '#B3E1F8',
		300: '#B4D4E2',
		350: '#9FCCDF',
		400: '#8CAFC3',
		500: '#5089A3',
		600: '#246380',
		700: '#004260',
		900: '#002D42',
	},
	gold: {
		100: '#F8F5EF',
		200: '#F7EFDB',
		300: '#F1DEAB',
		400: '#E3C77D',
		500: '#CA9A43',
		600: '#A67006',
		700: '#8F5F00',
	},
	blueberry: {
		100: '#EBF2FF',
		150: '#D7E5FD',
		175: '#C9DAFB',
		200: '#B5CBF8',
		225: '#90B6FF',
		250: '#93B0FA',
		2501: '#A1B9E9',
		255: '#6591FF',
		275: '#5685FF',
		285: '#4C67FB',
		300: '#3A4BE3',
		400: '#4643FA',
		500: '#2B1EC1',
		600: '#312EC7',
		700: '#3E3FA6',
		800: '#303181',
		850: '#333363',
		900: '#1F1F49',
	},
	orange: {
		100: '#FFF0EA',
		200: '#FFE9E5',
		300: '#FEDACB',
		400: '#FF6359',
		500: '#F26346',
		600: '#F14B24',
		650: '#EB3A00',
		700: '#BA3516',
		800: '#9A1E01',
		850: '#6B2534',
		900: '#43233A',
	},
	fountain_blue: {
		500: '#55ADD1',
		600: '#1F779C',
	},
	question_colors: {
		1: '#98BBFF',
		2: '#ACD7F6',
		3: '#FFA99D',
	},
	outline: {
		10: '#2323511A',
		20: '#23235133',
		40: '#23235166',
		64: '#232351A3',
		100: '#232351',
	},
} as const;

export default colors;
