import gql from 'graphql-tag';

export default gql`
	mutation stripeUpgrade($input: StripeUpgradeInput!) {
		stripeUpgrade(input: $input) {
			amount
			currencyCode
			nextBillingDate
			actionRequiredSecret
		}
	}
`;
