import gql from 'graphql-tag';

export default gql`
	fragment LoginPage on User {
		team {
			id
			loginPage {
				enable
				url
			}
		}
	}
`;
