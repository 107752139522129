import React, { ComponentType } from 'react';
import {
	useNavigate,
	useLocation,
	useParams,
	NavigateFunction,
	Location,
	Params,
} from 'react-router-dom';

interface WithRouterProps {
	navigate: NavigateFunction;
	location: Location;
	params: Params;
}

const withRouter = <P extends WithRouterProps>(
	Component: ComponentType<P>,
): ComponentType<Omit<P, keyof WithRouterProps>> => {
	const WrappedComponent = (props: Omit<P, keyof WithRouterProps>) => {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();

		return <Component {...(props as P)} navigate={navigate} location={location} params={params} />;
	};

	WrappedComponent.displayName = `withRouter(${Component.displayName || Component.name || 'Component'})`;

	return WrappedComponent;
};

export default withRouter;
