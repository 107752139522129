import { ButtonBase } from '~/ui/components/Button';
import styled from '@emotion/styled';
import { ButtonPlainProps } from './';
import { getThemeColor } from 'ui';
import { css } from '@emotion/react';

const buttonStyles = ({
	border,
	borderColor,
	theme,
	borderRadius,
	size,
	backgroundColor,
	padding,
	width,
}: ButtonPlainProps) => css`
	border: ${border} ${borderColor ? getThemeColor(borderColor, theme) : 'transparent'};
	border-radius: ${borderRadius}px;
	height: ${size}px;
	align-items: center;
	justify-content: center;
	background: ${getThemeColor(backgroundColor, theme)};
	padding: ${padding};
	width: ${typeof width === 'number' ? `${width}px` : width};
`;

export const ButtonStyled = styled(ButtonBase)<ButtonPlainProps>`
	display: flex;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	${({ disabled, disabledProps, ...props }) =>
		buttonStyles(disabled ? { ...props, ...disabledProps } : props)};

	&:hover {
		${({ hoverProps, disabled, ...rest }) =>
			disabled ? null : buttonStyles({ ...rest, ...hoverProps })};
	}

	&:active {
		${({ hoverProps, activeProps, disabled, ...rest }) =>
			disabled ? null : buttonStyles({ ...rest, ...hoverProps, ...activeProps })};
	}
`;
