import gql from 'graphql-tag';

export default gql`
	fragment DashboardExport on Survey {
		title
		language {
			... on SystemSurveyLanguage {
				code
			}
		}
		publicUrl
		firstResponseAt
		lastResponseAt
		totalRespondents
		responseCount
	}
`;
