import React, { FC, memo } from 'react';
import { TableProps, TableWrapper, Cell, Row, Header } from '.';

export const Table: FC<TableProps> = ({ className, columns, dataSource, size }: TableProps) => (
	<TableWrapper className={className} data-testid="ui-table">
		<table>
			<thead>
				<Header>
					{columns.map((column) => (
						<Cell
							key={column?.dataIndex}
							width={column.width}
							justifyContent={column.justifyContent}
							size={size}
							numOfColumns={columns.length}
						>
							{column?.title}
						</Cell>
					))}
				</Header>
			</thead>

			<tbody>
				{dataSource.map((row, key) => (
					<Row key={key}>
						{columns.map((column) => (
							<Cell
								key={column?.dataIndex}
								width={column.width}
								justifyContent={column.justifyContent}
								size={size}
								numOfColumns={columns.length}
							>
								{column.render ? column.render(row) : row[column?.dataIndex]}
							</Cell>
						))}
					</Row>
				))}
			</tbody>
		</table>
	</TableWrapper>
);

Table.displayName = 'Table';

Table.defaultProps = {
	size: 'xl',
};

export default memo(Table);
