import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon';

import styles from './style.less';

const Close = ({ color, onClick, className }) => (
	<div className={classNames(styles.close, styles[color], className)} onClick={onClick}>
		<Icon name="cancel-music" className={styles.icon} />
	</div>
);

Close.propTypes = {
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'default',
		'white',
		'gray',
		'red',
		'transparent',
		'primary',
		'designer',
		'grey',
	]),
	onClick: PropTypes.func,
};

Close.defaultProps = {
	className: '',
	color: 'default',
	onClick: () => null,
};

export default Close;
