import gql from 'graphql-tag';

export default gql`
	mutation readNotification($id: ID!) {
		readNotification(id: $id) {
			success
			message
		}
	}
`;
