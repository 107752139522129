import gql from 'graphql-tag';

export default gql`
	fragment SurveyPage on Survey {
		content {
			mainPages {
				id
				position
				hash
			}
		}
	}
`;
