import React, { FC, forwardRef, useRef, useEffect, memo, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { TextareaProps } from './';
import TextField from '../TextField/TextField';
import { multipleRefs, restrict, setNativeTextareaValue } from '../../utils';
import CharCounter from '../TextField/CharCounter/CharCounter';
import styled from '@emotion/styled';

export const View = styled.div`
	display: flex;
	align-items: center;
`;

export const RenderRight = styled.div`
	display: flex;
	padding: 0 16px;
`;

export const TextareaStyled = styled.textarea<{ autoSize: boolean }>`
	${({ autoSize }) =>
		autoSize
			? `
	scrollbar-width: none;
	overflow: hidden`
			: ''};
`;

const renderView = () => <View className="view" />;

export const Textarea: FC<TextareaProps> = forwardRef(({ id, ...props }: TextareaProps, ref) => {
	const textareaRef = useRef(null);
	const {
		autoSize,
		autoFocus,
		disabled,
		name,
		rows,
		value,
		onBlur,
		onFocus,
		onChange,
		editable,
		placeholder,
		withCounter,
		select,
		max,
		restriction,
		maxLength,
		renderRight,
		'data-testid': dataTestId,
		autoHeight,
		onClick,
		selectOnClick,
		height,
	} = props;

	React.useEffect(() => {
		const textarea = textareaRef.current;
		const setSize = () => {
			if (autoSize) {
				textarea.style.width = 0;
				textarea.style.whiteSpace = 'nowrap';
				textarea.style.width = `${textarea.scrollWidth + 1}px`;
				textarea.style.whiteSpace = '';
				// textarea.style.height = 'auto';
				// textarea.style.height = `${textarea.scrollHeight}px`;
			} else {
				// textarea.style.width = 'auto';
				// textarea.style.height = 'auto';
			}

			textarea.style.height = 'auto';
			textarea.style.height = height ? `${height}px` : `${textarea.scrollHeight}px`;
		};

		Object.defineProperty(textarea, 'value', {
			set: function (value) {
				setNativeTextareaValue(textarea, value);
				this.textContent = value;
				setSize();
			},
		});

		setSize();

		const resizeObserver = new ResizeObserver(() => {
			setSize();
		});

		resizeObserver.observe(textarea);

		// textarea.addEventListener('input', setSize);
		// return () => textarea.removeEventListener('input', setSize);

		if (autoSize) {
			textarea.addEventListener('input', setSize);
			return () => textarea.removeEventListener('input', setSize);
		} else {
			textarea.removeEventListener('input', setSize);
		}
	}, [autoSize, rows]);

	useEffect(() => {
		const textarea = textareaRef.current;
		const setHeight = () => {
			textarea.style.height = 'auto';
			textarea.style.height = height ? `${height}px` : `${textarea.scrollHeight}px`;
		};

		Object.defineProperty(textarea, 'value', {
			set: function (value) {
				setNativeTextareaValue(textarea, value);
				this.textContent = value;
				setHeight();
			},
		});

		setHeight();

		textarea.addEventListener('input', setHeight);
		return () => textarea.removeEventListener('input', setHeight);
	}, [autoHeight]);

	useEffect(() => {
		autoFocus && textareaRef.current.focus();
		select && textareaRef.current.select();
	}, [autoFocus, select, disabled, editable]);

	useEffect(() => {
		if (typeof value !== 'undefined') {
			setNativeTextareaValue(textareaRef.current, value);
		}
	}, [value]);

	useEffect(() => {
		const textarea = textareaRef.current;
		if (restriction) {
			const check = () => restrict(textareaRef.current, restriction);
			textareaRef.current.addEventListener('input', check);
			return () => textarea.removeEventListener('input', check);
		}
	}, [restriction]);

	const counter = withCounter ? (
		<CharCounter ref={textareaRef} max={max} value={value} className="textarea-char-counter" />
	) : null;

	const handleOnTextFieldFocus = useCallback(() => {
		selectOnClick && textareaRef.current.select();
		onFocus && onFocus();
	}, [onFocus, selectOnClick]);

	return (
		<TextField {...props} counter={counter} onClick={onClick}>
			{/* rows * textarea line-height + margin*/}
			<Scrollbars
				autoHeight
				autoHeightMax={autoSize || autoHeight || height ? '100%' : rows * 20 + 8}
				renderView={renderView}
			>
				<TextareaStyled
					name={name}
					rows={rows}
					// value={value}
					autoSize={autoSize}
					onBlur={onBlur}
					onFocus={handleOnTextFieldFocus}
					onChange={onChange}
					disabled={!editable || disabled}
					placeholder={placeholder || ' '}
					maxLength={maxLength}
					ref={multipleRefs(textareaRef, ref)}
					data-testid={dataTestId ?? 'textarea-ui'}
					id={id}
				/>
			</Scrollbars>
			{renderRight && <RenderRight className="textarea-render-right">{renderRight}</RenderRight>}
		</TextField>
	);
});

Textarea.displayName = 'Textarea';

Textarea.defaultProps = {
	rows: 1,
	size: 'md',
	width: 200,
	editable: true,
};

export default memo(Textarea);
