import gql from 'graphql-tag';

export default gql`
	mutation requestTeamSeats($input: RequestTeamSeatsInput!) {
		requestTeamSeats(input: $input) {
			success
			message
		}
	}
`;
