import styled from '@emotion/styled';
import { FilterSelectorProps, FilterChipProps } from '.';
import Typography, { TypographyProps } from '../Typography';

export const StyledSelector = styled.div<FilterSelectorProps>`
	display: flex;
	flex: 1 0 auto;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	padding: 9px ${({ filters }) => (!!filters ? 9 : 15)}px 9px 11px;
	border: 1px solid
		${({ theme, active }) => (active ? theme.colors.grey[400] : theme.colors.black[10])};
	border-radius: 28px;
	background-color: ${({ active, theme }) => (active ? theme.colors.grey[400] : 'transparent')};
	transition:
		border 200ms ease-out,
		background-color 200ms ease-out;

	&:hover {
		border: 1px solid ${({ theme }) => theme.colors.grey[400]};
		background-color: ${({ theme }) => theme.colors.grey[400]};
	}

	i {
		margin-right: 8px;
	}
`;

export const Number = styled(Typography)<TypographyProps | FilterSelectorProps>`
	border-radius: 10px;
	background-color: ${({ theme }) => theme.colors.active_blue[500]};
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
`;

export const Chip = styled.div<FilterChipProps>`
	display: inline-flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.grey[300]};
	padding: 12px 16px 12px 12px;
	border-radius: 26px;
	max-width: 264px;

	.crossButton {
		margin-right: 8px;
	}

	.typo {
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const ChipWrapper = styled.div<FilterChipProps>`
	display: inline-flex;
`;
