import styled from '@emotion/styled';
import { PaginationProps } from './';

export const PaginationWrapper = styled.div<Partial<PaginationProps>>`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	${({ theme }) => theme.typography.textSemibold250}
`;

export const Page = styled.div<
	Partial<PaginationProps> & { disabled?: boolean; onClick?: () => void }
>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.grey_shades_with_blue[200] : theme.colors.grey_shades_with_blue[600]};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

	&:not(:last-child) {
		margin-right: 8px;
	}

	&.active {
		color: ${({ theme }) => theme.colors.secondary[600]};
		transition: all 200ms ease-out;
		background-color: ${({ theme }) => theme.colors.secondary[200]};
		cursor: default;
	}

	&:hover {
		&::before {
			background-color: ${({ theme, onClick }) =>
				onClick ? theme.colors.grey[300] : 'transparent'};
		}
	}

	&::before {
		transition: background-color 200ms ease-out;
		z-index: -1;
		border-radius: 20px;
		position: absolute;
		content: ' ';
		width: 40px;
		height: 40px;
	}
`;
