import React, { FC, memo, useMemo } from 'react';
import { LSContentRightStyled, LSContentRightProps } from '.';
import { LogicMap } from '~/ui/components/LogicSelect/constants';

const LSContentRight: FC<LSContentRightProps> = ({
	selected,
	options,
	closeSelect,
	choiceId,
	setChoiceLogic,
	logicValue,
}) => {
	const Content = useMemo(() => LogicMap[selected], [selected]);

	return (
		<LSContentRightStyled>
			<Content
				options={options}
				closeSelect={closeSelect}
				choiceId={choiceId}
				setChoiceLogic={setChoiceLogic}
				logicValue={logicValue}
			/>
		</LSContentRightStyled>
	);
};

export default memo(LSContentRight);
