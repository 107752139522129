import React, { FC, memo, useCallback, MouseEvent } from 'react';
import {
	LogicSelectButtonStyled,
	LogicSelectButtonProps,
	FlexGroup,
	FlexGroupLeft,
	QuestionNumberStyled,
} from '.';
import { Icon, Typography, Button, Tooltip } from 'ui';
import { LOGIC_OPTION_TYPES } from '~/pages/Builder23_1/constants/constants';
import { useIntl } from 'react-intl';

const LogicSelectButton: FC<LogicSelectButtonProps> = ({ onClick, isOpen, value, onRemove }) => {
	const { formatMessage } = useIntl();
	const handleOnRemoveClick = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			onRemove();
			isOpen && onClick();
		},
		[isOpen, onClick, onRemove],
	);

	const getIcon = useCallback(() => {
		switch (value?.value) {
			case LOGIC_OPTION_TYPES.TO_QUESTION:
				return value?.number;
			case LOGIC_OPTION_TYPES.TO_PAGE:
				return <Icon name="page-thin-16" />;
			case LOGIC_OPTION_TYPES.TO_URL:
				return <Icon name="link-thin-16" />;
			case LOGIC_OPTION_TYPES.TO_SUBMIT:
				return <Icon name="enter-16" />;
			default:
				return null;
		}
	}, [value?.number, value?.value]);

	return (
		<LogicSelectButtonStyled isOpen={isOpen} onClick={onClick}>
			<FlexGroupLeft>
				{value ? <QuestionNumberStyled>{getIcon()}</QuestionNumberStyled> : null}
				<Typography
					variant="textRegular300_24"
					color="grey_shades_with_blue[800]"
					className="choiceText"
				>
					{value?.text || formatMessage({ id: 'app.common.select' })}
				</Typography>
			</FlexGroupLeft>
			<FlexGroup>
				{value ? (
					<Tooltip
						description
						placement="top"
						offset={[0, 16]}
						content={formatMessage({ id: 'app.designer.modal.remove-skip' })}
					>
						<Button variant="icon" startIcon="delete-16" onClick={handleOnRemoveClick} size="sm" />
					</Tooltip>
				) : null}
				<Icon name="chevrondown-16" color="grey_shades_with_blue[350]" className="chevron" />
			</FlexGroup>
		</LogicSelectButtonStyled>
	);
};

export default memo(LogicSelectButton);
