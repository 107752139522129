import styled from '@emotion/styled';
import { BubbleProps } from './index';
import { BUBBLE_DELAY } from '~/ui/components/Bubble/Bubble';

export const BubbleContent = styled.div<BubbleProps>`
	${({ theme }) => theme.typography.textSemibold300};
	color: ${({ theme, variant }) =>
		variant === 'dark' ? theme.colors.white : theme.colors.black[90]};
	display: flex;
	align-items: center;
	border-radius: ${({ borderRadius }) => borderRadius}px;
	min-height: 12px;
	min-width: 12px;
	background-color: ${({ theme, variant }) =>
		variant === 'dark' ? theme.colors.grey_shades_with_blue[700] : theme.colors.white};
	filter: ${({ withShadow }) =>
		withShadow ? 'drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.24))' : 'none'};
	z-index: 20;
	transition:
		visibility ${BUBBLE_DELAY}ms ease-out,
		opacity ${BUBBLE_DELAY}ms ease-out,
		transform ${BUBBLE_DELAY}ms;
	// IE11 fix
	position: relative;

	// workaround for hiding arrow shadow on the edge

	.bubble-arrow {
		.arrow-transform {
			overflow: hidden;
		}
	}

	// animation of visible/hidden state of tooltip

	&[data-animation='fade'][data-state='false'] {
		transform: translateY(-8px);
		visibility: hidden;
		opacity: 0;
	}

	&[data-animation='fade'][data-state='true'] {
		transform: translateY(0);
		visibility: visible;
		// Workaround - when 1 then flickering
		opacity: 0.99;
	}

	// arrow positioning and rotation

	&[data-placement^='right'] {
		.bubble-arrow {
			left: -23px;

			.arrow-transform {
				transform: rotate(90deg);
			}
		}
	}

	&[data-placement^='left'] {
		.bubble-arrow {
			right: -23px;

			.arrow-transform {
				transform: rotate(-90deg);
			}
		}
	}

	&[data-placement^='top'] {
		.bubble-arrow {
			bottom: -12px;
		}
	}

	&[data-placement^='bottom'] {
		.bubble-arrow {
			top: -12px;

			.arrow-transform {
				transform: rotate(180deg);
			}
		}
	}

	// arrow color

	path {
		fill: ${({ theme, variant }) =>
			variant === 'dark' ? theme.colors.grey_shades_with_blue[700] : theme.colors.white};
	}
`;

export const BubbleChildren = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;
