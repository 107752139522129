import gql from 'graphql-tag';

export default gql`
	query responsesToBeRemoved($uid: String!, $first: Int) {
		survey(uid: $uid) {
			uid
			responseConnection(first: $first) {
				toBeRemovedCount
			}
		}
	}
`;
