import gql from 'graphql-tag';

export default gql`
	fragment FilloutStatusStatsSummary on Survey {
		filloutStatusStats {
			breakdown {
				value
				count
			}
		}
	}
`;
