export { PAGES, PAGES_PATH } from './pages';
export HEADER from './header';
export SETTINGS from './settings';
export TIMEZONES from './timezones';
export DURATIONS from './durations';
export COMPLETENESS from './completeness';
export SOURCES from './sources';
export ROLES from './roles';
export { PLANS, PRO_PLANS, BASIC_PLANS, PLANS_NEW, PLANS_24 } from './plans';
export { EXPERIMENTS, EXPERIMENT_STATE, AB_BM_VARIANT } from './experiments';
export { SERVER_LOCATION } from './serverLocation';
export RELEVANT_COUNTRIES from './relevantCountries';
export FREE_MAIL_DOMAINS from './freeMailDomains';
export STRATEGY from './responseLimitStrategy';
export NOTIFICATIONS from './notifications';
export UPGRADE_MODAL_TYPES from './upgradeModalStripeTypes';
export PERIOD from './period';
export { EXPIRATION_WARNING_LIMIT } from './expirationLimits';
export LOCATIONS, { UTM_PREFIX } from './locations';
export EVENTS from './events';
export { QUESTION_TYPES } from './questionTypes';
export { LOGIN_URL_SUFFIX } from './loginUrlSuffix';
export { TOOLTIP_BUILDER_WP } from './tours';

// SSR fix
const { env } = typeof window === 'undefined' ? { env: {} } : window;
export const API = `https://${env?.API}`;
export const WS = `wss://${env?.API}`;
export const AUTH = env?.AUTH;
