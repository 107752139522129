import React, { FC, memo } from 'react';
import { Row, HeadColumn, Options, HeadCheckbox, HeadControl } from './List.styled';
import Checkbox from '../Checkbox';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import { HeadProps } from './';

export const Head: FC<HeadProps> = ({
	columns,
	padding,
	checkable,
	checkedAll,
	onCheckAll,
	checked,
	headControl,
}: HeadProps) => (
	<Row head={true}>
		{checkable && (
			<HeadColumn width={56} textAlign="left" padding={padding}>
				<Options padding={padding} checked={checked}>
					<HeadCheckbox>
						<Tooltip
							content={!checked ? 'Select all' : 'Deselect all'}
							placement="top"
							maxWidth="100%"
						>
							<Checkbox checked={checked} onChange={onCheckAll} deselect={!checkedAll && checked} />
						</Tooltip>
					</HeadCheckbox>
					<HeadControl checked={checked}>
						{typeof headControl == 'function' && headControl()}
					</HeadControl>
				</Options>
			</HeadColumn>
		)}
		{columns.map((column) => (
			<HeadColumn
				key={column?.dataIndex}
				width={column.width}
				textAlign={column.textAlign}
				padding={padding}
			>
				<Typography
					variant="textSemibold200"
					color={column.color || 'grey_shades_with_blue[700]'}
					className="typo"
				>
					{column?.title}
				</Typography>
			</HeadColumn>
		))}
	</Row>
);

Head.displayName = 'List';

export default memo(Head);
