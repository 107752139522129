import gql from 'graphql-tag';

export default gql`
	mutation setDashboardReport($input: SetDashboardReportInput!) {
		setDashboardReport(input: $input) {
			enabled
			to
			when
			repeat
		}
	}
`;
