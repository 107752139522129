import { graphql } from '@apollo/client/react/hoc';
import { getMainDefinition } from 'apollo-utilities';
import { v4 as uuid } from 'uuid';
import getQuery from '~/cache/query/get';
import getFragment from '~/cache/fragments/get';

const importMutation = (type) => {
	try {
		return require(`./mutations/${type}`).default;
	} catch (err) {
		console.error(`Undefined mutation type: ${type}.`, err);
		return null;
	}
};
const Mutation = (mutation_name = '__NOT_DEFINED_TYPE__', options = {}) => {
	let { propName, optimisticResponse, update, refetchQueries, ...mutateOptions } = options;
	const mutation = importMutation(mutation_name);
	const definition = getMainDefinition(mutation);
	const definitionName = definition.name.value;
	const prop = propName || definitionName;
	const id = uuid();
	return graphql(mutation, {
		props: ({ mutate, ownProps }) => ({
			[prop]: async (variables = {}, _options = {}) => {
				const { debounce, ..._mutateOptions } = _options;
				mutateOptions = mutateOptions || {};
				if (variables) mutateOptions.variables = variables;
				if (debounce || options.debounce)
					mutateOptions.context = {
						debounceKey: `${prop}:${id}`,
						debounceTimeout: debounce || options.debounce,
					};
				if (typeof update === 'function')
					mutateOptions.update = (cache, { data }) =>
						update({
							cache,
							data: data[definitionName] || data,
							ownProps,
							getQuery,
							getFragment,
							variables,
						});
				if (typeof refetchQueries === 'function') {
					mutateOptions.refetchQueries = ({ data }) =>
						refetchQueries({
							data,
							ownProps,
							getQuery,
						});
				} else {
					mutateOptions.refetchQueries = refetchQueries;
				}
				if (optimisticResponse)
					mutateOptions.optimisticResponse =
						typeof optimisticResponse === 'function'
							? optimisticResponse(variables)
							: optimisticResponse;
				const result = await mutate({
					...mutateOptions,
					..._mutateOptions,
				});
				return result && result.data ? result.data[definition] || result.data : null;
			},
		}),
	});
};
export default Mutation;
