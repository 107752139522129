import gql from 'graphql-tag';

const query = (children, fragments) => gql`
		query user($id: Int, $input: ActivityConnectionInput) {
			user(id: $id) {
			 ${children}
			}
		}
		${fragments}
	`;

export default query;
