const normalizeTranslate = (json) => {
	if (!json) return {};

	return JSON.parse(
		JSON.stringify(json).replace(/{{|}}/g, (match) => {
			switch (match) {
				case '{{':
					return '{';
				case '}}':
					return '}';
			}
		}),
	);
};

export default normalizeTranslate;
