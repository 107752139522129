import gql from 'graphql-tag';

export default gql`
	mutation setTeamLanguage($language: LanguageCode!) {
		setTeamLanguage(language: $language) {
			success
			message
		}
	}
`;
