import styled from '@emotion/styled';
import { CharCounterProps } from './';

export const StyledCharCounter = styled.div<CharCounterProps & { count: number }>`
	${({ theme }) => theme.typography.textRegular200};
	color: ${({ theme }) => theme.colors.black[50]};
	margin-right: ${({ size }) => (size === 'xs' || size === 'smPhoneInput' ? 10 : 16)}px;

	.counter {
		color: ${({ theme, count, max }) =>
			count === 0
				? `${theme.colors.black[50]} !important`
				: count > (typeof max === 'number' ? max : parseInt(max))
					? `${theme.colors.red[600]} !important`
					: 'inherit'};
	}
`;
