import React, { FC, memo } from 'react';
import { MoreToneIconProps, StyledMoreToneIcon } from './';
import { ReactSVG } from 'react-svg';

export const MoreToneIcon: FC<MoreToneIconProps> = ({
	name,
	active,
	...props
}: MoreToneIconProps) => {
	let svgImage: string;
	try {
		svgImage = require(`./assets/${name}.svg`);
	} catch (e) {
		console.error(e.message);
		return null;
	}

	return (
		<StyledMoreToneIcon active={active}>
			<ReactSVG src={svgImage} {...props} />
		</StyledMoreToneIcon>
	);
};

export default memo(MoreToneIcon) as FC<MoreToneIconProps>;
