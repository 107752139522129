import gql from 'graphql-tag';

export default gql`
	mutation addAnswerFilter($question: ID!, $answers: [AnswerFilterInput!]!) {
		addAnswerFilter(question: $question, answers: $answers) {
			id
			questionId
			questionOrdinal
			answers {
				answer
				answerOption
				customAnswer
				answerTextValue
			}
		}
	}
`;
