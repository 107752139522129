import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TweenLite } from 'gsap';
// import 'gsap/src/uncompressed/plugins/CSSPlugin';

import CarouselContext from './CarouselContext';

import styles from './styles.less';

class Carousel extends React.PureComponent {
	static propTypes = {
		animation: PropTypes.oneOfType([
			PropTypes.shape({ prev: PropTypes.func, next: PropTypes.func }),
			PropTypes.oneOf(['slideHorizontal', 'slideVertical', 'fade']),
		]),
		animationDuration: PropTypes.number,
		className: PropTypes.shape({
			container: PropTypes.string,
			frame: PropTypes.string,
		}),
		controls: PropTypes.func,
		style: PropTypes.object,
	};

	static defaultProps = {
		animation: 'slideHorizontal',
		animationDuration: 0.3,
		className: {
			container: '',
			frame: '',
		},
	};

	static getDerivedStateFromProps({ contentKey, contents }, prevState) {
		if (contentKey !== prevState.contentKey) {
			const contentsKeys = Object.keys(contents || {});
			const directionTo = prevState.contentKey
				? contentsKeys.indexOf(contentKey) > contentsKeys.indexOf(prevState.contentKey)
					? 'next'
					: 'prev'
				: undefined;
			return { directionTo, contentKey, prevContentKey: prevState.contentKey };
		} else return null;
	}

	constructor(props) {
		super(props);

		this.state = {};
		this.containerRef = React.createRef();
		this.animations = this.getAnimations(props.animation);
	}

	componentDidUpdate({ animation, contentKey }) {
		if (this.props.animation !== animation) {
			this.animations = this.getAnimations(this.props.animation);
		}
	}

	getAnimations = (animation) => {
		if (typeof animation === 'object') {
			return animation;
		}

		switch (animation) {
			case 'slideHorizontal':
				return {
					prev: this.slideRight,
					next: this.slideLeft,
				};
			case 'slideVertical':
				return {
					prev: this.slideDown,
					next: this.slideUp,
				};
			case 'fade':
				return {
					prev: this.fade,
					next: this.fade,
				};
		}
	};

	handleSlide = (data, key) => {
		data && this.props.onSlide(data);
		this.props.slide(key);
	};

	/**
	 * ANIMATIONS
	 */

	slideLeft = (prevSlide, slide, onComplete) => {
		prevSlide &&
			TweenLite.fromTo(
				prevSlide,
				this.props.animationDuration,
				{ left: '0%', opacity: 1 },
				{ left: '-100%', opacity: 0 },
			);
		slide &&
			TweenLite.fromTo(
				slide,
				this.props.animationDuration,
				{ left: '100%', opacity: 0 },
				{ left: '0%', opacity: 1, onComplete },
			);
	};

	slideRight = (prevSlide, slide, onComplete) => {
		prevSlide &&
			TweenLite.fromTo(
				prevSlide,
				this.props.animationDuration,
				{ left: '0%', opacity: 1 },
				{ left: '100%', opacity: 0 },
			);
		slide &&
			TweenLite.fromTo(
				slide,
				this.props.animationDuration,
				{ left: '-100%', opacity: 0 },
				{ left: '0%', opacity: 1, onComplete },
			);
	};

	slideUp = (prevSlide, slide, onComplete) => {
		prevSlide &&
			TweenLite.fromTo(
				prevSlide,
				this.props.animationDuration,
				{ top: '0%', opacity: 1 },
				{ top: '-100%', opacity: 0 },
			);
		slide &&
			TweenLite.fromTo(
				slide,
				this.props.animationDuration,
				{ top: '100%', opacity: 0 },
				{ top: '0%', opacity: 1, onComplete },
			);
	};

	slideDown = (prevSlide, slide, onComplete) => {
		prevSlide &&
			TweenLite.fromTo(
				prevSlide,
				this.props.animationDuration,
				{ top: '0%', opacity: 1 },
				{ top: '100%', opacity: 0 },
			);
		slide &&
			TweenLite.fromTo(
				slide,
				this.props.animationDuration,
				{ top: '-100%', opacity: 0 },
				{ top: '0%', opacity: 1, onComplete },
			);
	};

	fade = (prevSlide, slide, onComplete) => {
		prevSlide &&
			TweenLite.fromTo(prevSlide, this.props.animationDuration, { opacity: 1 }, { opacity: 0 });
		slide &&
			TweenLite.fromTo(
				slide,
				this.props.animationDuration,
				{ opacity: 0 },
				{ opacity: 1, onComplete },
			);
	};

	createSlide = (contents, key, childrenProps) =>
		React.cloneElement(contents[key], {
			...childrenProps,
			key,
			next: (key, data) => () => this.handleSlide(data, key),
			prev: (key, data) => () => this.handleSlide(data, key),
			prevKey: this.state.prevContentKey,
		});

	render() {
		const { contents, contentKey, childrenProps, className, style, id } = this.props;

		// if (contents && contentKey && (!this.slide || contentKey !== this.slide.key)) {
		// 	this.slide = this.createSlide(contents, contentKey, childrenProps);
		// }

		if (contents && contentKey) {
			this.slide = this.createSlide(contents, contentKey, childrenProps);
		}

		return (
			contentKey && (
				<CarouselContext.Provider
					value={{
						next: (key, data) => () => this.handleSlide(data, key),
						prev: (key, data) => () => this.handleSlide(data, key),
						prevKey: this.state.prevContentKey,
					}}
				>
					{this.props.header &&
						this.props.header({
							next: (key, data) => () => this.handleSlide(data, key),
							prev: (key, data) => () => this.handleSlide(data, key),
							prevKey: this.state.prevContentKey,
						})}

					<div
						ref={this.containerRef}
						className={classNames(styles.container, className.container)}
						style={style}
						id={id}
					>
						<TransitionGroup component={null}>
							{Object.keys(contents)
								.filter((key) => key === contentKey)
								.map((key) => (
									<Transition
										timeout={this.props.animationDuration * 1000}
										style={{ display: 'flex', position: 'relative' }}
										appear={!!this.state.directionTo}
										key={key}
										onEnter={(node) => this.animations[this.state.directionTo](null, node)}
										onExit={(node) => {
											TweenLite.set(node, {
												position: 'absolute',
												top: 0,
												left: 0,
												height: '100%',
											});
											this.animations[this.state.directionTo](node, null);
										}}
									>
										<div className={styles.slide}>{this.slide}</div>
									</Transition>
								))}
						</TransitionGroup>
					</div>
				</CarouselContext.Provider>
			)
		);
	}
}

export default Carousel;
