import gql from 'graphql-tag';

export default gql`
	mutation moveSurveyToWorkspace($surveyId: ID!, $workspaceId: ID) {
		moveSurveyToWorkspace(surveyId: $surveyId, workspaceId: $workspaceId) {
			success
			message
		}
	}
`;
