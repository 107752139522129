import React, {
	FC,
	Fragment,
	memo,
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { Power1, TweenLite } from 'gsap';
import {
	Navigation,
	Items,
	SlideBar,
	Slider,
	Underline,
	HorizontalNavigationItem,
	HorizontalNavigationProps,
	ItemContent,
	ItemWrapper,
} from '.';
import { Link } from 'react-router-dom';

export const HorizontalNavigation: FC<HorizontalNavigationProps> = ({
	className,
	items,
	value,
	noUnderline,
	id,
	icon,
}: HorizontalNavigationProps) => {
	const [active, setActive] = useState(value === undefined ? 0 : value);
	const uniqueId = useRef(`horizontalNavi_${Math.random()}`);
	const ref = useRef(null);

	const setAndMove = useCallback((value, target) => {
		setActive(value);
		TweenLite.to(ref?.current, 0.5, {
			ease: Power1.easeOut,
			left: target.offsetLeft,
			width: target.offsetWidth,
		});
	}, []);

	useEffect(() => {
		if (value) {
			setAndMove(value, document.getElementById(`${uniqueId.current}_${value}`));
		}
	}, [setAndMove, value]);

	useLayoutEffect(() => {
		const slider = ref.current;
		const updateSize = () => {
			// ? bez setTimeout - ted je tady, aby se stihl donacist ref ?
			setTimeout(() => {
				slider.style.width = `${document.getElementById(`${uniqueId.current}_${active}`).offsetWidth}px`;
				slider.style.left = `${document.getElementById(`${uniqueId.current}_${active}`).offsetLeft}px`;
			}, 1);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, [active]);

	const linkWrap = useCallback(
		(index, path, id, component) =>
			path ? (
				<Link to={path} key={`${index}_${path}`} id={id}>
					{component}
				</Link>
			) : (
				component
			),
		[],
	);

	const handleOnClick = useCallback(
		(e, index, disabled) => {
			if (!disabled) {
				setAndMove(index, e.target);
			}
		},
		[setAndMove],
	);

	return (
		<>
			<Navigation className={className} id={id}>
				<SlideBar />
				<Items icon={icon}>
					{items.map((item, index) => (
						<Fragment key={index}>
							{linkWrap(
								index,
								item.path,
								item.id,
								<ItemWrapper>
									<HorizontalNavigationItem
										variant="textSemibold300"
										className={index === active ? 'active' : undefined}
										key={index}
										onClick={(e) => handleOnClick(e, index, item.disabled)}
										id={`${uniqueId.current}_${index}`}
										active={index === active}
										disabled={item.disabled}
										icon={icon}
									>
										{item.label}
									</HorizontalNavigationItem>
								</ItemWrapper>,
							)}
							{index < items.length - 1 ? icon : null}
						</Fragment>
					))}
				</Items>
				<SlideBar>
					<Slider ref={ref} />
					<Underline noUnderline={noUnderline} />
				</SlideBar>
			</Navigation>
			{items.map((i, index) =>
				i.children ? (
					<ItemContent active={index === active} key={index}>
						{i.children}
					</ItemContent>
				) : null,
			)}
		</>
	);
};

HorizontalNavigation.defaultProps = {
	icon: null,
};

const HorizontalNaviExp = memo(HorizontalNavigation);

export { HorizontalNaviExp };
