import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Bubble, Icon } from 'ui';
import cn from 'classnames';
import { withSendTracking } from '~/hoc';
import Loader from '~/components/Loader';
import query from '~/cache/query';
import { UNSUPPORTED_L } from '~/components/InApps';
import s from './style.less';

const InfoTooltip = ({ name, className, language, sendTracking }) => {
	const [loading, setLoading] = useState(true);
	const selectedLanguages = UNSUPPORTED_L.includes(language) ? 'en' : language;

	const handleOnHidden = useCallback(() => {
		if (!loading) {
			setLoading(true);
			sendTracking(`${name}_hover`, `${name}_hover`);
		}
	}, [loading, name, sendTracking]);

	const handleOnIframeLoad = useCallback(() => {
		setTimeout(() => setLoading(false));
	}, []);

	const content = (
		<>
			<Loader visible={loading} bgColor="transparent" />
			<iframe
				className={cn(loading && s.hidden, s.iframe)}
				width="100%"
				height="114%" //too large padding in iframe
				frameBorder="0"
				src={`${window.env.WEBSITE_URL}/${selectedLanguages}/inapp?msg_id=${name}`}
				onLoad={handleOnIframeLoad}
			></iframe>
		</>
	);

	const handleOnClick = useCallback(() => {
		sendTracking(`${name}_click`, `${name}_click`);
	}, [name, sendTracking]);

	return name ? (
		<Bubble
			content={content}
			placement="bottom"
			trigger="mouseenter"
			popperOptions={{
				strategy: 'fixed',
			}}
			withArrow={false}
			contentClassName={s.tooltipContent}
			className={s.bubble}
			delay={[300, 0]}
			onHidden={handleOnHidden}
		>
			<Icon
				color="grey_shades_with_blue[250]"
				name="help-16"
				className={cn(className, name)}
				onClick={handleOnClick}
			/>
		</Bubble>
	) : null;
};

InfoTooltip.propTypes = {
	className: PropTypes.string,
	language: PropTypes.string,
	name: PropTypes.string,
	sendTracking: PropTypes.func,
};

export default compose(
	memo,
	query('account', {
		fragments: ['Language'],
		mapProps: ({ user }) => ({ language: user?.language }),
	}),
	withSendTracking,
)(InfoTooltip);
