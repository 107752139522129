import gql from 'graphql-tag';

export default gql`
	fragment UserBubbleData on User {
		fullName
		email
		activeService {
			autorenewal
			name
			period
			responseLimit
			isTrial
			activeTill
		}
		submittedSurveysThisMonth
	}
`;
