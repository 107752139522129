import gql from 'graphql-tag';

export default gql`
	mutation updateSurveySharingMetadata($input: SurveySharingMetadataInput) {
		updateSurveySharingMetadata(input: $input) {
			image
			title
			description
		}
	}
`;
