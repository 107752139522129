import gql from 'graphql-tag';

export default gql`
	query responsePage($uid: String!, $perPage: Int!) {
		responsePage(uid: $uid, perPage: $perPage) {
			totalCount
			overLimitCount
		}
	}
`;
