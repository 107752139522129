import styled from '@emotion/styled';

export type AddBarTooltipPictureIds =
	| 'single_choice'
	| 'multiple_choice'
	| 'image_choice'
	| 'star_rating'
	| 'text_answer'
	| 'email_answer'
	| 'number_answer'
	| 'date_answer'
	| 'nps'
	| 'matrix'
	| 'form'
	| 'ordering'
	| 'dropdown'
	| 'semantic_differential'
	| 'rating_scale'
	| 'custom_text';

const getPicture = (pictureId: AddBarTooltipPictureIds): string => {
	const req = require(`./assets/${pictureId}.png`);
	return req.default ?? req;
};

export const StyledTooltipContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 224px;
	border-radius: 16px;
	padding: 16px;
	${({ theme }) => theme.shadows[400]};
	border: ${({ theme }) => `1px solid ${theme.colors.grey_shades_with_blue[50]}`};
`;

export const StyledPicture = styled.div<{ picture: AddBarTooltipPictureIds }>`
	width: 192px;
	height: 120px;
	border-radius: 6px;
	background: ${({ picture }) => `url("${getPicture(picture)}") no-repeat center / contain`};
`;

export const StyledTitle = styled.div`
	padding: 16px 0 4px 0;
	${({ theme }) => theme.typography.textSemibold250};
	color: ${({ theme }) => theme.colors.black[100]};
`;

export const StyledDescription = styled.div`
	${({ theme }) => theme.typography.textRegular250};
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[500]};
	text-align: left;
`;
