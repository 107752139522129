import React, { FC, memo } from 'react';
import { LabelProps, StyledLabel, StyledErrorMsg, StyledLabelContainer } from './';

export const Label: FC<LabelProps> = ({ children, className, error, ...props }: LabelProps) =>
	children ? (
		<StyledLabelContainer className={className} data-testid="ui-label">
			<StyledLabel className="label" {...props}>
				{children}
			</StyledLabel>
			<StyledErrorMsg className="error" error={error}>
				{error}
			</StyledErrorMsg>
		</StyledLabelContainer>
	) : null;

Label.defaultProps = {
	colorVariant: 'greyShadesWithBlue',
	labelTypo: 'textMedium300',
};

export default memo(Label);
