import gql from 'graphql-tag';

export default gql`
	fragment invitation on Survey {
		invitation(id: $id) {
			recipientOffsetConnection(
				perPage: $perPage
				page: $page
				withStatusEither: $withStatusEither
			) {
				totalCount
				edges {
					node {
						deliveryStatus
						email
						submitDateTime
						source
						sourceDateTime
					}
				}
			}
		}
	}
`;
