import styled from '@emotion/styled';
import { TdProps } from './';

export const TableWrapper = styled.div`
	width: 100%;
	table {
		width: 100%;
	}
`;

export const Th = styled.th<TdProps>`
	padding: ${({ padding }) => !padding && '4px 24px 16px 24px'};
	${(props) => `text-align: ${props?.textAlign || 'left'}`};
	${(props) => props?.width && `width: ${props?.width}px;`}

	&:first-of-type {
		padding-left: 24px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	&:last-of-type {
		${(props) => `text-align: ${props?.textAlign || 'right'}`};
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
`;

export const Td = styled.td<TdProps>`
	padding: ${({ padding }) => (padding ? `${padding} ${padding.split(' ')[0]} 0` : '20px 24px')};
	${(props) => `text-align: ${props?.textAlign || 'left'}`};
	${(props) => props?.width && `width: ${props?.width}px;`};
	vertical-align: middle;

	&:first-of-type {
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		${({ padding }) => padding && `padding-left: ${padding.split(' ')[1]}`};
	}

	&:last-of-type {
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}
`;

export const Tr = styled.tr<TdProps & { onClick: () => void }>`
	transition: background-color 200ms ease-out;
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
	${({ active, theme }) => active && `background-color: ${theme.colors.primary[150]}`};
	&:nth-of-type(odd) {
		${({ striped, theme }) => striped && `background-color: ${theme.colors.grey[200]}`};
	}
	&:hover {
		${({ striped, theme, active }) =>
			active ? '' : `background-color: ${theme.colors.grey_shades_with_blue[striped ? 50 : 25]}`};
	}
`;
