import React, { Fragment, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Spacer, Typography } from '@survio/ui';
import s from './UpgradeSlideWithQuotes/style.less';
import { BUSINESS_VARIANT, PLANS_23 } from '~/constants/plans';
import { PERIOD } from '~/constants';

const ChooseUserQuantity = ({
	register,
	intl,
	activePro,
	stripePriceList,
	currencyCode,
	stripePricingPlan: stripePricingPlanProp,
	selectedPeriod,
}) => {
	const split = PLANS_23.BUSINESS_1.split('1');
	const { current: stripePricingPlan } = useRef(stripePricingPlanProp);

	return (
		<div>
			<Typography variant="textSemibold300" color="black[100]" className={s.sectionTitle}>
				{intl.formatMessage({ id: 'app.upgrade.select-number-of-seats' })}
			</Typography>
			{BUSINESS_VARIANT.filter((v) => parseInt(v) >= parseInt(activePro)).map((variant) => {
				const price =
					(stripePriceList.plans.find((p) => p.identifier === `${split[0]}${variant}${split[1]}`)?.[
						selectedPeriod === PERIOD.YEARLY ? 'yearlyAmount' : 'monthlyAmount'
					] -
						stripePricingPlan[
							selectedPeriod === PERIOD.YEARLY ? 'yearlyAmount' : 'monthlyAmount'
						]) /
					(selectedPeriod === PERIOD.YEARLY ? 12 : 1);

				return (
					<Fragment key={variant}>
						<RadioButton
							withBox
							{...register('business_variant')}
							className={s.radio}
							value={variant}
							key={variant}
						>
							{variant}
							<Spacer width={8} />
							<Spacer />
							<Typography variant="textMedium300">
								{price >= 0 &&
									`+ ${intl.formatNumber(price, {
										currency: currencyCode,
										style: 'currency',
										minimumFractionDigits: 0,
										maximumFractionDigits: 0,
									})} / ${intl.formatMessage({
										id: 'app.account.month',
									})}`}
							</Typography>
						</RadioButton>
						<Spacer height={2} />
					</Fragment>
				);
			})}
		</div>
	);
};

ChooseUserQuantity.propTypes = {
	intl: PropTypes.object,
	register: PropTypes.object,
	activePro: PropTypes.string,
	stripePriceList: PropTypes.object,
	currencyCode: PropTypes.string,
	stripePricingPlan: PropTypes.object,
	selectedPeriod: PropTypes.string,
};

export default memo(ChooseUserQuantity);
