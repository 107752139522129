import { setNativeInputValue, setNativeTextareaValue } from './';

const restrict = (
	field: HTMLInputElement | HTMLTextAreaElement,
	restriction: RegExp | [RegExp, string],
): void => {
	let c = field.selectionStart;
	const v = field.value;
	const searchValue = Array.isArray(restriction) ? restriction[0] : restriction;
	const replaceValue = Array.isArray(restriction) ? restriction[1] : '';
	if (searchValue.test(v)) {
		const newVal = field.value.replace(searchValue, replaceValue);
		field.type === 'textarea'
			? setNativeTextareaValue(field as HTMLTextAreaElement, newVal)
			: setNativeInputValue(field as HTMLInputElement, newVal);
		replaceValue.length === 0 && c--;
	}
	field.setSelectionRange(c, c);
};

export default restrict;
