import gql from 'graphql-tag';

export default gql`
	fragment QuestionsFilterModal on Survey {
		questions {
			... on QuestionInterface {
				id
				text
				number
			}
			... on ChoiceQuestion {
				hasPictureChoices
				isMultipleAnswer
				isQuizItem
				choices {
					id
					text
					open
					quizPoints
					picture {
						url
					}
				}
			}
			... on MatrixQuestion {
				type
				cols {
					id
					text
					ordinal
				}
				rows {
					id
					text
					ordinal
				}
			}
			... on RatingQuestion {
				pointCount
			}
			... on SemanticDifferentialQuestion {
				pointCount
				rows {
					id
					leftLabel
					rightLabel
					ordinal
				}
			}
			... on TextQuestion {
				subtype: type
			}
		}
	}
`;
