import ActionTypes from '../constants/actionTypes';

export const openModal = (contents, key, data = {}, overlayClassName) => ({
	type: ActionTypes.OPEN_MODAL,
	payload: {
		contents,
		data,
		key,
		overlayClassName,
	},
});

export const closeModal = () => ({
	type: ActionTypes.CLOSE_MODAL,
});

export const setModalData = (data) => ({
	type: ActionTypes.SET_MODAL_DATA,
	payload: data,
});

export const slideModal = (key) => ({
	type: ActionTypes.SLIDE_MODAL,
	payload: key,
});
