import getQuery from '~/cache/query/get';

export default {
	defaults: {
		designer: {
			isOpen: false,
			tab: 'default',
			__typename: 'Designer',
		},
	},
	resolvers: {
		Mutation: {
			/** show / hide designer **/
			designerToggle: (_, { isOpen }, { cache }) => {
				cache.writeQuery({
					query: getQuery('designer/client', ['IsOpen']),
					data: {
						designer: {
							isOpen,
							__typename: 'Designer',
						},
					},
				});
			},
			/** change view of designer (default - list of themes / edit - customize selected theme) **/
			designerSetTab: (_, { tab }, { cache }) => {
				cache.writeQuery({
					query: getQuery('designer/client', ['Tab']),
					data: {
						designer: {
							tab,
							__typename: 'Designer',
						},
					},
				});
			},
		},
	},
};
