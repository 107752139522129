import gql from 'graphql-tag';

import RESPONSE_FRAGMENT from '~/cache/fragments/results/response';

export default gql`
	query responsePage(
		$uid: String!
		$perPage: Int!
		$page: Int
		$onlyUntilId: ID
		$submitted: SortDirection
	) {
		responsePage(
			uid: $uid
			perPage: $perPage
			page: $page
			onlyUntilId: $onlyUntilId
			sorting: { submitted: $submitted }
		) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			totalCount
			edges {
				node {
					...Response
				}
				cursor
			}
		}
	}
	${RESPONSE_FRAGMENT}
`;
