import gql from 'graphql-tag';

export default gql`
	fragment dropBoxAccounts on User {
		integrations {
			dropBox {
				id
				name
			}
		}
	}
`;
