import gql from 'graphql-tag';

export default gql`
	mutation sendLoginChangeCode($password: String!, $email: String) {
		sendLoginChangeCode(password: $password, email: $email) {
			message
			success
		}
	}
`;
