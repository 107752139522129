import { QuestionConfig, QuestionConfigId, QuestionGroups } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const noop = () => null;

/**
 * Get QuestionConfigId from QuestionConfig
 * @param questionConfig
 * @returns {QuestionConfigId}
 */
export const getQuestionConfigId = (questionConfig: QuestionConfig): QuestionConfigId => {
	const { __typename, type, subtype, layout, isMultipleAnswer } = questionConfig;
	return {
		__typename,
		type,
		subtype,
		layout,
		isMultipleAnswer,
	};
};

/**
 * Check if QuestionConfig is equal to questionTypeId
 * @param questionType
 * @param questionTypeId
 * @returns {boolean}
 * @example
 * isQuestionTypeId({ __typename: 'Question', type: 'Matrix', subtype: 'Single', layout: 'Vertical' }, { __typename: 'Question', type: 'Matrix', subtype: 'Single', layout: 'Vertical' })
 * // true
 * isQuestionTypeId({ __typename: 'Question', type: 'Matrix', subtype: 'Single', layout: 'Vertical' }, { __typename: 'Question', type: 'Matrix', subtype: 'Single', layout: 'Horizontal' })
 * // false
 */
export const isQuestionTypeId = (
	questionType: QuestionConfig,
	questionTypeId: QuestionConfigId,
): boolean =>
	questionTypeId.__typename === questionType.__typename &&
	questionTypeId.type === questionType.type &&
	questionTypeId.subtype === questionType.subtype &&
	questionTypeId.layout === questionType.layout &&
	questionTypeId.isMultipleAnswer === questionType.isMultipleAnswer;

/**
 * Group QuestionConfig by QuestionGroups
 * @param list QuestionConfig[]
 * @returns {QuestionConfig[][]}
 */
export const groupByQuestionGroups = (list: QuestionConfig[]): QuestionConfig[][] => {
	const mapIndex = {};
	QuestionGroups.forEach((group, index) => {
		mapIndex[group] = index;
	});

	const out: QuestionConfig[][] = QuestionGroups.map(() => []);
	list.forEach((item) => {
		const { questionGroup } = item.ui;
		out[mapIndex[questionGroup]].push(item);
	});
	return out;
};

export const utils = {
	noop,
	isQuestionTypeId,
};
