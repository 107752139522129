import React, { memo, useEffect, useRef, useState } from 'react';
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose';
import PropTypes from 'prop-types';

import { Tooltip } from 'ui';

import styles from './style.less';

const Text = ({ children, width, maxLine, tooltipProps }) => {
	const [clamped, setClamped] = useState(false);
	const ref = useRef();

	useEffect(() => {
		if (ref.current?.scrollHeight > ref.current?.clientHeight) {
			setClamped(true);
		}
	}, [ref, children]);

	return (
		<div ref={ref} style={{ width }} className={styles.ellipsis}>
			{maxLine && maxLine > 0 ? (
				clamped ? (
					<Tooltip
						description
						content={children}
						maxWidth={tooltipProps?.maxWidth || 'unset'}
						className={styles.tooltip}
						{...tooltipProps}
					>
						<LinesEllipsisLoose text={children} maxLine={maxLine} />
					</Tooltip>
				) : (
					<LinesEllipsisLoose text={children} maxLine={maxLine} />
				)
			) : (
				<div className={styles.container}>{children}</div>
			)}
		</div>
	);
};

Text.propTypes = {
	basedOn: PropTypes.oneOf(['letters', 'words']),
	children: PropTypes.string.isRequired,
	maxLine: PropTypes.string || PropTypes.number,
	tooltipProps: PropTypes.object,
	width: PropTypes.string.isRequired,
};

export default memo(Text);
