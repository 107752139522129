import gql from 'graphql-tag';

export default gql`
	mutation customizeUrl($input: CustomUrlInput!) {
		customizeUrl(input: $input) {
			url
			prefix
		}
	}
`;
