import gql from 'graphql-tag';

export default gql`
	fragment UrlParameters on Survey {
		settings {
			urlParameters {
				list {
					id
					name
				}
			}
		}
	}
`;
