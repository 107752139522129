import gql from 'graphql-tag';

export default gql`
	fragment UpgradeNotifications on User {
		activeService {
			name
			responseCollectionLimit
		}
		notifications {
			id
			type
		}
	}
`;
