import styled from '@emotion/styled';
import { RadioButtonPropsBase } from './';
import { css } from '@emotion/react';

export const Background = styled.div<RadioButtonPropsBase>`
	width: 100%;
	display: flex;
	align-items: center;
`;

export const StyledRadio = styled.label<RadioButtonPropsBase>`
	display: flex;
	align-items: center;
	position: relative;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	user-select: none;

	&:hover {
		.radio--background {
			${({ withBox, theme, disabled }) =>
				withBox &&
				!disabled &&
				css`
					background-color: ${theme.colors.grey[300]};
				`};
		}
	}

	.radio--background {
		width: 100%;
		display: flex;
		align-items: center;
		transition: background-color 300ms ease-out;
		//background-color: transparent;
		${({ withBox }) =>
			withBox &&
			css`
				padding: 16px;
				border-radius: 8px;
			`}
	}

	> input {
		position: absolute;
		opacity: 0;
		display: none;
		cursor: pointer;

		&:checked ~ .radio--background {
			${({ withBox, theme }) =>
				withBox &&
				css`
					background-color: ${theme.colors.grey[300]};
				`};
		}

		&:checked ~ div > .radio--checkmark {
			border: 1px solid rgba(0, 0, 0, 0.16);
			background-color: ${({ theme: { colors }, disabled }) =>
				disabled ? colors.black[20] : colors.primary[500]};
			transition: all 200ms ease-out;

			&:after {
				opacity: 1;
			}
		}
	}

	.radio--label {
		flex: 1 0 auto;
		margin-left: 12px;

		.label {
			align-items: center;
			margin-bottom: 0;
			color: ${({ theme: { colors }, disabled }) =>
				disabled ? colors.black[20] : colors.grey_shades_with_blue[600]};
		}
	}
`;

export const Checkmark = styled.div<RadioButtonPropsBase>`
	position: relative;
	height: 18px;
	width: 18px;
	min-width: 18px;
	border: 1px solid
		${({ theme: { colors }, disabled, invalid }) =>
			disabled ? colors.black[20] : invalid ? colors.red[600] : colors.grey_shades_with_blue[400]};
	border-radius: 50%;
	margin: 3px;
	box-sizing: border-box;
	transition: border-color 200ms ease-out;

	&:after {
		border: 1px solid rgba(0, 0, 0, 0.16);
		background-clip: padding-box;
		opacity: 0;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: ${({ theme: { colors } }) => colors.white};
		transition: all 200ms ease-out;
	}
`;
