import gql from 'graphql-tag';

export default gql`
	fragment Tours on User {
		tours {
			name
			showAutomatically
			step
		}
	}
`;
