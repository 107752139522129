import gql from 'graphql-tag';

export default gql`
	query filloutDurationStats($uid: String!) {
		filloutDurationStats(uid: $uid) {
			breakdown {
				value
				count
			}
		}
	}
`;
