import gql from 'graphql-tag';

export default gql`
	fragment QuestionInEmail on Survey {
		question(id: $id) {
			... on QuestionInterface {
				id
				text
				helpText
				required
				ordinal
				edited
				number
				answered
				picture {
					url
				}
				video {
					url
					thumb
				}
			}
			... on ChoiceQuestion {
				hasPictureChoices
				layout
				isMultipleAnswer
				minimumAnswers
				maximumAnswers
				layout
				isQuizItem
				choices {
					id
					text
					open
					ordinal
					quizPoints
					picture {
						url
					}
				}
			}
			... on RatingQuestion {
				pointCount
			}
			... on NetPromoterQuestion {
				leftText
				rightText
			}
		}
	}
`;
