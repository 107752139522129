import React, { FC, forwardRef, memo, RefObject } from 'react';
import styled from '@emotion/styled';
import { ButtonBaseProps, ButtonBasePropsBase } from './';

export const StyledButtonBase = styled.button`
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
	background: transparent;
	span {
		display: flex;
		align-items: center;
		position: relative;
	}
`;

export const ButtonBase: FC<ButtonBaseProps> = forwardRef<HTMLElement, ButtonBasePropsBase>(
	({ children, ...props }: ButtonBasePropsBase, ref: RefObject<HTMLButtonElement>) => (
		<StyledButtonBase data-testid="ui-button" {...props} ref={ref}>
			<span>{children}</span>
		</StyledButtonBase>
	),
);

ButtonBase.displayName = 'ButtonBase';

export default memo(ButtonBase) as FC<ButtonBaseProps>;
