import gql from 'graphql-tag';

export default gql`
	query lastInvitationMessage($uid: String!) {
		lastInvitationMessage(uid: $uid) {
			fromName
			title
			introText
			buttonText
			buttonColor
			closingText
			embeddedQuestion
		}
	}
`;
