import * as Types from './ChartTile.d';
import ChartTile, { ChartTile as NotPureChartTile } from './ChartTile';
import { StyledChartTile, Content, Stretcher, BottomBar, EditBubble } from './ChartTile.styled';

export {
	NotPureChartTile as ChartTile,
	StyledChartTile,
	Content,
	Stretcher,
	BottomBar,
	EditBubble,
};
export type ChartTileProps = Types.ChartTileProps;
export default ChartTile;
