import styled from '@emotion/styled';
import { SliderProps } from '.';

export const SliderWrapper = styled.div<SliderProps>`
	width: 100%;
	display: flex;
	align-items: center;
`;

export const StyledSlider = styled.input<SliderProps>`
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 4px;
	border-radius: 20px;
	background: ${({ theme: { colors } }) =>
		`${colors.grey_shades_with_blue[100]} linear-gradient(${colors.primary[500]}, ${colors.primary[500]}) no-repeat`};
	outline: none;

	// Chrome
	::-webkit-slider-thumb {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #ffffff;
		box-shadow:
			0 1px 2px rgba(0, 19, 41, 0.32),
			0 2px 8px rgba(0, 19, 41, 0.24);
		-webkit-appearance: none;
		cursor: ew-resize;
	}

	// Firefox
	::-moz-range-thumb {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #ffffff;
		box-shadow:
			0 1px 2px rgba(0, 19, 41, 0.32),
			0 2px 8px rgba(0, 19, 41, 0.24);
		-webkit-appearance: none;
		cursor: ew-resize;
	}

	// IE
	::-ms-thumb {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #ffffff;
		box-shadow:
			0 1px 2px rgba(0, 19, 41, 0.32),
			0 2px 8px rgba(0, 19, 41, 0.24);
		-webkit-appearance: none;
		cursor: ew-resize;
	}
`;
