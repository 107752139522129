import gql from 'graphql-tag';

export default gql`
	fragment googleSheetsAccounts on User {
		integrations {
			googleSheets {
				id
				name
			}
		}
	}
`;
