import gql from 'graphql-tag';

export default gql`
	query countryList {
		countryList {
			code
			name
			language
			vatRate
		}
	}
`;
