import gql from 'graphql-tag';

export default gql`
	fragment CustomUrl on Survey {
		publicUrl
		customUrl {
			url
			prefix
		}
	}
`;
