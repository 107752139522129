import React, { FC, forwardRef, memo, RefObject } from 'react';
import { CheckboxProps, CheckboxPropsBase, CheckBox, StyledLabel, CheckboxWrapper } from './';
import CheckmarkSVG from '!!svg-react-loader!./assets/checkmark.svg';
import DeselectSVG from '!!svg-react-loader!./assets/deselect.svg';

export const Checkbox: FC<CheckboxProps> = forwardRef<HTMLElement, CheckboxPropsBase>(
	(
		{
			checked,
			className,
			onChange,
			name,
			children,
			value,
			disabled,
			size,
			error,
			deselect,
			id,
			onClick,
			onBlur,
			...props
		}: CheckboxPropsBase,
		ref: RefObject<HTMLInputElement>,
	) => (
		<CheckboxWrapper
			className={className}
			disabled={disabled}
			data-testid="ui-checkbox"
			size={size}
			error={error}
			onClick={onClick}
			{...props}
		>
			<input
				type="checkbox"
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				name={name}
				ref={ref}
				disabled={disabled}
				checked={checked}
				id={id}
			/>
			<CheckBox
				className="checkbox--checkmark"
				disabled={disabled}
				error={error}
				size={size}
				deselect={deselect}
				{...props}
			>
				{deselect ? <DeselectSVG className="checkmark" /> : <CheckmarkSVG className="checkmark" />}
			</CheckBox>
			<StyledLabel
				className="checkbox--label"
				size={size}
				variant={size === 'sm' ? 'textRegular200' : 'textMedium300'}
				error={error}
				disabled={disabled}
				{...props}
			>
				{children}
			</StyledLabel>
		</CheckboxWrapper>
	),
);

Checkbox.displayName = 'Checkbox';

export default memo(Checkbox);
