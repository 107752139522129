import gql from 'graphql-tag';

export default gql`
	fragment Activities on User {
		team {
			id
			activitiesConnection(input: $input) {
				edges {
					cursor
					node {
						... on ActivityInterface {
							email
							timestamp
							memberId
							userName
						}
						... on SendingOutInvitations {
							data {
								emails
							}
						}
						... on CancellingInvitation {
							data {
								email
							}
						}
						... on ChangeOfMemberRole {
							data {
								email
								name
								role
							}
						}
						... on MemberDeactivation {
							data {
								email
								name
							}
						}
						... on CreatingASurvey {
							data {
								surveyName
							}
						}
						... on DeletingASurvey {
							data {
								surveyName
							}
						}
						... on ReportDownload {
							data {
								reportType
								surveyName
							}
						}
						... on DeletingMemberSurveys {
							data {
								surveyNames
								email
								name
							}
						}
						... on MemberReactivation {
							data {
								email
								name
							}
						}
						... on MovingMemberSurveys {
							data {
								surveyNames
								from {
									email
									name
								}
								to {
									email
									name
								}
							}
						}
					}
				}
				totalCount
			}
		}
	}
`;
