export default [
	'AllContentItems',
	'IntroPage',
	'RepeatButton',
	'Settings',
	'SubmitButton',
	'SurveyPage',
	'ThanksPage',
	'Theme',
	'Title',
];
