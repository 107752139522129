import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { TweenLite } from 'gsap';
import classNames from 'classnames';
import WindowSizeListener from 'react-window-size-listener';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import ModalContext from './ModalContext';

import styles from './style.less';

require('gsap/ScrollToPlugin');

class Content extends React.PureComponent {
	static contextType = ModalContext;

	static propTypes = {
		back: PropTypes.func,
		children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
		className: PropTypes.string,
		close: PropTypes.func.isRequired,
		closeColor: PropTypes.string,
		closeOnCrossClick: PropTypes.bool,
		closeOnEsc: PropTypes.bool,
		closeOnOutsideClick: PropTypes.bool,
		footer: PropTypes.node,
		header: PropTypes.node,
		maxWidth: PropTypes.number,
		minHeight: PropTypes.number,
		padding: PropTypes.bool,
		withoutPadding: PropTypes.bool,
	};

	static defaultProps = {
		back: null,
		closeColor: 'default',
		appear: true,
		closeOnCrossClick: true,
		closeOnEsc: true,
		closeOnOutsideClick: true,
		padding: true,
		maxWidth: 600,
		minHeight: 0,
		withoutPadding: false,
	};

	constructor(props) {
		super(props);

		this.scrollbarRef = React.createRef();
		this.headerRef = React.createRef();
		this.mainRef = React.createRef();
		this.footerRef = React.createRef();

		this.state = {
			children: [],
		};
	}

	componentDidMount() {
		this.context.setModal(this.props);
		this.resolveChildren();
	}

	componentDidUpdate(prevProps) {
		if (this.props.children !== prevProps.children) {
			this.resolveChildren();
		}
	}

	resolveChildren = () => {
		const children = React.Children.toArray(
			typeof this.props.children === 'function'
				? this.props.children({
						scrollToElement: this.scrollToElement,
						scrollTop: this.scrollTop,
						scrollToTop: this.scrollToTop,
						scrollToBottom: this.scrollToBottom,
						getScrollValues: this.getScrollValues,
				  })
				: this.props.children,
		);

		this.setState({ children });
	};

	scrollToElement = (scrollTo) => TweenLite.to(this.scrollbarRef.current.view, 1, { scrollTo });

	scrollTop = (scrollTo = 0) => TweenLite.to(this.scrollbarRef.current.view, 1, { scrollTo });

	scrollToTop = () => TweenLite.to(this.scrollbarRef.current.view, 1, { scrollTo: 0 });

	scrollToBottom = () =>
		TweenLite.to(this.scrollbarRef.current.view, 1, {
			scrollTo: this.getScrollValues().scrollHeight,
		});

	getScrollValues = () => this.scrollbarRef.current.getValues();

	setMaxHeight = ({ windowHeight, windowWidth }) => {
		setTimeout(() => {
			if (!this.mainRef.current) {
				return;
			}
			const { padding, fullPageTo, fullPage } = this.props;

			const maxHeight =
				(fullPageTo >= windowWidth || fullPage
					? windowHeight
					: Number(document.querySelector('.modal')?.style.maxHeight.replace('px', ''))) -
				((this.headerRef.current?.clientHeight || 0) +
					(this.footerRef.current?.clientHeight || 0) +
					(padding ? 100 : 50));

			this.mainRef.current.style.maxHeight = `${maxHeight + 50}px`;
		});
	};

	render() {
		const { children } = this.state;

		const header = children.find((child) => child.type === (<Header />).type);
		const body = children.find((child) => child.type === (<Body />).type);
		const footer = children.find((child) => child.type === (<Footer />).type);

		return (
			<React.Fragment>
				{header && (
					<div ref={this.headerRef} className={styles.header}>
						{header}
					</div>
				)}

				{body && (
					<div className={classNames('main', styles.main)} ref={this.mainRef}>
						<Scrollbars
							ref={this.scrollbarRef}
							autoHeight
							autoHeightMax="auto"
							className={styles.scrollbar}
						>
							<div
								className={classNames(
									styles.body,
									this.props.withoutPadding && styles.withoutPadding,
								)}
							>
								{body}
							</div>
						</Scrollbars>
					</div>
				)}

				{footer && <div ref={this.footerRef}>{footer}</div>}

				<WindowSizeListener onResize={this.setMaxHeight} />
			</React.Fragment>
		);
	}
}

export default Content;
