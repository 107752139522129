import React, { FC, forwardRef, memo } from 'react';
import { SegmentationButtonProps, SegmentationButtonStyled } from '.';
import { Icon } from 'ui';

export const SegmentationButton: FC<SegmentationButtonProps> = forwardRef(
	({ label, icon, ...rest }, ref) => (
		<SegmentationButtonStyled>
			<input type="radio" ref={ref} {...rest} />
			<Icon name={icon} />
			<div className="segmentationBtn-label">{label}</div>
			<div className="segmentationBtn-bg" />
		</SegmentationButtonStyled>
	),
);

SegmentationButton.displayName = 'SegmentationButton';

export default memo(SegmentationButton);
