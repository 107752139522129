import gql from 'graphql-tag';

export default gql`
	mutation editSurveyDashboard($input: EditSurveyDashboardInput!) {
		editSurveyDashboard(input: $input) {
			id
			title
			logoUrl
		}
	}
`;
