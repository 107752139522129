import gql from 'graphql-tag';

export default gql`
	mutation UpdateFullName($changes: UserEditableInput!, $id: Int) {
		updateUser(changes: $changes, id: $id) {
			fullName
			phoneNumber
		}
	}
`;
