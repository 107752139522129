import gql from 'graphql-tag';

export default gql`
	fragment oneDriveList on User {
		integrations {
			oneDrive {
				folderNames
			}
		}
	}
`;
