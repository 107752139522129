const includesPlan = (plans, planName) => {
	if (!plans || !planName) {
		return false;
	}

	const check = typeof plans === 'string' ? [plans] : plans;
	return check.some((plan) => new RegExp(`^${planName.replace('DEMO ', '')}$`, 'i').test(plan));
};

export default includesPlan;
