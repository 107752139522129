import React, { FC, forwardRef, memo, RefObject, useCallback } from 'react';
import Icon from '../Icon';
import {
	TextButton,
	TextNoBgButton,
	ContainedButton,
	BorderedIconButton,
	IconButton,
	ImageButton,
	ButtonProps,
	SimpleLink,
	SimpleLink250,
	SimpleLink200,
	RoundedWithText,
	ButtonPropsWithRef,
	WithIconAndShadow,
	HelpButton,
	BlurredButton,
} from './';
import cn from 'classnames';

import Drive from '!!svg-react-loader!./assets/drive.svg';
import { ButtonLinkIcon } from './Button.styled';

const ButtonContent = ({ startIcon, children, endIcon }: ButtonProps): JSX.Element => (
	<span className="buttonContent">
		{startIcon && <Icon name={startIcon} className="startIcon" />}
		{children}
		{endIcon && <Icon name={endIcon} className="endIcon" />}
	</span>
);

export const Button: FC<ButtonPropsWithRef> = forwardRef<HTMLButtonElement, ButtonProps>(
	({ onClick, onClickProps, ...props }: ButtonProps, ref: RefObject<HTMLButtonElement>) => {
		const { children, startIcon, disabled, rounded, variant, endIcon, active, highlighted } = props;

		const handleOnClick = useCallback(
			(e) => onClick && onClick(e, onClickProps),
			[onClickProps, onClick],
		);

		const IconButtonDiv = IconButton.withComponent('div');

		switch (variant) {
			case 'text':
				return (
					<TextButton {...props} ref={ref} onClick={handleOnClick}>
						{!disabled && <div className="background" />}
						<ButtonContent startIcon={startIcon} endIcon={endIcon}>
							{children}
						</ButtonContent>
					</TextButton>
				);
			case 'textNoBg':
				return (
					<TextNoBgButton {...props} rounded={rounded || true} ref={ref} onClick={handleOnClick}>
						<ButtonContent startIcon={startIcon} endIcon={endIcon}>
							{children}
						</ButtonContent>
					</TextNoBgButton>
				);
			case 'simpleLink':
			case 'simpleLinkGrey':
			case 'simpleLinkBlue':
				return (
					<SimpleLink {...props} ref={ref} onClick={handleOnClick}>
						{startIcon && <Icon name={startIcon} className="startIcon" />}
						{children}
						{endIcon && <Icon name={endIcon} className="endIcon" />}
					</SimpleLink>
				);
			case 'simpleLink250':
				return (
					<SimpleLink250 {...props} ref={ref} onClick={handleOnClick}>
						<ButtonContent startIcon={startIcon} endIcon={endIcon}>
							{children}
						</ButtonContent>
					</SimpleLink250>
				);
			case 'simpleLink200':
				return (
					<SimpleLink200 {...props} ref={ref} onClick={handleOnClick}>
						<ButtonContent startIcon={startIcon} endIcon={endIcon}>
							{children}
						</ButtonContent>
					</SimpleLink200>
				);
			case 'icon':
				return (
					<IconButton
						{...props}
						rounded={rounded || true}
						ref={ref}
						onClick={handleOnClick}
						highlighted={highlighted}
					>
						{!disabled && <div className="background" />}
						{!startIcon ? <div>Set an icon</div> : <Icon name={startIcon} className="startIcon" />}
					</IconButton>
				);
			case 'icon-div':
				return (
					<IconButtonDiv
						{...props}
						rounded={rounded || true}
						onClick={handleOnClick}
						highlighted={highlighted}
					>
						{!disabled && <div className="background" />}
						{!startIcon ? <div>Set an icon</div> : <Icon name={startIcon} className="startIcon" />}
					</IconButtonDiv>
				);
			case 'image':
				return (
					<ImageButton {...props} ref={ref} onClick={handleOnClick}>
						<ButtonContent>
							{!disabled && <div className="background" />}
							<Drive className="image" />
							{children}
						</ButtonContent>
					</ImageButton>
				);
			case 'iconButton':
				return (
					<BorderedIconButton {...props} ref={ref} onClick={handleOnClick}>
						{!startIcon ? <div>Set an icon</div> : <Icon name={startIcon} className="startIcon" />}
					</BorderedIconButton>
				);
			case 'blurred':
				return (
					<BlurredButton {...props} ref={ref} onClick={handleOnClick}>
						{!startIcon ? <div>Set an icon</div> : <Icon name={startIcon} className="startIcon" />}
					</BlurredButton>
				);
			case 'roundedWithText':
				return (
					<RoundedWithText {...props} ref={ref} onClick={handleOnClick}>
						{!startIcon ? <div>Set an icon</div> : <Icon name={startIcon} className="startIcon" />}
						{children}
					</RoundedWithText>
				);
			case 'iconShadow':
				return (
					<WithIconAndShadow {...props} ref={ref} onClick={handleOnClick}>
						{!startIcon ? <div>Set an icon</div> : <Icon name={startIcon} className="startIcon" />}
						{children}
					</WithIconAndShadow>
				);
			case 'help':
				return (
					<HelpButton {...props} ref={ref} onClick={handleOnClick}>
						{!startIcon ? <div>Set an icon</div> : <Icon name={startIcon} className="startIcon" />}
						{children}
					</HelpButton>
				);
			case 'buttonLinkIcon':
				return (
					<ButtonLinkIcon {...props} ref={ref} onClick={handleOnClick}>
						{!startIcon ? null : <Icon name={startIcon} className="startIcon" />}
						{children}
					</ButtonLinkIcon>
				);
			default:
			case 'contained':
				return (
					<ContainedButton
						{...props}
						ref={ref}
						onClick={handleOnClick}
						className={cn(props.className, active && 'isActive')}
					>
						<ButtonContent startIcon={startIcon} endIcon={endIcon}>
							{children}
						</ButtonContent>
					</ContainedButton>
				);
		}
	},
);

Button.displayName = 'Button';

Button.defaultProps = {
	color: 'primary',
	variant: 'contained',
	size: 'md',
	type: 'button',
	withDarkBackground: false,
};

export default memo(Button) as FC<ButtonPropsWithRef>;
