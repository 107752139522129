import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { PERIOD } from '~/constants';
import { RadioButton, Spacer, Status, Typography } from '@survio/ui';

import s from './style.less';

const SubscriptionOptions = ({
	period,
	register,
	intl,
	sale,
	stripePricingPlan: stripePricingPlanProp,
	currencyCode,
}) => {
	const { current: stripePricingPlan } = useRef(stripePricingPlanProp);
	const showMonthly = period !== PERIOD.YEARLY && stripePricingPlan?.monthlyAmount;

	return (
		<div className={s.subscription}>
			{showMonthly && (
				<>
					<RadioButton withBox {...register('period')} className={s.radio} value={PERIOD.MONTHLY}>
						{intl.formatMessage({ id: 'app.stripe.monthly-subscription' })}
						<Spacer />
						<Typography variant="textMedium300">
							{`${intl.formatNumber(stripePricingPlan?.monthlyAmount, {
								currency: currencyCode,
								style: 'currency',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							})} / ${intl.formatMessage({
								id: 'app.account.month',
							})}`}
						</Typography>
					</RadioButton>

					<Spacer height={2} />
				</>
			)}

			<RadioButton withBox {...register('period')} className={s.radio} value={PERIOD.YEARLY}>
				{intl.formatMessage({ id: 'app.stripe.annual-subscription' })}
				<Spacer width={8} />
				{showMonthly && (
					<Status state="Save">{`${intl.formatMessage({
						id: 'app.pricelist.save',
					})} ${sale}%`}</Status>
				)}
				<Spacer />
				{period !== PERIOD.YEARLY && (
					<Typography variant="textMedium300">
						{`${intl.formatNumber(stripePricingPlan?.yearlyAmount / 12, {
							currency: currencyCode,
							style: 'currency',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						})} / ${intl.formatMessage({
							id: 'app.account.month',
						})}`}
					</Typography>
				)}
			</RadioButton>
		</div>
	);
};

SubscriptionOptions.propTypes = {
	period: PropTypes.oneOf(Object.values(PERIOD)).isRequired,
	register: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	sale: PropTypes.number.isRequired,
	stripePricingPlan: PropTypes.object.isRequired,
	currencyCode: PropTypes.string.isRequired,
};

export default SubscriptionOptions;
