import gql from 'graphql-tag';

export default gql`
	fragment ThanksPage on Survey {
		content {
			thanksPage {
				title
				content
				logo {
					url
				}
			}
		}
	}
`;
