import gql from 'graphql-tag';

export default gql`
	mutation createBlankSurvey($title: String!, $workspaceId: ID) {
		createBlankSurvey(title: $title, workspaceId: $workspaceId) {
			title
			privateIdentifier
			uid
			publicIdentifier
			publicUrl
			content {
				introPage {
					title
					content
					startButton
					logo {
						url
						w
						h
						offsetX
						offsetY
					}
				}
				mainPages {
					id
					position
					items {
						... on QuestionInterface {
							id
							text
							helpText
							required
							ordinal
							edited
							answered
							picture {
								url
							}
							video {
								url
								thumb
							}
						}
						... on ChoiceQuestion {
							hasPictureChoices
							layout
							isMultipleAnswer
							minimumAnswers
							maximumAnswers
							layout
							isQuizItem
							choices {
								id
								text
								open
								ordinal
								picture {
									url
								}
							}
						}
						... on MatrixQuestion {
							type
							minimumAnswers
							maximumAnswers
							cols {
								id
								text
								ordinal
							}
							rows {
								id
								text
								ordinal
							}
						}
						... on ImportanceQuestion {
							rows {
								text
								id
								ordinal
							}
						}
						... on RatingQuestion {
							pointCount
						}
						... on NetPromoterQuestion {
							leftText
							rightText
						}
						... on SemanticDifferentialQuestion {
							pointCount
							rows {
								id
								leftLabel
								rightLabel
								ordinal
							}
						}
						... on TextQuestion {
							subtype: type
							answerLengthLimit
						}
						... on DividePointsQuestion {
							rows {
								text
								id
								ordinal
							}
							pointCount
							units
						}
						... on TextBlock {
							id
							ordinal
							text
						}
					}
				}
				thanksPage {
					title
					content
					logo {
						url
						w
						h
						offsetX
						offsetY
					}
				}
				submitButtonText
				repeatButtonText
			}
			theme
		}
	}
`;
