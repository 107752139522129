import gql from 'graphql-tag';

export default gql`
	fragment pendingProforma on User {
		pendingProforma {
			number
			invoiceUrl
		}
	}
`;
