import Text from '~/survio-ui/Text';
import React from 'react';

const divide = {
	cols: ({ id, rows, text, pointCount, units }) =>
		rows.map((item) => ({
			dataIndex: `${id}-${item.id}`,
			// intlID: 'app.results.individual-questioncolumn',
			title: (intl) => (
				<Text maxLine={2}>{`${text} x ${item.text} (${intl.formatMessage({
					id: 'app.common.from',
				})} ${pointCount} ${units})`}</Text>
			),
			questionId: id,
			answerId: item.id,
			width: 270,
		})),
	rows: ({ answer, cols }) => {
		const columns = {};
		cols.forEach((col) => {
			const index = answer.answer.items.findIndex((a) => a.item.id === col.answerId);
			columns[col.dataIndex] = index < 0 ? 0 : answer.answer.items[index].assigned;
		});
		return columns;
	},
};
export default divide;
