import gql from 'graphql-tag';

export default gql`
	fragment SettingsLangCode on Survey {
		settings {
			languageCode
			language {
				... on SystemSurveyLanguage {
					code
				}
				... on CustomSurveyLanguage {
					id
					name
					translations
					sourceLanguage {
						code
					}
				}
			}
		}
	}
`;
