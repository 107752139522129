import gql from 'graphql-tag';

export default gql`
	query user($id: Int) {
		user(id: $id) {
			hasSubscription
			country {
				code
			}
		}
	}
`;
