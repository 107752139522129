import surveyManagementImg from '~/components/SalesModal/assets/surveyManagement.svg';

import langsImg from './assets/langs.svg';
import metaImg from './assets/meta.png';
import indexingImg from './assets/indexing.png';
import workspImg from './assets/workspaces.svg';
import invite from './assets/invite-members.png';
import increase from './assets/increase-team.png';
import teamLang from './assets/team-language.svg';
import clp from './assets/custom-login-page.svg';
import membersActivity from './assets/members-activity.svg';
import dashboards from './assets/dashboards.png';
import serverLocationImg from './assets/server-location.svg';
import { PLANS_NEW } from '~/constants';

export const CUSTOM_LANGUAGE = 'customLang';
export const META_SALES = 'surveyCustomSharingMetadata';
export const WORKSPACES_SALES = 'workspaces';
export const INVITATION_SALES = 'inviteMembers';
export const INCREASE_TEAM_SALES = 'increase_team';
export const TEAM_LANGUAGE_SALES = 'team_language';
export const CUSTOM_LOGIN_PAGE_SALES = 'custom_login_page';
export const ACTIVITIES_SALES = 'activities';
export const DASHBOARDS_SALES = 'dashboards';
export const SERVER_SALES = 'server-location';
export const SURVEY_MANAGEMENT_SALES = 'surveyManagement';
export const SHARE_DASHBOARDS_SALE = 'dashboardSharingSale';
export const INDEXING = 'indexing';

export const content = {
	[META_SALES]: {
		title: 'app.distribute.modal.social-share-title',
		text: 'app.distribute.modal.social-share-desc',
		features: 'standard',
		image: metaImg,
		stripeFeatures: PLANS_NEW.PLUS,
		plan: 'standard_p_23',
	},
	[CUSTOM_LANGUAGE]: {
		title: 'app.settings.modal-new-language-title',
		text: 'app.settings.modal-new-language-desc',
		features: 'standard',
		image: langsImg,
		stripeFeatures: PLANS_NEW.STANDARD,
		plan: 'standard_p_23',
	},
	[INDEXING]: {
		title: 'app.multiuser.private-surveys',
		text: 'app.multiuser.private-surveys-desc',
		features: 'plus',
		image: indexingImg,
		stripeFeatures: PLANS_NEW.STANDARD,
		plan: 'pro_business_1_p_23',
	},
	[SURVEY_MANAGEMENT_SALES]: {
		title: 'app.dashboard.survey-management',
		text: 'app.dashboard.cooperation-modal-desc',
		features: 'starter',
		image: surveyManagementImg,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'pro_business_1_p_23',
	},
	[WORKSPACES_SALES]: {
		title: 'app.workspaces.modal-title',
		text: 'app.workspaces.modal-info',
		features: 'standard',
		image: workspImg,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'standard_p_23',
	},
	[INVITATION_SALES]: {
		title: 'app.multiuser.modal-headline-1',
		text: 'app.multiuser.modal-headline-1-desc',
		features: 'starter',
		image: invite,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'pro_business_3_p_23',
	},
	[INCREASE_TEAM_SALES]: {
		title: 'app.multiuser.increase-team-size',
		text: 'app.multiuser.increase-team-size-desc',
		features: 'starter',
		image: increase,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'pro_business_3_p_23',
	},
	[TEAM_LANGUAGE_SALES]: {
		title: 'app.multiuser.team-language',
		text: 'app.multiuser.team-language-desc',
		features: 'starter',
		image: teamLang,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'pro_business_1_p_23',
	},
	[CUSTOM_LOGIN_PAGE_SALES]: {
		title: 'app.multiuser.cust-login-page',
		text: 'app.multiuser.cust-login-page-tagline',
		features: 'medium',
		image: clp,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'enterprise',
	},
	[ACTIVITIES_SALES]: {
		title: 'app.multiuser.activity-log-modal-title',
		text: 'app.multiuser.activity-log-modal-desc',
		features: 'starter',
		image: membersActivity,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'pro_business_1_p_23',
	},
	[DASHBOARDS_SALES]: {
		title: 'app.dashboard.title',
		text: 'app.dashboard.desc',
		features: 'starter',
		image: dashboards,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'pro_business_1_p_23',
	},
	[SERVER_SALES]: {
		title: 'app.geolocation.title',
		text: 'app.geolocation.modal-desc',
		features: 'enterprise',
		image: serverLocationImg,
		stripeFeatures: PLANS_NEW.CUSTOM,
		plan: 'enterprise',
	},
	[SHARE_DASHBOARDS_SALE]: {
		title: 'app.dashboard.title-2',
		text: 'app.dashboard.desc-2',
		features: 'starter',
		image: dashboards,
		stripeFeatures: PLANS_NEW.PRO,
		plan: 'pro_business_1_p_23',
	},
};

export const newPlansFeatures = {
	[PLANS_NEW.STANDARD]: [
		{
			id: 'app.pricelist.2022-standard-responses',
		},
		{
			id: 'app.pricelist.2022-standard-shares',
		},
		{
			id: 'app.pricelist.2022-standard-responses',
		},
		{
			id: 'app.pricelist.2022-standard-shares',
		},
		{
			id: 'app.pricelist.2022-standard-cloud',
		},
		{
			id: 'app.pricelist.2022-standard-api',
		},
		{
			id: 'app.pricelist.2022-standard-zapier',
		},
		{
			id: 'app.pricelist.2022-standard-seo',
		},
	],
	[PLANS_NEW.PLUS]: [
		{
			id: 'app.pricelist.2022-plus-responses',
		},
		{
			id: 'app.pricelist.2022-plus-shares',
		},
		{
			id: 'app.pricelist.2022-plus-summary',
		},
		{
			id: 'app.pricelist.2022-plus-resp-export',
		},
		{
			id: 'app.pricelist.2022-plus-redirect',
		},
		{
			id: 'app.pricelist.2022-plus-footer',
		},
		{
			id: 'app.pricelist.2022-plus-meta-desc',
		},
		{
			id: 'app.pricelist.2022-plus-support',
		},
		{
			id: 'app.pricelist.2022-plus-tu-page',
		},
		{
			id: 'app.pricelist.2022-plus-result-sharing',
		},
	],
	[PLANS_NEW.PRO]: [
		{
			id: 'app.pricelist.2022-pro-responses',
		},
		{
			id: 'app.pricelist.2022-pro-shares',
		},
		{
			id: 'app.pricelist.2022-pro-seats',
		},
		{
			id: 'app.pricelist.2022-pro-summary',
		},
		{
			id: 'app.pricelist.2022-pro-export',
		},
		{
			id: 'app.pricelist.2022-pro-workspaces',
		},
		{
			id: 'app.pricelist.2022-pro-data-management',
		},
		{
			id: 'app.pricelist.2022-pro-logs',
		},
		{
			id: 'app.pricelist.2022-pro-custom-charts',
		},
		{
			id: 'app.pricelist.2022-pro-support',
		},
		{
			id: 'app.pricelist.2022-pro-chat',
		},
	],
	[PLANS_NEW.CUSTOM]: [
		{
			id: 'app.pricelist.2022-entreprise-responses',
		},
		{
			id: 'app.pricelist.2022-entreprise-shares',
		},
		{
			id: 'app.pricelist.2022-entreprise-sso',
		},
		{
			id: 'app.pricelist.2022-entreprise-data-centers',
		},
		{
			id: 'app.pricelist.2022-entreprise-success',
		},
		{
			id: 'app.pricelist.2022-entreprise-legal',
		},
	],
};

// TODO:
export const plansFeatures = {
	mini: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			prefix: '1000 ',
			id: 'app.designer.modal.service-sales-2',
		},
		{
			prefix: '100+ ',
			id: 'app.designer.modal.service-sales-4',
		},
		{
			id: 'app.designer.modal.service-sales-5',
			suffix: 'app.designer.modal.service-sales-17',
		},
		{
			id: 'app.designer.modal.service-sales-6',
			suffix: 'app.designer.modal.service-sales-21',
		},
		{
			id: 'app.designer.modal.service-sales-7',
		},
		{
			id: 'app.designer.modal.service-sales-8',
		},
	],
	standard: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			prefix: '10.000 ',
			id: 'app.designer.modal.service-sales-2',
		},
		{
			prefix: '100+ ',
			id: 'app.designer.modal.service-sales-4',
		},
		{
			id: 'app.designer.modal.service-sales-5',
			suffix: 'app.designer.modal.service-sales-18',
		},
		{
			id: 'app.designer.modal.service-sales-6',
			suffix: 'app.designer.modal.service-sales-22',
		},
		{
			id: 'app.designer.modal.service-sales-7',
		},
		{
			id: 'app.designer.modal.service-sales-9',
			suffix: 'app.designer.modal.service-sales-20',
		},
		{
			id: 'app.designer.modal.service-sales-10',
		},
		{
			id: 'app.designer.modal.service-sales-11',
		},
		{
			id: 'app.designer.modal.service-sales-12',
		},
	],
	plus: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			id: 'app.designer.modal.service-sales-3',
		},
		{
			prefix: '1000+ ',
			id: 'app.designer.modal.service-sales-4',
		},
		{
			id: 'app.designer.modal.service-sales-5',
			suffix: 'app.designer.modal.service-sales-18',
		},
		{
			id: 'app.designer.modal.service-sales-6',
			suffix: 'app.designer.modal.service-sales-23',
		},
		{
			id: 'app.designer.modal.service-sales-7',
		},
		{
			id: 'app.designer.modal.service-sales-9',
			suffix: 'app.designer.modal.service-sales-24',
		},
		{
			id: 'app.designer.modal.service-sales-10',
		},
		{
			id: 'app.designer.modal.service-sales-13',
		},
		{
			id: 'app.designer.modal.service-sales-14',
		},
		{
			id: 'app.designer.modal.service-sales-15',
		},
	],
	starter: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			id: 'app.multiuser.modal-sales-2',
		},
		{
			prefix: '50 000',
			id: 'app.multiuser.modal-sales-3',
		},
		{
			id: 'app.multiuser.modal-sales-4',
		},
		{
			id: 'app.multiuser.modal-sales-5',
		},
		{
			id: 'app.multiuser.modal-sales-6',
		},
		{
			id: 'app.multiuser.modal-sales-7',
		},
		{
			id: 'app.multiuser.modal-sales-8',
		},
		{
			id: 'app.multiuser.modal-sales-9',
		},
		{
			id: 'app.multiuser.modal-sales-10',
		},
	],
	medium: [
		{
			id: 'app.designer.modal.medium-sales-1',
		},
		{
			id: 'app.designer.modal.medium-sales-2',
		},
		{
			id: 'app.designer.modal.medium-sales-3',
		},
		{
			id: 'app.designer.modal.medium-sales-4',
		},
		{
			id: 'app.designer.modal.medium-sales-5',
		},
		{
			id: 'app.designer.modal.medium-sales-6',
		},
		{
			id: 'app.designer.modal.medium-sales-7',
		},
		{
			id: 'app.designer.modal.medium-sales-8',
		},
		{
			id: 'app.designer.modal.medium-sales-9',
		},
		{
			id: 'app.designer.modal.medium-sales-10',
		},
	],
	enterprise: [
		{
			id: 'app.designer.modal.pro-sales-1',
		},
		{
			id: 'app.designer.modal.pro-sales-2',
		},
		{
			id: 'app.designer.modal.pro-sales-3',
		},
		{
			id: 'app.designer.modal.pro-sales-4',
		},
		{
			id: 'app.designer.modal.pro-sales-5',
		},
		{
			id: 'app.designer.modal.pro-sales-6',
		},
		{
			id: 'app.designer.modal.pro-sales-7',
		},
		{
			id: 'app.designer.modal.pro-sales-8',
		},
		{
			id: 'app.designer.modal.pro-sales-9',
		},
		{
			id: 'app.designer.modal.pro-sales-10',
		},
	],
};

export const planFeatures23 = ({ responseLimit }) => ({
	mini_p_23: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			prefix: `${responseLimit} `,
			id: 'app.designer.modal.service-sales-2',
		},
		{
			prefix: '100+ ',
			id: 'app.designer.modal.service-sales-4',
		},
		{
			id: 'app.designer.modal.service-sales-5',
			suffix: 'app.designer.modal.service-sales-17',
		},
		{
			id: 'app.designer.modal.service-sales-6',
			suffix: 'app.designer.modal.service-sales-21',
		},
		{
			id: 'app.designer.modal.service-sales-7',
		},
		{
			id: 'app.designer.modal.service-sales-8',
		},
	],
	standard_p_23: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			prefix: `${responseLimit} `,
			id: 'app.designer.modal.service-sales-2',
		},
		{
			prefix: '100+ ',
			id: 'app.designer.modal.service-sales-4',
		},
		{
			id: 'app.designer.modal.service-sales-5',
			suffix: 'app.designer.modal.service-sales-18',
		},
		{
			id: 'app.designer.modal.service-sales-6',
			suffix: 'app.designer.modal.service-sales-22',
		},
		{
			id: 'app.designer.modal.service-sales-7',
		},
		{
			id: 'app.designer.modal.service-sales-9',
			suffix: 'app.designer.modal.service-sales-20',
		},
		{
			id: 'app.designer.modal.service-sales-10',
		},
		{
			id: 'app.designer.modal.service-sales-11',
		},
		{
			id: 'app.designer.modal.service-sales-12',
		},
	],
	pro_business_1_p_23: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			id: 'app.multiuser.modal-sales-2',
		},
		{
			prefix: `${responseLimit} `,
			id: 'app.multiuser.modal-sales-3',
		},
		{
			id: 'app.multiuser.modal-sales-4',
		},
		{
			id: 'app.multiuser.modal-sales-5',
		},
		{
			id: 'app.multiuser.modal-sales-6',
		},
		{
			id: 'app.multiuser.modal-sales-7',
		},
		{
			id: 'app.multiuser.modal-sales-8',
		},
		{
			id: 'app.multiuser.modal-sales-9',
		},
		{
			id: 'app.multiuser.modal-sales-10',
		},
	],
	pro_business_3_p_23: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			id: 'app.multiuser.modal-sales-2',
		},
		{
			prefix: `${responseLimit} `,
			id: 'app.multiuser.modal-sales-3',
		},
		{
			id: 'app.multiuser.modal-sales-4',
		},
		{
			id: 'app.multiuser.modal-sales-5',
		},
		{
			id: 'app.multiuser.modal-sales-6',
		},
		{
			id: 'app.multiuser.modal-sales-7',
		},
		{
			id: 'app.multiuser.modal-sales-8',
		},
		{
			id: 'app.multiuser.modal-sales-9',
		},
		{
			id: 'app.multiuser.modal-sales-10',
		},
	],
	pro_business_5_p_23: [
		{
			id: 'app.designer.modal.service-sales-1',
		},
		{
			id: 'app.multiuser.modal-sales-2',
		},
		{
			prefix: `${responseLimit} `,
			id: 'app.multiuser.modal-sales-3',
		},
		{
			id: 'app.multiuser.modal-sales-4',
		},
		{
			id: 'app.multiuser.modal-sales-5',
		},
		{
			id: 'app.multiuser.modal-sales-6',
		},
		{
			id: 'app.multiuser.modal-sales-7',
		},
		{
			id: 'app.multiuser.modal-sales-8',
		},
		{
			id: 'app.multiuser.modal-sales-9',
		},
		{
			id: 'app.multiuser.modal-sales-10',
		},
	],
	enterprise: [
		{
			id: 'app.pricelist.2022-entreprise-responses',
		},
		{
			id: 'app.pricelist.2022-entreprise-shares',
		},
		{
			id: 'app.pricelist.2022-entreprise-sso',
		},
		{
			id: 'app.pricelist.2022-entreprise-data-centers',
		},
		{
			id: 'app.pricelist.2022-entreprise-success',
		},
		{
			id: 'app.pricelist.2022-entreprise-legal',
		},
	],
});

export const events = {
	[META_SALES]: {
		open: {
			id: 365,
			name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings',
		},
		activate: {
			id: 366,
			name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings_ActivatePremium',
		},
		close: {
			id: 367,
			name: 'builder_sharelink_click_upsell_feature_socialnetsharesettings_closeWindow',
		},
	},
	[CUSTOM_LANGUAGE]: {
		open: {
			id: 354,
			name: 'builder_preferences_click_upsell_feature_surveylanguage_create_custom',
		},
		activate: {
			id: 355,
			name: 'builder_preferences_click_upsell_feature_surveylanguage_create_custom_ActivatePremium',
		},
		close: {
			id: 356,
			name: 'builder_preferences_click_upsell_feature_surveylanguage_create_custom_windowClose',
		},
	},
	[INDEXING]: {
		open: {
			id: 362,
			name: 'builder_preferences_upsell_feature_allowindexing',
		},
		activate: {
			id: 410,
			name: 'builder_preferences_upsell_feature_allowindexing_ActivatePremium',
		},
		close: {
			id: 411,
			name: 'builder_preferences_upsell_feature_allowindexing_closeWindow',
		},
	},
	[WORKSPACES_SALES]: {
		open: {
			id: 391,
			name: 'dashboard_click_upsell_feature_workspaces',
		},
		activate: {
			id: 392,
			name: 'dashboard_click_upsell_feature_workspaces_ActivatePremium',
		},
		close: {
			id: 393,
			name: 'dashboard_click_upsell_feature_workspaces_closeWindow',
		},
	},
	[INVITATION_SALES]: {
		open: {
			id: 399,
			name: 'team_click_upsell_feature_invitemember',
		},
		activate: {
			id: 400,
			name: 'team_click_upsell_feature_invitemember_ActivatePremium',
		},
		close: {
			id: 401,
			name: 'team_click_upsell_feature_invitemember_closeWindow',
		},
	},
	[INCREASE_TEAM_SALES]: {
		open: {
			id: 420,
			name: 'team_click_upsell_feature_teamsize',
		},
		activate: {
			id: 421,
			name: 'team_click_upsell_feature_teamsize_ActivatePremium',
		},
		close: {
			id: 422,
			name: 'team_click_upsell_feature_teamsize_closeWindow',
		},
	},
	[TEAM_LANGUAGE_SALES]: {
		open: {
			id: 425,
			name: 'team_click_upsell_feature_teamlang_upgrade',
		},
		activate: {
			id: 426,
			name: 'team_click_upsell_feature_teamlang_ActivatePremium',
		},
		close: {
			id: 427,
			name: 'team_click_upsell_feature_teamlang_closeWindow',
		},
	},
	[CUSTOM_LOGIN_PAGE_SALES]: {
		open: {
			id: 425,
			name: 'team_click_upsell_feature_customlogin',
		},
		activate: {
			id: 426,
			name: 'team_click_upsell_feature_customlogin_ActivatePremium',
		},
		close: {
			id: 427,
			name: 'team_click_upsell_feature_customlogin_closeWindow',
		},
	},
	[ACTIVITIES_SALES]: {
		open: {
			id: 442,
			name: 'team_click_upsell_feature_log',
		},
		activate: {
			id: 443,
			name: 'team_click_upsell_feature_log_ActivatePremium',
		},
		close: {
			id: 444,
			name: 'team_click_upsell_feature_log_closeWindow',
		},
	},
	[DASHBOARDS_SALES]: {
		open: {
			id: 478,
			name: 'team_click_upsell_feature_dashboards',
		},
		activate: {
			id: 479,
			name: 'team_click_upsell_feature_dashboards_ActivatePremium',
		},
		close: {
			id: 480,
			name: 'team_click_upsell_feature_dashboards_closeWindow',
		},
	},
	[SERVER_SALES]: {
		open: {
			id: 515,
			name: 'profile_click_upsell_feature_geolocation',
		},
		activate: {
			id: 516,
			name: 'profile_click_upsell_feature_geolocation_ActivatePremium',
		},
		close: {
			id: 517,
			name: 'profile_click_upsell_feature_geolocation_closeWindow',
		},
	},
};
