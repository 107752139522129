import gql from 'graphql-tag';

export default gql`
	fragment googleDriveAccounts on User {
		integrations {
			googleDrive {
				id
				name
			}
		}
	}
`;
