import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Wrapper, Iframe, CloseButton, CloseWrapper } from './InAppWidget.styled';
import { Menu } from '~/ui';
import { useIntl } from 'react-intl';

export type InAppWidgetProps = {
	onClose: (skipped: boolean) => void;
	url: string;
	language: string;
	width: number;
	height: number;
	onLoad: (e: SyntheticEvent<HTMLIFrameElement>) => void;
};

const InAppWidget = ({ onClose, url, language, width, height, onLoad }: InAppWidgetProps) => {
	const [show, setShow] = useState(false);
	const intl = useIntl();

	const handleOnLoad = useCallback(
		(e: SyntheticEvent<HTMLIFrameElement>) => {
			setShow(true);
			onLoad(e);
		},
		[onLoad],
	);

	const handleClose = useCallback(
		(skipped: boolean) => () => {
			onClose(skipped);
			setShow(false);
		},
		[onClose],
	);

	return (
		<Wrapper show={show} width={width} height={height}>
			<Iframe allow="fullscreen" onLoad={handleOnLoad} src={url.replace('{lang}', language)} />
			<CloseWrapper>
				<Menu
					dark
					size="sm"
					offset={[-22, 40]}
					minWidth={192}
					placement="left-end"
					items={[
						{
							id: 'close',
							onClick: handleClose(true),
							label: intl.formatMessage({ id: 'app.product-tour-try-next-time' }),
						},
						{
							id: 'skip',
							onClick: handleClose(false),
							label: intl.formatMessage({ id: 'app.product-tour-skip-completely' }),
						},
					]}
				>
					<CloseButton variant="iconButton" startIcon="close-medium-24" size="sm" />
				</Menu>
			</CloseWrapper>
		</Wrapper>
	);
};

export default InAppWidget;
