import gql from 'graphql-tag';

export default gql`
	mutation setChartTheme($input: ChartThemeInput!) {
		setChartTheme(input: $input) {
			id
			name
			colors
			isLocked
			isSystem
			__typename
		}
	}
`;
