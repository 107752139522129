// workaround to resolve Tippy.js warning
// - when visibility is set manually from props, "trigger" and "hideOnClick" props can't be set

const controlledTippyFix = (
	visible: boolean,
	trigger: string,
	hideOnClick: boolean,
): { trigger?: string; hideOnClick?: boolean } => {
	if (typeof visible === 'boolean') {
		return {};
	}
	return {
		trigger,
		hideOnClick,
	};
};

export default controlledTippyFix;
