import React, { FC, ReactNode, memo } from 'react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from '../Button';
import Icon from '../Icon';
import { ThemeType } from '../ThemeProvider/theme';

export type NotificationVariant =
	| 'primary'
	| 'secondary'
	| 'success'
	| 'warning'
	| 'danger'
	| 'preview'
	| 'default';

export type NotificationProps = {
	children: ReactNode;
	className?: string;
	inModal?: boolean;
	onClose?: (event: React.MouseEvent<HTMLElement>) => void;
	theme?: ThemeType;
	variant?: NotificationVariant;
};

const Cross = styled(Button)<ButtonProps>`
	color: ${({ theme: { colors } }) => colors.white};
	margin: ${({ theme: { helpers } }) => `0 4px 0 ${helpers.spacer(4)}px`};
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 24px;

	.background {
		background-color: ${({ theme: { colors } }) => colors.white};
		opacity: 0.32;
	}
`;

const InfoIcon = styled.span<NotificationProps>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	flex: 1 0 auto;
	${({ theme: { helpers } }) => `margin-right: ${helpers.spacer(2)}px`};

	${({ variant, theme: { colors } }) =>
		variant === 'secondary' || variant === 'preview'
			? `color: ${colors.black[90]}`
			: `color: ${colors?.white}`};

	i {
		${({ theme: { colors }, variant }) => {
			switch (variant) {
				case 'primary':
					return `color: ${colors.primary[250]}`;
				case 'secondary':
					return `color: ${colors.white}`;
				case 'success':
					return `color: ${colors.green_success[400]}`;
				case 'warning':
					return `color: ${colors.yellow[400]}`;
				case 'danger':
					return `color: ${colors.red[500]}`;
				default:
					return `color: ${colors.white}`;
			}
		}};
	}
`;

const TextWrapper = styled.div<NotificationProps>`
	display: flex;
	align-items: center;
	min-height: 24px;

	.medium {
		${({ theme }) => theme.typography.textMedium300};
	}
`;

const NotificationWrapper = styled.div<NotificationProps>`
	${({ theme }) => theme.typography.textRegular300};
	background: ${({ theme }) => theme.colors.grey_shades_with_blue[700]};
	display: inline-flex;
	justify-content: space-between;
	align-items: flex-start;
	border-radius: 10px;
	position: relative;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.23);
	padding: 12px;
	color: ${({ theme }) => `${theme.colors.white}`};
	max-width: ${({ inModal, theme: { helpers } }) =>
		inModal ? helpers.spacer(99) : helpers.spacer(90)}px;

	@media (max-width: 1031px) {
		max-width: ${({ inModal, theme: { helpers } }) =>
			inModal ? helpers.spacer(68) : helpers.spacer(60)}px;
	}
	@media (max-width: 783px) {
		max-width: ${({ theme: { helpers } }) => helpers.spacer(60)}px;
	}

	@media (max-width: 479px) {
		max-width: 100%;
	}

	div > a,
	span > a {
		cursor: pointer;
		text-decoration: ${({ variant }) =>
			variant === 'secondary' || variant === 'preview' || variant === 'default'
				? 'underline'
				: 'none'};
		${({ variant, theme: { colors } }) => {
			switch (variant) {
				case 'default':
				case 'preview':
				case 'secondary':
					return `color: ${colors.white}`;
				case 'success':
					return `color: ${colors.green_success[400]}`;
				case 'warning':
					return `color: ${colors.yellow[400]}`;
				case 'danger':
					return `color: ${colors.red[500]}`;
				case 'primary':
					return `color: ${colors.primary[250]}`;
				default:
					return `color: ${colors.secondary[500]}`;
			}
		}};

		&:hover {
			text-decoration: ${({ variant }) =>
				variant === 'secondary' || variant === 'preview' || variant === 'default'
					? 'none'
					: 'underline'};
		}
	}
`;

export const Notification: FC<NotificationProps> = ({
	children,
	className,
	onClose,
	variant,
	inModal,
}: NotificationProps) => {
	const VariantSymbol = () => {
		switch (variant) {
			case 'primary':
				return (
					<InfoIcon variant={variant}>
						<Icon name="info-filled-24" />
					</InfoIcon>
				);
			case 'secondary':
				return (
					<InfoIcon variant={variant}>
						<Icon name="info-filled-24" />
					</InfoIcon>
				);
			case 'success':
				return (
					<InfoIcon variant={variant}>
						<Icon name="success-24" />
					</InfoIcon>
				);
			case 'danger':
				return (
					<InfoIcon variant={variant}>
						<Icon name="warning-large-24" />
					</InfoIcon>
				);
			case 'warning':
				return (
					<InfoIcon variant={variant}>
						<Icon name="warning-large-24" />
					</InfoIcon>
				);
			default:
				return (
					<InfoIcon variant={variant}>
						<Icon name="info-filled-24" />
					</InfoIcon>
				);
		}
	};
	return (
		<NotificationWrapper
			variant={variant}
			className={className}
			inModal={inModal}
			data-testid="ui-notification"
		>
			{variant !== 'default' && <VariantSymbol />}
			<TextWrapper>{children}</TextWrapper>
			{!!onClose && (
				<Cross variant="icon" startIcon="close-16" onClick={onClose} type="button" size="sm" />
			)}
		</NotificationWrapper>
	);
};

Notification.defaultProps = {
	variant: 'default',
};

export default memo(Notification) as FC<NotificationProps>;
