import gql from 'graphql-tag';

export default gql`
	fragment QuestionsAnalyze on Survey {
		questions {
			... on QuestionInterface {
				id
				ordinal
				text
				number
			}
			... on ChoiceQuestion {
				isMultipleAnswer
				hasPictureChoices
				isQuizItem
				choices {
					id
					ordinal
					text
					quizPoints
					picture {
						url
						w
						h
					}
				}
			}
			... on RatingQuestion {
				pointCount
			}
			... on TextQuestion {
				subtype: type
			}
			... on MatrixQuestion {
				type
			}
			... on SemanticDifferentialQuestion {
				rows {
					id
					leftLabel
					rightLabel
				}
				pointCount
			}
		}
	}
`;
