import gql from 'graphql-tag';

export default gql`
	mutation setUpgradeModal(
		$isOpen: Boolean
		$upgradeType: String
		$plan: String
		$period: String
		$salesVariant: String
		$iframeProps: Object
		$saleSource: Object
	) {
		setUpgradeModal(
			isOpen: $isOpen
			upgradeType: $upgradeType
			plan: $plan
			period: $period
			salesVariant: $salesVariant
			iframeProps: $iframeProps
			saleSource: $saleSource
		) @client
	}
`;
