import styled from '@emotion/styled';

export const NoteStyled = styled.div`
	background: ${({ theme }) => theme.colors.yellow[50]};
	border: 1px solid ${({ theme }) => theme.colors.yellow[250]};
	border-radius: 8px;
	padding: 12px 16px;
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.typography.textRegular300};
	color: ${({ theme }) => theme.colors.yellow[900]};
`;

export const NoteLabelStyled = styled.div`
	${({ theme }) => theme.typography.textSemibold300};
	margin-bottom: 8px;
	color${({ theme }) => theme.colors.yellow[700]};
`;
