import styled from '@emotion/styled';
import { getPlusIconBrightnessContrastColors } from './brightnessContrastColors';

export const StyledContainer = styled.div<{ brightness: number }>`
	height: 1px;
	width: 100%;
	max-width: 650px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 50;
	display: flex;
	align-items: center;
	justify-content: center;

	.verticalLine {
		&:before {
			left: calc(50% - 852px / 2 + 64px / 2);
		}

		&:after {
			left: calc(50% + 32px - 8px);
		}

		&:before,
		&:after {
			width: calc(394px - 16px - 8px);
			content: '';
			position: absolute;
			top: 0;
			height: 0;
			border-radius: 2px;

			border-color: ${({ brightness }) => (brightness < 136 ? '#ffffffb3' : '#12121766')};

			&.darkBg {
				&:before,
				&:after {
					border-color: #ffffffb3;
				}
			}
		}

		&.active {
			&:before,
			&:after {
				height: 2px;
			}
		}

		&.hidden {
			&:before,
			&:after {
				height: 0;
				border-width: 0;
			}
		}
	}
`;

export const StyledPlus = styled.div`
	outline: 1px solid #23235133;
	z-index: 30;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	gap: 10px;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0 1px 2px 0 #1018280d;

	${getPlusIconBrightnessContrastColors}
	&:hover {
		+ .verticalLine {
			&:before,
			&:after {
				border: none;
				height: 2px;
			}
		}
	}
`;
