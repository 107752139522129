import gql from 'graphql-tag';

export default gql`
	fragment TrafficHistory on Survey {
		trafficHistory {
			date
			visits
			submits
			unfinished
		}
	}
`;
