import gql from 'graphql-tag';

export default gql`
	fragment header on Survey {
		title
		settings {
			headerText
			headerLogo
		}
	}
`;
