import gql from 'graphql-tag';

export default gql`
	mutation connectSheetsIntegration($fileName: String!, $integration: ID!, $uid: ID!) {
		connectSheetsIntegration(fileName: $fileName, integration: $integration, uid: $uid) {
			integration {
				id
				email
				name
				avatar
			}
			activeSince
			fileName
			url
		}
	}
`;
