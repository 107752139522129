export const LOGIC_OPTION_TYPES = {
	TO_URL: 'TO_URL',
	TO_PAGE: 'TO_PAGE',
	TO_QUESTION: 'TO_QUESTION',
	TO_SUBMIT: 'TO_SUBMIT',
} as const;

export const LOGIC_OPTION_FROM_API_TYPES = {
	REDIRECT: 'redirect',
	PAGE: 'page',
	QUESTION: 'question',
	SUBMIT: 'submit',
} as const;

export const LOGIC_OPTION_FROM_API_TYPES_MAP = {
	[LOGIC_OPTION_FROM_API_TYPES.REDIRECT]: LOGIC_OPTION_TYPES.TO_URL,
	[LOGIC_OPTION_FROM_API_TYPES.PAGE]: LOGIC_OPTION_TYPES.TO_PAGE,
	[LOGIC_OPTION_FROM_API_TYPES.QUESTION]: LOGIC_OPTION_TYPES.TO_QUESTION,
	[LOGIC_OPTION_FROM_API_TYPES.SUBMIT]: LOGIC_OPTION_TYPES.TO_SUBMIT,
} as const;

export const LOGIC_OPTION_FROM_API_TYPES_MAP_REVERSE = {
	[LOGIC_OPTION_TYPES.TO_URL]: LOGIC_OPTION_FROM_API_TYPES.REDIRECT,
	[LOGIC_OPTION_TYPES.TO_PAGE]: LOGIC_OPTION_FROM_API_TYPES.PAGE,
	[LOGIC_OPTION_TYPES.TO_QUESTION]: LOGIC_OPTION_FROM_API_TYPES.QUESTION,
	[LOGIC_OPTION_TYPES.TO_SUBMIT]: LOGIC_OPTION_FROM_API_TYPES.SUBMIT,
} as const;
