import gql from 'graphql-tag';

export default gql`
	fragment footer on Survey {
		settings {
			footerText
			allowReturn
			isPoweredBySurvioShown
		}
	}
`;
