import gql from 'graphql-tag';

export default gql`
	mutation updateWorkspace($id: ID!, $input: WorkspaceInput!) {
		updateWorkspace(id: $id, input: $input) {
			id
			name
		}
	}
`;
