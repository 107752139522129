/* ----- POLYFILLS ----- */
// import 'core-js';
// import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'blueimp-canvas-to-blob';
import 'array-flat-polyfill';
import 'intersection-observer';
import smoothscroll from 'smoothscroll-polyfill';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import React from 'react';
import { render } from 'react-dom';

import '~/config';

import auth from '~/auth';

import createApolloClient from '~/createApolloClient';
import configureStore from '~/store/configureStore';
import Root from './components/Root';
import Homepage from './pages/Homepage';
import { LOGIN_URL_SUFFIX, UTM_PREFIX } from './constants';

import 'ui/components/Icon/icomoon/style.css';

// if (process.env.NODE_ENV !== 'production') {
// 	const { whyDidYouUpdate } = require('why-did-you-update');
// 	whyDidYouUpdate(React);
// }

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

smoothscroll.polyfill();

if (window.env.SENTRY_DSN) {
	Sentry.init({
		dsn: window.env.SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	});
}

const { userId } = auth.getTokenData(auth.getToken());

if (userId) {
	if (window.env.SENTRY_DSN) {
		Sentry.setUser({ id: userId });
	}

	if (process.env.NODE_ENV !== 'development') {
		const { datadogLogs } = require('@datadog/browser-logs');
		datadogLogs.init({
			clientToken: 'pub2853bb5d680725ef11975a116c4b4185',
			forwardErrorsToLogs: true,
		});
		datadogLogs.setGlobalContextProperty('userId', userId);

		if (window.env.SMARTLOOK === 'true') {
			require('./smartlook');
		}

		if (window.env.GOOGLE_ANALYTICS === 'true') {
			require('./analytics');
		}
	}

	fetch(`${window.env.GRAPHQL_BASE_URL}/graphql/schema.json`, { cache: 'no-store' })
		.then((result) => result.json())
		.then((result) => {
			window.localStorage.removeItem('refresh_count');
			const client = createApolloClient(result);
			const store = configureStore();
			const root = document.getElementById('root');
			render(<Root client={client} store={store} />, root);
		})
		.catch(() => {
			let refreshCount = window.localStorage.getItem('refresh_count') || 1;
			if (refreshCount < 6) {
				refreshCount++;
				window.localStorage.setItem('refresh_count', refreshCount);
				window.location.reload();
			}
		});
} else {
	auth.removeToken();

	if (process.env.NODE_ENV === 'development') {
		render(<Homepage />, document.getElementById('root'));
	} else {
		const { language } = auth.getTokenData(auth.getToken());
		const lang = language || window.navigator.language.slice(0, 2);
		const appUrl = new URL(location.href);
		const appParams = new URLSearchParams(appUrl.search);

		const newUrl = new URL(
			['skoda.survio.com', 'stage-skoda.survio.com'].includes(location.host)
				? `${location.origin}/login`
				: `${window.env.HOME_URL}/${lang}/${LOGIN_URL_SUFFIX[lang]}`,
		);

		for (const key of appParams.keys()) {
			if (key.startsWith(UTM_PREFIX)) {
				newUrl.searchParams.set(key, appParams.get(key));
				appUrl.searchParams.delete(key);
			}
		}
		newUrl.searchParams.set('redirect', appUrl.href);
		window.location.replace(newUrl.href);
	}
}
