import styled from '@emotion/styled';
import { ImageUploaderProps, LabelProps, ButtonsProps } from './';

export const StyledImageUploader = styled.div<
	Partial<
		Omit<
			ImageUploaderProps & {
				bubble?: boolean;
				error?: boolean;
				withButtons?: boolean;
				mini?: boolean;
			},
			'onChange'
		>
	>
>`
	cursor: ${({ disabled, image, mini }) =>
		mini ? 'pointer' : disabled ? 'not-allowed' : image ? 'auto' : 'pointer'};
	display: inline-flex;
	flex-direction: column;
	transition: all 200ms ease-in-out;
	width: ${({ maxWidth }) => maxWidth}px;
	height: ${({ maxHeight }) => maxHeight}px;
	.border {
		position: relative;
		z-index: 500;
		border: ${({ theme: { colors }, disabled, error, image, bubble, mini, loadingg }) => {
			switch (true) {
				case mini && !(image || loadingg) && !error:
					return `1px dashed ${colors.grey_shades_with_blue[200]}`;
				case mini && error:
					return `1px dashed ${colors.red[600]}`;
				case mini && (!!image || loadingg):
					return '2px solid transparent';
				case !!image && !mini:
					return `1px solid ${bubble ? colors.red[600] : 'rgba(0, 0, 0, 0.1)'}`;
				case disabled:
					return `1px solid ${colors.black[10]}`;
				case error:
					return `1px solid ${colors.red[600]}`;
				default:
					return `1px dashed ${colors.grey_shades_with_blue[300]}`;
			}
		}};

		border-radius: ${({ mini, image }) => (mini ? (image ? 3 : 14) : 10)}px;
		display: flex;
		justify-content: center;
		flex: 1 0 auto;
		&:hover {
			${({ theme, disabled, error, image }) =>
				!image && !disabled && !error && `border: 2px solid ${theme.colors.primary[285]}`};
			${({ mini, theme }) => mini && `border: 2px solid ${theme.colors.primary[500]}`};
		}

		.itemLabel {
			white-space: nowrap;
		}
	}
`;

export const BorderedContent = styled.div<
	Partial<
		Omit<ImageUploaderProps & { error: boolean; isLoading: boolean; mini: boolean }, 'onChange'>
	>
>`
	position: absolute;
	height: 100%;
	top: 2px;
	transition: all 200ms;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	margin: -2px;
	background-color: ${({ theme: { colors } }) => colors.white};
	width: 100%;
	z-index: 100;
	.icon {
		transition: color 200ms;
		color: ${({ theme: { colors }, error, disabled }) =>
			disabled
				? colors.grey_shades_with_blue[300]
				: error
					? colors.red['600']
					: colors.grey_shades_with_blue[500]};
		margin-bottom: ${({ theme, mini }) => !mini && theme.helpers.spacer()}px;
	}
	.image {
		position: absolute;
		top: ${({ mini }) => (mini ? '-2px' : 0)};
		left: ${({ mini }) => (mini ? '-2px' : 0)};
		z-index: ${({ mini }) => (mini ? -1 : 'auto')};
		width: ${({ maxWidth, mini }) => (mini ? `${maxWidth}px` : '100%')};
		height: ${({ maxHeight, mini }) => (mini ? `${maxHeight}px` : '100%')};
		border-radius: ${({ mini }) => (mini ? 3 : 9)}px;
		transition: opacity 200ms;
		opacity: ${({ image, isLoading }) => (image && !isLoading ? 1 : isLoading ? 0.2 : 0)};
		background-image: url(${({ image }) => image});
		background-position: center;
		background-repeat: no-repeat;
		background-size: ${({ backgroundSize }) => backgroundSize};
	}
`;

export const Label = styled.div<LabelProps>`
	${({
		theme: {
			typography: { textSemibold250, textRegular200 },
		},
		secondary,
	}) => (secondary ? textRegular200 : textSemibold250)};
	color: ${({ theme: { colors }, secondary, error, disabled }) =>
		disabled
			? colors.grey_shades_with_blue[300]
			: secondary
				? colors.black[50]
				: error
					? colors.red[600]
					: colors.grey_shades_with_blue[500]};
	transition: all 200ms;
	text-align: center;
`;

export const Buttons = styled.div<ButtonsProps>`
	display: flex;
	flex: 0 1 auto;
	transition: all 200ms;
	overflow: hidden;
	margin-top: ${({ theme, isVisible }) => (isVisible ? theme.helpers.spacer() : 0)}px;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	max-height: ${({ isVisible }) => (isVisible ? '40px' : '0px')};
	// IE11 & FF fix
	min-height: 0;

	.withRightMargin {
		margin-right: ${({ theme }) => theme.helpers.spacer()}px;
	}

	.bubble-wrapper > div[data-tippy-root] {
		width: 100%;
		max-width: 272px;
	}
`;

export const BubbleContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 18px 20px 18px 18px;
	.sure-label {
		display: flex;
		flex: 1 0 auto;
	}
	.close-delete-button {
		margin-left: 28px;
	}
`;
