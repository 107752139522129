import gql from 'graphql-tag';

export default gql`
	fragment Scrub on Response {
		deleted
		answers {
			questionId
			answer {
				... on AnswerDataChoices {
					list {
						choice {
							text
							id
							open
							ordinal
						}
						ownText
					}
				}
				... on AnswerDataRating {
					rating
					maximum
				}
				... on AnswerDataMatrixText {
					rows {
						rowId
						texts
					}
				}
				... on AnswerDataMatrixChoiced {
					rows {
						rowId
						colIds
						selected
					}
				}
				... on AnswerDataSemanticDifferential {
					list {
						row {
							id
							ordinal
						}
						answer {
							rating
							maximum
						}
					}
				}
				... on AnswerDataNPS {
					rating
				}
				... on AnswerDataText {
					text
				}
				... on AnswerDataDividePoints {
					items {
						id
						item {
							id
							text
							ordinal
						}
						assigned
					}
				}
				... on AnswerDataOrder {
					order
				}
			}
		}
	}
`;
