import FRAGMENT from '~/cache/fragments/results/chartSettings';

export const client = {
	defaults: {
		analyzeChartSettings: [
			{
				id: 0,
				type: 'column',
				order: 'dsc',
				labels: true,
				color: {
					key: '',
					list: [],
					__typename: 'AnalyzeChartSettingsColor',
				},
				__typename: 'AnalyzeChartSettings',
			},
		],
	},
	resolvers: {
		Query: {
			chartSettings: (_, { question }, { cache }) => {
				return {
					...(cache.readFragment({
						fragment: FRAGMENT,
						id: `AnalyzeChartSettings:${question}`,
					}) || {
						id: question,
						type: 'default',
						percentage: false,
						labels: true,
						order: 'dsc',
						color: {
							key: '',
							list: [],
							__typename: 'AnalyzeChartSettingsColor',
						},
						__typename: 'AnalyzeChartSettings',
					}),
				};
			},
		},
		Mutation: {
			setChartSettings: (_, { options }, { cache }) => {
				const id = `AnalyzeChartSettings:${options.id}`;
				const fragment = FRAGMENT;
				let data = cache.readFragment({
					fragment,
					id,
				});
				if (options.color) options.color.__typename = 'AnalyzeChartSettingsColor';
				data = {
					...data,
					...options,
					__typename: 'AnalyzeChartSettings',
				};
				return cache.writeFragment({ fragment, id, data });
			},
		},
	},
};
