import React from 'react';
import { CountrySelector, usePhoneInput } from 'react-international-phone';
import { useTheme } from '@emotion/react';
import TextField, { TextFieldProps } from '../TextField';

import './PhoneInput.css';

export type PhoneInputProps = Omit<TextFieldProps, 'onChange'> & {
	value: string;
	width?: number;
	editable?: boolean;
	onChange?: (phone: string) => void;
	defaultCountry?: string;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

// refactor function below with forwardRef
const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
	({ name, onChange, value = '', width = 200, editable = true, defaultCountry, ...rest }, ref) => {
		const theme = useTheme();
		const { inputValue, handlePhoneValueChange, country, setCountry } = usePhoneInput({
			defaultCountry: defaultCountry || 'us',
			value,
			onChange: (data) => {
				if (onChange) {
					onChange(data.phone);
				}
			},
		});

		return (
			<TextField
				{...rest}
				width={width}
				editable={editable}
				renderLeft={
					<CountrySelector
						selectedCountry={country.iso2}
						onSelect={(country) => setCountry(country.iso2)}
						style={
							{
								'--grey-shades-with-blue-100': theme.colors.grey_shades_with_blue[100],
								'--grey-shades-with-blue-200': theme.colors.grey_shades_with_blue[200],
								'--primary-blueberry-150': theme.colors.blueberry[150],
								'--shadow100': theme.shadows.values[100],
								// Text Regular / 300
								fontFamily: 'Inter',
								fontStyle: 'normal',
								fontWeight: 400,
								fontSize: '14px',
								lineHeight: '20px',
							} as React.CSSProperties
						}
						dropdownArrowClassName="icon-arrow-down-16"
					/>
				}
			>
				<input
					id="input-ui"
					className="phone-input"
					value={inputValue}
					type="tel"
					name={name}
					onChange={handlePhoneValueChange}
					ref={ref}
				/>
			</TextField>
		);
	},
);

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
