export const formatLang = (lang) => {
	switch (lang) {
		case 'en':
			return 'enUS';
		case 'no':
			return 'nb';
		case 'br':
			return 'ptBR';
		default:
			return lang;
	}
};
