import tableTransform from '~/pages/Results/table';
import query from '~/cache/query';

export default query('survey', {
	fragments: ['QuestionsIndividual'],
	options: (ownProps) => ({ variables: { uid: ownProps.uid } }),
	mapProps: ({ survey, loading }) => {
		if (loading) {
			return {
				loadingCols: true,
			};
		}
		let cols = [];
		if (survey && survey.questions) {
			survey.questions.map(
				(question) => (cols = [...cols, ...tableTransform(question.__typename, question, true)]),
			);
		}
		return {
			loadingCols: false,
			cols,
		};
	},
});
