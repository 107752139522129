import React, {
	FC,
	forwardRef,
	memo,
	MouseEvent,
	ReactNode,
	RefAttributes,
	RefObject,
	useCallback,
} from 'react';
import { DropdownVariant, StyledDropdownDefaultButton, StyledDropdownButtonButton } from './';
import Icon from '../Icon';
import Button, { ButtonType } from '../Button';
import { MenuSize } from '../Menu';
import { ThemeType } from '../ThemeProvider/theme';
import { Tooltip } from '../Tooltip';
import { IconNameType } from '../Icon/IconNamesList';

type DropdownButtonPropsBase = {
	actionBtnIcon?: IconNameType;
	actionBtnTooltip?: ReactNode;
	children: ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
	dark?: boolean;
	disabled?: boolean;
	onActionButtonClick?: (e: MouseEvent<HTMLElement>) => void;
	open?: boolean;
	renderLeft?: ReactNode;
	size?: MenuSize;
	theme?: ThemeType;
	type?: ButtonType;
	variant?: DropdownVariant;
	width?: string | number;
};
export type DropdownButtonProps = DropdownButtonPropsBase & RefAttributes<HTMLElement>;

export const DropdownButton: FC<DropdownButtonProps> = forwardRef<
	HTMLElement,
	DropdownButtonPropsBase
>(
	(
		{
			open,
			size,
			children,
			variant,
			renderLeft,
			onActionButtonClick,
			actionBtnTooltip,
			actionBtnIcon,
			...props
		}: DropdownButtonPropsBase,
		ref: RefObject<HTMLDivElement>,
	) => {
		const handleOnActionBtnClick = useCallback(
			(e) => {
				e.stopPropagation();
				onActionButtonClick && onActionButtonClick(e);
			},
			[onActionButtonClick],
		);

		return variant === 'button' ? (
			<StyledDropdownButtonButton ref={ref} open={open} type="button" size={size} {...props}>
				<div className="buttonChildren">{children}</div>
				<Icon
					className="right-arrow"
					name={size === 'sm' ? 'arrow-down-16' : 'very-small-arrow-down-16'}
				/>
			</StyledDropdownButtonButton>
		) : (
			<StyledDropdownDefaultButton
				ref={onActionButtonClick ? undefined : ref}
				open={open}
				size={size}
				type="button"
				renderLeft={renderLeft}
				{...props}
			>
				{renderLeft}
				{children}
				<Icon className="right-arrow" name="chevrondown-16" />
				{onActionButtonClick && (
					<>
						<div className="btnSeparator" />
						<Tooltip description content={actionBtnTooltip} placement="top" offset={[0, 12]}>
							<Button
								size="sm"
								variant="icon-div"
								startIcon={actionBtnIcon}
								className="actionButton"
								onClick={handleOnActionBtnClick}
								color="red"
							/>
						</Tooltip>
					</>
				)}
				{onActionButtonClick && <div className="faker" ref={ref} />}
			</StyledDropdownDefaultButton>
		);
	},
);

DropdownButton.displayName = 'DropdownButton';

export default memo(DropdownButton) as FC<DropdownButtonProps>;
