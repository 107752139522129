import gql from 'graphql-tag';

export default (children, fragments) => gql`
		query getTemplate($uid: String!) {
			surveyTemplates {
				template(uid: $uid) {
					${children}
				}
			}
		}
		${fragments}
	`;
