import gql from 'graphql-tag';

export default gql`
	fragment Experiments on User {
		experiments {
			data
			name
			variant
			state
		}
	}
`;
