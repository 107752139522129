import React, { useCallback, useEffect, memo } from 'react';
import { Notification as NotificationUI } from 'ui';
import PropTypes from 'prop-types';

const Notification = ({ onClose, id, variant, children, content, className, howLong = 5000 }) => {
	const handleClose = useCallback(() => onClose(id), [id, onClose]);

	useEffect(() => {
		if (typeof howLong === 'number') {
			const timeout = setTimeout(handleClose, howLong);
			return () => clearTimeout(timeout);
		}
	}, [handleClose, howLong]);

	return (
		<NotificationUI
			variant={variant}
			onClose={handleClose}
			inModal={document.body.style.overflow === 'hidden'}
			className={className}
		>
			{children || content}
		</NotificationUI>
	);
};

Notification.propTypes = {
	children: PropTypes.element,
	content: PropTypes.element,
	className: PropTypes.string,
	howLong: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	id: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.string,
};

export default memo(Notification);
