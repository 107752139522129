import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const plural = ({
	number,
	stringId,
	stringValues,
	stringsArray,
	prefix,
	suffix,
	empty,
	specialZeroString,
	firstIndex = 0,
}) => (
	<>
		{prefix}
		{!empty && (
			<FormattedHTMLMessage
				id={
					specialZeroString && number === 0
						? specialZeroString
						: number === 1
						? stringsArray?.[0] || `${stringId}-${firstIndex}`
						: 1 < number && number < 5
						? stringsArray?.[1] || `${stringId}-${firstIndex + 1}`
						: stringsArray?.[2] || `${stringId}-${firstIndex + 2}`
				}
				values={stringValues}
			/>
		)}
		{suffix}
	</>
);

export default plural;
