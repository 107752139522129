import React, { FC, memo, useCallback, useLayoutEffect, useRef } from 'react';

import { SliderProps, SliderWrapper, StyledSlider } from '.';

export const Slider: FC<SliderProps> = ({
	className,
	onChange,
	min,
	max,
	...props
}: SliderProps) => {
	const ref = useRef(null);

	useLayoutEffect(() => {
		const setSize = () => {
			const val = ref.current.value;

			ref.current.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';
		};

		setSize();

		const range = ref.current;
		range.addEventListener('input', setSize);
		return () => range.removeEventListener('input', setSize);
	}, [min, max]);

	const handleOnChange = useCallback(
		(e) => {
			onChange && onChange(Number(e.target.value));
		},
		[onChange],
	);

	return (
		<SliderWrapper className={className}>
			<StyledSlider
				{...props}
				min={min}
				max={max}
				type="range"
				ref={ref}
				onChange={handleOnChange}
			/>
		</SliderWrapper>
	);
};

Slider.defaultProps = {
	max: 100,
	min: 0,
	step: 1,
};

export default memo(Slider) as FC<SliderProps>;
