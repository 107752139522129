import gql from 'graphql-tag';

export default gql`
	mutation saveShareResultsSettings(
		$uid: String!
		$shareResultsSettings: ShareResultSettingsInput!
	) {
		saveShareResultsSettings(uid: $uid, shareResultsSettings: $shareResultsSettings) {
			url
			published
			showIndividualResponses
			enableResultsAndResponsesExport
			showSurveyStatistics
			showSharing
			password
			passwordActive
			introductoryText
		}
	}
`;
