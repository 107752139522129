import styled from '@emotion/styled';
import { HorizontalNavigationItemProps, HorizontalNavigationProps } from '.';
import { Typography } from '../Typography';

export const Navigation = styled.div<HorizontalNavigationProps>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	position: relative;
`;

export const Items = styled.div<HorizontalNavigationProps>`
	display: flex;
	> a {
		&:not(:first-of-type) {
			margin-left: 12px;
		}
		&:not(:last-child) {
			margin-right: 12px;
		}
	}
`;

export const HorizontalNavigationItem = styled(Typography)<HorizontalNavigationItemProps>`
	white-space: nowrap;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	color: ${({ theme: { colors }, disabled, active }) =>
		disabled
			? colors.grey_shades_with_blue[300]
			: active
				? colors.primary[500]
				: colors.grey_shades_with_blue[500]};
	transition: color 300ms ease-out;

	&:hover {
		color: ${({ theme, active, disabled }) =>
			!disabled && !active ? theme.colors.grey_shades_with_blue[700] : undefined};
	}
`;

export const SlideBar = styled.div<HorizontalNavigationProps>`
	display: flex;
	width: 100%;
	position: relative;
	height: 3px;
`;

export const Slider = styled.div<HorizontalNavigationProps>`
	position: absolute;
	bottom: -1px;
	left: 0;
	background-color: ${({ theme }) => theme.colors.primary[500]};
	height: 100%;
	z-index: 1;
	border-radius: 2px 2px 0 0;
`;

export const Underline = styled.div<HorizontalNavigationProps>`
	position: absolute;
	bottom: -1px;
	left: 0;
	height: 1px;
	width: 100%;
	background-color: ${({ theme, noUnderline }) =>
		noUnderline ? 'transparent' : theme.colors.grey_shades_with_blue[50]};
`;

export const ItemContent = styled.div<Omit<HorizontalNavigationItemProps, 'onClick'>>`
	opacity: ${({ active }) => (active ? 1 : 0)};
	visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
	max-height: ${({ active }) => (active ? '100%' : 0)};
	overflow: hidden;
`;

export const ItemWrapper = styled.div`
	display: flex;

	&:not(:last-child) {
		margin-right: 24px;
	}
`;
