import gql from 'graphql-tag';

export default gql`
	fragment Invoices on User {
		invoices {
			number
			issued
			link
			price
			name
			internal
			currency
		}
	}
`;
