import styled from '@emotion/styled';
import { HighlighterProps } from '.';
import { getThemeColor } from '../../utils';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
	0% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
`;

export const StyledHighlighter = styled.div<HighlighterProps>`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 3px solid ${({ color, theme }) => getThemeColor(color, theme)};
	position: absolute;

	&:before {
		position: absolute;
		top: 4px;
		left: 4px;
		display: flex;
		content: '';
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: ${({ color, theme }) => getThemeColor(color, theme)};
	}

	&:after {
		content: '';
		position: absolute;
		top: -100%;
		left: -100%;
		width: 54px;
		height: 54px;
		opacity: 0;
		border-radius: 50%;
		background-color: ${({ color, theme }) => getThemeColor(color, theme)};
		animation: ${pulse} 2.1s ease-out infinite;
	}
`;
