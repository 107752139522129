import styled from '@emotion/styled';
import { ButtonBase } from '../Button';
import { DropdownButtonProps } from './';
import { DropdownProps } from './';
import { Item } from '../Menu';
import { LabelProps } from '../Label';
import { css } from '@emotion/react';

export const StyledDropdownDefaultButton = styled(ButtonBase)<DropdownButtonProps>`
	${({ size, theme: { typography } }) => {
		switch (size) {
			case 'lg':
				return typography.textRegular300;
			case 'sm':
				return typography.textRegular300;
			case 'xs':
				return typography.textSemibold200;
			default:
			case 'md':
				return typography.textRegular300;
		}
	}};
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.grey_shades_with_blue[300] : theme.colors.grey_shades_with_blue[800]};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	text-align: start;
	word-break: break-word;
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	border-radius: 8px;
	background-color: ${({ open, theme, dark }) =>
		dark ? theme.colors.black[80] : open ? theme.colors.white : theme.colors.white};
	border: 1px solid
		${({ open, theme: { colors }, dark }) =>
			open
				? dark
					? colors.grey_shades_with_blue[200]
					: colors.primary[400]
				: colors.grey_shades_with_blue[200]};
	> span {
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: ${({ size }) => {
			switch (size) {
				case 'lg':
					return '15px 19px 15px 0';
				case 'sm':
					return '7px 11px 7px 0';
				case 'xs':
					return '3px 9px 3px 0';
				default:
				case 'md':
					return '9px 11px 9px 0';
			}
		}};
		padding-left: ${({ renderLeft }) => (renderLeft ? 12 : 0)}px;
		border-radius: inherit;
	}
	&:hover {
		background-color: ${({ theme, disabled, dark }) =>
			!disabled && (dark ? theme.colors.black[70] : theme.colors.grey_shades_with_blue[5])};
		border: 1px solid
			${({ open, theme: { colors }, dark }) =>
				open
					? dark
						? colors.grey_shades_with_blue[200]
						: colors.primary[400]
					: colors.grey_shades_with_blue[250]};
	}
	&:active {
		background-color: ${({ theme, disabled, dark }) =>
			!disabled && (dark ? theme.colors.black[70] : theme.colors.grey_shades_with_blue[5])};
	}
	.right-arrow {
		margin-left: ${({ size }) => (size === 'xs' ? 8 : 16)}px;
		transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
		transition: transform 200ms ease-out;
		color: ${({ disabled, theme: { colors }, dark }) =>
			!disabled
				? dark
					? colors.grey_shades_with_blue[200]
					: colors.grey_shades_with_blue[400]
				: colors.grey_shades_with_blue[300]};
	}
	.placeholder {
		&:hover {
			background-color: transparent;
		}
		.itemLabel {
			color: ${({ theme }) => theme.colors.black[100]};
			${({ theme, size }) =>
				size === 'xs' &&
				css`
					${theme.typography.textRegular200}
					line-height: 24px
				`};
		}
	}
	.btnSeparator {
		display: flex;
		flex: 1 0 auto;
		height: 24px;
		width: 1px;
		background-color: ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
		margin: 0 12px;
	}
	.actionButton {
		display: flex;
		align-items: center;
		color: ${({ theme }) => theme.colors.grey_shades_with_blue[500]};
		z-index: 4;
	}
	.faker {
		position: absolute;
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		top: -1px;
		left: -1px;
		border-radius: inherit;
		padding: inherit;
		z-index: 3;
	}
`;

export const StyledDropdownButtonButton = styled(ButtonBase)<DropdownButtonProps>`
	${({ theme: { typography: t }, size }) =>
		size === 'sm' ? t.textSemibold200 : t.textSemibold300};
	color: ${({ theme: { colors }, disabled, open, size }) =>
		disabled
			? colors.grey_shades_with_blue[300]
			: open
				? colors.grey_shades_with_blue[700]
				: size === 'sm'
					? colors.grey_shades_with_blue[500]
					: colors.grey_shades_with_blue[600]};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	&:hover {
		color: ${({ theme, disabled }) => !disabled && theme.colors.grey_shades_with_blue[700]};
	}
	&:active {
		color: ${({ theme, disabled }) => !disabled && theme.colors.grey_shades_with_blue[700]};
	}
	.right-arrow {
		display: flex;
		flex: 1 0 auto;
		margin-left: ${({ theme }) => `${theme.helpers.spacer(0.5)}px`};
		transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
		transition: all 200ms ease-out;
		${({ disabled, theme }) => disabled && theme.colors.grey_shades_with_blue[300]};
	}
`;

export const DropdownItem = styled(Item)`
	padding: 0;
	margin-left: 0;
	max-width: ${({ dropdownButton }) => (dropdownButton ? 'calc(100% - 32px)' : 'unset')};
	background-color: transparent;
	.itemLabelColor {
		display: inline-block;
		vertical-align: middle;
		width: 14px;
		height: 14px;
		border-radius: 4px;
		margin-right: 16px;
		background: ${({ label }) => (typeof label === 'string' ? label : 'none')};
		border: 1px solid rgba(0, 0, 0, 0.2);
	}

	.itemLabel {
		margin-left: ${({ size }) => (size === 'xs' ? 10 : size === 'lg' ? 16 : 12)}px !important;
		color: ${({ theme: { colors }, dark }) => (dark ? colors.white : colors.black[100])};
		text-align: left; // IE11
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.activeBackground {
		display: none;
	}
`;

export const StyledDropdown = styled.div<Omit<DropdownProps, 'onChange'>>`
	display: inline-flex;
	flex-direction: ${({ mini, variant }) => (variant === 'colorPicker' && mini ? 'row' : 'column')};
	${({ mini, variant }) => (variant === 'colorPicker' && mini ? 'align-items: center' : '')};
	width: ${({ width, variant }) =>
		variant === 'button'
			? 'auto'
			: typeof width === 'string'
				? width
				: typeof width === 'number'
					? `${width}px`
					: 'auto'};
	min-width: ${({ variant }) => (variant === 'button' ? 'auto' : '40px')};
	${({ mini, variant }) =>
		variant === 'colorPicker' && mini ? 'justify-content: space-between' : ''};

	.hidden-input {
		position: absolute;
		display: none;
	}

	.menu-content {
		padding: 10px 10px 10px 8px;

		.chrome-picker {
			width: 100% !important;
			box-shadow: none !important;

			.flexbox-fix {
				&:first-of-type {
					> div:first-of-type {
						display: none !important;
					}
				}

				&:last-child {
					display: none !important;
				}
			}

			> div {
				&:first-of-type {
					border-radius: 4px !important;
				}

				&:last-child {
					padding: 15px 0 !important;
				}
			}

			.hue-horizontal {
				border-radius: 30px;
			}
		}

		.flex-column {
			display: flex;
			flex-direction: column;
		}

		.flex {
			display: flex;
			align-items: baseline;

			.typo {
				margin-right: 12px;
			}

			.input {
				width: 100%;
				min-width: auto;
			}
		}
	}

	.dropdown-label > .label {
		${({ variant, mini }) => (variant === 'colorPicker' && mini ? 'margin: 0' : '')}
	}
`;

export const StyledErrorMsg = styled.div<LabelProps>`
	display: flex;
	color: ${({ theme }) => theme.colors.red[600]};
	${({ theme }) => theme.typography.textRegular200};
	margin-top: ${({ error }) => (error ? 8 : 0)}px;
	transition:
		max-height 200ms ease-out,
		opacity 200ms ease-out 100ms;
	max-height: ${({ error }) => (error ? 20 : 0)}px;
	opacity: ${({ error }) => (error ? 1 : 0)};
	overflow: hidden;
`;

export const StyledMiniColorPickerWrapper = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const StyledMiniColorPicker = styled.div<
	LabelProps & {
		rounded?: boolean;
	}
>`
	display: flex;
	width: ${({ rounded }) => (rounded ? '24px' : '32px')};
	height: 24px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: ${({ rounded }) => (rounded ? '500px' : '6px')};
	cursor: pointer;
	background-color: ${({ color }) => color};
`;
