import styled from '@emotion/styled';
import { StyledIcon } from '~/ui/components/Icon';

export const SegmentationButtonStyled = styled.label`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: calc(100% / 2 - 6px);
	max-width: 166px;
	height: 184px;
	border-radius: 16px;
	padding: 16px;
	position: relative;
	${({ theme }) => theme.typography.textRegular300};
	cursor: pointer;
	border: none;
	outline: none;
	background: transparent;
	box-sizing: border-box;

	@media (min-width: 360px) {
		${({ theme }) => theme.typography.textRegular400};
	}

	&:hover {
		.segmentationBtn-bg {
			transform: scaleX(1.04) scaleY(1.025);
		}
	}

	&:nth-of-type(1) {
		.segmentationBtn-bg {
			background: ${({ theme }) => theme.colors.primary[150]};
		}
		color: ${({ theme }) => theme.colors.primary[800]};
	}
	&:nth-of-type(2) {
		.segmentationBtn-bg {
			background: ${({ theme }) => theme.colors.secondary[100]};
		}
		color: ${({ theme }) => theme.colors.secondary[900]};
	}
	&:nth-of-type(3) {
		.segmentationBtn-bg {
			background: ${({ theme }) => theme.colors.upgrade[100]};
		}
		color: ${({ theme }) => theme.colors.upgrade[850]};
	}
	&:nth-of-type(5) {
		.segmentationBtn-bg {
			background: #e5e1f5;
		}
		color: #413379;
	}
	&:nth-of-type(4) {
		.segmentationBtn-bg {
			background: #f5efe6;
		}
		color: ${({ theme }) => theme.colors.yellow[900]};
	}
	&:nth-of-type(6) {
		.segmentationBtn-bg {
			background: #f2f2f5;
		}
		color: ${({ theme }) => theme.colors.grey_shades_with_blue[600]};
	}

	${StyledIcon} {
		flex: 1;
		z-index: 2;
	}

	input {
		position: absolute;
		opacity: 0;
		display: none;
	}

	.segmentationBtn-label {
		display: flex;
		align-items: center;
		flex: 1;
		z-index: 2;
		text-align: center;
	}

	.segmentationBtn-bg {
		position: absolute;
		top: 0;
		left: 0;
		background: red;
		border-radius: 16px;
		width: 100%;
		height: 100%;
		z-index: 1;
		transition: transform 100ms ease-in-out;
	}
`;
