import gql from 'graphql-tag';

export default gql`
	mutation requestCustomPlan($input: RequestCustomPlanInput!) {
		requestCustomPlan(input: $input) {
			message
			success
		}
	}
`;
