import React, { FC, memo, useCallback } from 'react';
import { Row as StyledRow, Column } from './List.styled';
import { Checkbox } from '../Checkbox';
import { RowProps } from './';

export const Row: FC<RowProps> = ({
	columns,
	active,
	index,
	row,
	onClick,
	padding,
	striped,
	checkable,
	checked,
	onCheck,
}: RowProps) => {
	const handleOnClick = useCallback(() => onClick(row), [row]);

	const handleOnCheck = useCallback(() => onCheck && onCheck(index), [index, onCheck]);

	return (
		<StyledRow onClick={handleOnClick} padding={padding} striped={striped} active={active}>
			{checkable && (
				<Column width={56} textAlign="left" padding={padding}>
					<Checkbox checked={checked} onChange={handleOnCheck} />
				</Column>
			)}
			{columns.map((column) => (
				<Column
					key={column?.dataIndex}
					width={column.width}
					textAlign={column.textAlign}
					padding={padding}
				>
					{column.render ? column.render(row) : row[column?.dataIndex]}
				</Column>
			))}
		</StyledRow>
	);
};

Row.displayName = 'List';

Row.defaultProps = {
	striped: true,
	checkable: false,
};

export default memo(Row);
