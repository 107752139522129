import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { openModal } from '~/actions';
import USER_LANG from '~/cache/queries/user/userLang';
import PRICE_LIST from '~/cache/queries/user/priceList';
import query from '~/cache/query';
import { withSendTracking, withBusinessModel01Handling } from '~/hoc';
import SalesModal from '../components/SalesModalUI';
import withCleverbridge from '~/hoc/withCleverbridge';
import { PRICING_EXPERIMENT } from '~/constants/experiments';

export default compose(
	injectIntl,
	connect(null, {
		openModal,
	}),
	withBusinessModel01Handling,
	graphql(USER_LANG, {
		props: ({ data }) => ({
			userLang: data?.user?.language,
		}),
	}),
	graphql(PRICE_LIST, {
		props: ({ data: { priceList, loading } }) => ({
			priceList,
			loading,
		}),
	}),
	query('account', {
		fragments: ['ActiveService'],
		mapProps: ({ user }) => ({
			provider: user?.activeService?.provider,
			serviceName: user?.activeService?.name,
		}),
	}),
	query('stripe', {
		// skip: ({ isBusinessModel01 }) => !isBusinessModel01,
		fragments: ['Prices'],
		mapProps: ({ stripePriceList, loading }) => ({
			priceList: {
				...stripePriceList.plans.reduce((a, v) => ({ ...a, [v.identifier]: v }), {}),
				currencyCode: stripePriceList.currencyCode,
				loading,
			},
		}),
	}),
	query('stripe', {
		fragments: ['Prices'],
		mapProps: ({ stripePriceList }) => ({
			testPrice23A: stripePriceList?.experiment === PRICING_EXPERIMENT.TEST_PRICE_23_A,
			testPrice24A: stripePriceList?.experiment === PRICING_EXPERIMENT.TEST_PRICE_24_A,
		}),
	}),
	withSendTracking,
	withCleverbridge,
)(SalesModal);
