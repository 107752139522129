import styled from '@emotion/styled';
import { TextFieldProps } from './';
import { getThemeColor } from '../../utils';

const isSmOrsmPhoneInputSize = (size: TextFieldProps['size']) =>
	size === 'md' || size === 'smPhoneInput';

export const TextFieldStyled = styled.label<
	Pick<TextFieldProps, 'width' | 'theme'> & { tabIndex: number }
>`
	outline: none;
	max-width: 100%;
	display: inline-flex;
	flex-direction: column;
	min-width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};

	.textarea-char-counter {
		margin-top: 8px;
	}

	&:focus-within {
		.textarea-char-counter.counter,
		.counter {
			color: ${({ theme }) => theme.colors.black[100]};
		}
	}

	.placeholderHidden {
		position: absolute;
		visibility: hidden;
	}
`;

export const TextFieldRight = styled.div<Omit<TextFieldProps, 'onChange'>>`
	${({ theme }) => theme.typography.textRegular200};
	display: flex;
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[400]};
	margin-right: ${({ size }) => (size === 'xs' ? 10 : 12)}px;
	position: relative;
`;

export const NumberArrowsStyled = styled(TextFieldRight)`
	color: ${({ theme, disabled }) =>
		disabled ? theme.colors.grey_shades_with_blue[300] : theme.colors.grey_shades_with_blue[350]};
	flex-direction: column;
	margin-right: 4px;
`;

export const TextFieldLeft = styled.div<
	Pick<TextFieldProps, 'theme' | 'disabled' | 'editable' | 'size' | 'error'>
>`
	display: flex;
	color: ${({ disabled, editable, error, theme }) => {
		switch (true) {
			case disabled:
				return theme.colors.black[20];
			case !editable:
				return theme.colors.grey_shades_with_blue[500];
			case !!error:
				return theme.colors.red[600];
			default:
				return theme.colors.grey_shades_with_blue[500];
		}
	}};
	margin-left: ${({ size }) => {
		switch (size) {
			case 'xs':
				return '8px';
			case 'sm':
			case 'md':
			case 'lg':
				return '16px';
			case 'smPhoneInput':
				return '0';
		}
	}};
`;

export const TextFieldBase = styled.div<
	Pick<
		TextFieldProps,
		| 'theme'
		| 'disabled'
		| 'editable'
		| 'size'
		| 'error'
		| 'borderColor'
		| 'withCounter'
		| 'renderRight'
		| 'dark'
	>
>`
	cursor: ${({ disabled, editable }) => {
		switch (true) {
			case disabled:
				return 'not-allowed';
			case !editable:
				return 'pointer';
			default:
				return 'text';
		}
	}};

	${NumberArrowsStyled} {
		cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	}

	display: flex;
	border-radius: 8px;
	background: ${({ theme, dark, disabled }) =>
		dark
			? theme.colors.black[dark]
			: disabled
				? theme.colors.grey_shades_with_blue[10]
				: theme.colors.white};
	border: 1px solid
		${({ disabled, error, theme, borderColor, dark }) => {
			if (borderColor) {
				return getThemeColor(borderColor, theme);
			}
			switch (true) {
				case disabled:
					return theme.colors.grey_shades_with_blue[200];
				case !!error:
					return theme.colors.red[600];
				case dark !== undefined:
					return theme.colors.black[dark];
				default:
					return theme.colors.grey_shades_with_blue[200];
			}
		}};
	padding: ${({ size }) => {
		switch (size) {
			case 'xs':
			case 'sm':
				return '7px 0';
			case 'md':
				return '11px 0';
			case 'lg':
				return '8px 0';
			case 'smPhoneInput':
				return '0';
		}
	}};
	box-shadow: 0 1px 2px 0 #1018280d;

	&:hover {
		background: ${({ theme, editable, dark, disabled }) =>
			!editable
				? theme.colors.white
				: dark
					? theme.colors.black[dark - 10]
					: disabled
						? theme.colors.grey[200]
						: theme.colors.grey_shades_with_blue[5]};
		border: ${({ disabled, editable, error, theme, borderColor, dark }) =>
			!borderColor &&
			!disabled &&
			editable &&
			!error &&
			`1px solid ${
				dark ? theme.colors.black[dark - 10] : theme.colors.grey_shades_with_blue[200]
			}`};
	}

	&:focus-within,
	&:active {
		border-color: ${({ error, theme, borderColor, disabled, editable, dark }) =>
			borderColor
				? getThemeColor(borderColor, theme)
				: error || disabled || !editable
					? undefined
					: dark
						? theme.colors.black[50]
						: theme.colors.primary[400]};
		background: ${({ theme, dark, disabled }) =>
			dark
				? theme.colors.black[dark - 10]
				: disabled
					? theme.colors.grey[200]
					: theme.colors.white};
		${({ theme, size, disabled, error }) =>
			size !== 'xs' && !error
				? disabled
					? ''
					: `outline: 4px solid ${theme.colors.primary[150]}`
				: ''};

		${TextFieldLeft} {
			color: ${({ error, theme }) => (error ? undefined : theme.colors.primary[500])};
		}
	}

	.children-wrapper {
		align-items: center;

		.view {
			display: flex;
		}
	}

	> div {
		display: flex;
		flex: 1 0 auto;
		max-width: 100%;
		align-items: baseline;

		input,
		textarea {
			width: 0;
			opacity: 1;
			padding: 0;
			flex: 1 0 0;
			resize: none;
			border: none;
			outline: none;
			cursor: inherit;
			box-shadow: none;
			background: transparent;
			color: ${({ disabled, theme, dark }) => {
				switch (true) {
					case disabled:
						return theme.colors.grey_shades_with_blue[300];
					case dark !== undefined:
						return theme.colors.white;
					default:
						return theme.colors.grey_shades_with_blue[800];
				}
			}};
			margin: ${({ size }) =>
				`${size === 'xs' ? 0 : 2}px ${size === 'xs' ? 10 : 16}px ${size === 'xs' ? 0 : 2}px ${
					size === 'xs' ? 10 : 16
				}px`};
			margin-left: ${({ size }) => {
				switch (size) {
					case 'xs':
						return '8px';
					case 'sm':
						return '12px';
					case 'smPhoneInput':
						return '8px';
					case 'md':
						return '14px';
					case 'lg':
						return '16px';
				}
			}};

			::selection {
				background: ${({ theme }) => theme.colors.primary[225]};
			}

			${({ size, theme: { typography } }) =>
				`${
					size === 'xs'
						? typography.textRegular200
						: size === 'lg'
							? typography.textMedium500
							: typography.textRegular300
				}`};

			&[disabled] {
				-webkit-text-fill-color: ${({ editable, theme }) =>
					editable ? theme.colors.black[20] : theme.colors.grey_shades_with_blue[500]};
			}

			::placeholder {
				color: ${({ theme, dark }) =>
					dark ? theme.colors.white : theme.colors.grey_shades_with_blue[400]};
				opacity: ${({ dark }) => (dark ? 0.5 : 1)};
			}

			::-ms-clear {
				display: none;
			}
		}

		input {
			margin-right: ${({ withCounter, renderRight }) =>
				withCounter || renderRight ? '8px' : 'inherit'};
			max-width: ${({ size }) =>
				`calc(100% - ${
					2 *
					(size === 'xs'
						? 8
						: isSmOrsmPhoneInputSize(size) || size === 'sm'
							? 12
							: size === 'md'
								? 14
								: 16)
				}px)`};
		}

		textarea {
			margin: 2px 12px;
		}

		//css hack because of input:not(:placeholder-shown)
		${TextFieldLeft} {
			order: -1;
		}

		// has value
		input:not(:placeholder-shown),
		textarea:not(:placeholder-shown) {
			color: ${({ disabled, error, theme, dark }) =>
				disabled || error
					? undefined
					: dark
						? theme.colors.white
						: theme.colors.grey_shades_with_blue[800]};
			-webkit-text-fill-color: ${({ disabled, error, theme, dark }) =>
				disabled || error
					? undefined
					: dark
						? theme.colors.white
						: theme.colors.grey_shades_with_blue[800]};

			+ ${TextFieldLeft} {
				color: ${({ disabled, error, theme }) =>
					disabled || error ? undefined : theme.colors.primary[500]};
			}
		}

		// this workaround is used because Chrome changes the background color to a light blue colour when autofill
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			transition: background-color 5000s ease-in-out 0s;
		}

		// next few lines are for disabling arrows in number-type input
		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type='number'] {
			-moz-appearance: textfield;
		}
	}
`;

export const TextFieldError = styled.div<
	Pick<TextFieldProps, 'theme' | 'error' | 'withCounter' | 'counter'>
>`
	color: ${({ theme }) => theme.colors.red[600]};
	${({ theme }) => `${theme.typography.textRegular200}`};
	transition:
		margin 200ms ease-out,
		max-height 200ms ease-out,
		opacity 200ms ease-out 100ms;
	max-height: ${({ error }) => (error ? 40 : 0)}px;
	opacity: ${({ error }) => (error ? 1 : 0)};
	visibility: ${({ error }) => (error ? 'visible' : 'hidden')};
	overflow: hidden;
	margin-top: ${({ withCounter, counter, error }) =>
		!error ? 0 : withCounter && counter ? 4 : 8}px;
`;
