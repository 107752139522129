import styled from '@emotion/styled';

import { LabelProps } from './';

export const StyledLabelContainer = styled.div<LabelProps>`
	display: flex;
	flex-direction: column;
`;

export const StyledLabel = styled.div<LabelProps>`
	display: flex;
	${({ theme, labelTypo }) => theme.typography[labelTypo]}
	margin-bottom: ${({ error, theme: { helpers } }) =>
		error ? helpers.spacer(0.5) : helpers.spacer()}px;
	transition: color 200ms ease-out;
	color: ${({ disabled, theme, colorVariant }) => {
		switch (colorVariant) {
			case 'greyShadesWithBlue':
				return disabled
					? theme.colors.grey_shades_with_blue[300]
					: theme.colors.grey_shades_with_blue[800];
			default:
			case 'black':
				return disabled ? theme.colors.black[20] : theme.colors.black[100];
		}
	}};

	&:after {
		display: ${({ required }) => (required ? 'contents' : 'none')};
		//color: ${({ theme }) => theme.colors.primary[500]};
		white-space: pre;
		content: ' *';
	}
`;

export const StyledErrorMsg = styled.div<LabelProps>`
	display: ${({ error }) => (error ? 'flex' : 'none')};
	color: ${({ theme }) => theme.colors.red[500]};
	${({ theme }) => theme.typography.textRegular200};
	margin-bottom: 14px;
`;
