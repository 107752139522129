export default {
	resolvers: {
		SurveySettings: {
			mockStringField: () => 'value',
			mockObjectField: () => ({
				key: 'value',
				nextKey: 'nextValue',
				__typename: 'MockObjectField',
			}),
		},
		Survey: {
			dashboards: () => [
				{
					id: 123,
					logo: 'https://dummyimage.com/200x100/000/fff&text=test',
					title: 'Title',
					createdAt: new Date(),
					__typename: 'SurveyDashboard',
				},
			],
		},
		User: {
			mockUserStringField: () => 'value',
			mockUserObjectField: () => ({
				key: 'value',
				nextKey: 'nextValue',
				__typename: 'MockUserObjectField',
			}),
		},
		Query: {
			mockQuery: () => ({
				mockQueryStringField: 'value',
				mockQueryObjectField: {
					key: 'value',
					nextKey: 'nextValue',
					__typename: 'MockQueryObjectField',
				},
				__typename: 'MockQuery',
			}),
		},
	},
};

/*
query survey($uid: String!) {
  survey(uid: $uid) {
    title
    dashboards @client
    settings {
      headerText
      mockStringField @client
      mockObjectField @client
    }
  }
}

query user {
  user {
    email
    mockUserStringField @client
    mockUserObjectField @client
  }
}

query mockQuery {
  mockQuery @client {
    mockQueryStringField
    mockQueryObjectField
  }
}
 */
