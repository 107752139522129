import React, { FC, memo, MouseEvent, useCallback, useState } from 'react';
import { FilterChipProps, Chip, ChipWrapper } from '.';
import { Typography, Button, Tooltip } from '../../.';

export const FilterChip: FC<FilterChipProps> = ({
	label,
	disabled,
	onCloseClick,
	id,
	interactive = true,
	className,
	...props
}: FilterChipProps) => {
	const [ref, setRef] = useState(null);
	const tooltipDisabled = ref?.scrollWidth <= ref?.offsetWidth;

	const handleOnClick = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			onCloseClick && onCloseClick(id, e);
		},
		[id, onCloseClick],
	);

	return (
		<ChipWrapper className={className}>
			<Tooltip
				content={label}
				disabled={tooltipDisabled}
				description
				maxWidth="none"
				placement="bottom"
			>
				<Chip className={className} {...props} data-testid="ui-filterChip">
					{interactive && (
						<Button
							variant="icon"
							size="xs"
							startIcon="close-small-16"
							className="crossButton"
							disabled={disabled}
							onClick={handleOnClick}
						/>
					)}
					<Typography
						variant="textMedium200"
						color={disabled ? 'grey_shades_with_blue[300]' : 'grey_shades_with_blue[600]'}
						className="typo"
						ref={setRef}
					>
						{label}
					</Typography>
				</Chip>
			</Tooltip>
		</ChipWrapper>
	);
};

export default memo(FilterChip);
