import RESULTS from './results';
import { IN_APP_MODAL } from '~/components/InApps';

/*
key: {
	icon: String, // optional
	name: String, // Optional
	path: String // required
	header: Object.shape({ // optional
		visible: Bool
	})
}
*/
const PAGES = {
	account: {
		path: '/account',
		icon: 'my_account-24',
		title: 'app.multiuser.my-profile',
		header: {
			background: false,
			navigation: false,
			preview: false,
			parent: 'app.settings.account',
		},
	},
	subscriptions: {
		path: '/account/subscriptions',
		icon: 'premium-24',
		title: 'app.account.subscriptions',
		header: {
			background: false,
			navigation: false,
			preview: false,
			parent: 'app.settings.account',
		},
	},
	members: {
		path: '/account/members',
		icon: 'members-24',
		title: 'app.multiuser.members',
		header: {
			background: false,
			navigation: false,
			preview: false,
			parent: 'app.multiuser.team',
		},
	},
	surveyManagement: {
		path: '/account/survey-management',
		icon: 'survey-management-24',
		title: 'app.dashboard.survey-management',
		header: {
			background: false,
			navigation: false,
			preview: false,
			parent: 'app.multiuser.team',
		},
	},
	activities: {
		path: '/account/activities',
		icon: 'members-activity-24',
		title: 'app.multiuser.member-activity',
		header: {
			background: false,
			navigation: false,
			preview: false,
			parent: 'app.multiuser.team',
		},
	},
	accountSettings: {
		path: '/account/settings',
		icon: 'settings-24',
		title: 'app.common.settings',
		header: {
			background: false,
			navigation: false,
			preview: false,
			parent: 'app.multiuser.team',
		},
	},
	notifications: {
		title: 'My Account',
		path: '/account/notifications',
		header: {
			background: false,
			navigation: false,
			preview: false,
		},
	},
	pricing: {
		title: 'Pricing',
		path: '/pricing',
		header: {
			visible: false,
		},
	},
	builder: {
		icon: 'edit',
		title: 'app.common.menu-designer',
		menu: 'app.common.menu-designer',
		path: '/:uid/builder',
		headerTooltip: 'app.support.header-tooltip-questionnaire',
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
			share: true,
		},
	},
	settings: {
		id: 'settings-page',
		icon: 'settings-24',
		menu: 'app.common.menu-settings',
		title: 'app.designer.survey-preferences',
		path: '/:uid/settings',
		headerTooltip: 'app.support.header-tooltip-settings',
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
	},
	advanced: {
		icon: 'advanced-24',
		title: 'app.common.advanced-settings',
		path: '/:uid/settings/advanced',
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
	},
	integrations: {
		icon: 'integrations-24',
		title: 'app.account.tab-integrations',
		path: '/:uid/settings/integrations',
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
	},
	security: {
		icon: 'security-24',
		title: 'app.designer.security',
		path: '/:uid/settings/security',
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
	},
	'get-responses': {
		id: 'get-responses-page',
		icon: 'contact',
		title: 'app.common.menu-collect',
		menu: 'app.common.menu-collect',
		path: '/:uid/get-responses',
		headerTooltip: 'app.support.header-tooltip-responses',
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
	},
	results: {
		id: 'results',
		icon: 'analyse-24',
		title: 'app.results.analyze-results',
		path: '/:uid/results',
		menu: 'app.common.menu-analyze',
		headerTooltip: 'app.support.header-tooltip-analyze',
		type: RESULTS.ANALYZE,
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
	},
	individual: {
		id: 'individual',
		icon: 'individual-responses-24',
		title: 'app.results.individual-responses',
		path: '/:uid/results/individual',
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
		info: IN_APP_MODAL.INDIVIDUAL_RESPONSES,
	},
	summary: {
		id: 'summary',
		icon: 'summary-24',
		title: 'app.results.survey-summary',
		path: '/:uid/results/summary',
		type: RESULTS.SUMMARY,
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
		info: IN_APP_MODAL.SUMMARY_STATS,
	},
	share: {
		id: 'share',
		icon: 'share-24',
		title: 'app.results.share-results',
		path: '/:uid/results/share',
		type: RESULTS.SHARE,
		header: {
			underline: false,
			withShadow: true,
			surveyPath: true,
		},
	},
	dashboard: {
		id: 'dashboard',
		title: 'app.common.surveys',
		path: '/',
		header: {
			navigation: false,
			preview: false,
			search: true,
			surveyPath: true,
		},
	},
	dashboardCreate: {
		title: 'app.dashboard.title',
		path: '/:uid/results/dashboards/create',
		header: {
			visible: false,
		},
	},
	dashboardEdit: {
		title: 'app.dashboard.title',
		path: '/:uid/results/dashboards/:dashboardId',
	},
	dashboardExport: {
		title: 'app.dashboard.title',
		path: '/:uid/results/dashboards/:dashboardId/export',
	},
	dashboardTileExport: {
		title: 'app.dashboard.title',
		path: '/:uid/results/dashboards/:dashboardId/tile/:tileId/export',
	},
	dashboardTileChartExport: {
		title: 'app.dashboard.title',
		path: '/:uid/results/dashboards/:dashboardId/tile/:tileId/export/chart',
	},
	dashboards: {
		id: 'dashboards',
		icon: 'dashboard-24',
		title: 'app.dashboard.title',
		path: '/:uid/results/dashboards',
		type: RESULTS.DASHBOARDS,
		menuInfo: IN_APP_MODAL.DASHBOARD_GRAPH_CUST,
	},
	wizard: {
		title: '[T] Wizzard',
		path: '/wizard',
		header: {
			visible: true,
			rightNavigation: false,
			underline: false,
			logo: 'text',
			transparent: true,
		},
		// header: {
		// 	navigation: false,
		// 	preview: false,
		// 	transparent: (width) => width >= 840,
		// 	dashboardAlwaysVisible: true,
		// 	children: <HeaderContent />,
		// },
	},
	templates: {
		title: '[T] Wizzard',
		path: '/wizard/templates',
		header: {
			visible: false,
		},
	},
	new: {
		title: '[T] Wizzard',
		path: '/wizard/new',
		header: {
			visible: false,
		},
	},
	'not-found': {
		title: '404',
		path: '/not-found',
		header: {
			navigation: false,
		},
	},
	search: {
		title: 'app.workspaces.search-in-surveys',
		path: '/search',
		header: {
			background: false,
			navigation: false,
			preview: false,
			search: true,
		},
	},
	advance_invoice: {
		path: '/advance_invoice',
		header: {
			visible: false,
		},
	},
	segmentation: {
		title: 'Segmentation',
		path: '/segmentation',
	},
};

if (process.env.NODE_ENV !== 'production') {
	PAGES.sandbox = {
		title: 'Sandbox',
		path: '/sandbox',
	};
}

Object.keys(PAGES).map((key) => (PAGES[key].key = key));
// PAGES KEYS BY PATH
const PAGES_PATH = {};
Object.keys(PAGES).map((key) => (PAGES_PATH[PAGES[key].path] = key));

export { PAGES, PAGES_PATH };
