import pathOr from 'ramda/es/pathOr';
import { graphql } from '@apollo/client/react/hoc';
import GetQuery from './get';

const Query = (queryType = '', queryOptions = {}) => {
	let { propName, mapProps, options, loading, fragments, optimisticResponse, result, skip } =
		queryOptions;
	const query = GetQuery(queryType, fragments);
	if (typeof propName === 'string') propName = [propName];
	if (typeof result === 'object') result = [result];
	const config = {
		props: ({ data, ownProps }) => {
			if (data.loading)
				return {
					[loading || 'loading']: true,
				};
			const props = {};
			props.refetch = data.refetch;
			props.fetchMore = data.fetchMore;
			props.networkStatus = data.networkStatus;
			// get operations name
			query.definitions
				.filter((a) => a.kind === 'OperationDefinition')
				.map((def, i) => {
					const { value } = def.selectionSet.selections[0].name;

					props[(propName && propName[i]) || value] = result
						? pathOr(result[i].or, result[i].path, data[value])
						: data[value];
				});
			if (typeof mapProps === 'function') return mapProps(props, ownProps);
			return props;
		},
	};
	if (options) config.options = options;
	if (optimisticResponse) config.optimisticResponse = optimisticResponse;
	if (skip) config.skip = skip;
	return graphql(query, config);
};

export default Query;
