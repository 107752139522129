import { PLANS_NEW, PERIOD } from '~/constants';

export const TYPES = {
	standard: PLANS_NEW.STANDARD,
	plus: PLANS_NEW.PLUS,
	pro: PLANS_NEW.PRO,
};

export const OPS = {
	1: PERIOD.MONTHLY,
	12: PERIOD.YEARLY,
};

export const PERIODS = {
	[PERIOD.MONTHLY]: 1,
	[PERIOD.YEARLY]: 12,
};
