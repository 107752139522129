export enum QUESTION_TYPES {
	CHOICE_MULTIPLE_IMAGES = 'ChoiceMultipleImagesQuestion',
	CHOICE_MULTIPLE = 'ChoiceMultipleQuestion',
	CHOICE_IMAGES = 'ChoiceImagesQuestion',
	CHOICE_SINGLE = 'ChoiceQuestion',
	TEXT = 'TextQuestion',
	TEXT_TEXT = 'TextQuestionText',
	TEXT_NUMBER = 'TextQuestionNumber',
	TEXT_EMAIL = 'TextQuestionEmail',
	TEXT_DATE = 'TextQuestionDate',
	TEXT_PLACE = 'TextQuestionPlace',
	MATRIX_SINGLE = 'MatrixQuestionSingle',
	MATRIX_MULTI = 'MatrixQuestionMulti',
	MATRIX_TEXT = 'MatrixQuestionText',
	MATRIX = 'MatrixQuestion',
	RATING = 'RatingQuestion',
	DIVIDE_POINTS = 'DividePointsQuestion',
	SEMANTIC_DIFFERENTIAL = 'SemanticDifferentialQuestion',
	NPS = 'NetPromoterQuestion',
	IMPORTANCE = 'ImportanceQuestion',
}
