import React, { memo, useCallback } from 'react';
import { Button, Input, Typography, Modal } from 'ui';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useForm } from 'react-hook-form';
import s from './style.less';
import query from '~/cache/query';
import mutation from '~/cache/mutation';
import Notifications from '~/components/Notifications';
import { isEmail } from '~/utils';
import { PLANS_NEW } from '~/constants';

const CustomPlanSlide = ({ intl, email, requestCustomPlan, close, upgradeToPlan, prev }) => {
	const { setSlide } = Modal.useModal();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ defaultValues: { email: email } });

	const onSubmit = useCallback(
		({ email }) => {
			requestCustomPlan({ input: { email } });
			Notifications.add({
				variant: 'success',
				children: intl.formatMessage({
					id: 'app.stripe.custom-quote-request-msg',
				}),
			});
			close();
		},
		[close, intl, requestCustomPlan],
	);

	const validate = useCallback(
		(value) => (isEmail(value) ? true : intl.formatMessage({ id: 'app.common.valid-email' })),
		[intl],
	);

	return (
		<Modal.Content prev={prev || (upgradeToPlan === PLANS_NEW.CUSTOM ? undefined : PLANS_NEW.PRO)}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Typography variant="title800" className={s.btm24Margin}>
					{intl.formatMessage({ id: 'app.stripe.get-custom-quote-1' })}
				</Typography>
				<Typography variant="textRegular300" color="grey_shades_with_blue[400]">
					{intl.formatMessage({ id: 'app.stripe.custom-quote-desc' })}
				</Typography>
				<Input
					label={intl.formatMessage({ id: 'app.designer.modal.email' })}
					className={s.emailInput}
					{...register('email', { validate })}
					error={errors.email?.message}
				/>
				<Modal.Footer className={s.customFooter}>
					<Button size="lg" type="submit" className={s.right16margin}>
						{intl.formatMessage({ id: 'app.stripe.get-custom-quote-2' })}
					</Button>
					<Button
						size="lg"
						color="ghost"
						onClick={upgradeToPlan === PLANS_NEW.CUSTOM ? close : () => setSlide(PLANS_NEW.PRO)}
					>
						{intl.formatMessage({ id: 'app.common.cancel' })}
					</Button>
				</Modal.Footer>
			</form>
		</Modal.Content>
	);
};

CustomPlanSlide.propTypes = {
	close: PropTypes.func,
	email: PropTypes.string,
	intl: PropTypes.object,
	prev: PropTypes.string,
	requestCustomPlan: PropTypes.func,
	upgradeToPlan: PropTypes.string,
};

export default compose(
	memo,
	mutation('requestCustomPlan'),
	query('account', {
		fragments: ['UserNameEmail'],
		mapProps: ({ user }) => ({ email: user?.email }),
	}),
	injectIntl,
)(CustomPlanSlide);
