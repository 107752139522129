import React, { useRef, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { Transition } from 'react-transition-group';
import { TimelineLite, TweenLite } from 'gsap';

import { getQuestionConfigId, groupByQuestionGroups, noop } from '../utils';

import { Icon } from '../../Icon';
import Bubble from '../../Bubble';
import AddPlus, { AddPlusProps } from '../AddPlus/AddPlus';

import AddBarTooltip from '../AddBarTooltip';
import {
	StyledAnimationWrapper,
	StyledAddBar,
	StyledQuestionGroup,
	StyledQuestion,
} from './AddBar.styled';

import {
	QuestionConfig,
	QuestionConfigId,
	QuestionGroups,
	QuestionGroupsTitleTranslations,
	QuestionGroupsTitleVisibility,
} from '../types';

export type AddBarProps = {
	addPlusProps: AddPlusProps;
	isOpen?: boolean;
	list?: QuestionConfig[];
	addBarWidth: string;
	addBarLeft: string;
	onClick?: (id: QuestionConfigId) => void;
	onToggle?: () => void;
	className?: string;
	lockedAddbar?: boolean;
};

const AddBar: React.FC<AddBarProps> = ({
	addPlusProps,
	isOpen = false,
	list = [],
	addBarWidth,
	addBarLeft,
	onClick = noop,
	onToggle = noop,
	className,
	lockedAddbar,
}) => {
	const t = useIntl();
	const dropdownRef = useRef<HTMLDivElement>(null);
	const handleClickOutside = () => {
		isOpen && !lockedAddbar && onToggle();
	};

	const scrollToAddBar = useCallback(() => {
		setTimeout(() => {
			const page = document.getElementById('scrollable-area-survey_id');
			const { scrollTop } = page;

			const { top: y, height } = dropdownRef.current.getBoundingClientRect();
			const half = window.innerHeight / 2;
			const scrollTo = scrollTop + y - 35 - half + height / 2;

			// prevent shaking on small scrolls
			if (!scrollTop && scrollTo < 35 && scrollTo > -35) {
				return;
			}
			TweenLite.to(page, 0.4, {
				scrollTo: scrollTo < 0 ? 0 : scrollTo,
			});
		}, 100);
	}, []);

	const handleOpen = useCallback(() => {
		const tl = new TimelineLite();

		tl.to(dropdownRef.current, 0.2, {
			height: 386,
			marginTop: 20,
		})
			.set(dropdownRef.current, {
				height: 'auto',
				onComplete: scrollToAddBar,
			})
			.to(dropdownRef.current, 0.2, {
				autoAlpha: 1,
			});
	}, [scrollToAddBar]);

	const handleClose = useCallback(
		() =>
			new TimelineLite()
				.to(dropdownRef.current, 0.2, {
					autoAlpha: 0,
				})
				.to(dropdownRef.current, 0.2, {
					height: 0,
					marginTop: 0,
				}),
		[],
	);

	const getQuestionTestId = (question: QuestionConfig) => {
		const prefix = 'add_';
		const suffix = '_menu_item';

		switch (question.__typename) {
			case 'ChoiceQuestion':
				if (question.hasPictureChoices) {
					return prefix + 'imagechoice' + suffix;
				} else if (question?.layout === 'SELECT') {
					return prefix + 'dropdown' + suffix;
				} else if (question.isMultipleAnswer) {
					return prefix + 'multiplechoice' + suffix;
				} else {
					return prefix + 'singlechoice' + suffix;
				}

			case 'RatingQuestion':
				return prefix + 'star' + suffix;
			case 'SemanticDifferentialQuestion':
				return prefix + 'semantic' + suffix;

			case 'TextQuestion':
				switch (question?.subtype) {
					case 'EMAIL':
						return prefix + 'email' + suffix;
					case 'DATE':
						return prefix + 'date' + suffix;
					case 'NUMBER':
						return prefix + 'numerical' + suffix;
					case 'TEXT':
					default:
						return prefix + 'text' + suffix;
				}

			case 'NetPromoterQuestion':
				return prefix + 'nps' + suffix;
			case 'MatrixQuestion':
				return prefix + 'matrix' + suffix;
			case 'ImportanceQuestion':
				return prefix + 'ordering' + suffix;
			case 'DividePointsQuestion':
				return prefix + 'rating' + suffix;
			case 'TextBlock':
				return prefix + 'customtext' + suffix;
		}
	};

	const handleItemClick = (id: QuestionConfigId) => () => {
		onClick(id);
	};

	const groupedQuestions = groupByQuestionGroups(list);

	return (
		<div className={className}>
			<OutsideClickHandler onOutsideClick={handleClickOutside}>
				<AddPlus {...addPlusProps} />

				<Transition
					in={isOpen}
					onEnter={handleOpen}
					onExit={handleClose}
					unmountOnExit
					mountOnEnter
					timeout={400}
				>
					<StyledAnimationWrapper ref={dropdownRef} width={addBarWidth} left={addBarLeft}>
						<StyledAddBar data-testid="add_question_section">
							{groupedQuestions.map((list, groupIndex) => {
								const questionGroupTitle = QuestionGroupsTitleVisibility[groupIndex] ? (
									<FormattedHTMLMessage id={QuestionGroupsTitleTranslations[groupIndex]} />
								) : (
									''
								);
								return (
									<StyledQuestionGroup key={QuestionGroups[groupIndex]}>
										<div className="title">{questionGroupTitle}</div>
										{list.map((item, i) => {
											const { ui } = item;
											return (
												<Bubble
													key={i}
													content={<AddBarTooltip questionConfig={item} />}
													trigger="mouseenter"
													delay={[1000, 0]}
													interactive={false}
													offset={[0, 10]}
													withArrow={false}
													withShadow={false}
												>
													<StyledQuestion data-testid={getQuestionTestId(item)} onClick={handleItemClick(getQuestionConfigId(item))}>
														<Icon
															name={ui.iconName}
															color={ui.iconColor}
															className="icon"
															iconTextColor={ui.iconTextColor}
														/>
														<div className="label">{t.formatMessage({ id: ui.title })}</div>
													</StyledQuestion>
												</Bubble>
											);
										})}
									</StyledQuestionGroup>
								);
							})}
						</StyledAddBar>
					</StyledAnimationWrapper>
				</Transition>
			</OutsideClickHandler>
		</div>
	);
};

export default AddBar;
