import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { CellProps, Cell } from '.';
import { Tooltip } from '../Tooltip';

// NOT IN USE RIGHT NOW

const TableCell: FC<CellProps> = ({ children, ...props }: CellProps) => {
	const ref = useRef(null);
	const [tooltip, setTooltip] = useState('');

	useEffect(() => {
		const el = ref?.current?.getElementsByTagName('span')?.[0]?.firstChild;
		if (el?.scrollWidth > el?.offsetWidth) {
			setTooltip(ref?.current?.textContent);
		}
		// (!) in deps array should be just ref.current
		// eslint-disable-next-line
	}, [ref.current]);

	return (
		<Cell {...props} ref={ref}>
			<Tooltip content={tooltip} description disabled={!tooltip}>
				{children}
			</Tooltip>
		</Cell>
	);
};

export default memo(TableCell);
