import gql from 'graphql-tag';

const query = (children, fragments) => gql`
		query stripePriceList($currencyCode: String) {
			stripePriceList(currencyCode: $currencyCode) {
			 ${children}
			}
		}
		${fragments}
	`;

export default query;
