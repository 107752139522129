import gql from 'graphql-tag';

export default gql`
	mutation createPage($surveyUID: String!, $position: Int, $hash: ID) {
		createPage(surveyUID: $surveyUID, position: $position, hash: $hash) {
			id
			position
			hash
		}
	}
`;
