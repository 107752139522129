import gql from 'graphql-tag';

export default gql`
	query survey($uid: String!) {
		respondentSources(survey: $uid) {
			respondentsTotal
			breakdown {
				value
				count
			}
		}
	}
`;
