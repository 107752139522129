import gql from 'graphql-tag';

export default gql`
	fragment Settings on Survey {
		settings {
			availability {
				active
				deadlinesActive
				from
				till
			}
			allowRepeat
			isAutomaticNextQuestionActive
			allowReturn
			numberQuestions
			localeCode
			languageCode
			language {
				... on SystemSurveyLanguage {
					code
				}
				... on CustomSurveyLanguage {
					id
					name
					sourceLanguage {
						code
					}
				}
			}
			responseNotifications
			responseNotificationsIncludeAnswers
			responseNotificationsSlack
			urlParameters {
				active
				list {
					id
					name
				}
			}
			exitUrl {
				active
				value
			}
			passwordProtection {
				active
				password
			}
			ipRestriction {
				active
				list {
					id
					ip
				}
			}
		}
	}
`;
