import colors from './colors';

const survioColors = {
	...colors,
	primary: {
		100: colors.blueberry[100],
		150: colors.blueberry[150],
		175: colors.blueberry[175],
		200: colors.blueberry[200],
		225: colors.blueberry[225],
		250: colors.blueberry[250],
		2501: colors.blueberry[2501],
		255: colors.blueberry[255],
		275: colors.blueberry[275],
		285: colors.blueberry[285],
		300: colors.blueberry[300],
		400: colors.blueberry[400],
		500: colors.blueberry[500],
		600: colors.blueberry[600],
		700: colors.blueberry[700],
		800: colors.blueberry[800],
		850: colors.blueberry[850],
		900: colors.blueberry[900],
	},
	secondary: {
		100: colors.brand_steel[100],
		200: colors.brand_steel[200],
		300: colors.brand_steel[300],
		400: colors.brand_steel[400],
		500: colors.brand_steel[500],
		600: colors.brand_steel[600],
		700: colors.brand_steel[700],
		900: colors.brand_steel[900],
	},
	upgrade: {
		100: colors.orange[100],
		200: colors.orange[200],
		300: colors.orange[300],
		400: colors.orange[400],
		500: colors.orange[500],
		600: colors.orange[600],
		650: colors.orange[650],
		700: colors.orange[700],
		800: colors.orange[800],
		850: colors.orange[850],
		900: colors.orange[900],
	},
	outline: {
		100: colors.outline[100],
		64: colors.outline[64],
		40: colors.outline[40],
		20: colors.outline[20],
		10: colors.outline[10],
	},
} as const;

export default survioColors;
