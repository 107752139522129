import gql from 'graphql-tag';

export default gql`
	mutation changeSurveyThemeReset($uid: String!, $theme: Json!) {
		changeSurveyTheme {
			reset(input: { uid: $uid, theme: $theme }) {
				success
			}
		}
	}
`;
