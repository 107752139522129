import gql from 'graphql-tag';

export default gql`
	mutation addSurveyDashboardDatePeriodFilter($input: SurveyDashboardDatePeriodFilterInput!) {
		addSurveyDashboardDatePeriodFilter(input: $input) {
			id
			from
			till
		}
	}
`;
