import React from 'react';
import { FieldArray } from 'formik';

const withFieldArray = (name, validateOnChange = true) => (Component) => (props) => (
	<FieldArray name={name} validateOnChange={validateOnChange}>
		{(helpers) => <Component {...props} {...{ [`${name}Helpers`]: helpers }} />}
	</FieldArray>
);

export default withFieldArray;
