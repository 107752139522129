import styled from '@emotion/styled';
import { StyledMenu } from '~/ui/components/Menu';

export const LogicSelectStyled = styled.div<{
	topPosition: number | undefined;
	dWidth: number;
	dHeight: number;
}>`
	display: flex;
	flex-direction: column;

	> [data-tippy-root] {
		min-height: 200px;
		max-height: 440px;
		width: ${({ dWidth }) => dWidth - 316 - 40}px;
		height: ${({ dHeight, topPosition }) => `calc(${dHeight}px - ${topPosition}px - 20px)`};
	}

	${StyledMenu} {
		height: 100%;

		> div > div:first-of-type {
			padding: 0;
		}
	}
`;

export const LogicSelectButtonStyled = styled.div<{ isOpen: boolean }>`
	${({ theme }) => theme.typography.textRegular300_24};
	display: flex;
	border: 1px solid
		${({ theme: { colors }, isOpen }) =>
			isOpen ? colors.primary[400] : colors.grey_shades_with_blue[200]};
	border-radius: 8px;
	height: 40px;
	align-items: center;
	min-width: 200px;
	width: 100%;
	cursor: pointer;
	padding: 0 12px;
	justify-content: space-between;

	.chevron {
		${({ isOpen }) => isOpen && 'transform: rotate(180deg);'}
		transition: transform 0.2s;
	}

	.choiceText {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 0;
		flex-grow: 1;
		display: block;
	}
`;

export const FlexGroup = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const FlexGroupLeft = styled(FlexGroup)`
	flex: 1 0 auto;
`;

export const LogicSelectContentStyled = styled.div`
	display: flex;
	min-height: 100%;
	max-height: 100%;
	overflow: hidden;
`;

export const LSContentLeftStyled = styled.div`
	display: flex;
	flex-direction: column;
	border-right: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
	min-width: 220px;
	max-width: 220px;
	padding: 12px;
	gap: 2px;
	overflow: scroll;
`;

export const LSContentRightStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 12px;
	width: 100%;
	overflow: scroll;
`;

export const CategoryButtonStyled = styled.div<{ active: boolean; disabled?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 12px;
	${({ theme }) => theme.typography.textMedium300};
	color: ${({ theme, active, disabled }) =>
		disabled
			? theme.colors.grey_shades_with_blue[300]
			: active
				? theme.colors.primary[900]
				: theme.colors.grey_shades_with_blue[500]};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	border-radius: 8px;
	background: ${({ theme, active }) => (active ? theme.colors.primary[150] : 'transparent')};

	:hover {
		background: ${({ theme, active }) =>
			active ? theme.colors.primary[150] : theme.colors.grey_shades_with_blue[50]};
	}

	.arrow {
		color: ${({ theme, active, disabled }) =>
			disabled
				? theme.colors.grey_shades_with_blue[250]
				: active
					? theme.colors.primary[900]
					: theme.colors.grey_shades_with_blue[350]};
	}
`;

export const QuestionNumberStyled = styled.div<{ active?: boolean; disabled?: boolean }>`
	${({ theme }) => theme.typography.textMedium300};
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: baseline;
	width: 32px;
	height: 24px;
	border-radius: 12px;
	background: ${({ theme: { colors }, active, disabled }) =>
		disabled ? colors.outline[10] : active ? colors.primary[2501] : colors.outline[10]};
	color: ${({ theme: { colors }, disabled }) =>
		disabled ? colors.grey_shades_with_blue[300] : colors.grey_shades_with_blue[600]};
	flex-shrink: 0;
`;

export const HiddenInput = styled.input`
	display: none;
	position: absolute;
`;
