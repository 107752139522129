import gql from 'graphql-tag';

export default gql`
	mutation answerSegmentationSurvey($input: AnswerSegmentationSurveyInput!) {
		answerSegmentationSurvey(input: $input) {
			success
			experiments {
				name
				data
				variant
				state
			}
		}
	}
`;
