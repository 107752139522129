import gql from 'graphql-tag';

export default gql`
	mutation changeIntroPage($input: IntroPageMutationInput!) {
		changeIntroPage(input: $input) {
			success
			logo {
				url
			}
		}
	}
`;
