import gql from 'graphql-tag';

export default gql`
	fragment Prices on StripePricing {
		plans {
			name
			plan
			planName
			identifier
			yearlyAmount
			invitationLimit
			responseLimit
			features
			responseCollectionLimit
			responseCollectionPackages {
				quantity
				monthlyAmount
				yearlyAmount
			}
			monthlyAmount
			... on StripePriceProPlan {
				seatsDefault
				seatsMax
				yearlySeatAmount
			}
		}
		currencyCode
		experiment
	}
`;
