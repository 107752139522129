const isBrowser = typeof window !== 'undefined';

const isMobile = () => window.innerWidth < 1010;

const isTouch = () => {
	if (!window.navigator) return false;
	return 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export default { isBrowser, isMobile, isTouch };
