import React from 'react';
import PropTypes from 'prop-types';

import { PERIOD } from '~/constants';
import { Spacer, Typography } from '@survio/ui';

import s from './style';

const TotalPrice = ({ intl, priceData, totalPrice, currencyCode, selectedPeriod, period }) => (
	<div className={s.total}>
		<Typography variant="textSemibold500">
			{intl.formatMessage({ id: 'app.upgrade.total' })}
		</Typography>

		<Spacer />

		<div>
			<Typography variant="textSemibold500">
				{`${intl.formatNumber(priceData.amount || totalPrice, {
					currency: currencyCode || priceData.currencyCode,
					style: 'currency',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				})} ${(() => {
					switch (selectedPeriod) {
						case PERIOD.MONTHLY:
							return intl.formatMessage({ id: 'app.upgrade.for-one-month' });
						case PERIOD.YEARLY:
							return !period || period === PERIOD.MONTHLY
								? intl.formatMessage({ id: 'app.upgrade.for-months-2' }).replace('%s', 12)
								: intl.formatMessage({ id: 'app.upgrade.for-one-year' });
					}
				})()}`}
			</Typography>

			<Spacer height={4} />

			<div>
				<Spacer />
				<Typography variant="textRegular100" color="grey_shades_with_blue[300]" textAlign="right">
					{`(${intl.formatMessage({ id: 'app.upgrade.excl-vat' })})`}
				</Typography>
			</div>
		</div>
	</div>
);

TotalPrice.propTypes = {
	intl: PropTypes.object,
	priceData: PropTypes.object,
	totalPrice: PropTypes.number,
	currencyCode: PropTypes.string,
	selectedPeriod: PropTypes.string,
	period: PropTypes.string,
};

export default TotalPrice;
