import gql from 'graphql-tag';

export default gql`
	fragment googleDriveList on User {
		integrations {
			googleDrive {
				folderNames
			}
		}
	}
`;
