import gql from 'graphql-tag';

export default gql`
	query publicIdentifier($uid: String!) {
		survey(uid: $uid) {
			uid
			publicIdentifier
		}
	}
`;
