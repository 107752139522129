import React, {
	FC,
	forwardRef,
	memo,
	ReactNode,
	RefAttributes,
	RefObject,
	useCallback,
} from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';
import SvgSurvey from '!!svg-react-loader!./assets/survey.svg';
import { AvatarColorSetType, ColorType, ThemeType } from '../ThemeProvider/theme';
import { getThemeColor } from '../../utils';
import { Status } from '../Status';
import { Typography, TypographyVariant } from '../Typography';
import { Loader } from '../Loader';
import { Icon } from '../Icon';

export type AvatarProps = {
	className?: string;
	colorSet?: AvatarColorSetType;
	desc?: ReactNode | string;
	name?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	theme?: ThemeType;
	disabled?: boolean;
	isAtom?: boolean;
	active?: boolean;
	variant?: 'user' | 'survey';
	note?: string;
	search?: string;
	// textColor?: ColorType;
	you?: string;
	loading?: boolean;
	withWarning?: boolean;
	withError?: boolean;
	typoVariant?: TypographyVariant;
};

export type AvatarWithRefProps = AvatarProps & RefAttributes<HTMLDivElement>;

const createColor = (color: AvatarColorSetType, suffix: '[600]' | '[300]'): ColorType =>
	`avatar_colors.${color}${suffix}` as ColorType;

export const AvatarWrapper = styled.div<AvatarWithRefProps>`
	display: flex;
	width: ${({ isAtom }) => (isAtom ? 'auto' : '100%')};
	min-width: 0;
	${({ theme }) => theme?.typography.textMedium300};
	min-height: 40px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`;

const CharBall = styled.div<AvatarProps & { isLoading?: boolean }>`
	color: ${({ disabled, theme, you, isLoading, colorSet }) =>
		disabled && !isLoading
			? theme.colors.grey_shades_with_blue[300]
			: you
				? theme.colors.secondary[600]
				: colorSet
					? getThemeColor(createColor(colorSet, '[600]'), theme)
					: theme.colors.secondary[600]};
	align-items: center;
	align-self: center;
	background: ${({ colorSet, disabled, theme, you, isLoading }) =>
		disabled && !isLoading
			? theme.colors.grey[300]
			: you
				? theme.colors.secondary[200]
				: colorSet
					? getThemeColor(createColor(colorSet, '[300]'), theme)
					: theme.colors.secondary[200]};
	border-radius: 100%;
	display: inline-flex;
	min-width: 32px;
	justify-content: center;
	${({ theme }) => theme.typography.title200};
	width: 32px;
	height: 32px;
	transition: color 200ms ease-out;
	position: relative;

	.avatarWarningIcon {
		position: absolute;
		top: -4px;
		left: 20px;
		background-color: white;
		border-radius: 50%;
		color: ${({ withError, theme }) =>
			withError ? getThemeColor('red[500]', theme) : getThemeColor('yellow[500]', theme)};
	}

	.avatarLoader {
		align-items: center;
	}
`;

const SurveyIcon = styled(CharBall)<AvatarProps>`
	position: relative;
	border-radius: 0;
	background: transparent;

	.survey-svg {
		transition: fill 200ms ease-out;
		fill: ${({ colorSet, disabled, theme }) =>
			disabled ? theme.colors.grey[300] : getThemeColor(createColor(colorSet, '[300]'), theme)};
	}
`;

const Capital = styled.div`
	position: absolute;
	width: 32px;
	height: 32px;
	top: 1px;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TextWrapper = styled.div<AvatarProps>`
	display: inline-flex;
	flex: 1 1 auto;
	flex-direction: column;
	overflow: hidden;
	align-self: center;
	align-items: flex-start;
	padding-left: ${({ theme, variant }) => (variant === 'survey' ? 24 : theme.helpers.spacer(1))}px;
`;

const Flex = styled.div<AvatarProps>`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	&:first-of-type {
		margin-bottom: ${({ variant }) => (variant === 'survey' ? 4 : 0)}px;
	}
	.note {
		margin: 0 8px;
		overflow: hidden;
		display: flex;
		> div {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: block;
		}
	}
`;

const StyledText = styled.div<
	Pick<AvatarProps, 'theme' | 'disabled' | 'active'> & { typoVariant: TypographyVariant }
>`
	${({ theme, typoVariant }) =>
		typoVariant ? theme.typography[typoVariant] : theme.typography.textSemibold250};
	transition: color 200ms ease-out;
	color: ${({ disabled, theme: { colors }, active }) =>
		active ? colors.white : disabled ? colors.grey_shades_with_blue[300] : colors.black[100]};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const StyledName = styled(StyledText)<AvatarProps>`
	color: ${({ disabled, theme: { colors }, active }) => !active && !disabled && colors.black[100]};

	.nameFormat {
		display: contents;
		white-space: pre;
	}
`;

const StyledDesc = styled(StyledText)<AvatarProps>`
	${({ theme }) => theme.typography.textMedium200};
	color: ${({ disabled, active, theme: { colors } }) =>
		!active && !disabled && colors.grey_shades_with_blue[500]};
	margin-right: 8px;
`;

const VariantSymbol = memo(({ loading, ...props }: AvatarProps) => {
	const { desc, name, variant, note, isAtom, search, you, withWarning, withError, typoVariant } =
		props;

	const getInitials = useCallback(
		(string) => {
			let result = '';
			const stringArray = string?.split(' ');
			if (stringArray?.length > 1) {
				result = `${stringArray[0].trim().substr(0, 1).toUpperCase()}${
					variant === 'user'
						? stringArray[stringArray.length - 1].trim().substr(0, 1).toUpperCase()
						: ''
				}`;
			} else if (stringArray?.length === 1) {
				result = `${stringArray[0].trim().substr(0, 2).toUpperCase()}`;
			}
			return result;
		},
		[variant],
	);

	const firstChar = getInitials(name) || (typeof desc === 'string' && getInitials(desc)) || '';

	const getSearchedName = useCallback(() => {
		const splitted = name.split(new RegExp(`(${search})`, 'i'));
		return splitted.map((w, i) => (
			<Typography
				variant={typoVariant ? typoVariant : i % 2 ? 'textSemibold250' : 'textRegular250'}
				className="nameFormat"
				key={i}
			>
				{w}
			</Typography>
		));
	}, [name, search]);

	return (
		<>
			{variant === 'survey' ? (
				<SurveyIcon {...props}>
					<SvgSurvey />
					<Capital>{firstChar}</Capital>
				</SurveyIcon>
			) : (
				<CharBall isLoading={loading} {...props}>
					{(withWarning || withError) && (
						<Icon name="warning-circle-small-16" className="avatarWarningIcon" />
					)}
					{loading ? (
						<Loader
							color="secondary[600]"
							spaceSize={2}
							dotSize={3}
							jumpHeight={2}
							className="avatarLoader"
						/>
					) : (
						firstChar
					)}
				</CharBall>
			)}
			{!isAtom && (name || desc) && (
				<TextWrapper variant={variant}>
					{(name || you) && (
						<Flex variant={variant}>
							<StyledName {...props} typoVariant={typoVariant}>
								{search ? getSearchedName() : name} {you}
							</StyledName>
							{note && (
								<Status state="Note" className="note">
									<div>{note}</div>
								</Status>
							)}
						</Flex>
					)}
					<Flex>{desc && <StyledDesc {...props}>{desc}</StyledDesc>}</Flex>
				</TextWrapper>
			)}
		</>
	);
});

VariantSymbol.displayName = 'VariantSymbol';

export const Avatar: FC<AvatarWithRefProps> = forwardRef<HTMLDivElement, AvatarProps>(
	({ className, onClick, loading, ...props }: AvatarProps, ref: RefObject<HTMLDivElement>) => {
		const { disabled, active } = props;
		return (
			<AvatarWrapper
				className={cn(className, active ? 'active' : 'not-active')}
				data-testid="ui-Avatar"
				onClick={disabled ? null : onClick}
				ref={ref}
				{...props}
			>
				<VariantSymbol loading={loading} {...props} />
			</AvatarWrapper>
		);
	},
);

Avatar.displayName = 'Avatar';

Avatar.defaultProps = {
	disabled: false,
	variant: 'user',
	you: '',
	loading: false,
};

export default memo(Avatar) as FC<AvatarWithRefProps>;
