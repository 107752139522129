import React, { FC, memo } from 'react';
import { BubbleContent, Buttons } from '../ImageUploader.styled';
import Button from '../../Button';
import Bubble from '../../Bubble';
import { Tooltip } from '../../Tooltip';
import { ControlsProps } from '../index';

const Controls: FC<ControlsProps> = ({
	mini,
	withButtons,
	handleOnClick,
	disabled,
	isLoading,
	replaceBtnLabel,
	areYouSureLabel,
	handleOnRemove,
	deleteLabel,
	deleteDescription,
	bubble,
	handleBubble,
}: ControlsProps) =>
	!mini ? (
		<Buttons isVisible={withButtons}>
			<Button
				size="md"
				color="ghost"
				className="withRightMargin"
				onClick={handleOnClick}
				disabled={disabled || isLoading}
			>
				{replaceBtnLabel}
			</Button>
			<Bubble
				content={
					<BubbleContent>
						<div className="sure-label">{areYouSureLabel}</div>
						<Button
							variant="text"
							color="red"
							size="sm"
							withDarkBackground
							onClick={handleOnRemove}
						>
							{deleteLabel}
						</Button>
						<Button
							variant="icon"
							startIcon="close-16"
							size="sm"
							className="close-delete-button"
							withDarkBackground
							onClick={handleBubble}
						/>
					</BubbleContent>
				}
				placement="bottom"
				visible={bubble}
				variant="dark"
				onClickOutside={handleBubble}
			>
				<Tooltip
					content={deleteDescription}
					placement="top"
					description
					disabled={disabled || isLoading}
				>
					<Button
						size="md"
						color="ghost"
						startIcon="delete-16"
						variant="iconButton"
						disabled={disabled || isLoading}
						onClick={handleBubble}
					>
						Delete
					</Button>
				</Tooltip>
			</Bubble>
		</Buttons>
	) : null;

export default memo(Controls);
