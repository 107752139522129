import gql from 'graphql-tag';

export default gql`
	mutation addResponseDurationFilter($uid: String!, $sysidList: [DurationEnum!]) {
		addResponseDurationFilter(uid: $uid, sysidList: $sysidList) {
			id
			type
			minimum
			maximum
		}
	}
`;
