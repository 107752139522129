import React from 'react';
import classNames from 'classnames';

import styles from './style.less';

const Footer = ({ className, children }) => (
	<div className={classNames(styles.footer, className)}>{children}</div>
);

export default Footer;
