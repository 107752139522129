import gql from 'graphql-tag';

const query = (children, fragments) => gql`
		query surveyPages($uid: String!) {
			surveyPages(uid: $uid) @client {
			uid
			 ${children}
			}
		}
		${fragments}
	`;

export default query;
