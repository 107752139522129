import React, { memo } from 'react';
import { Loader as LoaderUI, Typography } from 'ui';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './style.less';

const Loader = ({
	visible,
	className,
	position = 'fixed',
	label,
	opacity = 0.9,
	bgColor = '#FFFFFF',
	children,
}) => (
	<div
		className={cn(styles.wrapper, className)}
		style={{ opacity: visible ? 1 : 0, visibility: visible ? 'visible' : 'hidden', position }}
	>
		<div className={cn(styles.bg, 'background')} style={{ opacity, background: bgColor }} />
		<LoaderUI />
		{label && (
			<Typography
				variant="textRegular250"
				color="grey_shades_with_blue[600]"
				className={styles.label}
			>
				{label}
			</Typography>
		)}
		{children}
	</div>
);

Loader.propTypes = {
	bgColor: PropTypes.string,
	children: PropTypes.element,
	className: PropTypes.string,
	position: PropTypes.string,
	visible: PropTypes.bool,
	label: PropTypes.string,
	opacity: PropTypes.number,
};

export default memo(Loader);
