export const EMPTY_INDIVIDUAL = '';

export const CREATE_THEME_DEFAULT_NAME = 'app.labels.color-3';

export const defaultChartPreferences = {
	type: 'default',
	percentage: false,
	labels: true,
	order: 'DESC',
};
