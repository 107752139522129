import gql from 'graphql-tag';

export default gql`
	query inAppContent($id: Int) {
		user(id: $id) {
			userID: id
			inAppContent {
				id
				eventTriggers
				url
				timeout
				displayType
			}
		}
	}
`;
