import styled from '@emotion/styled';
import { FocusBubbleProps } from '.';

export const FocusBubbleWrapper = styled.div<FocusBubbleProps>`
	display: flex;
	position: relative;

	.fade-enter {
		opacity: 0;
	}

	.fade-enter-active {
		opacity: 1;
		transition: opacity 300ms ease-out;
	}
	.fade-exit {
		opacity: 1;
	}
	.fade-exit-active {
		opacity: 0;
		transition: opacity 300ms ease-out;
	}

	> .menu-child-wrapper {
		position: absolute;
		width: 100% !important;
		height: 100%;
		top: 0;
		left: 0;
	}

	.notClickableBackground {
		background-color: transparent;
		position: fixed;
		left: 0;
		top: 0;
	}
`;

export const StyledFocusBubble = styled.div<FocusBubbleProps>`
	width: 100%;
	height: 100%;
	/* for IE */
	box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.3);
	/* for real browsers */
	box-shadow: 0 0 0 120vmax rgba(0, 0, 0, 0.3);
	z-index: 123;
`;
