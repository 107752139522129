import styled from '@emotion/styled';
import { LogoProps } from './index';

export const StyledLogo = styled.div<LogoProps>`
	display: inline-flex;
	width: ${({ text }) => (text ? 94 : 24)}px;
	height: 24px;
	justify-content: center;
	align-items: center;
	> div > div {
		width: ${({ text }) => (text ? 94 : 22)}px;
		height: ${({ text }) => (text ? 23 : 22)}px;
	}
	path {
		fill: ${({ color, theme }) => (color ? color : theme.colors.black[100])};
	}
`;
