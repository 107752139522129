import styled from '@emotion/styled';
import { TdProps, HeadCheckboxProps, OptionsProps } from './';
import { StyledRowProps } from './';

export const TableWrapper = styled.div`
	width: 100%;
	padding-bottom: 35px;
`;

export const HeadColumn = styled.div<TdProps>`
	flex: 0 1 auto;
	position: relative;
	width: ${({ width }) => `${width}px`};
	padding: ${({ padding }) => padding || '4px 24px 16px 24px'};
	justify-content: ${({ textAlign }) => textAlign || 'unset'};
	width: ${({ width }) => `${width}px`};

	&:last-child {
		text-align: right;
	}

	.typo {
		display: flex;
		justify-content: ${({ textAlign }) => textAlign || 'unset'};
	}
`;

export const Column = styled.div<TdProps>`
	flex: 0 1 auto;
	display: flex;
	align-items: center;
	width: ${({ width }) => `${width}px`};
	padding: ${({ padding }) => padding || `20px 24px`};
	width: ${({ width }) => `${width}px`};
	justify-content: ${({ textAlign }) => textAlign || 'unset'};

	&:last-child {
		text-align: right;
	}
`;

export const Row = styled.div<StyledRowProps>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
	border-radius: 8px;
	transition: background-color 200ms ease-out;
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
	${({ active, theme }) => active && `background-color ${theme.colors.grey[300]}`};
	&:nth-of-type(odd) {
		${({ striped, theme }) => striped && `background-color: ${theme.colors.grey[200]}`};
	}
	${({ head, striped, theme }) =>
		!head &&
		`&:hover {
		background-color: ${theme.colors.grey[striped ? 400 : 300]}
	}`}
`;

export const Options = styled.div<OptionsProps>`
	position: absolute;
	top: 50%;
	margin-top: -32px;
	height: 64px;
	left: 0;
	width: auto;
	display: flex;
	align-items: center;
	${({ checked, theme }) =>
		checked
			? `
		background-color:${theme.colors.white};
		box-shadow: 0px 6px 24px 0px rgba(0, 19, 41, 0.24);
	`
			: `
		background-color:transparent;
		box-shadow: 0px 6px 24px 0px rgba(0, 19, 41, 0);
	`};
	${({ padding }) => padding && `padding-left: ${padding.split(' ')[1]}`};
	border-radius: 12px;
	z-index: 20;
	vertical-align: top;
	transition: all 200ms ease-out;
`;

export const HeadCheckbox = styled.div<HeadCheckboxProps>`
	flex: 0 1 auto;
`;

export const StyledPagination = styled.div<HeadCheckboxProps>`
	padding: 48px 15px 13px;
`;

export const HeadControl = styled.div<OptionsProps>`
	flex: 0 1 auto;
	align-items: center;
	display: flex;
	${({ checked }) =>
		checked
			? `
		visibility:visible;
		opacity:1;`
			: `
		visibility:hidden;
		opacity:0;`};
	transition: all 200ms ease-out;
`;
