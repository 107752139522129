import gql from 'graphql-tag';

export default gql`
	fragment IntroPage on Survey {
		title
		content {
			introPage {
				active
				title
				content
				startButton
				logo {
					url
				}
			}
		}
	}
`;
