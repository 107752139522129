import gql from 'graphql-tag';

export default gql`
	query chartSettings($question: Int!) {
		chartSettings(question: $question) @client {
			id
			type
			percentage
			order
			labels
			color {
				key
				list
			}
		}
	}
`;
