import { useCallback, useEffect } from 'react';

/**
 * Hook that fires fn on press button with specific keycode
 */
const useOnKeyPressed = (
	keyCode: number | string,
	onPress: () => void,
	disabled?: boolean,
): void => {
	const keyPressedFunction = useCallback(
		(event) => {
			if (event.keyCode === keyCode) {
				onPress();
			}
		},
		[keyCode, onPress],
	);

	useEffect(() => {
		if (disabled) return;
		document.addEventListener('keydown', keyPressedFunction, false);

		return () => {
			document.removeEventListener('keydown', keyPressedFunction, false);
		};
	}, [disabled, keyPressedFunction]);
};

export default useOnKeyPressed;
