import React, { FC, memo, useCallback, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import { sticky as stickyPlugin, followCursor as followCursorPlugin, Instance } from 'tippy.js';
import { TooltipChildren, TooltipContent, TooltipProps, TooltipWrapper } from './';
import SvgArrow from '!!svg-react-loader!./assets/arrow.svg';
import { controlledTippyFix } from '../../utils';

export const Tooltip: FC<TooltipProps> = ({
	appendTo,
	children,
	className,
	content,
	disabled,
	delay,
	description,
	maxWidth,
	offset,
	onBeforeUpdate,
	onHide,
	onMount,
	placement,
	sticky,
	visible,
	followCursor,
	trigger = 'mouseenter focus',
	triggerTarget,
	'data-test-id': testId,
	wrapperClassname,
	...rest
}: TooltipProps) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [arrowElement, setArrowElement] = useState(null);
	const [visibility, setVisibility] = useState(false);
	const [computedPlacement, setComputedPlacement] = useState(placement);

	const dist = description ? 0.5 : 1;

	/**
	 * pad(multi: number = 1) => number is function for returning tooltip arrow padding in pixels (multi * 8)
	 */
	const pad = useCallback((multi = 1) => 8 * multi, []);

	/**
	 * These popper offset modifier util functions are used for right padding
	 * and alignment of tooltip content and tooltip arrow.
	 *
	 * Padding of the arrow is designed to be 8px from the edge on top or bottom and
	 * 4px from the edge on left or right.
	 *
	 * If the tooltip is too small (width < 32px or height < 40px - values based on design),
	 * then the arrow is aimed outside of the tooltip child. This is the reason for special
	 * conditions below when the box is 'pushed out' and is not aligned with the border of
	 * a child and the arrow is aimed to the center of child.
	 */
	const getOffsetValues = useCallback(
		(paddingMultiplierX, paddingMultiplierY) => {
			const offsetX = offset ? offset[0] : 0;
			const offsetY = offset ? offset[1] : 0;

			return [offsetX + pad(paddingMultiplierX), offsetY + pad(paddingMultiplierY)];
		},
		[offset, pad],
	);

	const getOffset = useCallback(() => {
		const widthOffset = (pad(5) - anchorEl?.offsetWidth) / pad(2);
		const heightOffset = (pad(4) - anchorEl?.offsetHeight) / pad(2);

		switch (computedPlacement) {
			case 'top-end':
			case 'bottom-end':
				return anchorEl?.offsetWidth <= pad(5)
					? getOffsetValues(widthOffset, dist)
					: getOffsetValues(0, dist);
			case 'top-start':
			case 'bottom-start':
				return anchorEl?.offsetWidth <= pad(5)
					? getOffsetValues(-widthOffset, dist)
					: getOffsetValues(0, dist);
			case 'right-end':
			case 'left-end':
				return anchorEl?.offsetHeight <= pad(4)
					? getOffsetValues(heightOffset, dist)
					: getOffsetValues(0, dist);
			case 'right-start':
			case 'left-start':
				return anchorEl?.offsetHeight <= pad(4)
					? getOffsetValues(-heightOffset, dist)
					: getOffsetValues(0, dist);
			default:
				return getOffsetValues(0, dist);
		}
	}, [
		anchorEl?.offsetWidth,
		anchorEl?.offsetHeight,
		computedPlacement,
		getOffsetValues,
		dist,
		pad,
	]);

	const offsetModifier = React.useMemo(
		() => ({
			name: 'offset',
			options: {
				offset: getOffset(),
			},
		}),
		[getOffset],
	);

	const handleOnMount = useCallback(
		(instance) => {
			setVisibility(true);
			onMount && onMount(instance);
		},
		[onMount],
	);

	const handleOnHide = useCallback(
		(instance: Instance) => {
			const unmountInstance = (event) => {
				if (event.elapsedTime >= 0.2) {
					instance.unmount();
				}
				// need to control when we remove the listener because transitionend fires multiple times
				instance.popper.firstChild.removeEventListener('transitionend', unmountInstance);
			};
			instance.popper.firstChild.removeEventListener('transitionend', unmountInstance);
			instance.popper.firstChild.addEventListener('transitionend', unmountInstance);
			visibility && setVisibility(false);
			onHide && onHide(instance);
		},
		[onHide, visibility],
	);

	/**
	 * Function for setting a state of new placement in case of there is not enough space
	 * for tooltip and recomputing tooltip offset.
	 */
	const handleOnBeforeUpdate = useCallback(
		(instance) => {
			!visibility && setComputedPlacement(instance.popperInstance?.state.placement);
			onBeforeUpdate && onBeforeUpdate(instance);
		},
		[onBeforeUpdate, visibility],
	);

	const noTriggerWhenMenuIsActive = disabled === 'whenMenuIsActive' && !!rest.active ? '' : trigger;

	// workaround to resolve Tippy.js warning (see ./utils/tippyFix.ts)
	const controlledFix = useCallback(
		() => controlledTippyFix(visible, noTriggerWhenMenuIsActive, true),
		[noTriggerWhenMenuIsActive, visible],
	);

	const getPlugins = useCallback(() => {
		const plugins = [];
		if (followCursor) {
			plugins.push(followCursorPlugin);
		}
		if (sticky) {
			plugins.push(stickyPlugin);
		}
		return plugins;
	}, [followCursor, sticky]);

	const isDisabled = disabled === 'whenMenuIsActive' ? false : disabled;

	return isDisabled ? (
		<span data-test-id={testId} className={className}>
			{children}
		</span>
	) : (
		<TooltipWrapper className={wrapperClassname} data-testid="ui-tooltip" data-test-id={testId}>
			<Tippy
				{...(appendTo && { appendTo })}
				delay={delay !== undefined ? delay : description ? [100, 0] : 0}
				onBeforeUpdate={handleOnBeforeUpdate}
				animation={true}
				placement={placement}
				{...(sticky && { sticky: true })}
				{...(followCursor && { followCursor })}
				plugins={getPlugins()}
				onMount={handleOnMount}
				onHide={handleOnHide}
				{...controlledFix()}
				visible={visible}
				popperOptions={{
					strategy: 'fixed',
					modifiers: [
						{ name: 'arrow', options: { element: arrowElement, padding: pad() } },
						offsetModifier,
					],
				}}
				triggerTarget={triggerTarget}
				render={(attrs) => (
					<TooltipContent
						description={description}
						className={className}
						maxWidth={maxWidth}
						data-animation="fade"
						data-state={visibility}
						data-testid="ui-tooltip-content"
						dark={rest.dark}
						{...attrs}
					>
						{content}
						<div className="tooltip-arrow" ref={setArrowElement} data-popper-arrow="">
							<SvgArrow className="arrow-svg" />
						</div>
					</TooltipContent>
				)}
				{...rest}
				theme={undefined}
			>
				<TooltipChildren ref={setAnchorEl} className="tooltip-children">
					{children}
				</TooltipChildren>
			</Tippy>
		</TooltipWrapper>
	);
};

Tooltip.defaultProps = {
	dark: true,
	placement: 'bottom',
	interactive: true,
	sticky: false,
	maxWidth: 200,
};

export default memo(Tooltip) as FC<TooltipProps>;
