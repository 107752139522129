import { graphql } from '@apollo/client/react/hoc';
import { v4 as uuid } from 'uuid';

import QUERY from '~/cache/queries/builder/survey';
import {
	MUTATION_NPSQuestion,
	MUTATION_RatingQuestion,
	MUTATION_MatrixQuestion,
	MUTATION_SemanticQuestion,
	MUTATION_ChoiceQuestion,
	MUTATION_ImageChoiceQuestion,
	MUTATION_TextQuestion,
	MUTATION_OrdinalQuestion,
	MUTATION_SplitPointsQuestion,
	MUTATION_TextBlock,
	MUTATION_DeleteItem,
	MUTATION_CopyItem,
} from '~/cache/mutations/builder/questions';

export const updateOrdinalAndNumber = (mainPages) => {
	const pages = mainPages.map((page) => ({
		...page,
		items: page.items.map((item, itemIndex) => ({
			...item,
			ordinal: itemIndex + 1,
			...(typeof item.number !== 'undefined' && { number: itemIndex + 1 }),
		})),
	}));

	let number = 1;
	for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
		const items = pages[pageIndex].items.map((item, itemIndex) => {
			const updatedItem = {
				...item,
				ordinal: itemIndex + 1,
			};
			if (typeof item.number !== 'undefined') {
				updatedItem.number = number;
				number++;
			}
			return updatedItem;
		});
		pages[pageIndex] = {
			...pages[pageIndex],
			items,
		};
	}

	return pages;
};

const update =
	({ active, ...ownProps }, page, questionData, ordinal, localPicture = null) =>
	(cache, { data }) => {
		const { id, choices, rows, cols, logic = {} } = data[Object.keys(data)[0]];
		const uid = ownProps.uid || ownProps.match.params.uid;
		const { survey } = cache.readQuery({
			query: QUERY,
			variables: { uid },
		});
		const { mainPages } = survey.content;
		const pageIndex = mainPages.findIndex((a) => a.hash === active);
		const selectedPage = mainPages[pageIndex];
		const index = selectedPage.items.findIndex((item) => item.id === id);
		const exists = index > -1;
		const sortByOrdinal = (a, b) => (a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0);

		const sortedChoices = choices ? [...choices].sort(sortByOrdinal) : undefined;
		const sortedRows = rows ? [...rows].sort(sortByOrdinal) : undefined;
		const sortedCols = cols ? [...cols].sort(sortByOrdinal) : undefined;

		const item = {
			logic,
			...questionData,
			...data[Object.keys(data)[0]],
			...(sortedChoices && { choices: sortedChoices }),
			...(sortedRows && { rows: sortedRows }),
			...(sortedCols && { cols: sortedCols }),
		};

		if (!questionData.deletePicture && localPicture) {
			item.picture = {
				...localPicture,
				__typename: 'Picture',
			};
		}

		if (questionData.video) {
			item.video = {
				...questionData.video,
				__typename: 'Video',
			};
		}

		const updatedItems = exists
			? [...selectedPage.items.slice(0, index), item, ...selectedPage.items.slice(index + 1)]
			: [
					...selectedPage.items.slice(0, ordinal - 1),
					item,
					...selectedPage.items.slice(ordinal - 1),
				];

		const updatedPage = {
			...selectedPage,
			items: updatedItems,
		};

		const updatedMainPages = [
			...mainPages.slice(0, pageIndex),
			updatedPage,
			...mainPages.slice(pageIndex + 1),
		];

		const updatedSurvey = {
			...survey,
			content: {
				...survey.content,
				mainPages: updateOrdinalAndNumber(updatedMainPages),
			},
			questions: [...survey.questions, item],
		};

		cache.writeQuery({
			query: QUERY,
			variables: { uid },
			data: {
				survey: updatedSurvey,
			},
		});
	};

export const mutateNetPromoterQuestion = graphql(MUTATION_NPSQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateNetPromoterQuestion: (questionData, page, ordinal, localPicture) => {
			const {
				id,
				text,
				helpText,
				leftText,
				rightText,
				required,
				picture,
				deletePicture,
				edited,
				video,
			} = questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					leftText,
					rightText,
					required,
					ordinal,
					edited,
					video,
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					NPSQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						__typename: 'NetPromoterQuestion',
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateRatingQuestion = graphql(MUTATION_RatingQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateRatingQuestion: (questionData, page, ordinal, localPicture) => {
			const { id, text, helpText, pointCount, required, picture, deletePicture, edited, video } =
				questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					pointCount,
					required,
					ordinal,
					edited,
					video,
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					ratingQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						__typename: 'RatingQuestion',
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateMatrixQuestion = graphql(MUTATION_MatrixQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateMatrixQuestion: (questionData, page, ordinal, localPicture) => {
			const {
				id,
				text,
				helpText,
				cols,
				rows,
				required,
				type,
				picture,
				deletePicture,
				edited,
				video,
				minimumAnswers,
				maximumAnswers,
			} = questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					cols: cols.map(({ id, text }, index) => ({
						id,
						text,
						ordinal: index + 1,
					})),
					rows: rows.map(({ id, text }, index) => ({
						id,
						text,
						ordinal: index + 1,
					})),
					required,
					type,
					ordinal,
					edited,
					video,
					chosenAnswerCount: {
						minimumAnswers,
						maximumAnswers,
					},
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					matrixQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						isEditedLimit: false,
						number: '',
						rows: variables.questionData.rows.map((item) => ({
							...item,
							id: item.id || uuid(),
							__typename: 'MatrixItem',
						})),
						cols: variables.questionData.cols.map((item) => ({
							...item,
							id: item.id || uuid(),
							__typename: 'MatrixItem',
						})),
						__typename: 'MatrixQuestion',
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateSemanticDifferentialQuestion = graphql(MUTATION_SemanticQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateSemanticDifferentialQuestion: (questionData, page, ordinal, localPicture) => {
			const {
				id,
				text,
				helpText,
				rows,
				pointCount,
				required,
				picture,
				deletePicture,
				edited,
				video,
			} = questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					rows: rows.map(({ id, leftLabel, rightLabel }, index) => ({
						id,
						ordinal: index + 1,
						leftLabel,
						rightLabel,
					})),
					pointCount,
					required,
					ordinal,
					edited,
					video,
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					semanticQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						rows: variables.questionData.rows.map((item) => ({
							...item,
							id: item.id || uuid(),
							picture: null,
							__typename: 'SemanticDifferentialRow',
						})),
						__typename: 'SemanticDifferentialQuestion',
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateChoiceQuestion = graphql(MUTATION_ChoiceQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateChoiceQuestion: (questionData, page, ordinal, localPicture) => {
			const {
				id,
				text,
				helpText,
				choices,
				required,
				layout,
				isMultipleAnswer,
				picture,
				deletePicture,
				edited,
				video,
				minimumAnswers,
				maximumAnswers,
				isQuizItem = false,
			} = questionData;

			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					choices: choices.map(({ id, text, open, quizPoints }, index) => ({
						id,
						text,
						open,
						ordinal: index + 1,
						quizPoints: Number(quizPoints),
					})),
					required,
					layout,
					ordinal,
					isMultipleAnswer,
					edited,
					video,
					isQuizItem,
					chosenAnswerCount: {
						minimumAnswers,
						maximumAnswers,
					},
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					choiceQuestion: {
						...questionData,
						...variables.questionData,
						isEditedLimit: false,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						choices: variables.questionData.choices.map((item) => ({
							...item,
							id: item.id || uuid(),
							picture: null,
							__typename: 'Choice',
						})),
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateImageChoiceQuestion = graphql(MUTATION_ImageChoiceQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateImageChoiceQuestion: (questionData, page, ordinal, localPicture) => {
			const {
				id,
				text,
				helpText,
				choices,
				required,
				type,
				isMultipleAnswer,
				picture,
				deletePicture,
				edited,
				video,
				minimumAnswers,
				maximumAnswers,
			} = questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					choices: choices.map(({ id, text, image }, index) => ({
						id,
						text,
						image,
						ordinal: index + 1,
					})),
					required,
					type,
					ordinal,
					isMultipleAnswer,
					edited,
					video,
					chosenAnswerCount: {
						minimumAnswers,
						maximumAnswers,
					},
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					imageChoiceQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						layout: 'ROW',
						isQuizItem: false,
						isEditedLimit: false,
						choices: variables.questionData.choices.map((item, i) => ({
							picture: item.image
								? {
										url: URL.createObjectURL(item.image),
										__typename: 'Picture',
									}
								: choices[i].picture?.url
									? {
											url: choices[i].picture.url,
											__typename: 'Picture',
										}
									: null,
							...item,
							id: item.id || uuid(),
							open: false,
							quizPoints: 0,
							__typename: 'Choice',
						})),
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateTextQuestion = graphql(MUTATION_TextQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateTextQuestion: (questionData, page, ordinal, localPicture) => {
			const {
				id,
				text,
				helpText,
				required,
				subtype,
				limit,
				picture,
				deletePicture,
				edited,
				video,
				answerLengthLimit,
			} = questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					required,
					type: subtype,
					limit,
					ordinal,
					edited,
					video,
					answerLengthLimit: Number(answerLengthLimit),
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					textQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						__typename: 'TextQuestion',
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateImportanceQuestion = graphql(MUTATION_OrdinalQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateImportanceQuestion: (questionData, page, ordinal, localPicture) => {
			const { id, text, helpText, rows, required, picture, deletePicture, edited, video } =
				questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					rows: rows.map(({ id, text }, index) => ({
						id,
						text,
						ordinal: index + 1,
					})),
					required,
					ordinal,
					edited,
					video,
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					ordinalQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						rows: variables.questionData.rows.map((item) => ({
							...item,
							id: item.id || uuid(),
							__typename: 'PresetTextAnswer',
						})),
						__typename: 'ImportanceQuestion',
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateDividePointsQuestion = graphql(MUTATION_SplitPointsQuestion, {
	props: ({ mutate, ownProps }) => ({
		mutateDividePointsQuestion: (questionData, page, ordinal, localPicture) => {
			const {
				id,
				text,
				helpText,
				rows,
				required,
				pointCount,
				units,
				picture,
				deletePicture,
				edited,
				video,
			} = questionData;
			const variables = {
				questionData: {
					page,
					id,
					text,
					helpText,
					rows: rows.map(({ id, text }, index) => ({
						id,
						text,
						ordinal: index + 1,
					})),
					required,
					pointCount,
					units,
					ordinal,
					edited,
					video,
				},
			};
			if (picture) variables.questionData.picture = picture;
			if (deletePicture) variables.questionData.deletePicture = deletePicture;
			return mutate({
				variables,
				optimisticResponse: {
					splitPointsQuestion: {
						...questionData,
						...variables.questionData,
						picture: null,
						id: questionData.id || uuid(),
						number: '',
						rows: variables.questionData.rows.map((item) => ({
							...item,
							id: item.id || uuid(),
							picture: null,
							__typename: 'PresetTextAnswer',
						})),
						__typename: 'DividePointsQuestion',
					},
				},
				update: update(ownProps, page, questionData, ordinal, localPicture),
			});
		},
	}),
});

export const mutateTextBlock = graphql(MUTATION_TextBlock, {
	props: ({ mutate, ownProps }) => ({
		mutateTextBlock: (data, page, ordinal) => {
			const { id, text } = data;
			return mutate({
				variables: {
					data: {
						page,
						id,
						text,
						ordinal,
					},
				},
				optimisticResponse: {
					textBlock: {
						__typename: 'TextBlock',
						id: id || 0,
						text,
						ordinal,
					},
				},
				update: update(ownProps, page, data, ordinal),
			});
		},
	}),
});

export const mutateDeleteItem = graphql(MUTATION_DeleteItem, {
	props: ({ mutate, ownProps: { uid, active } }) => ({
		mutateDeleteItem: (id) =>
			mutate({
				variables: { id },
				optimisticResponse: { deleteItem: true },
				update: (cache) => {
					const data = cache.readQuery({
						query: QUERY,
						variables: { uid },
					});

					const mainPages = data.survey.content.mainPages.map((page) => {
						if (page.hash === active) {
							const updatedItems = page.items.filter((item) => item.id !== id);
							return { ...page, items: updatedItems };
						}
						return page;
					});

					const updatedQuestions = data.survey.questions.filter((question) => question.id !== id);

					const updatedSurvey = {
						...data.survey,
						content: {
							...data.survey.content,
							mainPages: updateOrdinalAndNumber(mainPages),
						},
						questions: updatedQuestions,
					};

					cache.writeQuery({
						query: QUERY,
						variables: { uid },
						data: { survey: updatedSurvey },
					});
				},
			}),
	}),
});

export const copyItem = graphql(MUTATION_CopyItem, {
	props: ({ mutate, ownProps: { uid, active } }) => ({
		copyItem: (id) =>
			mutate({
				variables: { id },
				update: (cache, { data }) => {
					const result = cache.readQuery({
						query: QUERY,
						variables: { uid },
					});

					const mainPages = result.survey.content.mainPages.map((page) => {
						if (page.hash === active) {
							const index = page.items.findIndex((item) => item.id === id);
							const itemToCopy = page.items[index];
							const copy = data.copyItem
								? { ...data.copyItem.copy }
								: { ...itemToCopy, id: uuid() };
							copy.answered = false;
							copy.logic = {};

							const updatedItems = [
								...page.items.slice(0, index + 1),
								copy,
								...page.items.slice(index + 1),
							];

							return { ...page, items: updatedItems };
						}
						return page;
					});

					const updatedSurvey = {
						...result.survey,
						content: {
							...result.survey.content,
							mainPages: updateOrdinalAndNumber(mainPages),
						},
					};

					cache.writeQuery({
						query: QUERY,
						variables: { uid },
						data: { survey: updatedSurvey },
					});
				},
			}),
	}),
});
