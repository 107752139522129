import styled from '@emotion/styled';
import { ModalProps, ModalSidebarProps } from './';

export const StyledContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const StyledGradient = styled.div`
	top: 0;
	width: inherit;
	z-index: 9000;
	flex: 1 0 auto;
	position: fixed;
`;

export const StyledBody = styled.div<ModalProps & { center?: boolean; width?: number | string }>`
	flex: 1 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	padding: ${({ theme }) => `${theme.helpers.spacer(11)}px 0`};
	min-height: ${({ theme }) => `${theme.helpers.spacer(16)}px`};
	width: ${({ theme }) => `calc(100% - ${theme.helpers.spacer(6)}px)`};

	${({ center }) => center && 'margin: auto !important;'};
	${({ width }) => width && `width: ${width}${typeof width === 'number' ? 'px' : ''} !important`};
	${({ width }) => width && 'max-width: unset !important'};

	@media (min-width: 480px) {
		margin: auto;
		max-width: 416px;
		padding: ${({ theme }) => `${theme.helpers.spacer(13)}px 0`};
	}

	@media (min-width: 784px) {
		max-width: 544px;
		margin-left: ${({ theme }) => `${theme.helpers.spacer(15)}px`};
		padding: ${({ theme }) => `${theme.helpers.spacer(10)}px 0`};
	}

	${StyledGradient} {
		height: 88px;
		background: ${({ theme }) =>
			`linear-gradient(180deg, ${theme.colors.white} 50%, rgba(255, 255, 255, 0) 100%)`};

		@media (min-width: 480px) {
			height: 104px;
		}

		@media (min-width: 784px) {
			display: none;
		}
	}

	.content {
		width: inherit;
		min-height: 128px;

		> *:only-child {
			width: inherit;
		}
	}
`;

export const StyledFooter = styled.div<ModalProps>`
	z-index: 9000;
	padding: 32px 0;
`;

export const StyledHeader = styled.div<ModalProps>`
	z-index: 9002;
	top: 0;
	display: flex;
	justify-content: flex-start;
	position: fixed;
	background: linear-gradient(180deg, #ffffff 0%, #ffffff 67%, rgba(255, 255, 255, 0) 100%);
`;

export const StyledModal = styled.div<ModalProps>`
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	z-index: 9999;
	display: flex;
	align-items: center;
	background: ${({ theme, blurred }) =>
		blurred ? 'rgba(255, 255, 255, 0.52)' : theme.colors.white};
	backdrop-filter: blur(${({ blurred }) => (blurred ? 22 : 0)}px);

	.back,
	.close {
		z-index: 9005;
		position: fixed;
		top: ${({ theme }) => `${theme.helpers.spacer(2)}px`};
	}

	.back {
		left: ${({ theme }) => `${theme.helpers.spacer(2)}px`};
	}

	.close {
		right: ${({ theme }) => `${theme.helpers.spacer(2)}px`};
	}

	@media (min-width: 480px) {
		.back,
		.close {
			top: ${({ theme }) => `${theme.helpers.spacer(3)}px`};
		}

		.back {
			left: ${({ theme }) => `${theme.helpers.spacer(3)}px`};
		}

		.close {
			right: ${({ theme }) => `${theme.helpers.spacer(3)}px`};
		}
	}

	@media (min-width: 1032px) {
		.back,
		.close {
			top: ${({ theme }) => `${theme.helpers.spacer(4)}px`};
		}

		.back {
			left: ${({ theme }) => `${theme.helpers.spacer(4)}px`};
		}

		.close {
			right: ${({ theme }) => `${theme.helpers.spacer(4)}px`};
		}
	}

	.close.light {
		transition: color 200ms ease-in-out;
		color: ${({ theme }) => theme.colors.white};
		border-color: ${() => `rgba(255, 255, 255, 0.4)`};

		:hover {
			color: ${({ theme }) => theme.colors.black[100]};
			background: ${({ theme }) => theme.colors.white};
			border-color: ${({ theme }) => theme.colors.white};
		}
	}

	&.prev {
		${StyledContainer} {
			flex-direction: row-reverse;
		}

		.slide-exit-active {
			margin: 0 -100% 0 0;
		}
	}

	.slide-exit-active {
		margin: 0 0 0 -100%;
	}

	.slide-enter-active,
	.slide-exit-active {
		transition: margin 350ms ease-in-out;
	}
`;

export const StyledContent = styled.div<ModalProps>`
	width: 100%;
	height: 100%;
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	background: ${({ bgColor, theme }) => bgColor || theme.colors.white};

	&.hasScrollbar {
		${StyledBody}:has(${StyledFooter}) {
			padding-bottom: 144px;
		}

		${StyledFooter} {
			bottom: 0;
			width: inherit;
			position: fixed;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 13.33%, #ffffff 100%);
		}
	}
`;

export const StyledSidebar = styled.div<ModalSidebarProps>`
	display: none;
	flex: 1 0 auto;
	color: ${({ color }) => color};
	background: ${({ theme, bgColor }) => bgColor || theme.colors.grey[200]};

	width: ${({ fixedWidth }) =>
		fixedWidth
			? `${typeof fixedWidth === 'number' ? `${fixedWidth}px` : fixedWidth} !important`
			: '320px'};

	${StyledGradient} {
		height: 96px;
		background: ${({ theme, bgColor }) =>
			`linear-gradient(180deg, ${
				bgColor || theme.colors.grey[200]
			} 50%, rgba(255, 255, 255, 0) 100%)`};
	}

	.content {
		width: 256px;
		margin: auto;
		padding: ${({ theme }) => `${theme.helpers.spacer(10)}px 0`};
	}

	@media (min-width: 1032px) {
		display: flex;
	}

	@media (min-width: 1110px) {
		width: 384px;

		.content {
			width: 288px;
		}
	}

	@media (min-width: 1368px) {
		.content {
			margin-left: ${({ theme }) => `${theme.helpers.spacer(6)}px`};
		}
	}
`;

export const SidebarImage = styled.div<Omit<ModalProps, 'children'>>`
	z-index: 9001;
	display: none;
	width: ${({ hideSidebarImageOnSlides, slide }) =>
		hideSidebarImageOnSlides?.includes(slide) ? '0' : '320px'};
	height: 100%;
	flex: 1 0 auto;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colors.grey[200]};
	background-image: ${({ withSideImage }) => `url("${withSideImage}")`};
	background-size: cover;
	padding: ${({ theme }) => `${theme.helpers.spacer(10)}px 0`};
	opacity: ${({ hideSidebarImageOnSlides, slide }) =>
		hideSidebarImageOnSlides?.includes(slide) ? 0 : 1};
	transition:
		opacity 300ms ease-out,
		width 0ms linear 301ms;

	@media (min-width: 1032px) {
		display: flex;
	}

	@media (min-width: 1110px) {
		width: ${({ hideSidebarImageOnSlides, slide }) =>
			hideSidebarImageOnSlides?.includes(slide) ? '0' : '384px'};
	}
`;
