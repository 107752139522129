import React, { FC, forwardRef, memo, RefObject, useCallback, useState } from 'react';
import {
	NavigationProps,
	StyledNavigation,
	StyledHeader,
	StyledItem,
	StyledDevider,
	FolderProps,
	StyledStickyFooter,
	StyledTourWrapper,
	StyledFooterChildren,
	StyledScrollBarArea,
	StyledLeftBarContent,
	StyledMoreButton,
	StyledCategory,
} from './';
import Typography from '../Typography';
import { pushPropsIntoAllChildren } from '../../utils';
import { Scrollbars } from 'react-custom-scrollbars';
import { Icon } from '../Icon';

const Navigation: FC<NavigationProps> = forwardRef(
	(
		{
			children,
			className,
			tour,
			footerChildren,
			isBuilderVariant,
			id,
			endId,
			moreText,
			...props
		}: NavigationProps,
		ref: RefObject<HTMLDivElement>,
	) => {
		const [overflowing, setOverflowing] = useState(false);
		const [bottomReached, setBottomReached] = useState(false);

		const setStickiness = () => {
			const { height: coverHeight } = document.getElementById(id).getBoundingClientRect();
			const { height: contentHeight } = ref?.current.getBoundingClientRect();
			setOverflowing(contentHeight > coverHeight);

			const { scrollTop, scrollHeight, clientHeight } = document.getElementById(id);
			setBottomReached(scrollTop + clientHeight >= scrollHeight - 10);
		};

		const scrollToBottom = () => {
			document.getElementById(endId).scrollIntoView({ behavior: 'smooth' });
		};

		return (
			<StyledNavigation className={className} {...props}>
				<StyledScrollBarArea>
					<Scrollbars
						autoHide
						onUpdate={isBuilderVariant && setStickiness}
						renderView={({ style }) => <div style={style} className="scrollbarView" id={id} />}
					>
						<div id="test">
							<StyledLeftBarContent ref={ref} id="leftbarContent" variant={props.variant}>
								{children}
								<div id={endId} />
							</StyledLeftBarContent>
						</div>
					</Scrollbars>
					<StyledMoreButton
						overflowing={overflowing}
						bottomReached={bottomReached}
						onClick={scrollToBottom}
					>
						<Icon name="order-16" />
						{moreText}
					</StyledMoreButton>
				</StyledScrollBarArea>
				<StyledStickyFooter overflowing={overflowing} bottomReached={bottomReached}>
					{footerChildren && (
						<StyledFooterChildren overflowing={overflowing} bottomReached={bottomReached}>
							{footerChildren}
						</StyledFooterChildren>
					)}
					{tour && <StyledTourWrapper>{tour}</StyledTourWrapper>}
				</StyledStickyFooter>
			</StyledNavigation>
		);
	},
);

Navigation.displayName = 'Navigation';

const Header: FC<NavigationProps> = ({ children, className, variant }: NavigationProps) => (
	<StyledHeader className={className} variant={variant}>
		<Typography variant="textMedium300">{children}</Typography>
	</StyledHeader>
);

const Item: FC<NavigationProps> = forwardRef(
	({ children, typo, ...props }: NavigationProps, ref) => {
		const handleOnClick = useCallback(() => {
			const { onClick, id } = props;
			return onClick ? onClick(id) : null;
		}, [props]);

		return (
			<StyledItem {...props} ref={ref} onClick={handleOnClick}>
				<Typography variant={typo ? typo : props.active ? 'textMedium300' : 'textRegular300'}>
					{pushPropsIntoAllChildren(children, { active: props.active ? 1 : 0 })}
				</Typography>
			</StyledItem>
		);
	},
);

const Folder: FC<FolderProps> = ({ active }: FolderProps) => (
	<Icon name={active ? 'folder-opened-24' : 'folder-24'} />
);

const Category: FC<NavigationProps> = ({ children, ...props }: NavigationProps) => (
	<StyledCategory {...props}>
		{children}
		<Icon name="arrow-right-small-16" />
	</StyledCategory>
);

const Devider: FC = (props) => <StyledDevider {...props} />;

Item.displayName = 'Item';

Navigation.defaultProps = {
	endId: 'EndOfScrollableArea',
	id: 'scrollableArea',
	variant: 'navigation',
};

Item.defaultProps = {
	variant: 'navigation',
};

Header.defaultProps = {
	variant: 'navigation',
};

const NavigationExp = memo(Navigation);
const HeaderExp = memo(Header);
const ItemExp = memo(Item);
const FolderExp = memo(Folder);
const DeviderExp = memo(Devider);
const CategoryExp = memo(Category);

export { NavigationExp, HeaderExp, ItemExp, FolderExp, DeviderExp, CategoryExp };
