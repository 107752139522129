import styled from '@emotion/styled';
import { LoaderProps } from './';
import { css, keyframes } from '@emotion/react';
import { getThemeColor } from '../../utils';

const wave = (numOfDots, jumpHeight) => keyframes`
	0% {
		opacity: 0.1;
	}
	${`${Math.floor(100 / numOfDots)}%`} {
		opacity: 1;
		transform: translateY(-${jumpHeight}px);
	}
	${`${Math.floor(200 / numOfDots)}%`} {
		opacity: 0.1;
	}
`;

export const Dot = styled.div<LoaderProps>`
	animation: ${({ jumpHeight, numOfDots, speed }) => css`
		${wave(numOfDots, jumpHeight)} ${speed * numOfDots}ms infinite
	`};

	${({ numOfDots, speed }) =>
		[...Array(numOfDots)].map(
			(x, i) => css`
				:nth-of-type(${i}) {
					animation-delay: ${i * speed}ms;
				}
			`,
		)};

	height: ${({ dotSize }) => dotSize}px;
	width: ${({ dotSize }) => dotSize}px;
	background-color: ${({ theme, color }) => getThemeColor(color, theme) || theme.colors.black[100]};
	border-radius: 50%;
	display: inline-block;
	opacity: 0.1;
	:not(:last-child) {
		margin-right: ${({ spaceSize }) => spaceSize}px;
	}
`;

export const ThreeDotsLoader = styled.div<LoaderProps>`
	display: inline-flex;
	align-items: flex-end;
	height: ${({ jumpHeight, dotSize }) => jumpHeight + dotSize}px;
`;
