import gql from 'graphql-tag';

export default gql`
	fragment Response on Response {
		id
		submitted
		discarded
		deleted
		timeSpent {
			hours
			minutes
			seconds
			secondsTotal
		}
		number
		source
		email
		answers {
			questionId
			answer {
				... on AnswerDataChoices {
					list {
						choice {
							text
							id
							open
							ordinal
							quizPoints
						}
						ownText
					}
				}
				... on AnswerDataRating {
					rating
					maximum
				}
				... on AnswerDataMatrixText {
					rows {
						rowId
						texts
					}
				}
				... on AnswerDataMatrixChoiced {
					rows {
						rowId
						colIds
						selected
					}
				}
				... on AnswerDataSemanticDifferential {
					list {
						row {
							id
							ordinal
						}
						answer {
							rating
							maximum
						}
					}
				}
				... on AnswerDataNPS {
					rating
				}
				... on AnswerDataText {
					text
				}
				... on AnswerDataDividePoints {
					items {
						item {
							id
							text
							ordinal
						}
						assigned
					}
				}
				... on AnswerDataOrder {
					order
				}
			}
		}
		parameterValues
	}
`;
