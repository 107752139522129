import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { Modal, Typography } from 'ui';
import { PLANS_NEW } from '~/constants';

import s from './style.less';

const UpgradeSlidePanelWrapper = ({
	isTrial,
	actualPlan,
	className,
	title,
	children,
	onlyChildren,
}) => {
	const { setSlide } = Modal.useModal();
	const linkRef = useRef(null);

	useEffect(() => {
		if (linkRef.current) {
			const onHigherPlanClick = (e) => {
				if (!isTrial && actualPlan === PLANS_NEW.PRO) {
					e.preventDefault();
					setSlide(PLANS_NEW.CUSTOM);
				}
			};

			const a = linkRef.current.querySelector('a');
			a.addEventListener('click', onHigherPlanClick);

			return () => a.removeEventListener('click', onHigherPlanClick);
		}
	}, [actualPlan, setSlide, isTrial]);

	return (
		<div className={cn(s.panelWrapper, onlyChildren && s.left, className)}>
			{!onlyChildren && (
				<Typography variant="title800" className={s.title}>
					{title}
				</Typography>
			)}

			{children}

			{!onlyChildren && (
				<Typography
					variant="textRegular300"
					color="grey_shades_with_blue[500]"
					className={s.needMore}
				>
					<FormattedMessage id="app.stripe.need-more-responses" />{' '}
					<span ref={linkRef}>
						<FormattedHTMLMessage values={{ LINK: location.origin }} id="app.stripe.upgrade" />
					</span>
				</Typography>
			)}
		</div>
	);
};

UpgradeSlidePanelWrapper.propTypes = {
	isTrial: PropTypes.bool,
	actualPlan: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.node,
	onlyChildren: PropTypes.bool,
};

export default memo(UpgradeSlidePanelWrapper);
