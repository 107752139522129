import gql from 'graphql-tag';

export default gql`
	fragment metadata on Survey {
		sharingMetadata {
			description
			image
			title
		}
	}
`;
