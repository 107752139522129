import React, { memo } from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { SwitchTransition, Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';

const Switcher = ({
	componentA,
	componentB,
	visibleAWhen,
	visibleBWhen,
	className,
	timeout = [300, 300],
}) => {
	const onEnter = (node) => {
		TweenLite.fromTo(
			node,
			timeout && typeof timeout === 'number' ? timeout / 1000 : timeout[0] / 1000,
			{
				opacity: 0,
				visibility: 'hidden',
			},
			{
				opacity: 1,
				visibility: 'visible',
			},
		);
	};

	const onExit = (node) => {
		TweenLite.to(
			node,
			timeout && typeof timeout === 'number' ? timeout / 1000 : timeout[1] / 1000,
			{
				opacity: 0,
				visibility: 'hidden',
			},
		);
	};
	return (
		<SwitchTransition>
			<Transition
				key={visibleAWhen ? 'A' : visibleBWhen ? 'B' : 'C'}
				timeout={
					timeout && typeof timeout === 'number'
						? timeout
						: {
								enter: timeout[0],
								exit: timeout[1],
						  }
				}
				onEnter={onEnter}
				onExit={onExit}
				unmountOnExit
				mountOnEnter
			>
				<div className={className}>
					{visibleAWhen && componentA}
					{visibleBWhen && componentB}
				</div>
			</Transition>
		</SwitchTransition>
	);
};

Switcher.propTypes = {
	className: PropTypes.string,
	componentA: PropTypes.element,
	componentB: PropTypes.element,
	timeout: oneOf([PropTypes.array, PropTypes.number]),
	visibleAWhen: PropTypes.bool,
	visibleBWhen: PropTypes.bool,
};

export default memo(Switcher);
