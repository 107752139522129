import * as Sentry from '@sentry/react';
import withRouter from '~/hoc/withRouter';
import { PAGES } from '~/constants/pages';

class ErrorBoundary extends Sentry.ErrorBoundary {
	componentDidCatch(error, errorInfo) {
		if (process.env.NODE_ENV === 'production') {
			super.componentDidCatch(error, errorInfo);
			if (this.props.location.pathname !== PAGES['not-found'].path) {
				this.props.navigate(PAGES['not-found'].path);
				location.reload();
			}
		}
	}

	render() {
		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
