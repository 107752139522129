import gql from 'graphql-tag';

export default gql`
	fragment customLanguages on User {
		languageCode
		customLanguages {
			id
			name
			translations
			sourceLanguage {
				code
			}
		}
	}
`;
