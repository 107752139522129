import gql from 'graphql-tag';

export default gql`
	fragment totalSeatsCount on User {
		team {
			id
			totalSeatsCount
		}
	}
`;
