import gql from 'graphql-tag';

export default gql`
	mutation movePage($pageId: Int!, $newPosition: Int!) {
		movePage(pageId: $pageId, newPosition: $newPosition) {
			id
			position
			hash
		}
	}
`;
