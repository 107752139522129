import styled from '@emotion/styled';
import { CreateButtonProps } from '.';

export const StyledCreateButton = styled.div<CreateButtonProps>`
	border: ${({ theme: { colors }, disabled }) =>
		`1px dashed ${disabled ? colors.grey_shades_with_blue[300] : colors.primary[500]}`};
	border-radius: 16px;
	position: relative;
	width: ${({ width }) =>
		typeof width === 'string' ? width : typeof width === 'number' ? `${width}px` : 'auto'};
	box-sizing: border-box;
	cursor: pointer;
	color: ${({ theme: { colors }, disabled }) =>
		disabled ? colors.grey_shades_with_blue[300] : colors.primary[500]};
	transition: all 200ms;
	background-color: ${({ theme: { colors }, disabled }) =>
		disabled ? colors.grey[200] : colors.primary[100]};

	&:hover {
		color: ${({ theme: { colors }, disabled }) =>
			disabled ? colors.grey_shades_with_blue[300] : colors.primary[600]};
		background-color: ${({ theme: { colors }, disabled }) =>
			disabled ? colors.grey[200] : colors.primary[100]};
		border: ${({ theme: { colors }, disabled }) =>
			`1px dashed ${disabled ? colors.grey_shades_with_blue[300] : 'transparent'}`};
		.border {
			opacity: 1;
		}
	}
`;

export const Border = styled.div<CreateButtonProps>`
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: transparent;
	box-sizing: content-box;
	border-radius: 16px;
	border: 2px solid ${({ theme }) => theme.colors.primary[500]};
	top: -2px;
	left: -2px;
	transition: opacity 200ms ease-out;
`;

export const Stretcher = styled.div<CreateButtonProps>`
	padding-bottom: ${({ height }) =>
		typeof height === 'string' ? height : typeof height === 'number' ? `${height - 2}px` : '100%'};
	min-height: ${({ minHeight }) =>
		typeof minHeight === 'string'
			? minHeight
			: typeof minHeight === 'number'
				? `${minHeight}px`
				: '100%'};
`;

export const Content = styled.div<CreateButtonProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	i {
		margin-bottom: 16px;
	}
`;
