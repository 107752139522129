import gql from 'graphql-tag';

const query = (children, fragments) => gql`
		query survey($uid: String!) {
			survey(uid: $uid) {
			uid
			 ${children}
			}
		}
		${fragments}
	`;

export default query;
