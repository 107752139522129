import React, { FC, forwardRef, memo, RefObject, useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
	SurveyCardProps,
	StyledSurveyCard,
	Logo,
	Border,
	Background,
	Info,
	SurveyCardWithRef,
} from '.';
import { Button, Typography } from '../../index';
import { Menu } from '../Menu';
import { Status } from '../Status';

export const SurveyCard: FC<SurveyCardWithRef> = forwardRef<HTMLDivElement, SurveyCardProps>(
	(
		{
			backgroundImage,
			backgroundColor,
			backgroundOverlay,
			logo,
			note,
			title,
			onClick,
			onNoteClick,
			responses,
			className,
			menuItems,
			builderLink,
			titleText,
			noteText,
			publishStatus,
			responsesBtn,
			animated,
		}: SurveyCardProps,
		ref: RefObject<HTMLDivElement>,
	) => {
		const handleOnMenuClick = useCallback((e) => {
			e.preventDefault;
			e.stopPropagation();
		}, []);

		return (
			<StyledSurveyCard
				ref={ref}
				className={className}
				data-test-id="survey_tile"
				animated={animated}
				data-test-value={JSON.stringify({
					survey_logo: logo,
					survey_picture: backgroundImage,
					note: noteText,
					survey_name: titleText,
					responses: responses,
				})}
			>
				<Border />
				{publishStatus}
				<Link to={builderLink} onClick={onClick}>
					<Background
						className="cardBackground"
						backgroundImage={backgroundImage}
						backgroundColor={backgroundColor}
					/>
					<Logo className="cardLogo" logo={logo} backgroundOverlay={backgroundOverlay}>
						<div className="image" />
					</Logo>
				</Link>
				<Info responses={responses}>
					<Link to={builderLink} onClick={onClick}>
						<Typography variant="textMedium400" className="title" data-test-id="survey_tile_title">
							{title}
						</Typography>
					</Link>
					{noteText && (
						<Status state="Note_yellow" onClick={onNoteClick} className="note">
							{note}
						</Status>
					)}
					<div className="actionLine">
						{responsesBtn}
						<Menu
							placement="bottom-start"
							offset={({ placement: p }) => {
								if (p === 'bottom-start' || p === 'top-start') {
									return [-7, 16];
								} else {
									return [7, 16];
								}
							}}
							size="md"
							items={menuItems}
							width={232}
							popperOptions={{
								strategy: 'fixed',
								modifiers: [
									{
										name: 'flip',
										options: {
											fallbackPlacements: ['top-start', 'bottom-end', 'top-end', 'right', 'left'],
										},
									},
								],
							}}
							appendTo={() => document.body}
							data-test-id="survey_dropdown_menu"
						>
							<Button
								variant="icon"
								startIcon="more-large-16"
								size="sm"
								onClick={handleOnMenuClick}
							/>
						</Menu>
					</div>
				</Info>
			</StyledSurveyCard>
		);
	},
);

SurveyCard.displayName = 'SurveyCard';

export default memo(SurveyCard) as FC<SurveyCardWithRef>;
