import { useEffect, MutableRefObject } from 'react';

/**
 * Hook that fires fn on clicks outside the passed ref
 */
const useOutsideClick = (
	ref: MutableRefObject<HTMLElement>,
	onClick?: () => void,
	disabled?: boolean,
): void => {
	useEffect(() => {
		if (disabled || !onClick) return;
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClick();
			}
		};

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [disabled, onClick, ref]);
};

export default useOutsideClick;
