// eslint-disable-next-line
const copyToClipboard = (ref) => {
	ref.focus();
	ref.select();
	try {
		document.execCommand('copy');
	} catch (err) {
		console.log('Unable to store code in clipboard. ', err);
	}
	return true;
};

export default copyToClipboard;
