import { compose } from 'redux';
import React, { memo, useContext } from 'react';
import withRouter from '~/hoc/withRouter';
import mutation from '~/cache/mutation';
import { withSendTracking } from '~/hoc';
import query from '~/cache/query';
import InAppModal from '~/components/InAppModal';
import InAppPopup from '~/components/InAppPopup';
import InAppBar from '~/components/InAppBar';
import { CSSTransition } from 'react-transition-group';
import InAppSlideIn from '~/components/InAppSlideIn';
import InAppEmbeddedSurvey from '~/components/InAppEmbeddedSurvey';
import { InAppContentContext } from '../InAppContentProvider';

const displayType = {
	MODAL: 'modal',
	POPUP: 'popup',
	BAR: 'bar',
	'SLIDE-IN': 'slide-in',
	'EMBEDDED-SURVEY': 'embedded-survey',
};

const InAppContent = ({ sendTracking, closeInAppContent, userID = '', history, language = '' }) => {
	const { inAppContentIsOpen, inAppContent, setInAppContent } = useContext(InAppContentContext);

	if (!inAppContent) {
		return null;
	}

	const msg_id = new URLSearchParams(new URL(inAppContent.url).search).get('msg_id');
	const props = {
		inAppContentIsOpen,
		url: inAppContent.url,
		language,
		onClose: (skipped) => {
			sendTracking(0, `${msg_id}_close_v0`);
			closeInAppContent({ input: { id: inAppContent.id, skipped } });
			setInAppContent(null, false);
		},
		onLoad: (e) => {
			const iframe = e.target;
			sendTracking(0, `${msg_id}_open_v0`);
			window.addEventListener('message', (message) => {
				if (message.source !== iframe?.contentWindow) {
					return;
				}

				const msg = JSON.parse(message?.data);

				if (msg?.event === 'cta') {
					sendTracking(0, `${msg_id}_cta_v0`);

					if (msg?.data?.redirect) {
						history.push({
							pathname: msg?.data?.redirect,
							state: {
								saleSource: {
									section: 'app',
									element: msg_id,
									version: '',
								},
							},
						});
					}
				}
			});

			iframe?.contentWindow.postMessage(
				JSON.stringify({
					user: userID,
				}),
				'*',
			);
		},
	};

	const onExit = () => setInAppContent(null, false);

	switch (inAppContent.displayType) {
		default:
		case displayType.MODAL:
			return <InAppModal {...props} />;
		case displayType.POPUP:
			return <InAppPopup {...props} />;
		case displayType.BAR:
			return <InAppBar {...props} />;
		case displayType['SLIDE-IN']:
			return (
				<CSSTransition timeout={300} in={inAppContentIsOpen} onExited={onExit}>
					<InAppSlideIn {...props} />
				</CSSTransition>
			);
		case displayType['EMBEDDED-SURVEY']:
			return (
				<CSSTransition timeout={300} in={inAppContentIsOpen} onExited={onExit}>
					<InAppEmbeddedSurvey {...props} />
				</CSSTransition>
			);
	}
};

export default compose(
	memo,
	withRouter,
	mutation('closeInAppContent', {
		update: ({ cache, variables, getQuery }) => {
			const query = {
				query: getQuery('user/inAppContent'),
			};
			const { user } = cache.readQuery(query);
			cache.writeQuery({
				...query,
				data: {
					user: {
						...user,
						inAppContent: user.inAppContent.filter(({ id }) => variables.input.id !== id),
					},
				},
			});
		},
	}),
	withSendTracking,
	query('account', {
		fragments: ['Language'],
		mapProps: ({ user }) => ({ language: user?.language }),
	}),
	query('user/inAppContent', {
		mapProps: ({ user }) => ({ userID: user?.userID }),
	}),
)(InAppContent);
