import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from 'ui';
import { compose } from 'redux';
import cn from 'classnames';
import { useInAppModal } from '~/components/InApps';
import { withSendTracking } from '~/hoc';
import s from './style.less';

const LearnMore = ({ name, className, intl, variant, icon, size, sendTracking, active }) => {
	const { openInAppModal } = useInAppModal();

	const handleOnClick = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();
			openInAppModal(name);
			sendTracking(`${name}_click`, `${name}_click`);
		},
		[name, openInAppModal, sendTracking],
	);

	return name ? (
		<Button
			size={size || 'sm'}
			variant={variant.split('_bg')?.[0]}
			onClick={handleOnClick}
			color="ghost"
			startIcon={['icon', 'icon_bg'].includes(variant) ? 'info-large-16' : icon}
			className={cn(
				variant === 'simpleLink250' && s.learnMoreText,
				['icon', 'icon_bg'].includes(variant) && s.learnMoreIcon,
				variant === 'icon' && s.noBg,
				className,
				name,
				active && s.active,
			)}
		>
			{intl.formatMessage({ id: 'app.publish.learn-more' })}
		</Button>
	) : null;
};

LearnMore.propTypes = {
	active: PropTypes.number,
	className: PropTypes.string,
	icon: PropTypes.string,
	intl: PropTypes.object,
	name: PropTypes.string,
	sendTracking: PropTypes.func,
	size: PropTypes.string,
	variant: PropTypes.string,
};

export default compose(memo, injectIntl, withSendTracking)(LearnMore);
