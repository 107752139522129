import gql from 'graphql-tag';

export default gql`
	query {
		unlockedService {
			name
			identifier
			invoiceUrl
		}
	}
`;
