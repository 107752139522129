import getQuery from '~/cache/query/get';

export default {
	defaults: {
		stripeModal: {
			upgradeModalData: {
				isOpen: false,
				upgradeType: null,
				period: null,
				plan: null,
				salesVariant: null,
				iframeProps: null,
				saleSource: null,
				__typename: 'StripeUpgradeModalData',
			},
			__typename: 'StripeUpgradeModal',
		},
	},
	resolvers: {
		Mutation: {
			setUpgradeModal: (_, input, { cache }) => {
				cache.updateQuery({ query: getQuery('stripeModal') }, ({ stripeModal }) => ({
					stripeModal: {
						...stripeModal,
						upgradeModalData: {
							...stripeModal.upgradeModalData,
							...Object.fromEntries(
								// Filter out undefined values in the input object
								Object.entries(input).filter(([_, value]) => value !== undefined),
							),
							__typename: 'StripeUpgradeModalData',
						},
						__typename: 'StripeUpgradeModal',
					},
				}));

				return true;
			},
		},
	},
};
