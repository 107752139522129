import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { mediumStatuses, largeStatuses, StatusProps, smallStatuses, teamStatuses } from './';

export const StyledStatus = styled.div<StatusProps>`
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
	text-transform: ${({ state }) =>
		teamStatuses.indexOf(state as (typeof teamStatuses)[number]) !== -1 ? 'none' : 'uppercase'};
	display: inline-flex;
	border-radius: ${({ state }) =>
		teamStatuses.indexOf(state as (typeof teamStatuses)[number]) !== -1 ? 34 : 8}px;
	padding: ${({ state }) =>
		largeStatuses.indexOf(state as (typeof largeStatuses)[number]) !== -1 ||
		teamStatuses.indexOf(state as (typeof teamStatuses)[number]) !== -1
			? '8px 12px'
			: mediumStatuses.indexOf(state as (typeof mediumStatuses)[number]) !== -1
				? '5px 8px'
				: smallStatuses.indexOf(state as (typeof smallStatuses)[number]) !== -1
					? '3px 6px'
					: '2px 6px'};
	${({ theme: { typography }, state }) =>
		largeStatuses.indexOf(state as (typeof largeStatuses)[number]) !== -1
			? typography.title200
			: mediumStatuses.indexOf(state as (typeof mediumStatuses)[number]) !== -1 ||
				  smallStatuses.indexOf(state as (typeof smallStatuses)[number]) !== -1
				? typography.title100
				: teamStatuses.indexOf(state as (typeof teamStatuses)[number]) !== -1
					? typography.textSemibold200
					: typography.textSemibold100};
	${({ theme: { colors, typography }, state }) => {
		switch (state) {
			case 'PENDING':
				return css`
					color: ${colors.grey_shades_with_blue[600]};
					background-color: ${colors.grey[300]};
				`;
			case 'DEACTIVATED':
				return css`
					color: ${colors.red[500]};
					background-color: ${colors.red[100]};
				`;
			case 'BETA':
				return css`
					color: ${colors.white};
					background-color: ${colors.primary[275]};
					border-radius: 4px;
				`;
			case 'NEW':
				return css`
					color: ${colors.white};
					background-color: ${colors.upgrade[500]};
					border-radius: 5px;
				`;
			case 'FREE PLAN_M':
			case 'FREE PLAN_L':
				return css`
					color: ${colors.grey_shades_with_blue[400]};
					background-color: ${colors.grey[300]};
				`;
			case 'SET_ON':
				return css`
					background: ${colors.black[90]};
					background-clip: padding-box;
					color: ${colors.white};
					border: 1px solid rgba(0, 0, 0, 0.9);
					text-transform: none;
					padding: 5px 8px;

					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
						border: none;
					}
				`;
			case 'SET_OFF':
				return css`
					background: ${colors.grey[400]};
					background-clip: padding-box;
					color: ${colors.grey_shades_with_blue[400]};
					border: 1px solid rgba(0, 0, 0, 0.08);
					text-transform: none;
					padding: 5px 8px;
				`;
			// Nechal jsem tady vsechny typy planu pro pripad,
			// ze by je chtel Matus zase kazdej jinak (jak bylo v predchozi iteraci)(MHU)
			case 'MINI PLAN_M':
			case 'MINI PLAN_L':
			case 'STANDARD PLAN_M':
			case 'STANDARD PLAN_L':
			case 'PLUS PLAN_M':
			case 'PLUS PLAN_L':
			case 'STARTER PLAN_M':
			case 'STARTER PLAN_L':
			case 'MEDIUM PLAN_M':
			case 'MEDIUM PLAN_L':
			case 'ENTERPRISE PLAN_M':
			case 'ENTERPRISE PLAN_L':
				return css`
					color: ${colors.grey_shades_with_blue[600]};
					background-color: ${colors.grey_shades_with_blue[100]};
				`;
			case 'ACTIVE':
				return css`
					color: ${colors.green_success[500]};
					background-color: ${colors.green_success[100]};
				`;
			case 'Note_yellow':
				return css`
					color: ${colors.yellow[900]};
					background-color: ${colors.yellow[100]};
					text-transform: unset;
					border-radius: 6px;
					padding: 2px 6px;
				`;
			case 'Published':
				return css`
					padding: 6px 10px;
					color: ${colors.grey_shades_with_blue[600]};
					background-color: ${colors.grey[300]};
					text-transform: unset;
				`;
			case 'Unpublished':
				return css`
					padding: 6px 10px;
					color: ${colors.grey_shades_with_blue[300]};
					background-color: ${colors.white};
					text-transform: unset;
					border: 1px solid ${colors.grey_shades_with_blue[100]};
				`;
			case 'Save':
				return css`
					color: ${colors.white};
					background-color: ${colors.upgrade[500]};
					padding: 4px 6px;
					text-transform: none;
					${typography.textSemibold200}
				`;
			default:
			case 'Note':
				return css`
					color: ${colors.white};
					background-color: ${colors.grey_shades_with_blue[400]};
					text-transform: unset;
					border-radius: 3px;
				`;
		}
	}};
`;
