const getQuestionType = (question) => {
	if (!question) return;

	const { __typename } = question;

	switch (__typename) {
		case 'ChoiceQuestion': {
			const { isMultipleAnswer, hasPictureChoices } = question;
			if (isMultipleAnswer && hasPictureChoices) return 'ChoiceMultipleImagesQuestion';
			if (isMultipleAnswer) return 'ChoiceMultipleQuestion';
			if (hasPictureChoices) return 'ChoiceImagesQuestion';
			return 'ChoiceQuestion';
		}
		case 'TextQuestion': {
			const { subtype } = question;
			if (subtype === 'TEXT') return `${__typename}Text`;
			if (subtype === 'NUMBER') return `${__typename}Number`;
			if (subtype === 'EMAIL') return `${__typename}Email`;
			if (subtype === 'DATE') return `${__typename}Date`;
			if (subtype === 'PLACE') return `${__typename}Place`;
			return 'TextQuestion';
		}
		case 'MatrixQuestion': {
			const { type } = question;
			if (type === 'MATRIX_SINGLE_CHOICE') return `${__typename}Single`;
			if (type === 'MATRIX_MULTI_CHOICE') return `${__typename}Multi`;
			if (type === 'MATRIX_TEXT') return `${__typename}Text`;
			return 'MatrixQuestion';
		}
		default:
			return __typename;
	}
};

export default getQuestionType;
