import cn from 'classnames';
import React, {
	FC,
	forwardRef,
	memo,
	RefObject,
	useCallback,
	useState,
	useEffect,
	useRef,
	ReactText,
} from 'react';
import {
	DropdownButton,
	DropdownProps,
	DropdownPropsBase,
	StyledDropdown,
	DropdownItem,
	StyledErrorMsg,
	StyledMiniColorPicker,
} from './';
import Menu, { ItemProps } from '../Menu';
import { isHexColor, multipleRefs, setNativeInputValue } from '../../utils';
import Label from '../Label';
import { ChromePicker } from 'react-color';
import { Typography } from '../Typography';
import { Input } from '../Input';
import { StyledMiniColorPickerWrapper } from './Dropdown.styled';

export const Dropdown: FC<DropdownProps> = forwardRef<HTMLElement, DropdownPropsBase>(
	(
		{
			anchorWidth,
			buttonClassName,
			content,
			colorPickerContent,
			disabled,
			label,
			menuColor,
			menuMaxHeight,
			menuClassName,
			menuOffset,
			menuPlacement,
			menuSize,
			menuVariant,
			menuWidth,
			onHide,
			onMount,
			onHidden,
			onItemHover,
			name,
			items,
			onChange,
			onChangeHookForm,
			onBlur,
			placeholder,
			size,
			sticky,
			value,
			variant,
			width,
			error,
			triggerTarget,
			renderLeft,
			renderRight,
			onDropdownClick,
			menuPopperOptions,
			mini,
			onActionButtonClick,
			actionBtnTooltip,
			dark,
			actionBtnIcon,
			rounded,
			appendTo,
			...props
		}: DropdownPropsBase,
		ref: RefObject<HTMLInputElement>,
	) => {
		const [open, setOpen] = useState<boolean>(false);
		const [color, setColor] = useState<ReactText>('');
		const [inputRef, setInputRef] = useState(null);
		const [colorInputRef, setColorInputRef] = useState(null);
		const dropdownButtonRef = useRef<HTMLButtonElement>(null);

		// This useEffect is used because of setValue fn from hook-form for colorPicker
		useEffect(() => {
			if (inputRef && variant === 'colorPicker') {
				Object.defineProperty(inputRef, 'value', {
					set: function (value) {
						setNativeInputValue(inputRef, value);
						this.textContent = value;
						setColor(inputRef?.value);
					},
				});
			}
		}, [inputRef, inputRef?.value, variant]);

		useEffect(() => {
			if (typeof value !== 'undefined' && inputRef && variant === 'colorPicker') {
				setNativeInputValue(inputRef, value);
				setColor(value.toString());
			}
		}, [inputRef, value, variant]);

		useEffect(() => {
			if (color && inputRef) {
				setNativeInputValue(inputRef, color);
			}
		}, [color, inputRef]);

		const getItems = typeof items === 'function' ? items(inputRef?.value) : items;

		const handleOnOpen = useCallback(() => {
			!open && setOpen(true);
		}, [open]);

		const handleOnClose = useCallback(() => {
			open && setOpen(false);

			// ColorPicker =>> if user set not valid HEX input, set old color value
			if (variant === 'colorPicker' && !isHexColor(colorInputRef.value)) {
				setNativeInputValue(colorInputRef, color);
			}
		}, [color, colorInputRef, open, variant]);

		const handleOnClick = useCallback(() => {
			!open ? handleOnOpen() : handleOnClose();
		}, [handleOnClose, handleOnOpen, open]);

		const getPlaceholder = useCallback(
			() =>
				variant === 'button' ? (
					placeholder
				) : (
					<DropdownItem
						label={placeholder}
						className="placeholder"
						size={size}
						onHover={onItemHover}
					/>
				),
			[variant, placeholder, size, onItemHover],
		);

		const getValue = useCallback(
			(itemId: string | number) => {
				const item = getItems?.find((i: ItemProps) => i.id?.toString() === itemId) || {
					label: itemId,
				};

				if (!item.label) {
					return getPlaceholder();
				}
				return variant === 'button' ? (
					item.label
				) : (
					<DropdownItem
						{...item}
						size={size}
						disabled={disabled}
						dark={dark}
						noHover
						dropdownButton
					>
						{variant === 'colorPicker' ? (
							<div className="itemLabel">
								<div className="itemLabelColor" />
								{item.label}
							</div>
						) : undefined}
					</DropdownItem>
				);
			},
			[getItems, variant, size, disabled, dark, getPlaceholder],
		);

		const getMenuVariant = menuVariant || (variant === 'button' ? 'description' : 'default');

		const handleOnChange = useCallback(
			(itemId) => {
				const oldValue = inputRef.value;
				setNativeInputValue(inputRef, itemId);
				onChange && onChange(itemId, oldValue);
			},
			[inputRef, onChange],
		);

		const handleOnChangeChromaPicker = useCallback(({ hex }) => setColor(hex), []);

		const handleOnChangeChromaPickerInput = useCallback(
			(e) => {
				const hex = e.target.value;
				if (isHexColor(hex)) {
					if (color !== hex) {
						setColor(hex);
					}
					onChange && onChange(e.target.value);
				}
			},
			[color, onChange],
		);

		const handleOnDropdownClick = useCallback(() => {
			//@ts-ignore
			!open && onDropdownClick?.();
		}, [open, onDropdownClick]);

		const handleOnHide = useCallback(() => {
			handleOnClose();
			onHide && onHide(inputRef?.value);
		}, [handleOnClose, inputRef?.value, onHide]);

		const handleOnMount = useCallback(() => {
			onMount && onMount(inputRef?.value);
		}, [inputRef?.value, onMount]);

		const handleOnHidden = useCallback(() => {
			onHidden && onHidden(inputRef?.value);
		}, [inputRef?.value, onHidden]);

		return (
			<StyledDropdown
				width={width}
				disabled={disabled}
				variant={variant}
				mini={mini}
				{...props}
				onClick={handleOnDropdownClick}
			>
				{variant === 'colorPicker' && mini ? null : (
					<Label {...props} className="dropdown-label">
						{label}
					</Label>
				)}
				<input
					type="text"
					className="hidden-input"
					onChange={onChangeHookForm}
					onBlur={onBlur}
					name={name}
					ref={multipleRefs(setInputRef, ref)}
					// value={value}
				/>
				<Menu
					appendTo={appendTo}
					dark={dark}
					color={menuColor}
					onItemHover={onItemHover}
					content={
						variant === 'colorPicker' ? (
							<div className="menu-content">
								<ChromePicker
									disableAlpha
									color={color || value || inputRef?.value}
									onChange={handleOnChangeChromaPicker}
								/>
								<div className="flex-column">
									<div className="flex">
										<Typography variant="textRegular300" className="typo" id={`hex-label-${name}`}>
											Hex
										</Typography>
										<Input
											ref={setColorInputRef}
											id={`hex-input-${name}`}
											size="sm"
											width="100%"
											className="input"
											value={color || value || inputRef?.value}
											onChange={handleOnChangeChromaPickerInput}
										/>
									</div>
									{colorPickerContent ? colorPickerContent : null}
								</div>
							</div>
						) : typeof content === 'function' ? (
							({ contentClassname, onChange, isOpen, close }) =>
								content({
									contentClassname,
									onMenuChange: onChange,
									isOpen,
									close,
									onChange: handleOnChange,
									value: color || value || inputRef?.value,
								})
						) : (
							content
						)
					}
					className={cn(menuClassName, 'menu')}
					disabled={disabled}
					items={getItems}
					maxHeight={menuMaxHeight}
					onClickOutside={handleOnClose}
					offset={menuOffset}
					onChange={handleOnChange}
					onHide={handleOnHide}
					onMount={handleOnMount}
					onHidden={handleOnHidden}
					placement={menuPlacement}
					selectedId={typeof value === 'number' ? value : value || inputRef?.value}
					size={menuSize || size}
					sticky={sticky}
					variant={getMenuVariant}
					popperOptions={menuPopperOptions}
					anchorWidth={anchorWidth}
					width={variant === 'button' ? width : menuWidth || 'anchorWidth'}
					triggerTarget={
						onActionButtonClick
							? dropdownButtonRef?.current
							: triggerTarget && triggerTarget.length > 0
								? [dropdownButtonRef?.current, ...triggerTarget]
								: null
					}
				>
					{variant === 'colorPicker' ? (
						mini ? (
							<StyledMiniColorPickerWrapper>
								<Label {...props} className="dropdown-label">
									{label}
								</Label>
								<StyledMiniColorPicker
									rounded={rounded}
									color={color || value?.toString() || inputRef?.value?.toString()}
								/>
							</StyledMiniColorPickerWrapper>
						) : (
							<StyledMiniColorPicker
								rounded={rounded}
								color={color || value?.toString() || inputRef?.value?.toString()}
							/>
						)
					) : (
						<DropdownButton
							disabled={disabled}
							open={open}
							className={buttonClassName}
							size={size}
							onClick={handleOnClick}
							variant={variant}
							renderLeft={renderLeft}
							ref={dropdownButtonRef}
							onActionButtonClick={onActionButtonClick}
							actionBtnTooltip={actionBtnTooltip}
							dark={dark}
							actionBtnIcon={actionBtnIcon}
						>
							{getValue(color || value?.toString() || inputRef?.value?.toString())}
						</DropdownButton>
					)}
					{renderRight ? renderRight : null}
				</Menu>
				{!(disabled || (variant === 'colorPicker' && mini)) && (
					<StyledErrorMsg className="error" error={error}>
						{error}
					</StyledErrorMsg>
				)}
			</StyledDropdown>
		);
	},
);

Dropdown.displayName = 'Dropdown';

Dropdown.defaultProps = {
	size: 'lg',
	menuOffset: [0, 8],
	menuPlacement: 'bottom-start',
	sticky: false,
	variant: 'default',
};

export default memo(Dropdown) as FC<DropdownProps>;
