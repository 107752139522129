import gql from 'graphql-tag';

export default gql`
	fragment NextBilling on User {
		localeCode
		activeService {
			nextBilling {
				date
				amount
				currencyCode
				cardExpiration
			}
		}
	}
`;
