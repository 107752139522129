import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalUI } from 'ui';
import { compose } from 'redux';
import cn from 'classnames';
import query from '~/cache/query';
import Loader from '~/components/Loader';
import { UNSUPPORTED_L } from '~/components/InApps';
import s from './style.less';

const Modal = ({ close, name, language }) => {
	const [loading, setLoading] = useState(true);
	const selectedLanguages = UNSUPPORTED_L.includes(language) ? 'en' : language;
	return (
		<ModalUI close={close} blurred hideBackBtnOnSlides closeButtonProps={{ variant: 'blurred' }}>
			<ModalUI.Content center width="100%" className={s.modalContent}>
				<Loader visible={loading} bgColor="transparent" />
				<iframe
					className={cn(loading && s.hidden, s.iframe)}
					width="100%"
					height="100%"
					frameBorder="0"
					src={`${window.env.WEBSITE_URL}/${selectedLanguages}/inapp?msg_id=${name}`}
					onLoad={() => setTimeout(() => setLoading(false))}
				></iframe>
			</ModalUI.Content>
		</ModalUI>
	);
};

Modal.propTypes = {
	close: PropTypes.func,
	language: PropTypes.string,
	name: PropTypes.string,
};

export default compose(
	memo,
	query('account', {
		fragments: ['Language'],
		mapProps: ({ user }) => ({ language: user?.language }),
	}),
)(Modal);
