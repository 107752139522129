import styled from '@emotion/styled';
import { IconButton } from '../../Button';

export const ImageMenuStyled = styled.div<{
	visible: boolean;
}>`
	width: 76px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;

	position: absolute;
	top: 52px;
	right: 24px;

	background: ${({ theme }) => theme.colors.white};
	border: 1px solid #2323513d;
	border-radius: 8px;
	padding: 4px 12px;

	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

	${IconButton} {
		padding: 0;
	}

	.menu-content {
		width: 100%;
	}
`;

export const BubbleContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 16px;
	border-radius: 10px;
	padding: 4px 4px 4px 16px;
	background: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.black[100]};

	.sure-label {
		display: flex;
		white-space: nowrap;
	}
	.close-delete-button {
		margin-left: 0 !important;
		height: 32px;
		padding: 6px 12px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${({ theme }) => theme.colors.red[150]};

		.buttonContent {
			padding: 0 !important;
		}

		:hover {
			background: ${({ theme }) => theme.colors.red[200]};
			color: ${({ theme }) => theme.colors.red[700]};
		}
	}
`;
