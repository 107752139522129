import React, { FC, ReactNode, memo } from 'react';
import { ThemeProvider as Provider } from '@emotion/react';
import { ThemeType } from './theme';

export type ThemeProviderProps = {
	children: ReactNode;
	theme: ThemeType;
};

export const ThemeProvider: FC<ThemeProviderProps> = ({ children, theme }: ThemeProviderProps) => (
	<Provider theme={theme}>{children}</Provider>
);

export default memo(ThemeProvider);
