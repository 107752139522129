import React, { useRef, useCallback, useEffect } from 'react';
import { TweenLite } from 'gsap';
import Transition from 'react-transition-group/Transition';
import { Icon } from '../../Icon';

import { StyledContainer, StyledPlus } from './AddPlus.styled';
import PulsingCircles, { getAddPlusCicrlesColors } from '../PulsingCircles';
import { useTheme } from '@emotion/react';
import classNames from 'classnames';

export type AddPlusProps = {
	onClick: React.MouseEventHandler<HTMLDivElement>;
	active?: boolean;
	hidePlus?: boolean | null;
	isEmpty?: boolean;
	brightness?: number;
	focus?: boolean;
};

const AddPlus: React.FC<AddPlusProps> = ({
	active = false,
	onClick,
	hidePlus = false,
	isEmpty,
	brightness,
	focus,
}) => {
	const theme = useTheme();
	const plusThumbRef = useRef<HTMLDivElement>(null);

	const handleOpenPlus = useCallback(() => {
		TweenLite.to(plusThumbRef.current, 0.3, {
			autoAlpha: 1,
		});
	}, []);

	const handleClosePlus = useCallback(() => {
		const { current } = plusThumbRef;
		TweenLite.killTweensOf(current);
		TweenLite.to(current, 0.3, {
			autoAlpha: 0,
		});
	}, []);

	useEffect(() => {
		if (hidePlus) {
			handleClosePlus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const plus = (
		<>
			<StyledPlus
				id="add-plus-button"
				data-testid="add_question_button"
				onClick={onClick}
				ref={plusThumbRef}
				active={active}
				isEmpty={isEmpty}
				brightness={brightness}
			>
				<Icon name={active ? 'minus-large-16' : 'plus-16'} />
			</StyledPlus>
			<div className={classNames('verticalLine', focus && 'hidden', active && 'active')} />
		</>
	);

	return (
		<Transition in={hidePlus} onEnter={handleClosePlus} onExit={handleOpenPlus} timeout={300}>
			<StyledContainer brightness={brightness}>
				{isEmpty && !active ? (
					<PulsingCircles color={getAddPlusCicrlesColors({ brightness, theme })}>
						{plus}
					</PulsingCircles>
				) : (
					plus
				)}
			</StyledContainer>
		</Transition>
	);
};

export default AddPlus;
