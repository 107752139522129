import gql from 'graphql-tag';

export default gql`
	mutation changeSurveyThemeSetBackground($uid: String!, $background: Upload!) {
		changeSurveyTheme {
			setBackground(input: { uid: $uid, background: $background }) {
				background {
					id
					custom
					thumb
					url {
						original
						preview
						blur1
						blur2
						blur3
						prev_blur1
						prev_blur2
						prev_blur3
					}
				}
			}
		}
	}
`;
