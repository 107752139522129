export default {
	defaults: {
		surveyTemplates: [],
	},
	resolvers: {
		Query: {
			categories: async (_, { language }) => {
				const response = await fetch(
					`${window.env.TEMPLATES_BASE_URL}/templates/survey-templates-${language || 'en'}.json`,
					{ cache: 'no-store' },
				);
				const {
					data: { getSurveyTemplates },
				} = await response.json();

				return getSurveyTemplates;
			},
		},
	},
};
