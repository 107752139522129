import gql from 'graphql-tag';

export default gql`
	fragment Limits on User {
		activeService {
			responseLimit
			responseCollectionLimit
			responseLimitStrategy
			name
			subscriptionReference
		}
		submittedResponsesTotal
		submittedSurveysThisMonth
	}
`;
