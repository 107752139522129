export const PLANS = {
	FREE: 'Free',
	MINI: 'Mini',
	STANDARD: 'Standard',
	PLUS: 'Plus',
	STARTER: 'Starter',
	MEDIUM: 'Medium',
	ENTERPRISE: 'Enterprise',
};

export const PLANS_OLD = {
	FREE: 'free',
	MINI: 'personal',
	STANDARD: 'business',
	PLUS: 'elite',
	STARTER: 'small',
	MEDIUM: 'medium',
	ENTERPRISE: 'enterprise',
};

export const PLANS_NEW = {
	FREE_01: 'free_01',
	STANDARD: 'standard',
	PLUS: 'plus',
	PRO: 'pro',
	CUSTOM: 'custom',
};

export const PLANS_23 = {
	FREE: 'free',
	MINI: 'mini_p_23',
	STANDARD: 'standard_p_23',
	BUSINESS_1: 'pro_business_1_p_23',
	BUSINESS_3: 'pro_business_3_p_23',
	BUSINESS_5: 'pro_business_5_p_23',
};

export const PLANS_24 = {
	FREE: 'free_p_24',
	MINI: 'mini_p_24',
	STANDARD: 'standard_p_24',
	BUSINESS_1: 'pro_business_1_p_23',
	BUSINESS_3: 'pro_business_3_p_23',
	BUSINESS_5: 'pro_business_5_p_23',
};

export const PRO_PLANS = [
	PLANS_NEW.PRO,
	PLANS_NEW.CUSTOM,
	PLANS.STARTER,
	PLANS.MEDIUM,
	PLANS.ENTERPRISE,
	// TODO: wohack - vyresit featureList pro konkretni role (ACL)
	'Pro Business 1',
	'Pro Business 3',
	'Pro Business 5',
];

export const BASIC_PLANS = [PLANS.FREE, PLANS.MINI, PLANS.STANDARD, PLANS.PLUS];

export const BUSINESS_VARIANT = ['1', '3', '5'];
