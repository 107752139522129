import gql from 'graphql-tag';

const importFragment = (type = [], options = {}) => {
	const { key, path } = options;
	const file_path = path
		? path
		: !key
			? `queries/${type[0]}${type[1] ? `/${type[1]}` : ''}`
			: `fragments/${type[0]}/${key}`;
	try {
		return require(`~/cache/${file_path}`).default;
	} catch (err) {
		console.error(
			`Undefined ${key ? 'fragment' : 'query'} ${type.join('/')}${key ? ` - ${key}` : ''}`,
		);
		console.error(err);
		return null;
	}
};
const ArrayToPrint = (array) => {
	let res = '';
	for (let i = 0; i < array.length; i++) {
		res += ` ...${array[i].key}`;
	}
	return res;
};
const ArrayToGql = (array) => {
	let res = '';
	for (let i = 0; i < array.length; i++) {
		res = gql`
			${res}
			${array[i].fragment}
		`;
	}
	return res;
};
const GetQuery = (queryType = '', fragments) => {
	if (typeof fragments === 'string') fragments = fragments ? [fragments] : [];
	queryType = queryType.split('/');
	const _fragments = [];
	const wrapper = importFragment(queryType);
	if (!wrapper) {
		console.error('Query is not a function');
		return null;
	}
	if (!fragments || fragments.length === 0) return wrapper;
	fragments.map((key) => {
		const fragment = importFragment(queryType, { key });
		if (fragment)
			_fragments.push({
				fragment,
				key,
			});
	});
	return wrapper(ArrayToPrint(_fragments), ArrayToGql(_fragments));
};

export default GetQuery;
