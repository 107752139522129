import gql from 'graphql-tag';

export default gql`
	fragment TeamRole on User {
		team {
			id
			role
		}
	}
`;
