import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography } from 'ui';
import s from './style.less';

const Saved = ({ intl, finished }) => (
	<div className={s.wrapper}>
		<Typography variant="textMedium250" color="grey_shades_with_blue[400]" className={s.saved}>
			{finished ? <Icon name="cloud-saved-24" className={s.icon} /> : null}
			{finished
				? intl.formatMessage({ id: 'app.common.saved' })
				: `${intl.formatMessage({ id: 'app.common.saving' })} ...`}
		</Typography>
	</div>
);

Saved.propTypes = {
	finished: PropTypes.bool,
	intl: PropTypes.object,
};

export default memo(Saved);
