import gql from 'graphql-tag';

export default gql`
	fragment googleSheetsList on User {
		integrations {
			googleSheets {
				sheetNames
			}
		}
	}
`;
