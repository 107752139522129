import React, { FC, memo } from 'react';
import { Typography, Icon } from '../../.';
import { CreateButtonProps, StyledCreateButton, Content, Stretcher, Border } from './';

export const CreateButton: FC<CreateButtonProps> = ({
	className,
	children,
	label,
	onClick,
	'data-test-id': test,
	'data-test-id-title': testTitle,
	...props
}: CreateButtonProps) => (
	<StyledCreateButton
		className={className}
		data-testid="ui-createButton"
		onClick={props.disabled ? undefined : onClick}
		data-test-id={test}
		{...props}
	>
		{!props.disabled && <Border {...props} className="border" />}
		<Stretcher {...props} />
		<Content {...props}>
			<Icon name="create-40" />
			<Typography variant="textSemibold400" data-test-id={testTitle}>
				{label}
			</Typography>
			{children}
		</Content>
	</StyledCreateButton>
);

export default memo(CreateButton);
