import query from '~/cache/query';

export default query('survey/withId', {
	fragments: ['QuestionResults'],
	options: ({ uid, id }) => ({
		fetchPolicy: 'network-only',
		variables: { uid, id },
		context: { noBatch: true },
	}),
	mapProps: ({ survey, loading }, { modalLoading, settings }) => {
		if (loading || (typeof modalLoading !== 'undefined' && !modalLoading)) {
			return {
				loading: true,
			};
		}
		return {
			answers: { ...survey?.question?.results },
			settings: { ...settings, ...survey?.question?.results?.preferences },
		};
	},
});
