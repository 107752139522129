import gql from 'graphql-tag';

export default gql`
	mutation changeSurveyThemeCustomize($uid: String!, $theme: Json!) {
		changeSurveyTheme {
			customize(input: { uid: $uid, theme: $theme }) {
				success
			}
		}
	}
`;
