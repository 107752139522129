import gql from 'graphql-tag';

const query = (children, fragments) => gql`
		query {
			designer @client {
				${children}
			}
		}
		${fragments}
	`;

export default query;
