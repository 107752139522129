import gql from 'graphql-tag';

export default gql`
	mutation changeThanksPage($input: ThanksPageMutationInput!) {
		changeThanksPage(input: $input) {
			success
			logo {
				url
			}
		}
	}
`;
