import React, { memo, FC } from 'react';
import styled from '@emotion/styled';
import { ThemeType } from '../ThemeProvider/theme';

export type SeparatorProps = {
	className?: string;
	theme?: ThemeType;
};

const StyledSeparator = styled.div<SeparatorProps>`
	display: flex;
	height: 1px;
	align-items: center;
	background-clip: content-box;
	padding: 8px 16px;
	background-color: ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
	box-sizing: content-box;
`;

const SectionSeparator: FC<SeparatorProps> = ({ className }: SeparatorProps) => (
	<StyledSeparator className={className} />
);

export default memo(SectionSeparator);
