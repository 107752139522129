import styled from '@emotion/styled';
import { MoreToneIconProps } from '.';

export const StyledMoreToneIcon = styled.div<Partial<MoreToneIconProps>>`
	line-height: 0;
	svg {
		.light {
			fill: ${({ theme }) => theme.colors.white};
		}
		.medium {
			fill: ${({ active, theme: { colors } }) =>
				active ? colors.secondary[300] : colors.grey['400']};
		}
		.dark {
			fill: ${({ active, theme: { colors } }) =>
				active ? colors.secondary[600] : colors.grey_shades_with_blue['500']};
		}
	}
`;
