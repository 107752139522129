const values = {
	5: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
	25: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
	50: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
	100: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
	200: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
	300: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
	400: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
};

const shadows = {
	5: `box-shadow: ${values[5]};`,
	25: `box-shadow: ${values[25]};`,
	50: `box-shadow: ${values[50]};`,
	100: `box-shadow: ${values[100]};`,
	200: `box-shadow: ${values[200]};`,
	300: `box-shadow: ${values[300]};`,
	400: `box-shadow: ${values[400]};`,
	values,
};

export default shadows;
