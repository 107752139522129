import styled from '@emotion/styled';
import { ColorPaletteProps, ColorProps } from '.';

export const ColorPaletteStyled = styled.div<ColorPaletteProps>`
	display: ${({ colors }) => (!colors || colors.length < 1 ? 'none' : 'inline-flex')};
	border-radius: 4px;
	overflow: hidden;
	width: fit-content;
	border: 1px solid ${({ theme }) => theme.colors.white};
`;

export const Color = styled.div<ColorProps>`
	width: 16px;
	height: 16px;
	background-color: ${({ color }) => color};
`;
