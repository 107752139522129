import gql from 'graphql-tag';

export default gql`
	mutation addDatePeriodFilter($uid: String!, $from: DateTime!, $till: DateTime!) {
		addDatePeriodFilter(uid: $uid, from: $from, till: $till) {
			id
			from
			till
		}
	}
`;
