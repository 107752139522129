import styled from '@emotion/styled';
import { addAlphaToHex, Button } from 'ui';

export const CloseButton = styled(Button)`
	right: 0;
	top: -42px;
	position: absolute;
	border-radius: 8px;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => addAlphaToHex(theme.colors.grey_shades_with_blue[800], 0.6)};

	&:hover,
	&:active {
		background-color: ${({ theme }) => addAlphaToHex(theme.colors.grey_shades_with_blue[800], 0.8)};
	}

	.startIcon {
		padding: 3px;
	}
`;

export const CloseWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1000;
`;

export const Wrapper = styled.div<{ show: boolean; width: number; height: number }>`
	position: fixed;
	width: ${({ width }) => `${width}px`};
	height: ${({ height }) => `${height}px`};
	z-index: 999;
	bottom: 56px;
	right: 20px;
	transform-origin: right bottom;
	display: ${({ show }) => (show ? 'block' : 'none')};
	animation: show 300ms cubic-bezier(0.55, -0.01, 0.41, 1.5) forwards;

	opacity: 0;
	transform: scale(0.66);

	&.exit-active {
		animation: hide 300ms cubic-bezier(0.55, -0.01, 0.41, 1.5) forwards;
	}

	@keyframes show {
		0% {
			opacity: 0;
			transform: scale(0.66);
		}

		100% {
			opacity: 1;
			transform: scale(1);
		}
	}

	@keyframes hide {
		0% {
			opacity: 1;
			transform: scale(1);
		}

		100% {
			opacity: 0;
			transform: scale(0.66);
		}
	}
`;

export const Iframe = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
	border-radius: 14px;
	box-shadow: ${({ theme }) =>
		`0 5px 40px 0 ${addAlphaToHex(theme.colors.grey_shades_with_blue[800], 0.2)}`};
`;
