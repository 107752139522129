import gql from 'graphql-tag';

export default gql`
	mutation copySurvey($uid: String!, $title: String!, $workspaceId: ID) {
		copySurvey(input: { uid: $uid, title: $title, workspaceId: $workspaceId }) {
			uid
			id: uid
		}
	}
`;
