import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Icon, Spacer, Typography } from '@survio/ui';

import s from './style.less';
import img from '~/components/UpgradeModal/assets/image.png';

const RightPanel = ({ intl }) => (
	<div className={s.right}>
		<Icon name="quotes-32" color="grey_shades_with_blue[100]" />

		<Spacer height={16} />

		<Typography variant="textRegular300_22" color="grey_shades_with_blue[400]">
			{intl.formatMessage({ id: 'app.upgrade.review-text' })}
		</Typography>

		<Spacer height={24} />

		<div className={s.user}>
			<img alt="user" width={48} src={require(`../assets/reference/${intl.locale}.png`)} />

			<Spacer width={16} />

			<div>
				<Typography variant="textSemibold300" color="grey_shades_with_blue[500]">
					{intl.formatMessage({ id: 'app.upgrade.review-username' })}
				</Typography>

				<Typography variant="textRegular300_22" color="grey_shades_with_blue[300]">
					{intl.formatMessage({ id: 'app.upgrade.review-useremployment' })}
				</Typography>
			</div>
		</div>
	</div>
);

RightPanel.propTypes = {
	intl: PropTypes.object,
};

export default memo(RightPanel);
