import gql from 'graphql-tag';

const query = (children, fragments) => gql`
	query builder($uid: String!) {
		builder(uid: $uid) @client {
			position
			activePage
		}
	}
`;

export default query;
