import { ThemeType } from '../../ThemeProvider/theme';

// developed according to
// https://www.figma.com/file/i04DdEEKAVpc6aHlfQFoFv/%E2%9C%8F%EF%B8%8F-Redesign-Builderu?type=design&node-id=129%3A1459&mode=design&t=OTWRUmyIzhOCXYxg-1

/**
 * Add alpha to hex color
 * @param hexWithoutAlpha - hex color without alpha i.e. #000000 (6 digits)
 * @param hexAlpha - hex alpha value, check https://davidwalsh.name/hex-opacity table
 * @returns hex color with alpha
 *
 * @example
 * addAlphaToHex('#000000', 60) // '#00000060'
 * */
export const addAlphaToHex = (hexWithoutAlpha: string, hexAlpha: number) =>
	`${hexWithoutAlpha}${hexAlpha}`;

type IconBrightnessContrastColorsType = {
	active: boolean;
	isEmpty: boolean;
	brightness: number;
	theme: ThemeType;
};

/**
 * Get colors for plus icon according to brightness and contrast
 * @param active - button is active
 * @param isEmpty - survey is empty
 * @param brightness - brightness value
 * @param theme - theme object
 * @returns set of colors for plus icon
 *
 * @example
 * getPlusIconBrightnessContrastColors({
 * 	active: true,
 * 	isEmpty: false,
 * 	brightness: 100,
 * 	theme: theme,
 * })
 * // { backgroundColor: '#000000', color: '#ffffff' }
 * */
export const getPlusIconBrightnessContrastColors = ({
	active,
	isEmpty,
	brightness,
	theme,
}: IconBrightnessContrastColorsType) => {
	const gsb500 = theme.colors.grey_shades_with_blue[500];
	const white = theme.colors.white;
	const brightBlue = theme.colors.blueberry[250];
	const blue = theme.colors.blueberry[275];
	const darkBlue = theme.colors.primary[900];

	if (active) {
		if (brightness <= 50) {
			return {
				backgroundColor: blue,
				color: white,
				outlineColor: blue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: blue,
					height: 2,
				},
			};
		} else if (brightness < 118) {
			return {
				backgroundColor: brightBlue,
				color: darkBlue,
				outlineColor: brightBlue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: brightBlue,
					height: 2,
				},
			};
		} else if (brightness < 136) {
			return {
				backgroundColor: brightBlue,
				color: darkBlue,
				outlineColor: brightBlue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: brightBlue,
					height: 2,
				},
			};
		} else {
			return {
				backgroundColor: blue,
				color: white,
				outlineColor: blue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: blue,
					height: 2,
				},
			};
		}
	}
	if (isEmpty) {
		if (brightness <= 50) {
			return {
				backgroundColor: white,
				color: gsb500,
				[':hover']: {
					backgroundColor: blue,
					color: white,
					outlineColor: blue,
					['+.verticalLine:before, +.verticalLine:after']: {
						backgroundColor: blue,
					},
				},
			};
		} else if (brightness < 118) {
			return {
				backgroundColor: white,
				color: gsb500,
				[':hover']: {
					backgroundColor: brightBlue,
					color: darkBlue,
					outlineColor: brightBlue,
					['+.verticalLine:before, +.verticalLine:after']: {
						backgroundColor: brightBlue,
					},
				},
			};
		} else if (brightness < 136) {
			return {
				backgroundColor: white,
				color: gsb500,
				[':hover']: {
					backgroundColor: brightBlue,
					color: darkBlue,
					outlineColor: brightBlue,
					['+.verticalLine:before, +.verticalLine:after']: {
						backgroundColor: brightBlue,
					},
				},
			};
		} else {
			return {
				backgroundColor: blue,
				color: white,
				outlineColor: blue,
				[':hover']: {
					backgroundColor: blue,
					color: white,
					outlineColor: blue,
					['+.verticalLine:before, +.verticalLine:after']: {
						backgroundColor: blue,
					},
				},
			};
		}
	}

	if (brightness <= 50) {
		return {
			backgroundColor: white,
			color: gsb500,
			[':hover']: {
				backgroundColor: blue,
				color: white,
				outlineColor: blue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: blue,
				},
			},
		};
	} else if (brightness < 118) {
		return {
			backgroundColor: white,
			color: gsb500,
			[':hover']: {
				backgroundColor: brightBlue,
				color: darkBlue,
				outlineColor: brightBlue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: brightBlue,
				},
			},
		};
	} else if (brightness < 136) {
		return {
			backgroundColor: white,
			color: gsb500,
			[':hover']: {
				backgroundColor: brightBlue,
				color: darkBlue,
				outlineColor: brightBlue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: brightBlue,
				},
			},
		};
	} else {
		return {
			backgroundColor: white,
			color: gsb500,
			[':hover']: {
				backgroundColor: blue,
				color: white,
				outlineColor: blue,
				['+.verticalLine:before, +.verticalLine:after']: {
					backgroundColor: blue,
				},
			},
		};
	}
};
