import React, { FC, memo, useCallback, useMemo } from 'react';
import { Item, ToQuestionOrPageStyled } from '.';
import { LOGIC_OPTION_TYPES } from '~/pages/Builder23_1/constants/constants';
import { LSContentRightProps } from '../';
import { OptionProps } from '~/pages/Builder23_1/components/LogicDialog/types';

const ToQuestion: FC<Omit<LSContentRightProps, 'selected'>> = ({
	options,
	closeSelect,
	choiceId,
	setChoiceLogic,
	logicValue,
}) => {
	const questions = useMemo(
		() => options.filter((option) => option.value === LOGIC_OPTION_TYPES.TO_QUESTION),
		[options],
	);

	const handleOnClick = useCallback(
		(toId: string) => {
			const choiceLogic = {
				action: LOGIC_OPTION_TYPES.TO_QUESTION,
				actionValue: toId,
				conditionValue: choiceId,
			};
			setChoiceLogic(choiceLogic);
			closeSelect();
		},
		[choiceId, closeSelect, setChoiceLogic],
	);

	return (
		<ToQuestionOrPageStyled>
			{questions.map((question: OptionProps, index) => (
				<Item
					disabled={index === 0}
					key={question?.number}
					onClick={handleOnClick}
					{...question}
					active={question?.toId === logicValue?.value}
				/>
			))}
		</ToQuestionOrPageStyled>
	);
};

export default memo(ToQuestion);
