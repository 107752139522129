import React, { useEffect, memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { FormattedHTMLMessage } from 'react-intl';

import { Typography, Spacer } from 'ui';
import { PERIOD, UPGRADE_MODAL_TYPES } from '~/constants';
import SubscriptionOptions from './SubscriptionOptions';
import TotalPrice from './TotalPrice';
import Footer from './Footer';

import s from './style.less';
import ChooseUserQuantity from '~/components/UpgradeModalStripe/ChooseUserQuantity';

const LeftPanel = ({
	type,
	actualLimit,
	period,
	sale,
	intl,
	plan,
	responsePackage,
	currencyCode,
	selectedPeriod,
	planPrice,
	register,
	apolloClient,
	getUpdatedPrice,
	setLoading,
	nextBilling,
	stripePricingPlan,
	activePro,
	stripePriceList,
	isPro23A,
}) => {
	const isCardValid =
		moment(nextBilling?.cardExpiration, 'MM/YYYY').endOf('month').diff(moment(), 'days') > 0;
	const isUserWithSubscription = moment(nextBilling?.date).diff(moment()) > 0;
	const responsesPrice = responsePackage?.[`${selectedPeriod?.toLowerCase()}Amount`] || 0;
	const totalPrice = planPrice + responsesPrice;
	const selectedData = useRef(null);
	const [priceData, setPriceData] = useState({
		amount: 0,
		nextBillingDate: new Date(),
		nextBillingPrice: 0,
		currencyCode: 'EUR',
		taxRate: 0,
		subAmount: 0,
		prorateDiscount: 0,
	});

	useEffect(() => {
		if (isUserWithSubscription && responsePackage?.quantity && selectedPeriod) {
			setLoading(true);
			selectedData.current = {
				quantity: Number(responsePackage?.quantity),
				period: selectedPeriod,
			};
			getUpdatedPrice(apolloClient, {
				period: selectedPeriod,
				responses: responsePackage.quantity,
			})
				.then((result) => {
					// here needs to be this check for selected period and quantity
					// otherwise there could be a race condition
					if (
						responsePackage?.quantity === selectedData?.current.quantity &&
						selectedPeriod === selectedData.current.period
					) {
						setPriceData(result);
						setLoading(false);
					}
				})
				.catch(() => setLoading(false));
		}
	}, [
		apolloClient,
		getUpdatedPrice,
		isUserWithSubscription,
		responsePackage?.quantity,
		selectedPeriod,
		setLoading,
	]);

	return (
		<div className={s.left}>
			<Typography variant="title800">
				<FormattedHTMLMessage
					id={
						type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES
							? 'app.stripe.increase-response-limit-title'
							: 'app.stripe.subscribe-modal-title'
					}
					values={{ PLAN: isPro23A ? 'PRO Business' : plan, FORMER_LIMIT: actualLimit }}
				/>
			</Typography>

			<Spacer height={8} />

			<Typography variant="textSemibold300" className={s.sectionTitle}>
				{intl.formatMessage({ id: 'app.upgrade.choose-subscription-length' })}
			</Typography>
			<SubscriptionOptions
				intl={intl}
				period={period}
				register={register}
				sale={sale}
				stripePricingPlan={stripePricingPlan}
				currencyCode={currencyCode}
			/>

			{isPro23A && (
				<ChooseUserQuantity
					intl={intl}
					selectedPeriod={selectedPeriod}
					register={register}
					sale={sale}
					stripePricingPlan={stripePricingPlan}
					currencyCode={currencyCode}
					activePro={activePro}
					stripePriceList={stripePriceList}
				/>
			)}

			<div className={s.divider} />

			<TotalPrice
				priceData={priceData}
				totalPrice={totalPrice}
				intl={intl}
				currencyCode={currencyCode}
				selectedPeriod={selectedPeriod}
				period={period}
			/>

			<Spacer height={36} />

			<Footer isCardValid={isCardValid} intl={intl} />
		</div>
	);
};

LeftPanel.propTypes = {
	type: PropTypes.string.isRequired,
	actualLimit: PropTypes.number.isRequired,
	period: PropTypes.oneOf(Object.values(PERIOD)).isRequired,
	sale: PropTypes.bool.isRequired,
	intl: PropTypes.object.isRequired,
	plan: PropTypes.string.isRequired,
	responsePackage: PropTypes.object.isRequired,
	currencyCode: PropTypes.string.isRequired,
	selectedPeriod: PropTypes.string.isRequired,
	planPrice: PropTypes.number.isRequired,
	register: PropTypes.func.isRequired,
	apolloClient: PropTypes.object.isRequired,
	getUpdatedPrice: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	nextBilling: PropTypes.object.isRequired,
	stripePricingPlan: PropTypes.object.isRequired,
	activePro: PropTypes.string,
	stripePriceList: PropTypes.object,
	isPro23A: PropTypes.string,
};

export default memo(LeftPanel);
