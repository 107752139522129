import * as Types from './ImageUploader.d';
import ImageUploader, { ImageUploader as NotPureImageUploader } from './ImageUploader';
import Placeholder from './components/Placeholder';
import Controls from './components/Controls';
import {
	BorderedContent,
	Label,
	StyledImageUploader,
	Buttons,
	BubbleContent,
} from './ImageUploader.styled';

export {
	NotPureImageUploader as ImageUploader,
	BorderedContent,
	Label,
	StyledImageUploader,
	Buttons,
	BubbleContent,
	Placeholder,
	Controls,
};
export type ImageUploaderProps = Types.ImageUploaderProps;
export type LabelProps = Types.LabelProps;
export type ErrorType = Types.ErrorType;
export type ButtonsProps = Types.ButtonsProps;
export type OnChangeFuncType = Types.OnChangeFuncType;
export type PlaceholderProps = Types.PlaceholderProps;
export type ControlsProps = Types.ControlsProps;
export default ImageUploader;
