import React, { FC, memo, MouseEvent } from 'react';
import { ButtonStyled } from './ButtonPlain.styled';
import { ColorType, ThemeType } from '~/ui/components/ThemeProvider/theme';

export type ButtonPlainProps = {
	backgroundColor?: ColorType;
	border?: string;
	borderRadius?: number;
	borderColor?: ColorType;
	children?: React.ReactNode;
	'data-testid'?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	size?: number;
	hoverProps?: Omit<ButtonPlainProps, 'hoverProps' | 'activeProps'>;
	activeProps?: Omit<ButtonPlainProps, 'hoverProps' | 'activeProps'>;
	disabled?: boolean;
	disabledProps?: Omit<ButtonPlainProps, 'hoverProps' | 'activeProps'>;
	theme?: ThemeType;
	type?: 'button' | 'submit' | 'reset';
	padding?: string;
	className?: string;
	width?: string | number;
	tabIndex?: number;
};

const ButtonPlain: FC<ButtonPlainProps> = ({ children, ...rest }) => (
	<ButtonStyled {...rest}>{children}</ButtonStyled>
);

ButtonPlain.defaultProps = {
	type: 'button',
};

export default memo(ButtonPlain);
