import gql from 'graphql-tag';

export default gql`
	fragment hasGoogleSheetsIntegration on Survey {
		integrations {
			googleSheets {
				integration {
					id
				}
			}
		}
	}
`;
