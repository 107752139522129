import React from 'react';
import PropTypes from 'prop-types';

class OnEsc extends React.PureComponent {
	static propTypes = {
		action: PropTypes.func.isRequired,
	};

	componentDidMount() {
		document.addEventListener('keyup', this.onEscape);
	}

	componentWillUnmount() {
		document.removeEventListener('keyup', this.onEscape);
	}

	onEscape = ({ keyCode }) => keyCode === 27 && this.props.action();

	render() {
		return null;
	}
}

export default OnEsc;
