import { TextFieldProps } from '~/ui/components/TextField';
import React, {
	FC,
	forwardRef,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { RangeStyled } from './Range.styled';
import { Bubble, Icon, InputProps, multipleRefs, setNativeInputValue, Typography } from 'ui';

export const Range: FC<InputProps> = forwardRef(
	({ min, max, error, ...rest }: TextFieldProps, ref) => {
		const inputRef = useRef(null);
		const [errorMsg, setErrorMsg] = useState('');

		useEffect(() => {
			if (error) {
				setErrorMsg(error as string);
			}
		}, [error]);

		const increase = useCallback(
			(e) => {
				const val = inputRef.current.value;
				if (max ? val < max : true) {
					setNativeInputValue(inputRef.current, val ? parseInt(val) + 1 : min);
				}
				e.preventDefault();
			},
			[max, min],
		);

		const decrease = useCallback(
			(e) => {
				const val = inputRef.current.value;
				if (min ? min < val : true) {
					setNativeInputValue(inputRef.current, parseInt(val) - 1);
				}
				if (!val) setNativeInputValue(inputRef.current, min);
				e.preventDefault();
			},
			[min],
		);

		const tooltipContent = useMemo(
			() => (
				<div style={{ display: 'flex', alignItems: 'center', padding: 12, gap: 8 }}>
					<Icon name="warning-16" color="yellow[400]" />
					<Typography variant="textRegular300" color="grey_shades_with_blue[250]">
						{errorMsg}
					</Typography>
				</div>
			),
			[errorMsg],
		);

		return (
			<Bubble
				borderRadius={10}
				variant="dark"
				visible={!!error}
				content={tooltipContent}
				placement="bottom"
			>
				<RangeStyled
					{...rest}
					width={30}
					ref={multipleRefs(inputRef, ref)}
					type="number"
					renderLeft={
						<Icon name="minus-large-16" color="grey_shades_with_blue[350]" onClick={decrease} />
					}
					renderRight={
						<Icon name="plus-small-16" color="grey_shades_with_blue[350]" onClick={increase} />
					}
				/>
			</Bubble>
		);
	},
);

Range.defaultProps = {
	size: 'sm',
};

Range.displayName = 'Range';

export default memo(Range);
