import React from 'react';
import PropTypes from 'prop-types';

import OverlayPortal from './OverlayPortal';
import Modal from '../Modal';

const ModalContainer = ({
	closeModal,
	modal: { contents, data, key, overlayClassName },
	setModalData,
	slideModal,
}) => (
	<OverlayPortal show={!!key} className={overlayClassName}>
		<Modal
			close={closeModal}
			contentKey={key}
			contents={contents}
			data={data}
			onSlide={setModalData}
			slide={slideModal}
		/>
	</OverlayPortal>
);

ModalContainer.propTypes = {
	closeModal: PropTypes.func.isRequired,
	modal: PropTypes.shape({
		appear: PropTypes.bool,
		content: PropTypes.node,
		key: PropTypes.string,
	}).isRequired,
	setModalData: PropTypes.func.isRequired,
	slideModal: PropTypes.func.isRequired,
	overlayClassName: PropTypes.string,
};

export default ModalContainer;
