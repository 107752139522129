import gql from 'graphql-tag';

export default gql`
	fragment Team on User {
		email
		fullName
		languageCode
		team {
			id
			name
			hash
			role
			memberOffsetConnection(input: $input) {
				totalCount
				edges {
					node {
						id
						email
						user {
							id
							fullName
							email
							surveyCount
						}
						state
						role
					}
				}
			}
		}
	}
`;
