import gql from 'graphql-tag';

export default gql`
	fragment ChangeEmailData on User {
		email
		language
		activeService {
			name
		}
	}
`;
