import React from 'react';
import styled from '@emotion/styled';
import { ThemeType } from '../../ThemeProvider/theme';

type AddPlusCicrlesColorsType = {
	brightness: number;
	theme: ThemeType;
};

export const getAddPlusCicrlesColors = ({ brightness, theme }: AddPlusCicrlesColorsType) => {
	const white = theme.colors.white;
	const blue = theme.colors.blueberry[275];

	if (brightness <= 50) {
		return white;
	} else if (brightness < 118) {
		return white;
	} else if (brightness < 136) {
		return white;
	} else {
		return blue;
	}
};

export type PulsingCirclesProps = { color: string; children?: React.ReactNode };

export const Circle = styled.div<Pick<PulsingCirclesProps, 'color'> & { delay: number }>`
	border-radius: 50%;
	background-color: ${({ color }) => color};
	width: 22px;
	height: 22px;
	position: absolute;
	opacity: 0;
	animation: scaleIn 3.9s infinite ease-out;
	animation-delay: ${({ delay }) => delay}s;

	@keyframes scaleIn {
		from {
			transform: scale(1, 1);
			opacity: 0.5;
		}
		to {
			transform: scale(3, 3);
			opacity: 0;
		}
	}
`;

const PulsingCircles: React.FC<PulsingCirclesProps> = ({ color, children }) => (
	<>
		<Circle delay={0} color={color} />
		<Circle delay={1.3} color={color} />
		<Circle delay={2.6} color={color} />
		{children}
	</>
);

export default PulsingCircles;
