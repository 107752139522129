import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { EventEmitter } from 'fbemitter';
import { v4 as uuid } from 'uuid';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TweenLite } from 'gsap';
import { useMedia } from 'ui';

import Portal from '~/survio-ui/Portal';
import Notification from './Notification';
import styles from './styles.less';

const Notifications = () => {
	const [notifications, setNotifications] = useState([]);
	const listener = useMemo(
		() =>
			Notifications.emitter.addListener('add', (notification) =>
				setNotifications([{ id: uuid(), ...notification }].slice(-1)),
			),
		[],
	);

	useEffect(() => () => listener.remove(), [listener]);

	const animation = useMedia(
		['(min-width: 784px)', '(min-width: 1032px)'],
		[
			{
				from: { transform: 'translateY(-100%)' },
				to: { transform: 'translateY(24px)' },
				exit: { transform: 'translateY(-100%)' },
			},
			{
				from: { transform: 'translateY(-100%)' },
				to: { transform: 'translateY(32px)' },
				exit: { transform: 'translateY(-100%)' },
			},
		],
		{
			from: { transform: 'translateY(0)' },
			to: { transform: 'translateY(calc(-100% - 120px))' },
			exit: { transform: 'translateY(0)' },
		},
	);

	const onEnter = useCallback(
		(node) => TweenLite.fromTo(node, 0.24, animation.from, animation.to),
		[animation],
	);

	const onExit = useCallback((node) => TweenLite.to(node, 0.24, animation.exit), [animation]);

	const onClose = useCallback(
		(id) => setNotifications(notifications.filter((notification) => notification.id !== id)),
		[notifications],
	);

	return (
		<Portal containerID="notificationsContainer">
			<TransitionGroup component="span" className={styles.notifications}>
				{notifications.map((notification) => (
					<Transition key={notification.id} timeout={240} onEnter={onEnter} onExit={onExit}>
						<Notification {...notification} onClose={onClose} />
					</Transition>
				))}
			</TransitionGroup>
		</Portal>
	);
};

Notifications.emitter = new EventEmitter();
Notifications.add = (notification) => Notifications.emitter.emit('add', notification);

export default Notifications;
