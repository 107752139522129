import { combineReducers } from 'redux';

import modal, * as fromModal from './modal';

const rootReducer = combineReducers({
	modal,
});

export default rootReducer;

export const getModal = (state) => fromModal.getModal(state);
