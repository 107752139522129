import React, { FC, memo, useCallback } from 'react';
import { Pagination } from '../Pagination';
import { TableWrapper, StyledPagination } from './List.styled';
import Row from './Row';
import Head from './Head';
import { TweenLite } from 'gsap';
import { Scrollbars } from 'react-custom-scrollbars';
import { ListProps } from './';

/**
 * Original Code: Tomas Bilina©
 */
export const FixedList: FC<ListProps> = ({
	className,
	columns,
	dataSource,
	onClickRow,
	withHeader,
	padding,
	striped,
	checkable,
	checked,
	total,
	onCheckChange,
	headControl,
	perPage,
	page,
	onPageChange,
	maxHeight,
	minHeight,
}: ListProps) => {
	const handleOnCheckAll = useCallback(
		() => onCheckChange(checked.length == 0 ? Array.from(Array(dataSource.length).keys()) : []),
		[dataSource],
	);

	const handleOnCheck = useCallback(
		(index) => {
			const _checked = [].concat(checked);
			_checked.includes(index) ? _checked.splice(_checked.indexOf(index), 1) : _checked.push(index);
			onCheckChange(_checked);
		},
		[checked],
	);

	const handleOnClickRow = useCallback((row) => onClickRow && onClickRow(row), [onClickRow]);

	const handleOnChangePage = useCallback(
		(onPage) => {
			onPageChange(onPage);
			TweenLite.to(document.getElementsByClassName('scrollbar')[0].children, 0.3, { scrollTo: 0 });
		},
		[onPageChange],
	);

	return (
		<TableWrapper className={className} data-testid="ui-list">
			{withHeader && (
				<Head
					columns={columns}
					padding={padding}
					checkable={checkable}
					checkedAll={dataSource?.length == checked?.length}
					checked={checked?.length > 0}
					onCheckAll={handleOnCheckAll}
					headControl={headControl}
				/>
			)}
			<Scrollbars
				autoHeight
				autoHeightMax={maxHeight || '100%'}
				autoHeightMin={minHeight || 0}
				className="scrollbar"
			>
				{dataSource &&
					dataSource.map((row, index) => (
						<Row
							key={index}
							columns={columns}
							onCheck={handleOnCheck}
							checked={checked?.includes(index)}
							index={index}
							row={row}
							onClick={handleOnClickRow}
							checkable={checkable}
							striped={striped}
							padding={padding}
						/>
					))}
				{onPageChange && total > perPage && (
					<StyledPagination>
						<Pagination
							page={page}
							totalEntries={total}
							moveTo={handleOnChangePage}
							perPage={perPage}
						/>
					</StyledPagination>
				)}
			</Scrollbars>
		</TableWrapper>
	);
};

FixedList.displayName = 'List';

FixedList.defaultProps = {
	withHeader: true,
	striped: true,
	checkable: false,
	checked: [],
};

export default memo(FixedList);
