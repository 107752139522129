import React, { FC, memo } from 'react';
import { EmojiProps, StyledEmoji } from './';

export const Emoji: FC<EmojiProps> = ({ name, ...props }: EmojiProps) => {
	let srcImage;
	try {
		srcImage = require(`./assets/${name}.png`);
	} catch (e) {
		console.error(e.message);
		return null;
	}

	return <StyledEmoji {...props} src={srcImage} alt={`${name} emoji`} data-testid="ui-emoji" />;
};

export default memo(Emoji);
