import React, { FC, memo } from 'react';
import { Color, ColorPaletteProps, ColorPaletteStyled } from '.';

export const ColorPalette: FC<ColorPaletteProps> = ({ className, colors }: ColorPaletteProps) => (
	<ColorPaletteStyled className={className} colors={colors} data-testid="ui-colorPalette">
		{colors.slice(0, 4).map((color, i) => (
			<Color color={color} key={`${color}-${i}`} />
		))}
	</ColorPaletteStyled>
);

ColorPalette.defaultProps = {
	colors: [],
};

export default memo(ColorPalette);
