import styled from '@emotion/styled';
import { CheckboxPropsBase } from './';
import { Typography } from '../Typography';
import { getThemeColor } from 'ui';

export const CheckBox = styled.div<CheckboxPropsBase>`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: baseline;
	height: ${({ size }) => (size === 'sm' ? 16 : 18)}px;
	width: ${({ size }) => (size === 'sm' ? 16 : 18)}px;
	// IE11 (next-line)
	min-width: ${({ size }) => (size === 'sm' ? 16 : 18)}px;
	border: 1px solid
		${({ error, theme: { colors }, disabled }) =>
			disabled
				? colors.grey_shades_with_blue[300]
				: error
					? colors.red[600]
					: colors.grey_shades_with_blue[400]};

	border-radius: ${({ size }) => (size === 'sm' ? 4 : 5)}px;
	margin: ${({ size }) => (size === 'sm' ? 0 : 3)}px;
	box-sizing: border-box;
	transition: all 200ms ease-out;
`;

export const CheckboxWrapper = styled.label<CheckboxPropsBase>`
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	user-select: none;

	svg {
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 200ms ease-out;
	}

	> input {
		position: absolute;
		opacity: 0;
		display: none;
		cursor: pointer;

		&:checked ~ .checkbox--checkmark {
			background-color: ${({ theme, theme: { colors }, disabled, checkedColor }) => {
				switch (true) {
					case disabled:
						return colors.grey_shades_with_blue[300];
					case !!checkedColor:
						return getThemeColor(checkedColor, theme);
					default:
						return colors.primary[500];
				}
			}};
			border: 1px solid
				${({ theme, disabled, error, checkedColor }) => {
					switch (true) {
						case !disabled && !error && !!checkedColor:
							return getThemeColor(checkedColor, theme);
						case !disabled && !error:
							return theme.colors.primary[500];
						default:
							return 'inherit';
					}
				}};
			svg {
				opacity: 1;
				path {
					fill: #ffffff;
				}
			}
		}
	}
`;

export const StyledLabel = styled(Typography)<
	Pick<CheckboxPropsBase, 'size' | 'error' | 'disabled'>
>`
	display: flex;
	align-items: center;
	margin-left: 16px;
	color: ${({ error, disabled, theme }) =>
		error
			? theme.colors.red[600]
			: disabled
				? theme.colors.grey_shades_with_blue[300]
				: theme.colors.grey_shades_with_blue[500]};
`;
