import React, { FC, forwardRef, memo, RefObject } from 'react';
import styled from '@emotion/styled';
import { AlertProps, AlertWithRefProps } from '.';
import { getThemeColor } from '../../utils';
import SvgArrow from '!!svg-react-loader!./assets/arrow.svg';

const StyledAlert = styled.div<AlertProps>`
	position: relative;
	margin: ${({ arrowPosition }) =>
		arrowPosition.split('-')[0] === 'bottom' ? '0 0 9px 0' : '9px 0 0 0'};
`;

const ChildrenWrapper = styled.div<AlertProps>`
	align-items: center;
	background: ${({ color, theme }) => getThemeColor(color, theme)};
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	padding: ${({ padding }) => `${padding}${typeof padding === 'number' ? 'px' : ''}`};
`;

const ArrowWrapper = styled.div<AlertProps>`
	svg {
		* {
			fill: ${({ color, theme }) => getThemeColor(color, theme)};
		}
		box-sizing: content-box;
		position: absolute;
		${({ arrowPosition, theme }) =>
			arrowPosition.split('-')[0] === 'bottom'
				? `bottom: -7px; padding-right: ${theme.helpers.spacer()}px; transform: rotate(180deg);`
				: `top: -8px; padding-left: ${theme.helpers.spacer()}px; margin-bottom: -5px;`};

		${({ arrowPosition }) =>
			arrowPosition.indexOf('right') >= 0 &&
			`right: -35px; ${(arrowPosition) => (arrowPosition === 'top' ? 'top: 0' : 'bottom: -7px')}`}
	}
`;

export const Alert: FC<AlertWithRefProps> = forwardRef<HTMLDivElement, AlertProps>(
	({ children, withArrow, className, ...props }: AlertProps, ref: RefObject<HTMLDivElement>) => (
		<StyledAlert data-testid="ui-alert" className={className} {...props} ref={ref}>
			{withArrow && (
				<ArrowWrapper {...props}>
					<SvgArrow className="arrow-svg" />
				</ArrowWrapper>
			)}
			<ChildrenWrapper {...props}>{children}</ChildrenWrapper>
		</StyledAlert>
	),
);

Alert.displayName = 'Alert';

Alert.defaultProps = {
	arrowPosition: 'top',
	color: 'yellow[100]',
	padding: '12px 24px',
	withArrow: true,
};

export default memo(Alert) as FC<AlertWithRefProps>;
