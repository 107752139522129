import styled from '@emotion/styled';
import { PulserProps } from '.';

export const StyledPulser = styled.div<PulserProps>`
	display: flex;
	position: relative;

	@keyframes pulse {
		0% {
			height: 6px;
			width: 6px;
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			height: 20px;
			width: 20px;
			opacity: 0;
		}
	}

	::before {
		position: absolute;
		content: '';
		border: 5px solid ${({ theme }) => theme.colors.primary[275]};
		border-radius: 50%;
		width: 12px;
		height: 12px;
		top: ${({ top }) => top}px;
		left: ${({ left }) => left}px;
		right: ${({ right }) => right}px;
		bottom: ${({ bottom }) => bottom}px;
		z-index: 1;
		animation: pulse 1.3s cubic-bezier(0.53, 0.35, 0.6, 0.87) infinite;
		transform: translate(
			${({ translateCss }) => translateCss?.[0]},
			${({ translateCss }) => translateCss?.[1]}
		);
		display: ${({ active }) => (active ? 'block' : 'none')};
	}
`;
