import gql from 'graphql-tag';

export default gql`
	mutation changeSurveySettings($input: SurveySettingsUpdateInput!) {
		changeSurveySettings(input: $input) {
			newParameter
			newIp
			success
		}
	}
`;
