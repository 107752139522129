import React, { FC, memo } from 'react';
import { LoaderProps } from './Loader.d';
import { ThreeDotsLoader, Dot } from './Loader.styled';

export const Loader: FC<LoaderProps> = (props: LoaderProps) => (
	<ThreeDotsLoader data-testid="ui-loader" {...props}>
		{[...Array(props.numOfDots)].map((_, i) => (
			<Dot key={i} {...props} className={`dot dot-${i}`} />
		))}
	</ThreeDotsLoader>
);

Loader.defaultProps = {
	dotSize: 6,
	jumpHeight: 4,
	numOfDots: 3,
	spaceSize: 10,
	speed: 240,
};

export default memo(Loader);
