import gql from 'graphql-tag';

export default gql`
	fragment Questions on Survey {
		questions {
			id
			results {
				... on ChoicedQuestionAggregate {
					respondentsTotal
					preferences
					breakdown {
						answer {
							text
							id
							ordinal
						}
						count
					}
				}
				... on StarRatingQuestionAggregate {
					respondentsTotal
					preferences
					average
					breakdown {
						answer {
							text
							id
							ordinal
						}
						count
					}
				}
				... on NetPromoterQuestionAggregate {
					preferences
					summary {
						detractors
						passive
						promoters
						score
					}
					breakdown {
						answer {
							text
							id
							ordinal
						}
						count
					}
					respondentsTotal
				}
				... on MatrixQuestionAggregate {
					respondentsTotal
					preferences
					rowResults {
						row {
							text
							id
							ordinal
						}
						breakdown {
							answer {
								text
								id
								ordinal
								value
							}
							count
						}
					}
				}
				... on DividePointsQuestionAggregate {
					respondentsTotal
					units
					preferences
					results {
						item {
							text
							id
							ordinal
						}
						average
						sum
					}
				}
				... on SemanticDifferentialQuestionAggregate {
					respondentsTotal
					preferences
					rowResults {
						labels {
							rightLabel
							leftLabel
						}
						breakdown {
							value
							count
						}
						average
					}
				}
				... on ImportanceQuestionAggregate {
					respondentsTotal
					preferences
					results: breakdown {
						item {
							text
						}
						averagePoints
					}
				}
				... on TextQuestionAggregate {
					preferences
					respondentsTotal
					results {
						value
						count
					}
				}
			}
		}
	}
`;
