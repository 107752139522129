import { EMPTY_INDIVIDUAL } from '../constants';
import Text from '~/survio-ui/Text';
import React from 'react';

const divide = {
	cols: ({ id, rows, text }) =>
		rows.map((item) => ({
			dataIndex: `${id}-${item.id}`,
			// intlID: 'app.results.individual-questioncolumn',
			title: () => <Text maxLine={2}>{`${text} x ${item.text}`}</Text>,
			questionId: id,
			answerId: item.id,
			width: 270,
		})),
	rows: ({ answer, cols }) => {
		if (!answer) return {};
		const items = {};
		cols.map(({ dataIndex, answerId }) => {
			const index = answer.answer.order.indexOf(answerId);
			items[dataIndex] = index >= 0 ? `${index + 1}.` : EMPTY_INDIVIDUAL;
		});
		return items;
	},
};
export default divide;
