import React, { memo, useEffect, FC, forwardRef, MutableRefObject, useState } from 'react';
import { CharCounterProps, StyledCharCounter } from './';

const CharCounter: FC<CharCounterProps> = forwardRef(
	({ max, className, value, size }: CharCounterProps, ref) => {
		const [count, setCount] = useState(0);
		useEffect(() => {
			// source inspiration: https://codepen.io/pi/pen/dXbwwa
			const input = (ref as MutableRefObject<HTMLInputElement | HTMLTextAreaElement>).current;

			setCount(input.value.length);

			const changeEvent = (evt) => setCount(evt.target.value.length);
			input.addEventListener('input', changeEvent);

			return () => input.removeEventListener('input', changeEvent);
		}, [ref]);

		useEffect(() => {
			if (typeof value !== 'undefined') {
				setCount(value.length);
			}
		}, [value]);

		return (
			<StyledCharCounter count={count} max={max} className={className} size={size}>
				<span className="counter">{count}</span>
				<span>{typeof max === 'number' && `/${max}`}</span>
			</StyledCharCounter>
		);
	},
);

CharCounter.displayName = 'CharCounter';

export default memo(CharCounter);
