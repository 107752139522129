import gql from 'graphql-tag';

export default gql`
	fragment integrations on Survey {
		integrations {
			googleSheets {
				integration {
					id
					email
					name
					avatar
				}
				activeSince
				fileName
				url
			}
			googleDrive {
				integration {
					id
					email
					name
					avatar
				}
				activeSince
				folderName
				syncTime
			}
			oneDrive {
				integration {
					id
					email
					name
					avatar
				}
				activeSince
				folderName
				syncTime
			}
			dropBox {
				integration {
					id
					email
					name
					avatar
				}
				activeSince
				folderName
				syncTime
			}
			googleAnalytics {
				active
				activeSince
				code
			}
		}
	}
`;
