export const removeEmptyProps = (obj) =>
	!obj
		? obj
		: Object.fromEntries(
				Object.entries(obj)
					.filter(([, value]) => value !== '' && value !== null && value !== undefined)
					.map(([key, value]) => [key, value === Object(value) ? removeEmptyProps(value) : value]),
			);

export default removeEmptyProps;
