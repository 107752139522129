import styled from '@emotion/styled';

export type SpacerProps = {
	width?: number | string;
	height?: number | string;
	className?: string;
};
export const Spacer = styled.div<SpacerProps>`
	margin-top: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
	margin-left: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
`;

Spacer.defaultProps = {
	width: 'auto',
	height: 'auto',
};
