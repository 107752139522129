import FixedList, { FixedList as NotPureList } from './List';
import * as Types from './List.d';

export { NotPureList as List };
export type ListProps = Types.ListProps;
export type RowProps = Types.RowProps;
export type StyledRowProps = Types.StyledRowProps;
export type HeadProps = Types.HeadProps;
export type HeadCheckboxProps = Types.HeadCheckboxProps;
export type OptionsProps = Types.OptionsProps;
export type TdProps = Types.TdProps;
export default FixedList;
