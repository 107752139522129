import gql from 'graphql-tag';

export default gql`
	query survey($uid: String!) {
		survey(uid: $uid) {
			uid
			integrations {
				googleDrive {
					integration {
						id
						email
						name
						avatar
					}
					activeSince
					folderName
					syncTime
				}
			}
		}
	}
`;
