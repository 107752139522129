import builderQuery from '~/cache/queries/builder';
import fragment from '~/cache/fragments/builder/Complete';

const defaultCache = {
	position: 1,
	activePage: -1,
	__typename: 'Builder',
};

export default {
	defaults: {
		builder: [{ id: 0, __typename: 'Builder' }],
	},
	resolvers: {
		Mutation: {
			builderToPage: (_, { uid, index, position }, { cache }) => {
				cache.writeQuery({
					query: builderQuery(),
					variables: { uid },
					data: {
						builder: {
							position,
							activePage: index,
							__typename: 'Builder',
						},
					},
				});
				return null;
			},
		},
		Query: {
			builder: (_, { uid }, { cache }) =>
				cache.readFragment({
					fragment,
					id: `Builder:${uid}`,
				}) || {
					...defaultCache,
					id: uid,
				},
		},
	},
};
