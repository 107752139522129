import React, {
	FC,
	forwardRef,
	memo,
	RefAttributes,
	RefObject,
	ReactNode,
	ChangeEvent,
	MouseEvent,
} from 'react';
import styled from '@emotion/styled';
import { ColorType, ThemeType } from '../ThemeProvider/theme';
import { getThemeColor } from '../../utils';

export type SwitchPropsBase = {
	checked?: boolean;
	children?: ReactNode;
	className?: string;
	color?: ColorType;
	disabled?: boolean;
	name?: string;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'navigation';
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	theme?: ThemeType;
	onClick?: (event: MouseEvent) => void;
	'data-testid'?: string;
};

export type SwitchProps = SwitchPropsBase & RefAttributes<HTMLElement>;

const SwitchWrapper = styled.label<SwitchProps>`
	display: inline-flex;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const StyledSwitch = styled.div<SwitchProps>`
	position: relative;
	display: inline-flex;
	flex: 1 0 auto;
	width: ${({ size }) => {
		switch (size) {
			case 'navigation':
				return '22px';
			case 'xs':
				return '28px';
			case 'sm':
				return '34px';
			case 'md':
				return '42px';
			default:
				return '54px';
		}
	}};
	height: ${({ size }) => {
		switch (size) {
			case 'navigation':
				return '14px';
			case 'xs':
				return '16px';
			case 'sm':
				return '18px';
			case 'md':
				return '26px';
			default:
				return '32px';
		}
	}};
`;

const Checkbox = styled.input<Omit<SwitchPropsBase, 'size'> & { switchSize: string }>`
	opacity: 0;
	width: 0;
	height: 0;

	&:checked + .slider {
		background-color: ${({ theme, color }) => getThemeColor(color, theme)};
		border-color: ${({ theme }) => theme.colors.primary[700]};
	}

	&:checked + .slider:before {
		border-color: ${({ theme }) => theme.colors.primary[700]};
		transform: ${({ switchSize }) => {
			switch (switchSize) {
				case 'navigation':
					return 'translate(8px, 0)';
				case 'xs':
					return 'translate(12px, 0)';
				case 'sm':
					return 'translate(16px, 0)';
				case 'md':
					return 'translate(18px, 0)';
				default:
					return 'translate(24px, 0)';
			}
		}}
`;

export const Slider = styled.span<SwitchProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: inherit;
	background-color: ${({ theme: { colors }, disabled, size }) =>
		size === 'navigation'
			? disabled
				? colors.grey_shades_with_blue[250]
				: colors.grey_shades_with_blue[300]
			: disabled
				? colors.grey_shades_with_blue[100]
				: colors.grey_shades_with_blue[200]};
	border-radius: 999px;
	transition: background-color 200ms ease-in-out;
	width: ${({ size }) => {
		switch (size) {
			case 'navigation':
				return '22px';
			case 'xs':
				return '28px';
			case 'sm':
				return '34px';
			case 'md':
				return '42px';
			default:
				return '54px';
		}
	}};
	border: ${({ theme, size }) =>
		`${size === 'xs' || size === 'navigation' ? 0 : 1}px solid ${
			theme.colors.grey_shades_with_blue[200]
		}`};

	&:before {
		position: absolute;
		content: '';
		height: ${({ size }) => {
			switch (size) {
				case 'navigation':
					return '10px';
				case 'xs':
					return '12px';
				case 'sm':
					return '18px';
				case 'md':
					return '24px';
				default:
					return '30px';
			}
		}};

		width: ${({ size }) => {
			switch (size) {
				case 'navigation':
					return '10px';
				case 'xs':
					return '12px';
				case 'sm':
					return '18px';
				case 'md':
					return '24px';
				default:
					return '30px';
			}
		}};
		left: ${({ size }) => `${size === 'xs' || size === 'navigation' ? 2 : -1}px`};
		top: ${({ size }) => `${size === 'xs' || size === 'navigation' ? 2 : -1}px`};
		background-color: ${({ theme }) => theme.colors.white};
		transition: transform 200ms ease-in-out;
		border-radius: 999px;
		border: ${({ theme, size }) =>
			`${size === 'xs' || size === 'navigation' ? 0 : 1}px solid  ${
				theme.colors.grey_shades_with_blue[250]
			}`};
	}
`;

const StyledChildren = styled.div`
	margin-left: 16px;
`;

export const Switch: FC<SwitchProps> = forwardRef<HTMLElement, SwitchPropsBase>(
	(
		{ checked, size, className, children, disabled, color, onClick, ...rest }: SwitchPropsBase,
		ref: RefObject<HTMLInputElement>,
	) => (
		<SwitchWrapper className={className} disabled={disabled} onClick={onClick}>
			<StyledSwitch
				checked={checked}
				size={size}
				data-testid="ui-switch"
				className="ui-switch-toggle"
			>
				<Checkbox
					type="checkbox"
					checked={checked}
					ref={ref}
					data-testid="ui-switch-checkbox"
					switchSize={size}
					disabled={disabled}
					color={color}
					{...rest}
				/>
				<Slider checked={checked} size={size} className="slider" disabled={disabled} />
			</StyledSwitch>
			{children && <StyledChildren>{children}</StyledChildren>}
		</SwitchWrapper>
	),
);

Switch.defaultProps = {
	size: 'md',
	color: 'primary[300]',
};

Switch.displayName = 'Switch';

export default memo(Switch);
