import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './style.less';

import { noop } from '../utils';

const Radio = ({
	checked,
	className,
	color,
	disabled,
	id,
	name,
	onBlur,
	onChange,
	onFocus,
	value,
	errors,
	label,
}) => (
	<label className={classNames(styles.label, errors && styles.error)}>
		<label className={classNames(styles.radio, className)}>
			<input
				checked={checked}
				disabled={disabled}
				id={id}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				type="radio"
				value={value}
			/>
			<span className={classNames(styles.checkmark, styles[color], disabled && styles.disabled)} />
		</label>
		{label && <div className={styles.text}>{label}</div>}
	</label>
);

Radio.propTypes = {
	checked: PropTypes.bool.isRequired,
	className: PropTypes.string,
	color: PropTypes.oneOf(['primary', 'success', 'dark', 'light', 'filters_primary']),
	disabled: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	value: PropTypes.bool.isRequired,
};

Radio.defaultProps = {
	checked: false,
	color: 'primary',
	disabled: false,
	onBlur: noop,
	onFocus: noop,
	value: false,
};

export default Radio;
