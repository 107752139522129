const setNativeValue = (
	inputRef: HTMLInputElement | HTMLTextAreaElement,
	value: string | number,
	element: HTMLInputElement | HTMLTextAreaElement,
): void => {
	const nativeInputValueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
	if (!inputRef) return;

	nativeInputValueSetter.call(inputRef, value);
	const event = document.createEvent('Event');
	event.initEvent('input', true, true);
	// const event = new Event('input', { bubbles: true });
	inputRef.dispatchEvent(event);
};

const setNativeTextareaValue = (inputRef: HTMLTextAreaElement, value: string | number): void => {
	setNativeValue(inputRef, value, window.HTMLTextAreaElement.prototype);
};

const setNativeInputValue = (inputRef: HTMLInputElement, value: string | number): void => {
	setNativeValue(inputRef, value, window.HTMLInputElement.prototype);
};

export { setNativeInputValue, setNativeTextareaValue };
