import React, { FC, memo, useCallback } from 'react';
import { ChartTileProps, StyledChartTile, Content, Stretcher, EditBubble, BottomBar } from './';
import { Typography, Icon, Button, Menu } from '../../index';
import { ItemProps } from '../Menu';

export const ChartTile: FC<ChartTileProps> = ({
	className,
	children,
	onClick,
	title,
	responsesLabel,
	editLabel,
	deleteLabel,
	onEditClick,
	onDeleteClick,
	...props
}: ChartTileProps) => {
	const { empty } = props;
	const handleOnClick = useCallback((e) => e.stopPropagation(), []);

	const handleEdit = useCallback(
		(itemId: string, e: MouseEvent) => {
			e.stopPropagation();
			onEditClick && onEditClick(itemId, e);
		},
		[onEditClick],
	);

	const handleDelete = useCallback(
		(itemId: string, e: MouseEvent) => {
			e.stopPropagation();
			onDeleteClick && onDeleteClick(itemId, e);
		},
		[onDeleteClick],
	);

	const items: Array<ItemProps> = [
		{
			id: 'edit',
			label: editLabel,
			renderLeft: <Icon name="edit-16" color="grey_shades_with_blue[500]" />,
			onClick: handleEdit,
			disabled: empty,
		},
		{
			id: 'delete',
			label: deleteLabel,
			renderLeft: <Icon name="delete-16" color="grey_shades_with_blue[500]" />,
			onClick: handleDelete,
		},
	];

	return (
		<StyledChartTile
			className={className}
			data-testid="ui-chartTile"
			onClick={props.disabled || empty ? undefined : onClick}
			{...props}
		>
			<EditBubble {...props} className="editBubble">
				<Icon name="expand-24" />
			</EditBubble>
			<Stretcher {...props} />
			<Content {...props}>
				<Typography variant="textMedium300" color="grey_shades_with_blue[700]">
					{title}
				</Typography>
				{children}
				<BottomBar {...props}>
					<Typography variant="textRegular200" color="grey_shades_with_blue[400]">
						{!empty ? responsesLabel : ''}
					</Typography>
					<Menu
						items={items}
						placement="bottom-start"
						offset={[-12, 20]}
						className="menu"
						size="sm"
					>
						<Button variant="icon" startIcon="more-large-16" onClick={handleOnClick} />
					</Menu>
				</BottomBar>
			</Content>
		</StyledChartTile>
	);
};

export default memo(ChartTile);
