import gql from 'graphql-tag';

export default gql`
	mutation upgradeService(
		$freeMonths: Int
		$extendedFirstPeriod: Boolean
		$period: PremiumServicePeriod
		$type: PremiumServiceType
		$additionalSeatsCount: Int
		$saleSource: SaleSourceInput
	) {
		upgradeService(
			freeMonths: $freeMonths
			extendedFirstPeriod: $extendedFirstPeriod
			period: $period
			type: $type
			additionalSeatsCount: $additionalSeatsCount
			saleSource: $saleSource
		)
	}
`;
