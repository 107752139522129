import { EMPTY_INDIVIDUAL } from '../constants';
import Text from '~/survio-ui/Text';
import React from 'react';

const rating = {
	cols: (question) => [
		{
			dataIndex: question.id,
			// intlID: 'app.results.individual-questioncolumn',
			title: (intl) => (
				<Text maxLine={2}>{`${question.text} (${intl.formatMessage({ id: 'app.common.from' })} ${
					question.pointCount
				})`}</Text>
			),
			questionId: question.id,
			width: 270,
		},
	],
	rows: ({ answer }) => ({
		[answer.questionId]: answer.answer.rating ? answer.answer.rating : EMPTY_INDIVIDUAL,
	}),
};
export default rating;
