import Text from '~/survio-ui/Text';
import React from 'react';

const semantic = {
	cols: ({ id, number, rows, text }) =>
		rows.map((item) => ({
			dataIndex: `${id}-${item.id}`,
			// intlID: 'app.results.individual-questioncolumn',
			title: () => <Text maxLine={2}>{`${text} x ${item.leftLabel} x ${item.rightLabel}`}</Text>,
			questionId: id,
			answerId: item.id,
			width: 270,
		})),
	rows: ({ answer, cols }) => {
		const columns = {};
		cols.forEach((col, index) => {
			// const half = Array.from(
			// 	Array(Math.floor(answer.answer.list[index].answer.maximum / 2)).keys(),
			// );
			columns[col.dataIndex] = answer.answer.list[index].answer.rating;
			// 	[
			// 	...half.map((number) => -(number + 1)),
			// 	0,
			// 	...half.reverse().map((number) => number + 1),
			// ][answer.answer.list[index].answer.rating - 1];
		});
		return columns;
	},
};

export default semantic;
