import gql from 'graphql-tag';

export default gql`
	mutation processVerificationCode($code: String!) {
		processVerificationCode(code: $code) {
			message
			success
		}
	}
`;
