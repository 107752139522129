window.smartlook ||
	(function (d) {
		var o = (smartlook = function () {
			o.api.push(arguments);
		});
		const h = d.getElementsByTagName('head')[0];
		const c = d.createElement('script');
		o.api = new Array();
		c.async = true;
		c.type = 'text/javascript';
		c.charset = 'utf-8';
		c.src = 'https://rec.smartlook.com/recorder.js';
		c.crossOrigin = 'anonymous';
		h.appendChild(c);
	})(document);
smartlook('init', '817017b41c8eb543a90ac4ad7fa386a2c98603f0', { region: 'eu' });
