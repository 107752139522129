import gql from 'graphql-tag';

export default gql`
	mutation addSourceFilter($uid: String!, $sourceList: [VisitSource!]) {
		addSourceFilter(uid: $uid, sourceList: $sourceList) {
			id
			type
			value
		}
	}
`;
