import React, { FC, forwardRef, memo } from 'react';

import { ProgressBarProps, ProgressBarPropsWithRef, StyledBar, StyledIndicator } from './';

export const ProgressBar: FC<ProgressBarPropsWithRef> = forwardRef(
	({ className, ...props }: ProgressBarProps, ref) => (
		<StyledBar className={className} ref={ref}>
			<StyledIndicator {...props} className="indicator" />
		</StyledBar>
	),
);

ProgressBar.defaultProps = {
	overLimitColor: 'upgrade[400]',
	height: 4,
	color: 'primary[700]',
};

ProgressBar.displayName = 'ProgressBar';

export default memo(ProgressBar) as FC<ProgressBarPropsWithRef>;
