import React, { FC, ReactNode, memo, forwardRef, RefAttributes, RefObject } from 'react';
import styled from '@emotion/styled';
import { ColorType, ThemeType } from '../ThemeProvider/theme';
import { getThemeColor } from '../../utils';

export type TypographyVariant =
	| 'textRegular100'
	| 'textRegular200'
	| 'textRegular250'
	| 'textRegular300_22'
	| 'textRegular300_24'
	| 'textRegular300_26'
	| 'textRegular300'
	| 'textRegular400'
	| 'textRegular500'
	| 'textRegular600'
	| 'textRegular700'
	| 'textMedium100'
	| 'textMedium200'
	| 'textMedium250'
	| 'textMedium300_22'
	| 'textMedium300_24'
	| 'textMedium300_26'
	| 'textMedium300'
	| 'textMedium400'
	| 'textMedium500'
	| 'textMedium600'
	| 'textMedium700'
	| 'textSemibold100'
	| 'textSemibold200'
	| 'textSemibold250'
	| 'textSemibold300_22'
	| 'textSemibold300_26'
	| 'textSemibold300'
	| 'textSemibold400'
	| 'textSemibold500'
	| 'textSemibold600'
	| 'textSemibold700'
	| 'textSemibold800'
	| 'title100'
	| 'title200'
	| 'title300'
	| 'title400'
	| 'title500'
	| 'title600'
	| 'title700'
	| 'title800'
	| 'title900'
	| 'title1000'
	| 'title1100';

export type TypographyPropsBase = {
	id?: string;
	variant?: TypographyVariant;
	children: ReactNode;
	theme?: ThemeType;
	color?: ColorType;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	span?: boolean;
	style?: React.CSSProperties;
	textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
};

export type TypographyProps = TypographyPropsBase & RefAttributes<HTMLDivElement>;

export const Typo = styled.div<TypographyProps>`
	max-width: 100%;
	${({ variant, theme }) => theme.typography[variant]};
	${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
	${({ color, theme }) => color && `color: ${getThemeColor(color, theme)}`};
`;

export const TypoSpan = styled.span<TypographyProps>`
	max-width: 100%;
	${({ variant, theme }) => theme.typography[variant]};
	${({ color, theme }) => `color: ${getThemeColor(color, theme)}`};
`;

export const Typography: FC<TypographyProps> = forwardRef<HTMLDivElement, TypographyPropsBase>(
	(
		{ children = '', variant, span, ...props }: TypographyPropsBase,
		ref: RefObject<HTMLDivElement>,
	) =>
		span ? (
			<TypoSpan variant={variant} {...props} ref={ref}>
				{children}
			</TypoSpan>
		) : (
			<Typo variant={variant} {...props} ref={ref}>
				{children}
			</Typo>
		),
);

Typography.displayName = 'Typography';

Typography.defaultProps = {
	variant: 'textMedium300',
};

export default memo(Typography);
