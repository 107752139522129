import styled from '@emotion/styled';
import { addAlphaToHex, Button } from 'ui';

export const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 88px;
	z-index: 999;
	bottom: 0;
	box-shadow: ${({ theme }) =>
		`0 5px 40px 0 ${addAlphaToHex(theme.colors.grey_shades_with_blue[800], 0.2)}`};
`;

export const Iframe = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`;

export const CloseButton = styled(Button)`
	position: absolute;
	top: 36px;
	right: 36px;
`;
