import React, { FC, memo } from 'react';
import { HighlighterProps, StyledHighlighter } from '.';

const Highlighter: FC<HighlighterProps> = ({ ...props }: HighlighterProps) => (
	<StyledHighlighter {...props} data-testid="ui-highlighter" />
);

Highlighter.defaultProps = {
	color: 'primary[400]',
};

export default memo(Highlighter);
