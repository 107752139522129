export const TYPES = {
	personal: 'MINI',
	business: 'STANDARD',
	elite: 'PLUS',
	small: 'STARTER',
	medium: 'MEDIUM',
	enterprise: 'ENTERPRISE',
	// CB hotfix:
	free: 'FREE',
	mini: 'MINI',
	standard: 'STANDARD',
	plus: 'PLUS',
	starter: 'STARTER',
};

export const OPS = {
	1: 'MONTHLY',
	12: 'YEARLY',
};
