import Status, { Status as NotPureStatus } from './Status';
import * as Types from './Status.d';
import { StyledStatus } from './Status.styled';

export const largeStatuses = [
	'FREE PLAN_L',
	'MINI PLAN_L',
	'STANDARD PLAN_L',
	'PLUS PLAN_L',
	'STARTER PLAN_L',
	'MEDIUM PLAN_L',
	'ENTERPRISE PLAN_L',
] as const;

export const teamStatuses = ['ACTIVE', 'PENDING', 'DEACTIVATED'] as const;

export const mediumStatuses = [
	'FREE PLAN_M',
	'MINI PLAN_M',
	'STANDARD PLAN_M',
	'PLUS PLAN_M',
	'STARTER PLAN_M',
	'MEDIUM PLAN_M',
	'ENTERPRISE PLAN_M',
] as const;

export const smallStatuses = ['BETA'] as const;

export const otherStatuses = [
	'Note',
	'Note_yellow',
	'NEW',
	'SET_ON',
	'SET_OFF',
	'BETA',
	'Published',
	'Unpublished',
	'Save',
] as const;

export { NotPureStatus as Status, StyledStatus };
export type StatusProps = Types.StatusProps;
export type StatusPropsBase = Types.StatusPropsBase;
export type StatusState = Types.StatusState;
export default Status;
