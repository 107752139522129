import React, { useCallback } from 'react';
import { CloseButton, Iframe, Wrapper } from './InAppBar.styled';
import { Menu } from '~/ui';
import { useIntl } from 'react-intl';

const InAppBar = ({ onClose, url, language, onLoad }) => {
	const intl = useIntl();

	const handleClose = useCallback(
		(skipped: boolean) => () => {
			onClose(skipped);
		},
		[onClose],
	);

	return (
		<Wrapper>
			<Iframe allow="fullscreen" src={url.replace('{lang}', language)} onLoad={onLoad} />
			<Menu
				dark
				size="sm"
				offset={[-30, 66]}
				minWidth={192}
				placement="top-start"
				items={[
					{
						id: 'close',
						onClick: handleClose(true),
						label: intl.formatMessage({ id: 'app.product-tour-try-next-time' }),
					},
					{
						id: 'skip',
						onClick: handleClose(false),
						label: intl.formatMessage({ id: 'app.product-tour-skip-completely' }),
					},
				]}
			>
				<CloseButton variant="icon" startIcon="close-16" size="sm" />
			</Menu>
		</Wrapper>
	);
};

export default InAppBar;
