import gql from 'graphql-tag';

export default gql`
	mutation connectIntegration($folderName: String!, $integration: ID!, $uid: ID!, $syncAt: String) {
		connectIntegration(
			folderName: $folderName
			integration: $integration
			uid: $uid
			syncAt: $syncAt
		) {
			integration {
				id
				email
				name
				avatar
			}
			activeSince
			folderName
			syncTime
		}
	}
`;
