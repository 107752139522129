import React, { PureComponent } from 'react';
import { EventEmitter } from 'fbemitter';
import { v4 as uuid } from 'uuid';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TweenLite } from 'gsap';

import Portal from '../Portal';
import Notification from './Notification';
import styles from './styles.less';

class NotificationsContainer extends PureComponent {
	static emitter = new EventEmitter();
	static add = (notification) => NotificationsContainer.emitter.emit('add', notification);

	state = {
		notifications: [],
	};

	componentWillUnmount() {
		this.listener.remove();
	}

	listener = NotificationsContainer.emitter.addListener('add', (notification) =>
		this.setState(({ notifications }) => ({
			notifications: [...notifications, { id: uuid(), ...notification }].slice(-1),
		})),
	);

	onEnter = (node) =>
		TweenLite.fromTo(node, 0.4, { opacity: 1, height: 0 }, { opacity: 1, height: 40 });

	onExit = (node) =>
		TweenLite.fromTo(
			node,
			0.4,
			{ opacity: 1, height: 40 },
			{ opacity: 1, height: 0, onComplete: () => TweenLite.to(node, 0.2, { height: 0 }) },
		);

	close = (id) =>
		this.setState(({ notifications }) => ({
			notifications: notifications.filter((notification) => notification.id !== id),
		}));

	render() {
		const { notifications } = this.state;
		return (
			<Portal containerID="notificationsContainer">
				<TransitionGroup component="span" className={styles.notifications}>
					{notifications.map((notification) => (
						<Transition
							key={notification.id}
							timeout={600}
							onEnter={this.onEnter}
							onExit={this.onExit}
						>
							<div>
								<Notification {...notification} close={this.close} />
							</div>
						</Transition>
					))}
				</TransitionGroup>
			</Portal>
		);
	}
}

export default NotificationsContainer;
