import React, { memo } from 'react';
import { Typography, Icon } from '@survio/ui';
import PropTypes from 'prop-types';

import style from '../styles.less';

const Feature = ({ intl, feature: { prefix, id, suffix } }) => (
	<div className={style.feature}>
		<Icon name="large-check-16" className={style.featureCheck} />
		<Typography variant="textRegular250" color="grey_shades_with_blue[700]">
			{prefix && `${prefix} `}
			{intl.formatMessage({ id })}
			{suffix && ` ${intl.formatMessage({ id: suffix })}`}
		</Typography>
	</div>
);

Feature.propTypes = {
	feature: PropTypes.object,
	intl: PropTypes.object,
};

export default memo(Feature);
