import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { Typography } from '../Typography';
import helpers from '../ThemeProvider/theme/helpers';
import { ThemeType } from '../ThemeProvider/theme';

export type FooterProps = {
	theme?: ThemeType;
	className?: string;
	links: { title: string; href: string }[];
};

const FooterWrapper = styled.div<Pick<FooterProps, 'className' | 'links' | 'theme'>>`
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[500]};
	display: flex;
	justify-content: center;
	margin: ${helpers.spacer(8)}px 0;
	min-width: 100%;
	text-align: center;
	flex-direction: column;
`;

const Link = styled.a<Pick<FooterProps, 'theme'>>`
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[500]};
	text-decoration: none;
	&:hover {
		color: ${({ theme }) => theme.colors.active_blue[500]};
	}
`;

const StyledSeparator = styled.span`
	margin: 0 16px;
`;

const Copyright = styled(Typography)`
	margin-top: 16px;
`;

export const Footer: FC<FooterProps> = ({ links, className }: FooterProps) => {
	const Separator = () => <StyledSeparator>&#x22C5;</StyledSeparator>;

	return (
		<FooterWrapper className={className} data-testid="ui-footer" links={links}>
			<Typography variant="textRegular200">
				{links.map((link, key) => (
					<span key={key}>
						<Link href={link.href} target="_blank">
							{link.title}
						</Link>
						{key !== links.length - 1 && <Separator />}
					</span>
				))}
			</Typography>
			<Copyright variant="textRegular100">&copy; {new Date().getFullYear()} Survio</Copyright>
		</FooterWrapper>
	);
};

export default memo(Footer) as FC<FooterProps>;
