import React, { FC, memo, useCallback } from 'react';
import { PaginationProps, PaginationWrapper, Page } from './';
import { Icon } from '../Icon';

export const Pagination: FC<PaginationProps> = ({
	page,
	totalEntries,
	perPage,
	moveTo,
	className,
	...props
}: PaginationProps) => {
	const getNumberOfPages = useCallback(
		() => Math.ceil(totalEntries / perPage),
		[perPage, totalEntries],
	);

	const goToPage = useCallback((to) => () => page !== to && moveTo && moveTo(to), [moveTo, page]);

	const goToPreviousPage = useCallback(
		() => (page > 1 ? goToPage(page - 1) : undefined),
		[page, goToPage],
	);

	const goToNextPage = useCallback(
		() => (page < getNumberOfPages() ? goToPage(page + 1) : undefined),
		[getNumberOfPages, goToPage, page],
	);

	const renderPages = useCallback(() => {
		let i = 1;
		let render = true;
		const totalPages = getNumberOfPages();
		const pages = [
			<Page key="prev" disabled={page === 1} onClick={goToPreviousPage()}>
				<Icon name="previous-page-16" />
			</Page>,
		];

		if (!totalPages) {
			return null;
		}

		while (i <= totalPages) {
			if (render) {
				pages.push(
					<Page key={i} className={page === i ? 'active' : 'not-active'} onClick={goToPage(i)}>
						{i}
					</Page>,
				);
			}
			i++;

			/**
			 * Hide/show page numbers and replace it with 'three dots' for reduce number of pages.
			 *
			 * Format - 7 items all the time (selected page is 5):
			 *         		< 1 ... 4 5 6 ... 9 >
			 *         		    /          \
			 * 			   i < page	 	 i >= page
			 *
			 * For i >= page:
			 * 		1) i < totalPages ---> show last page every time
			 * 	    2) i > Math.max(page + 1, 5) ---> indexes 1 - 5 show every time when selected page is 1 - 5
			 * 	       then show only page with index selected page + 1
			 * 	    3) !(page === totalPages - 3 && i === totalPages - 1) ---> when selected page is with index
			 * 	       totalPages - 3 do not change index totalPages - 1 to 'three dots'
			 * 	       (eg. totalPages = 10 selected page = 7: 1...7 8 9 10)
			 * 		4) totalPages > 7 ---> hide pages only when totalPages > 7
			 * For i < page:
			 * 		1) i < Math.min(page - 1, totalPages - 4) ---> indexes (totalPages) - (totalPages - 4)
			 * 		   show every time when selected page is 1 - 5 then show only page with index selected page - 1
			 * 	    2) !(page === 4 && i === 2) ---> when selected page is with index 4 do not change
			 * 	       index 2 to 'three dots'
			 * 	    3) totalPages > 7 ---> hide pages only when totalPages > 7
			 */
			if (i >= page) {
				if (
					i < totalPages &&
					i > Math.max(page + 1, 5) &&
					!(page === totalPages - 3 && i === totalPages - 1) &&
					totalPages > 7
				) {
					render && i <= totalPages && pages.push(<Page key={`dots ${i}`}>...</Page>);
					render = false;
				} else {
					render = true;
				}
			} else {
				if (i < Math.min(page - 1, totalPages - 4) && !(page === 4 && i === 2) && totalPages > 7) {
					render && i <= totalPages && pages.push(<Page key={`dots ${i}`}>...</Page>);
					render = false;
				} else {
					render = true;
				}
			}
		}

		pages.push(
			<Page key="next" disabled={page === totalPages} onClick={goToNextPage()}>
				<Icon name="next-page-16" />
			</Page>,
		);
		return pages;
	}, [getNumberOfPages, goToNextPage, goToPage, goToPreviousPage, page]);

	return (
		<PaginationWrapper className={className} {...props}>
			{renderPages()}
		</PaginationWrapper>
	);
};

Pagination.defaultProps = {
	page: 1,
	perPage: 10,
};

export default memo(Pagination);
