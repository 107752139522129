import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import Icon from '~/survio-ui/Icon';
import img from './assets/image.png';

import styles from './style.less';

const Feedback = ({ intl }) => {
	const isMissingReviewLink =
		intl.formatMessage({ id: 'app.upgrade.review-link' }) === 'app.upgrade.review-link';

	return (
		<div className={styles.features}>
			<img
				alt="user"
				className={styles.image}
				src={require(`./assets/reference-${intl.locale}.png`)}
			/>

			<div className={styles.feedback}>{intl.formatMessage({ id: 'app.upgrade.review-text' })}</div>

			<div className={styles.user}>
				<div className={styles.name}>
					{intl.formatMessage({ id: 'app.upgrade.review-username' })}
				</div>
				<a
					target="_blank"
					className={styles.company}
					href={
						isMissingReviewLink
							? undefined
							: `//${intl.formatMessage({ id: 'app.upgrade.review-link' })}`
					}
					rel="noreferrer"
				>
					{intl.formatMessage({ id: 'app.upgrade.review-useremployment' })}
				</a>
			</div>

			<div className={styles.security}>
				<Icon className={styles.icon} name="security" />
				{intl.formatMessage({ id: 'app.upgrade.secure-payment' })}
			</div>
		</div>
	);
};

Feedback.propTypes = {
	intl: PropTypes.object,
};

export default compose(memo, injectIntl)(Feedback);
