import gql from 'graphql-tag';

export const MUTATION_NPSQuestion = gql`
	mutation NPSQuestion($questionData: NPSQuestionInput!) {
		NPSQuestion(questionData: $questionData) {
			id
			number
			picture {
				url
				w
				h
			}
		}
	}
`;

export const MUTATION_RatingQuestion = gql`
	mutation RatingQuestion($questionData: RatingQuestionInput!) {
		ratingQuestion(questionData: $questionData) {
			id
			number
			picture {
				url
				w
				h
			}
		}
	}
`;

export const MUTATION_MatrixQuestion = gql`
	mutation MatrixQuestion($questionData: MatrixQuestionInput!) {
		matrixQuestion(questionData: $questionData) {
			id
			number
			isEditedLimit
			picture {
				url
				w
				h
			}
			rows {
				id
				ordinal
				text
			}
			cols {
				id
				ordinal
				text
			}
		}
	}
`;

export const MUTATION_SemanticQuestion = gql`
	mutation SemanticQuestion($questionData: SemanticDiffQuestionInput!) {
		semanticQuestion(questionData: $questionData) {
			id
			number
			picture {
				url
				w
				h
			}
			rows {
				id
				ordinal
				leftLabel
				rightLabel
			}
		}
	}
`;

export const MUTATION_ChoiceQuestion = gql`
	mutation ChoiceQuestion($questionData: ChoiceQuestionInput!) {
		choiceQuestion(questionData: $questionData) {
			id
			number
			layout
			isQuizItem
			picture {
				url
				w
				h
			}
			isEditedLimit
			choices {
				id
				ordinal
				text
				open
				quizPoints
				picture {
					url
				}
			}
		}
	}
`;

export const MUTATION_ImageChoiceQuestion = gql`
	mutation ImageChoiceQuestion($questionData: ImageChoiceQuestionInput!) {
		imageChoiceQuestion(questionData: $questionData) {
			id
			number
			layout
			picture {
				url
				w
				h
			}
			isQuizItem
			isEditedLimit
			choices {
				id
				ordinal
				text
				open
				quizPoints
				picture {
					url
				}
			}
		}
	}
`;

export const MUTATION_TextQuestion = gql`
	mutation TextQuestion($questionData: TextQuestionInput!) {
		textQuestion(questionData: $questionData) {
			id
			number
			picture {
				url
				w
				h
			}
		}
	}
`;

export const MUTATION_OrdinalQuestion = gql`
	mutation OrdinalQuestion($questionData: OrdinalQuestionInput!) {
		ordinalQuestion(questionData: $questionData) {
			id
			number
			rows {
				id
				ordinal
				text
			}
		}
	}
`;

export const MUTATION_SplitPointsQuestion = gql`
	mutation SplitPointsQuestion($questionData: SplitPointsQuestionInput!) {
		splitPointsQuestion(questionData: $questionData) {
			id
			number
			picture {
				url
				w
				h
			}
			rows {
				id
				ordinal
				text
			}
		}
	}
`;

export const MUTATION_TextBlock = gql`
	mutation TextBlock($data: TextBlockInput!) {
		textBlock(data: $data) {
			id
			text
			ordinal
		}
	}
`;

export const MUTATION_DeleteItem = gql`
	mutation DeleteItem($id: Int!) {
		deleteItem(id: $id)
	}
`;

export const MUTATION_CopyItem = gql`
	mutation CopyItem($id: Int!) {
		copyItem(id: $id) {
			copy {
				... on QuestionInterface {
					id
					text
					helpText
					required
					ordinal
					number
					edited
					picture {
						url
						w
						h
					}
					video {
						url
						thumb
					}
				}
				... on ChoiceQuestion {
					hasPictureChoices
					layout
					isMultipleAnswer
					minimumAnswers
					maximumAnswers
					layout
					isQuizItem
					isEditedLimit
					choices {
						id
						text
						open
						ordinal
						quizPoints
						picture {
							url
						}
					}
				}
				... on MatrixQuestion {
					type
					minimumAnswers
					maximumAnswers
					isEditedLimit
					cols {
						id
						text
						ordinal
					}
					rows {
						id
						text
						ordinal
					}
				}
				... on ImportanceQuestion {
					rows {
						text
						id
						ordinal
					}
				}
				... on RatingQuestion {
					pointCount
				}
				... on NetPromoterQuestion {
					leftText
					rightText
				}
				... on SemanticDifferentialQuestion {
					pointCount
					rows {
						id
						leftLabel
						rightLabel
						ordinal
					}
				}
				... on TextQuestion {
					subtype: type
					answerLengthLimit
				}
				... on DividePointsQuestion {
					rows {
						text
						id
						ordinal
					}
					pointCount
					units
				}
				... on TextBlock {
					id
					ordinal
					text
				}
			}
		}
	}
`;
