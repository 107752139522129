import React, { FC, memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { TableProps, TableWrapper, Row, Header, Cell } from '.';

export const Table: FC<TableProps> = ({ className, columns, dataSource, size }: TableProps) => (
	<TableWrapper className={className} data-testid="ui-table">
		<Header>
			{columns.map((column) => (
				<Cell
					key={column?.dataIndex}
					width={column.width}
					justifyContent={column.justifyContent}
					size={size}
					numOfColumns={columns.length}
				>
					{column?.title}
				</Cell>
			))}
		</Header>
		<Scrollbars autoHeight autoHeightMax="100%" className="scrollbar">
			{dataSource.map((row, key) => (
				<Row key={key}>
					{columns.map((column) => (
						<Cell
							key={column?.dataIndex}
							width={column.width}
							justifyContent={column.justifyContent}
							size={size}
							numOfColumns={columns.length}
						>
							{column.render ? column.render(row) : row[column?.dataIndex]}
						</Cell>
					))}
				</Row>
			))}
		</Scrollbars>
	</TableWrapper>
);

Table.displayName = 'Table';

Table.defaultProps = {
	size: 'xl',
};

export default memo(Table);
