import choiceTransform from './choice';
import ratingTransform from './rating';
import matrixTransform from './matrix';
import textTransform from './text';
import npsTransform from './nps';
import divideTransform from './divide';
import orderTransform from './order';
import semanticTransform from './semantic';

const tableTransform = (type, data, cols = false) => {
	const transformType = cols ? 'cols' : 'rows';

	switch (type) {
		case 'ChoiceQuestion':
		case 'AnswerDataChoices':
			return choiceTransform[transformType](data);
		case 'RatingQuestion':
		case 'AnswerDataRating':
			return ratingTransform[transformType](data);
		case 'MatrixQuestion':
		case 'AnswerDataMatrixChoiced':
		case 'AnswerDataMatrixText':
			return matrixTransform[transformType](data);
		case 'TextQuestion':
		case 'AnswerDataText':
			return textTransform[transformType](data);
		case 'NetPromoterQuestion':
		case 'AnswerDataNPS':
			return npsTransform[transformType](data);
		case 'DividePointsQuestion':
		case 'AnswerDataDividePoints':
			return divideTransform[transformType](data);
		case 'ImportanceQuestion':
		case 'AnswerDataOrder':
			return orderTransform[transformType](data);
		case 'SemanticDifferentialQuestion':
		case 'AnswerDataSemanticDifferential':
			return semanticTransform[transformType](data);
		default:
			return cols ? [] : {};
	}
};
export default tableTransform;
