import gql from 'graphql-tag';

export default gql`
	fragment pendingEmailPopups on User {
		pendingEmailPopups {
			id
			body
		}
	}
`;
