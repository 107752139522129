import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import UpgradeModalStripe from '~/components/UpgradeModalStripe';
import query from '~/cache/query';
import { withBusinessModel01Handling } from '~/hoc';

const UpgradeModalContainer = ({ upgradeModalData, closeStripeUpgradeModal, ...rest }) =>
	upgradeModalData?.isOpen ? (
		<UpgradeModalStripe
			{...rest}
			{...upgradeModalData}
			type={upgradeModalData?.upgradeType}
			close={closeStripeUpgradeModal}
		/>
	) : (
		<></>
	);

UpgradeModalContainer.propTypes = {
	closeStripeUpgradeModal: PropTypes.func,
	intl: PropTypes.object,
	upgradeModalData: PropTypes.shape({
		isOpen: PropTypes.bool,
		type: PropTypes.string,
	}),
};

export default compose(
	memo,
	query('stripeModal', {
		mapProps: ({ stripeModal }) => ({
			upgradeModalData: stripeModal?.upgradeModalData,
		}),
	}),
	withBusinessModel01Handling,
)(UpgradeModalContainer);
