import gql from 'graphql-tag';

export default gql`
	fragment Smartlook on User {
		email
		registeredAt
		activeService {
			name
		}
	}
`;
