import auth from '~/auth';
import { removeTrackingToken } from '~/utils/tracking';

export const logout = () => {
	auth.removeToken();
	removeTrackingToken();
};

export const goToLogoutPage = () => {
	const { language } = auth.getTokenData(auth.getToken());
	const lang = language || window.navigator.language.slice(0, 2);

	const logoutUrl = ['skoda.survio.com', 'stage-skoda.survio.com'].includes(location.host)
		? `${location.origin}/login`
		: `${window.env.HOME_URL}/${lang}/logout`;

	window.location.replace(logoutUrl);
};

/**
 * This serves as a place for any tasks that need to be done when the user logs out.
 */
export const logoutCleanup = () => {
	// Remove the proforma warning from local storage
	window.localStorage.removeItem('proformaWarning');
};

export const logoutAndGoToLogoutPage = () => {
	logout();
	logoutCleanup();
	goToLogoutPage();
};
