import gql from 'graphql-tag';

export default gql`
	fragment HeaderSurveyData on Survey {
		title
		workspace {
			id
			name
		}
	}
`;
