import gql from 'graphql-tag';

export default gql`
	fragment ChooseQuestionsDashboard on Survey {
		questions {
			id
			text
		}
	}
`;
