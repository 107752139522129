import React, { useRef, FC, useEffect, useCallback, memo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from '@emotion/styled';
import { CopyCodeProps } from '.';
import { getThemeColor, copyToClipboard } from '../../utils';
import Button from '../Button';
import { isDark } from '../../utils';
import { useTheme } from '@emotion/react';

const StyledButton = styled(Button)<Omit<CopyCodeProps, 'variant'>>`
	color: ${({ linkColor, theme, disabled }) =>
		!disabled && linkColor && getThemeColor(linkColor, theme)};
	justify-content: left !important;

	.background {
		opacity: ${({ theme, background }) =>
			background && isDark(getThemeColor(background, theme)) ? 1 : undefined};
		background-color: ${({ theme, background }) =>
			background && isDark(getThemeColor(background, theme))
				? theme.colors.grey_shades_with_blue[600]
				: undefined};
	}
`;

const StyledCopyCode = styled.div<Omit<CopyCodeProps, 'onChange'>>`
	border-radius: ${({ theme }) => theme.helpers.spacer() - 2}px;
	background: ${({ background, theme, disabled }) =>
		disabled ? theme.colors.grey[200] : getThemeColor(background, theme)};
	padding: ${({
		variant,
		theme: {
			helpers: { spacer },
		},
	}) =>
		variant === 'textarea'
			? `${spacer(2) + 2}px ${spacer(3)}px ${spacer(3)}px ${spacer(3)}px`
			: '14px 24px 14px 16px'};
	height: auto;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};

	.copyButton {
		z-index: 1;
	}

	.scrollbar > div:first-of-type {
		background: ${({ background, theme, disabled }) =>
			disabled ? theme.colors.grey[200] : getThemeColor(background, theme)};
	}
`;

const StyledTextarea = styled.textarea<Omit<CopyCodeProps, 'onClick'>>`
	background: transparent;
	border: none;
	color: ${({ codeColor, theme }) => getThemeColor(codeColor, theme)};
	font-family: 'Lucida Console', 'Monaco', monospace, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-top: 14px;
	overflow: hidden;
	padding: 0;
	resize: none;
	width: 100%;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};

	::-webkit-resizer {
		display: none;
	}

	&::selection {
		color: ${({ theme, activeBackground }) =>
			isDark(getThemeColor(activeBackground, theme)) ? 'white' : undefined};
		background: ${({ activeBackground, theme }) =>
			activeBackground ? getThemeColor(activeBackground, theme) : undefined};
	}

	&:focus,
	&:hover,
	&:active {
		border: none;
		outline: none;
	}
`;

const StyledInput = styled.input<
	Pick<CopyCodeProps, 'codeColor' | 'theme' | 'activeBackground' | 'disabled'>
>`
	background: transparent;
	border: none;
	outline: none;
	resize: none;
	padding: 0;
	margin-right: 20px;
	width: 100%;
	${({ theme }) => theme.typography.textMedium300};
	color: ${({ codeColor, theme, disabled }) =>
		disabled ? theme.colors.grey_shades_with_blue[300] : getThemeColor(codeColor, theme)};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};

	&::selection {
		color: ${({ theme, activeBackground }) =>
			isDark(getThemeColor(activeBackground, theme)) ? 'white' : undefined};
		background: ${({ activeBackground, theme }) =>
			activeBackground ? getThemeColor(activeBackground, theme) : undefined};
	}
`;

const InputWrapper = styled.div`
	display: flex;
	width: 100%;
`;

export const CopyCode: FC<CopyCodeProps> = ({
	activeBackground,
	background,
	className,
	codeColor,
	copyText,
	code,
	linkColor,
	onClick,
	rows,
	variant,
	disabled,
	...props
}: CopyCodeProps) => {
	const textAreaRef = useRef(null);
	const theme = useTheme();

	useEffect(() => {
		const textarea = textAreaRef.current;
		const setSize = () => {
			textarea.style.height = 'auto';
			textarea.style.height = `${textarea.scrollHeight}px`;
		};

		setSize();

		textarea.addEventListener('input', setSize);
		return () => textarea.removeEventListener('input', setSize);
	}, [rows]);

	const selectText = useCallback(() => {
		if (textAreaRef) {
			textAreaRef.current.select();
		}
	}, []);

	const handleCopy = useCallback(
		(event) => {
			selectText();
			if (textAreaRef) {
				copyToClipboard(textAreaRef.current);
			}
			onClick && onClick(event);
		},
		[onClick, selectText],
	);

	const renderThumb = isDark(getThemeColor(background, theme))
		? ({ style, ...props }) => (
				<div
					{...props}
					style={{
						...style,
						borderRadius: '3px',
						backgroundColor: 'rgba(255, 255, 255, 0.4)',
					}}
				/>
			)
		: undefined;

	return (
		<StyledCopyCode
			data-testid="ui-copyCode"
			className={className}
			background={background}
			disabled={disabled}
			variant={variant}
			{...props}
		>
			{variant === 'textarea' && (
				<StyledButton
					onClick={handleCopy}
					size="sm"
					variant="text"
					startIcon="copy-16"
					linkColor={linkColor}
					background={background}
					disabled={disabled}
					className="copyButton"
				>
					{copyText}
				</StyledButton>
			)}
			{variant === 'input' ? (
				<InputWrapper>
					<StyledInput
						value={code}
						ref={textAreaRef}
						codeColor={codeColor}
						activeBackground={activeBackground}
						disabled={disabled}
						onClick={handleCopy}
						readOnly
					/>
					<StyledButton
						size="sm"
						variant="text"
						startIcon="copy-16"
						disabled={disabled}
						background={background}
						onClick={handleCopy}
					>
						{copyText}
					</StyledButton>
				</InputWrapper>
			) : (
				<Scrollbars
					autoHeight
					autoHeightMax={rows * 20 + 14 + 4}
					className="scrollbar"
					renderThumbVertical={renderThumb}
				>
					<StyledTextarea
						rows={rows}
						value={code}
						ref={textAreaRef}
						codeColor={codeColor}
						activeBackground={activeBackground}
						background={background}
						disabled={disabled}
						onClick={handleCopy}
						readOnly
					/>
				</Scrollbars>
			)}
		</StyledCopyCode>
	);
};

CopyCode.defaultProps = {
	rows: 3,
	activeBackground: 'active_blue[600]',
	background: 'black[100]',
	codeColor: 'grey_shades_with_blue[200]',
	copyText: 'Copy',
	linkColor: 'active_blue[400]',
	variant: 'textarea',
};

export default memo(CopyCode) as FC<CopyCodeProps>;
