import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const daysFormatter = (date) => {
	const days = moment().startOf('day').diff(moment(date).startOf('day'), 'days');
	switch (true) {
		case days === 0:
			return <FormattedMessage id="app.multiuser.today" tagName="span" />;
		case days === 1:
			return <FormattedMessage id="app.multiuser.yesterday" tagName="span" />;
		case days < 8:
			return (
				<FormattedMessage id="app.multiuser.days-ago" values={{ DAYS: days }} tagName="span" />
			);
		default:
			return moment(date).format('L');
	}
};

export default daysFormatter;
