const getImgProps = (
	img: HTMLImageElement,
	maxWidth?: number,
	maxHeight?: number,
	retina = false,
): { width: number; height: number; ratio: number } => {
	const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
	const width = Math.ceil(Math.min(img.width, img.width * ratio * (retina ? 2 : 1))); // *2 <- retina
	const height = Math.ceil(Math.min(img.height, img.height * ratio * (retina ? 2 : 1))); // *2 <- retina

	return { width, height, ratio };
};

export default getImgProps;
