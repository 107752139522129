import gql from 'graphql-tag';

export default gql`
	fragment QuestionsPivotModal on Survey {
		questions {
			... on QuestionInterface {
				id
				text
				number
			}
			... on ChoiceQuestion {
				hasPictureChoices
				isMultipleAnswer
			}
		}
	}
`;
