import gql from 'graphql-tag';

export default gql`
	mutation addSurveyDashboardSourceFilter($input: SurveyDashboardSourceFilterInput!) {
		addSurveyDashboardSourceFilter(input: $input) {
			id
			type
			value
		}
	}
`;
