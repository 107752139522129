import gql from 'graphql-tag';

export default gql`
	fragment CurrentPlan on User {
		activeService {
			name
			period
			isSubscription
			autorenewal
			nextBilling {
				date
				amount
				currencyCode
			}
		}
	}
`;
