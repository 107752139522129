import React, { FC, memo, useCallback } from 'react';
import { ToUrlOrSubmitStyled } from '~/ui/components/LogicSelect/LogicContents/LogicContents.styled';
import { Button, Icon, LSContentRightProps, Spacer, Typography } from 'ui';
import {
	LOGIC_OPTION_FROM_API_TYPES,
	LOGIC_OPTION_TYPES,
} from '~/pages/Builder23_1/constants/constants';
import { useIntl } from 'react-intl';

const ToSubmit: FC<Omit<LSContentRightProps, 'selected'>> = ({
	logicValue,
	setChoiceLogic,
	choiceId,
	closeSelect,
}) => {
	const { formatMessage } = useIntl();

	const handleApply = useCallback(() => {
		const choiceLogic = {
			action: LOGIC_OPTION_TYPES.TO_SUBMIT,
			actionValue: LOGIC_OPTION_TYPES.TO_SUBMIT,
			conditionValue: choiceId,
		};

		setChoiceLogic(choiceLogic);
		closeSelect();
	}, [choiceId, closeSelect, setChoiceLogic]);

	return (
		<ToUrlOrSubmitStyled>
			<Icon name="enter-16" color="grey_shades_with_blue[600]" className="to-icon" />
			<Spacer height={12} />
			<Typography variant="textMedium300" color="grey_shades_with_blue[600]">
				{formatMessage({ id: 'app.designer.modal.submit-button' })}
			</Typography>
			<Spacer height={4} />
			<Typography variant="textRegular300" color="grey_shades_with_blue[400]">
				{formatMessage({ id: 'app.designer.modal.submit-button-desc' })}
			</Typography>
			<Spacer height={32} />
			<Button
				className="toSubmit-apply"
				disabled={logicValue?.type === LOGIC_OPTION_FROM_API_TYPES.SUBMIT}
				onClick={handleApply}
			>
				{formatMessage({ id: 'app.designer.modal.apply-button' })}
			</Button>
		</ToUrlOrSubmitStyled>
	);
};

export default memo(ToSubmit);
