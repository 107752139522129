import gql from 'graphql-tag';

export default gql`
	query user($input: StripeCheckoutLinkInput) {
		user {
			getStripeCheckoutLink(input: $input) {
				url
				amount
				currency
			}
		}
	}
`;
