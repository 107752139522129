import React, { FC, memo, useCallback, useState } from 'react';
import { InLine, ToUrlOrSubmitStyled } from '.';
import { Button, Icon, Input, LSContentRightProps, Spacer, Typography } from 'ui';
import {
	LOGIC_OPTION_FROM_API_TYPES,
	LOGIC_OPTION_TYPES,
} from '~/pages/Builder23_1/constants/constants';
import { useIntl } from 'react-intl';

const isURL = (value) =>
	/^(https?):\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
		value,
	);

const ToUrl: FC<Omit<LSContentRightProps, 'selected'>> = ({
	choiceId,
	setChoiceLogic,
	closeSelect,
	logicValue,
}) => {
	const { formatMessage } = useIntl();
	const [error, setError] = useState('');
	const [url, setUrl] = useState(
		logicValue?.type === LOGIC_OPTION_FROM_API_TYPES.REDIRECT ? logicValue?.value : '',
	);

	const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUrl(e.target.value);
		setError('');
	}, []);

	const handleApply = useCallback(() => {
		if (!isURL(url)) {
			setError(formatMessage({ id: 'app.designer.modal.logic-url-error' }));
			return;
		}

		const choiceLogic = {
			action: url ? LOGIC_OPTION_TYPES.TO_URL : undefined,
			actionValue: url,
			conditionValue: choiceId,
		};

		setChoiceLogic(choiceLogic);
		closeSelect();
	}, [choiceId, closeSelect, formatMessage, setChoiceLogic, url]);

	return (
		<ToUrlOrSubmitStyled>
			<Icon name="link-thin-16" color="grey_shades_with_blue[600]" className="to-icon" />
			<Spacer height={12} />
			<Typography variant="textMedium300" color="grey_shades_with_blue[600]">
				{formatMessage({ id: 'app.designer.modal.website-link' })}
			</Typography>
			<Spacer height={4} />
			<Typography variant="textRegular300" color="grey_shades_with_blue[400]">
				{formatMessage({ id: 'app.designer.modal.website-link-desc' })}
			</Typography>
			<Spacer height={32} />
			<InLine>
				<Input
					placeholder="https://"
					size="sm"
					className="url-input"
					value={url}
					onChange={handleOnChange}
					error={error}
				/>
				<Spacer width={8} />
				<Button onClick={handleApply} disabled={url === logicValue?.value}>
					{formatMessage({ id: 'app.designer.modal.apply-button' })}
				</Button>
			</InLine>
		</ToUrlOrSubmitStyled>
	);
};

export default memo(ToUrl);
