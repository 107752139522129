import styled from '@emotion/styled';
import { SurveyCardProps } from '.';
import { css } from '@emotion/react';
import { ThemeType } from '../ThemeProvider/theme';

const small = (theme: ThemeType) => css`
	width: 188px;
	height: 240px;
	.title {
		${theme.typography.textMedium300}
	}

	.cardBackground,
	.cardLogo {
		height: 150px;
	}
`;

const big = (theme: ThemeType) => css`
	width: 216px;
	height: 272px;
	.title {
		${theme.typography.textMedium400}
	}
	.cardBackground,
	.cardLogo {
		height: 178px;
	}
`;

export const StyledSurveyCard = styled.div<SurveyCardProps>`
	${({ theme }) => small(theme)}
	position: relative;
	display: flex;
	border-radius: 12px;
	transition: all 200ms ease-out;
	background-color: ${({ theme }) => theme.colors.white};
	overflow: hidden;
	opacity: ${({ animated }) => (animated ? 0 : 1)};

	&:hover {
		box-shadow: 0 0 16px rgba(0, 19, 41, 0.16);
	}

	a {
		text-decoration: none;
	}

	.bubble {
		position: absolute;
		top: 12px;
		right: 12px;
	}

	.note {
		margin-top: 8px;
		max-width: 100%;
	}

	@media (min-width: 560px) {
		${({ theme }) => big(theme)}
	}

	@media (min-width: 760px) {
		${({ theme }) => small(theme)}
	}

	@media (min-width: 800px) {
		${({ theme }) => big(theme)}
	}

	@media (min-width: 960px) {
		${({ theme }) => small(theme)}
	}

	@media (min-width: 1132px) {
		${({ theme }) => big(theme)}
	}

	@media (min-width: 1260px) {
		${({ theme }) => small(theme)}
	}

	@media (min-width: 1344px) {
		${({ theme }) => big(theme)}
	}
`;

export const Border = styled.div<SurveyCardProps>`
	border: 1px solid rgba(0, 19, 41, 0.14);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border-radius: 12px;
	z-index: 2;
	pointer-events: none;
`;

export const Background = styled.div<SurveyCardProps>`
	display: flex;
	background: ${({ backgroundImage, backgroundColor }) =>
		backgroundImage
			? `${backgroundColor} url(${backgroundImage}) center/cover no-repeat`
			: `${backgroundColor}`};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;
`;

export const Logo = styled.div<SurveyCardProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${({ backgroundOverlay }) => backgroundOverlay};
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;

	.image {
		display: ${({ logo }) => (logo ? 'flex' : 'none')};
		background: ${({ logo }) => `url(${logo})`} center/contain no-repeat;
		width: 100px;
		height: 58px;
		top: 0;
		left: 0;
	}
`;

export const Info = styled.div<SurveyCardProps>`
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 14px 20px 19px;
	width: 100%;
	box-sizing: border-box;
	align-items: flex-start;
	background-color: ${({ theme }) => theme.colors.white};

	.title {
		color: #000000 !important;
	}

	.actionLine {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 19px;

		.responses {
			white-space: pre;
		}
	}

	.responsesButton {
		pointer-events: ${({ responses }) => (!responses ? 'none' : 'auto')};
	}
`;

export const Note = styled.div<SurveyCardProps>`
	display: ${({ noteText }) => (noteText ? 'flex' : 'none')};
	justify-content: flex-start;
	align-items: flex-start;
	padding: 4px 8px;
	border-radius: 6px;
	margin-top: 8px;
	cursor: pointer;
	max-width: 100%;
	overflow: hidden;
	${({ theme }) => theme.typography.textMedium200}
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[600]};
	background-color: ${({ theme: { colors } }) => colors.grey[300]};
`;
