/**
 * Function to get only dirty/changed values in react hook form.
 * Useful for sending only new values to server.
 *
 * SOURCE: https://github.com/react-hook-form/react-hook-form/discussions/1991
 */
const getDirtyValues = (dirtyFields, allValues) => {
	// If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
	// "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
	if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;
	// Here, we have an object
	return Object.fromEntries(
		Object.keys(dirtyFields)
			.filter((key) => allValues[key] === false || allValues[key])
			.map((key) => [key, getDirtyValues(dirtyFields[key], allValues[key])]),
	);
};

export default getDirtyValues;
