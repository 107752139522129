import React, { FC, useCallback, memo, forwardRef, RefObject } from 'react';
import {
	MultipleSwitchProps,
	StyledMultipleSwitch,
	MultipleSwitchItem,
	MultipleSwitchWithRefProps,
} from '.';

export const MultipleSwitch: FC<MultipleSwitchWithRefProps> = forwardRef<
	HTMLInputElement,
	MultipleSwitchProps
>(
	(
		{
			className,
			items,
			onChange,
			value,
			name,
			disabled,
			onChangeHookForm,
			onBlur,
		}: MultipleSwitchProps,
		ref: RefObject<HTMLInputElement>,
	) => {
		const onChangeHandler = useCallback((id: string) => onChange && onChange(id), [onChange]);

		return (
			<StyledMultipleSwitch className={className}>
				{items?.map(({ id, ...itemProps }) => (
					<MultipleSwitchItem
						key={id}
						{...itemProps}
						id={id}
						active={value === id}
						onChangeHandler={onChangeHandler}
						ref={ref}
						name={name}
						value={value}
						disabled={disabled}
						onChange={onChangeHookForm}
						onBlur={onBlur}
					/>
				))}
			</StyledMultipleSwitch>
		);
	},
);

MultipleSwitch.displayName = 'MultipleSwitch';

export default memo(MultipleSwitch) as FC<MultipleSwitchWithRefProps>;
