import { Children, cloneElement, isValidElement, ReactNode } from 'react';

/**
 * Pushes selected props (pushedProps) to all children in Item.
 * It is needed e.g. because of active state in children component.
 * @param clonedChildren - children into which are props pushed
 * @param pushedProps - selected props
 */
const pushPropsIntoAllChildren = <T>(clonedChildren: ReactNode, pushedProps: T): ReactNode =>
	Children.map(clonedChildren, (child: ReactNode) => {
		// checking isValidElement is the safe way and avoids a typescript error too
		if (isValidElement(child)) {
			const subChildren = child.props.children;
			const newSubChildren = subChildren && pushPropsIntoAllChildren(subChildren, pushedProps);
			return cloneElement(child, { ...pushedProps, children: newSubChildren });
		}
		return child;
	});

export default pushPropsIntoAllChildren;
