const tryParseJSONObject = (json) => {
	if (json && typeof json === 'object') {
		return json;
	}

	try {
		const object = JSON.parse(json);
		// check for null:
		if (object && typeof object === 'object') {
			return object;
		}
	} catch (e) {
		return {};
	}
	return {};
};

export default tryParseJSONObject;
