import React, { FC, memo } from 'react';
import { Icon } from '../../Icon';
import { Label, PlaceholderProps } from '../index';

const Placeholder: FC<PlaceholderProps> = ({
	error,
	icon,
	mini,
	disabled,
	wrongFileError,
	overLimitError,
	primaryText,
	wrongFileErrorSecondary,
	overLimitErrorSecondary,
	secondaryText,
	image,
	isLoading,
}: PlaceholderProps) =>
	!image && !isLoading ? (
		<>
			<Icon name={icon} className="icon" />
			{!mini && (
				<Label error={!!error} disabled={disabled}>
					{error === 'wrongFile'
						? wrongFileError
						: error === 'overLimit'
							? overLimitError
							: primaryText}
				</Label>
			)}
			{!mini && (
				<Label error={!!error} disabled={disabled} secondary>
					{error === 'wrongFile'
						? wrongFileErrorSecondary
						: error === 'overLimit'
							? overLimitErrorSecondary
							: secondaryText}
				</Label>
			)}
		</>
	) : null;

export default memo(Placeholder);
