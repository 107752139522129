import { Power2, TweenMax } from 'gsap';

const vibrate = (repeat, xMove, time, ref) => {
	const getXMove = (index) => {
		const move = index === 0 ? Math.ceil(xMove / 2) : index === repeat - 1 ? 0 : xMove;
		return move * (index % 2 ? -1 : 1);
	};
	const getTime = (index) => (index === repeat - 1 ? time / 2 : time);
	for (let i = 0; i < repeat; i++) {
		TweenMax.to(ref.current, getTime(i), {
			x: getXMove(i),
			ease: Power2.easeInOut,
			delay: time * i,
		});
	}
};

export default vibrate;
