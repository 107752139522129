import React, { memo, useCallback, useEffect, useRef } from 'react';
import { Modal, Button, Typography, useMedia } from 'ui';
import PropTypes from 'prop-types';
import UpgradeModal from '~/components/UpgradeModal';
import { isProPlan, includesPlan } from '~/utils';
import { PLANS, PLANS_NEW } from '~/constants';
import Features from './components/Features';
import { plansFeatures, newPlansFeatures, planFeatures23, content, events } from './constants';
import styles from './styles.less';
import ConditionalLink from '~/components/ConditionalLink';
import { PLANS_OLD } from '~/constants/plans';

const SalesModal = ({
	close,
	openModal,
	intl,
	priceList,
	variant,
	sendTracking,
	prev,
	serviceName,
	isBusinessModel01,
	proforma,
	cleverbridge,
	testPrice23A,
	testPrice24A,
}) => {
	const _didUpgrade = useRef(false);
	const { setSlide } = Modal.useModal();
	const hasProSubscription = isProPlan(serviceName);

	useEffect(() => {
		if (variant in events) {
			events[variant].open && sendTracking(events[variant].open.id, events[variant].open.name);
			return () => {
				if (!_didUpgrade.current) {
					sendTracking(events[variant].close.id, events[variant].close.name);
				}
			};
		}
	}, [sendTracking, variant]);

	const salesContent = content[variant];
	const features =
		testPrice24A || testPrice23A
			? salesContent.plan
			: isBusinessModel01
				? salesContent.stripeFeatures
				: salesContent.features;
	const isEnterprise = includesPlan([PLANS.ENTERPRISE], features);
	const pro = isProPlan(features.charAt(0).toUpperCase() + features.slice(1));

	const titleVariant = useMedia(
		['(max-width: 479px)', '(max-width: 1109px)'],
		['title600', 'title700'],
		'title800',
	);

	const handleUpgrade = useCallback(() => {
		if (isEnterprise) {
			setSlide('upgradePRO');
		} else {
			if (cleverbridge) {
				close();
				openModal(
					{
						upgrade: <UpgradeModal />,
					},
					'upgrade',
					{
						type: features.toUpperCase(),
						period: pro ? 'YEARLY' : 'MONTHLY',
						selectedPeriod: 'YEARLY',
					},
				);
			} else {
				setSlide(
					testPrice24A && !features.startsWith('pro') ? features.replace('23', '24') : features,
				);
			}
		}
	}, [isEnterprise, setSlide, cleverbridge, close, openModal, features, pro, testPrice24A]);

	const handleActivateServices = useCallback(() => {
		if (variant in events) {
			sendTracking(events[variant].activate.id, events[variant].activate.name);
			_didUpgrade.current = true;
		}
		if (proforma) return;
		handleUpgrade();
	}, [variant, proforma, handleUpgrade, sendTracking]);

	return (
		<>
			<Modal.Content prev={prev}>
				<div style={{ backgroundImage: `url(${salesContent.image})` }} className={styles.image} />
				<Typography variant={titleVariant} className={styles.title}>
					{intl.formatMessage({ id: salesContent.title })}
				</Typography>
				<Typography variant="textRegular300" color="grey_shades_with_blue[400]">
					{intl.formatMessage({ id: salesContent.text })}
				</Typography>
				<Modal.Footer>
					<ConditionalLink
						disabled={!proforma}
						to={intl.formatMessage({ id: 'app.common.contact' })}
						target="_blank"
						external
					>
						<Button color="yellow" size="xl" onClick={handleActivateServices}>
							{intl.formatMessage({
								id:
									pro && !hasProSubscription && !isBusinessModel01
										? 'app.multiuser.upgrade-PRO'
										: 'app.designer.modal.activate-prem',
							})}
						</Button>
					</ConditionalLink>
				</Modal.Footer>
			</Modal.Content>
			<Modal.Sidebar>
				<Features
					isEnterprise={isEnterprise || features === PLANS_NEW.CUSTOM}
					features={
						testPrice24A || testPrice23A
							? planFeatures23({
									responseLimit: intl.formatNumber(priceList[features]?.responseLimit),
								})[features]
							: isBusinessModel01
								? newPlansFeatures[features]
								: plansFeatures[features]
					}
					intl={intl}
					price={intl.formatNumber(
						(cleverbridge
							? priceList[PLANS_OLD[features.toUpperCase()]]?.yearlyAmount
							: Object.values(priceList)?.find(
									(plan) =>
										plan?.identifier ===
										(testPrice24A && !features.startsWith('pro')
											? features.replace('23', '24')
											: testPrice24A || testPrice23A || isBusinessModel01
												? features
												: PLANS_OLD[features.toUpperCase()]),
								)?.yearlyAmount) / 12,
						{
							currency: priceList.currencyCode,
							style: 'currency',
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
						},
					)}
				/>
			</Modal.Sidebar>
		</>
	);
};

SalesModal.propTypes = {
	close: PropTypes.func,
	content: PropTypes.object,
	intl: PropTypes.object,
	isBusinessModel01: PropTypes.bool,
	openModal: PropTypes.func,
	prev: PropTypes.string,
	priceList: PropTypes.object,
	proforma: PropTypes.bool,
	sendTracking: PropTypes.func,
	variant: PropTypes.string,
	serviceName: PropTypes.string,
	cleverbridge: PropTypes.bool,
	testPrice23A: PropTypes.bool,
	testPrice24A: PropTypes.bool,
};

export default memo(SalesModal);
