import { graphql } from '@apollo/client/react/hoc';

import QUERY from '~/cache/queries/results/answer';

export default graphql(QUERY, {
	options: (ownProps) => {
		const variables = { uid: ownProps.uid, id: ownProps.cursor };
		if (ownProps.direction) variables.neighbor = ownProps.direction;
		return { variables };
	},
	props: ({ data: { loading, survey } }) => {
		if (loading) {
			return {
				loadingAnswer: true,
			};
		}

		const response = survey?.response ? { ...survey.response } : {};
		const { cursor, ...responseWithoutCursor } = response;

		return {
			...responseWithoutCursor,
		};
	},
});
