import gql from 'graphql-tag';

export default gql`
	mutation setSurveyNote($uid: String!, $text: String!) {
		setSurveyNote(input: { uid: $uid, text: $text }) {
			uid
			note
		}
	}
`;
