import gql from 'graphql-tag';

export default gql`
	mutation ChangePassword(
		$oldPassword: String!
		$newPassword: String!
		$confirmation: String!
		$id: Int
	) {
		changePassword(
			oldPassword: $oldPassword
			newPassword: $newPassword
			confirmation: $confirmation
			id: $id
		)
	}
`;
