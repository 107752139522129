import gql from 'graphql-tag';

export default gql`
	mutation createWorkspace($input: WorkspaceInput!) {
		createWorkspace(input: $input) {
			id
			name
		}
	}
`;
