const isEmail = (string) => {
	const re = /^([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9]{2,})$/;
	return re.test(string);
};

const splitEmails = (string) => {
	const splitted = string.split(/[ ,;]+/) || [];
	const noWhitespaces = splitted.map((e) => e.replace(/\s/g, ''));
	return noWhitespaces.filter((e) => e.length > 0);
};

const getValidEmails = (array) => array.filter((email) => isEmail(email));

const emailValidation = (array) => {
	const x = !array.some((email) => !isEmail(email));
	console.log(x);
	return x;
};

export { isEmail, splitEmails, getValidEmails, emailValidation };
