import gql from 'graphql-tag';

export default gql`
	fragment oneDriveAccounts on User {
		integrations {
			oneDrive {
				id
				name
			}
		}
	}
`;
