import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'ui';
import { ApolloConsumer } from '@apollo/client';
import { compose } from 'redux';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useStripe } from '@stripe/react-stripe-js';
import { OPS } from '~/pages/StripePricing/constants';
import {
	AB_BM_VARIANT,
	EXPERIMENT_STATE,
	EXPERIMENTS,
	PAGES,
	PERIOD,
	UPGRADE_MODAL_TYPES,
} from '~/constants';
import getStripeCheckoutLink from '~/cache/queries/getStripeCheckoutLink';
import getStripeUpgradePrice from '~/cache/queries/getStripeUpgradePrice';
import Loader from '~/components/Loader';
import RightPanel from './RightPanel';
import LeftPanel from './LeftPanel';
import s from './style.less';
import { PLANS_23, PLANS_OLD } from '~/constants/plans';
import query from '~/cache/query';
import withRouter from '~/hoc/withRouter';
import SaleSourceContext from '~/components/UpgradeModalStripe/SaleSourceContext';
import { getActivePage } from '~/utils';
import Notifications from '~/components/Notifications';
import { PRICING_EXPERIMENT } from '~/constants/experiments';

const UpgradeSlide = ({
	nextBilling,
	actualPlan,
	period,
	actualPeriod,
	stripePriceList,
	actualLimit,
	type,
	stripeUpgrade,
	intl,
	upgradeToPlan,
	refetchStripePriceList,
	apiLoading,
	prev,
	isTrial,
	isBusinessModel01,
	navigate,
	close,
	location,
}) => {
	const [loading, setLoading] = useState(false);
	const { saleSource } = useContext(SaleSourceContext);
	const { handleSubmit, register, watch, setValue } = useForm();

	const activePro = upgradeToPlan.match(new RegExp(/pro_business_([0-9]+)_p_23/))?.[1] || '1';
	const isPro23A = new RegExp(/pro_business_.+_p_23/gm).test(upgradeToPlan);
	const business_variant = watch('business_variant', activePro);
	const split = useMemo(() => PLANS_23.BUSINESS_1.split('1'), []);

	/** Get selected plan prices */
	const stripePricingPlan = useMemo(
		() =>
			stripePriceList?.plans.find(
				(p) =>
					p.identifier ===
					(isBusinessModel01
						? upgradeToPlan
						: isPro23A && business_variant
							? `${PLANS_23.BUSINESS_1.split('1')[0]}${business_variant}${
									PLANS_23.BUSINESS_1.split('1')[1]
								}`
							: PLANS_OLD[upgradeToPlan.toUpperCase()] || upgradeToPlan),
			),
		[business_variant, isBusinessModel01, isPro23A, stripePriceList?.plans, upgradeToPlan],
	);

	/** Hook form */
	const selectedLimit = watch('responses');
	const selectedPeriod =
		watch('period') ||
		period ||
		(!stripePricingPlan?.monthlyAmount
			? PERIOD.YEARLY
			: actualPeriod === 1
				? PERIOD.MONTHLY
				: PERIOD.YEARLY);

	/** Get response packages to select */
	const responsePackages = useMemo(
		() =>
			stripePricingPlan?.responseCollectionLimit
				? [
						{
							monthlyAmount: 0,
							quantity: stripePricingPlan.responseCollectionLimit,
							yearlyAmount: 0,
						},
						...stripePricingPlan.responseCollectionPackages,
					].filter(
						(p) =>
							(actualPeriod < 12 && selectedPeriod === PERIOD.YEARLY) || p.quantity > actualLimit,
					)
				: [],
		[actualLimit, actualPeriod, selectedPeriod, stripePricingPlan],
	);

	/**
	 * Set the value of the period radiobutton the same as it was at previous subscription
	 */
	useEffect(() => {
		if (type === UPGRADE_MODAL_TYPES.UPGRADE_PLAN) {
			setValue('period', period || PERIOD.YEARLY);
		}
	}, [actualPlan, setValue, type, actualPeriod, upgradeToPlan, period, isTrial]);

	/** Select first radiobutton in responses array */
	useEffect(() => {
		if (upgradeToPlan && responsePackages.length > 0) {
			setValue('responses', responsePackages[0].quantity.toString());
		}
	}, [responsePackages, setValue, upgradeToPlan]);

	useEffect(() => {
		if (isPro23A) {
			setValue('business_variant', activePro);
		}
	}, [activePro, isPro23A, setValue, upgradeToPlan]);

	const getSale = useCallback(() => {
		const responsePackage = stripePricingPlan?.responseCollectionPackages?.find(
			(p) => p.quantity === Number(selectedLimit),
		);
		const monthlyPrice =
			12 * stripePricingPlan?.monthlyAmount + 12 * (responsePackage?.monthlyAmount || 0);
		const yearlyPrice = stripePricingPlan?.yearlyAmount + (responsePackage?.yearlyAmount || 0);
		return Math.floor(100 - 100 * (yearlyPrice / monthlyPrice));
	}, [
		selectedLimit,
		stripePricingPlan?.monthlyAmount,
		stripePricingPlan?.responseCollectionPackages,
		stripePricingPlan?.yearlyAmount,
	]);

	const goToStripeCheckout = useCallback(
		async (client, { responses }) => {
			try {
				const backLink = new URLSearchParams(window.location.search).get('backLink');
				const { data } = await client.query({
					query: getStripeCheckoutLink,
					variables: {
						input: {
							successUrl:
								getActivePage(location.pathname).path === PAGES.pricing.path
									? `${window.location.origin}${backLink || ''}`
									: window.location.href,
							cancelUrl: window.location.href,
							saleSource,
							plan:
								type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES
									? null
									: isBusinessModel01
										? upgradeToPlan
										: isPro23A
											? `${split[0]}${business_variant}${split[1]}`
											: PLANS_OLD[upgradeToPlan.toUpperCase()] || upgradeToPlan,
							period: type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES ? null : selectedPeriod,
							...(!isBusinessModel01 ||
							Number(responses) === stripePricingPlan?.responseCollectionLimit
								? {}
								: { responseCollectionLimit: Number(responses) }),
						},
					},
				});

				if (data.user?.getStripeCheckoutLink?.url) {
					window.location.assign(data.user.getStripeCheckoutLink.url);
				}
			} catch {
			} finally {
				setLoading(false);
			}
		},
		[
			location?.pathname,
			saleSource,
			type,
			isBusinessModel01,
			upgradeToPlan,
			isPro23A,
			split,
			business_variant,
			selectedPeriod,
			stripePricingPlan?.responseCollectionLimit,
		],
	);

	const getUpdatedPrice = useCallback(
		async (client, { responses }) => {
			const { data } = await client.query({
				query: getStripeUpgradePrice,
				options: () => ({
					fetchPolicy: 'network-only',
				}),
				variables: {
					input: {
						plan:
							type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES
								? null
								: isBusinessModel01
									? upgradeToPlan
									: isPro23A
										? `${split[0]}${business_variant}${split[1]}`
										: PLANS_OLD[upgradeToPlan.toUpperCase()] || upgradeToPlan,
						period: type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES ? null : selectedPeriod,
						...(!isBusinessModel01 ||
						Number(responses) === stripePricingPlan?.responseCollectionLimit
							? {}
							: { responseCollectionLimit: Number(responses) }),
					},
				},
			});

			/** When user has pricing list in different currency than he bought
			 * the plan, we need to refetch pricing list in the same currency
			 * otherwise there will be displayed prices in two different currencies.
			 * e.g.: User is registered from Czech Rep., but he bought his plan in EUR
			 * => prices of pricing list will be default in CZK and prices of upgrade
			 * will be in EUR. In this case we need to get all the prices in EUR.
			 */
			if (
				data.user?.activeService?.getStripeUpgradePrice?.currencyCode !==
				stripePriceList?.currencyCode
			) {
				await refetchStripePriceList({
					currencyCode: data.user?.activeService?.getStripeUpgradePrice?.currencyCode,
				});
			}

			return data.user?.activeService?.getStripeUpgradePrice;
		},

		// (!) deps array - ok
		// eslint-disable-next-line
		[
			business_variant,
			isBusinessModel01,
			isPro23A,
			// refetchStripePriceList,
			selectedPeriod,
			split,
			stripePriceList?.currencyCode,
			stripePricingPlan?.responseCollectionLimit,
			type,
			upgradeToPlan,
		],
	);

	const stripe = useStripe();

	const payNow = useCallback(
		async ({ responses }) => {
			try {
				return stripeUpgrade({
					input: {
						saleSource,
						plan:
							type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES
								? null
								: isBusinessModel01
									? upgradeToPlan
									: isPro23A
										? `${split[0]}${business_variant}${split[1]}`
										: PLANS_OLD[upgradeToPlan.toUpperCase()] || upgradeToPlan,
						period: type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES ? null : selectedPeriod,
						...(!isBusinessModel01 ||
						Number(responses) === stripePricingPlan?.responseCollectionLimit
							? {}
							: { responseCollectionLimit: Number(responses) }),
					},
				});
			} catch (e) {
				setLoading(false);
			}
		},
		[
			business_variant,
			isBusinessModel01,
			isPro23A,
			saleSource,
			selectedPeriod,
			split,
			stripePricingPlan?.responseCollectionLimit,
			stripeUpgrade,
			type,
			upgradeToPlan,
		],
	);

	const planName = stripePricingPlan?.planName;

	const displayCardErrorMsg = useCallback(() => {
		Notifications.add({
			variant: 'danger',
			children: <FormattedHTMLMessage id="app.stripe.payment-unsuccessful-msg" />,
			howLong: null,
		});
		setLoading(false);
	}, []);

	return (
		<ApolloConsumer>
			{(client) => {
				const onSubmit = async (fields) => {
					setLoading(true);

					if (window.env.GOOGLE_ANALYTICS === 'true') {
						gtag('event', 'checkout_begin', {
							checkout_type: (() => {
								switch (true) {
									case !nextBilling:
										return 'buy-plan';
									case type === UPGRADE_MODAL_TYPES.UPGRADE_PLAN:
										return 'upgrade-plan';
									case type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES:
										return 'increase-responses';
								}
							})(),
						});
					}

					if (nextBilling) {
						try {
							const res = await payNow(fields);
							let state = {};
							let delay = 0;
							if (res.stripeUpgrade.actionRequiredSecret) {
								const paymentRes = await stripe.confirmCardPayment(
									res.stripeUpgrade.actionRequiredSecret,
								);
								state =
									paymentRes?.paymentIntent?.status !== 'succeeded' ? { paymentError: true } : {};
								delay = paymentRes?.paymentIntent?.status === 'succeeded' ? 1000 : 0;
							}
							setTimeout(() => {
								navigate(location.pathname, state);
								close();
								if (!state?.paymentError) {
									window.location.reload();
								}
							}, delay);
						} catch (e) {
							displayCardErrorMsg();
						}
					} else {
						await goToStripeCheckout(client, fields);
					}
				};

				const showLeftPanel =
					isPro23A ||
					(stripePricingPlan?.monthlyAmount &&
						(stripePricingPlan?.responseCollectionLimit || period !== PERIOD.YEARLY));

				return (
					<Modal.Content center width="100%" className={s.modalContent} prev={prev}>
						<Loader visible={apiLoading} />
						<form className={s.content} onSubmit={handleSubmit(onSubmit)}>
							{showLeftPanel && (
								<LeftPanel
									intl={intl}
									plan={planName}
									type={type}
									register={register}
									responsePackages={responsePackages}
									sale={getSale()}
									actualPlan={actualPlan}
									actualPeriod={actualPeriod}
									actualLimit={actualLimit}
									period={period}
									isTrial={isTrial}
									stripePricingPlan={stripePricingPlan}
									currencyCode={stripePriceList?.currencyCode}
									upgradeToPlan={upgradeToPlan}
									activePro={activePro}
									isPro23A={isPro23A}
									stripePriceList={stripePriceList}
								/>
							)}

							<RightPanel
								intl={intl}
								plan={planName}
								type={type}
								actualPlan={actualPlan}
								isTrial={isTrial}
								onlyChildren={showLeftPanel}
								register={register}
								responsePackage={
									responsePackages.find((p) => p.quantity === Number(selectedLimit)) || {
										quantity: stripePricingPlan?.responseLimit,
									}
								}
								currencyCode={stripePriceList?.currencyCode}
								selectedPeriod={selectedPeriod}
								planPrice={
									upgradeToPlan === actualPlan && period === OPS[actualPeriod]
										? 0
										: stripePricingPlan?.[`${selectedPeriod.toLowerCase()}Amount`]
								}
								seats={stripePricingPlan?.seatsDefault || 1}
								loading={loading}
								nextBilling={nextBilling}
								apolloClient={client}
								getUpdatedPrice={getUpdatedPrice}
								setLoading={setLoading}
							/>
						</form>
					</Modal.Content>
				);
			}}
		</ApolloConsumer>
	);
};

UpgradeSlide.propTypes = {
	actualLimit: PropTypes.number,
	actualPeriod: PropTypes.number,
	actualPlan: PropTypes.string,
	apiLoading: PropTypes,
	close: PropTypes.func,
	navigate: PropTypes.func,
	intl: PropTypes.object,
	isTrial: PropTypes.bool,
	nextBilling: PropTypes.object,
	period: PropTypes.oneOf([PERIOD.MONTHLY, PERIOD.YEARLY]),
	prev: PropTypes.string,
	refetchStripePriceList: PropTypes.func,
	stripePriceList: PropTypes.shape({
		plans: PropTypes.array,
		currencyCode: PropTypes.string,
	}),
	stripeUpgrade: PropTypes.func,
	type: PropTypes.oneOf([UPGRADE_MODAL_TYPES.UPGRADE_PLAN, UPGRADE_MODAL_TYPES.INCREASE_RESPONSES]),
	upgradeToPlan: PropTypes.string,
	isBusinessModel01: PropTypes.bool,
	location: PropTypes.object,
};

export default compose(
	memo,
	withRouter,
	injectIntl,
	query('stripe', {
		fragments: ['Prices'],
		mapProps: ({ stripePriceList }) => ({
			isBusinessModel01: stripePriceList?.experiment === PRICING_EXPERIMENT.AB_BUSINESS_MODEL_01,
		}),
	}),
)(UpgradeSlide);
