import gql from 'graphql-tag';

export default gql`
	mutation addSurveyDashboardAnswerFilter($input: SurveyDashboardAnswerFilterInput!) {
		addSurveyDashboardAnswerFilter(input: $input) {
			id
			questionId
			questionOrdinal
			answers {
				answer
				answerOption
				customAnswer
				answerTextValue
			}
		}
	}
`;
