import React, { useCallback } from 'react';
import { compose } from 'redux';
import withRouter from '~/hoc/withRouter';
import query from '~/cache/query';
import mutation from '~/cache/mutation';
import { PRICING_EXPERIMENT } from '~/constants/experiments';

const withUpgradeModal =
	(Component) =>
	({ setUpgradeModal, ...props }) => {
		const openStripeUpgradeModal = useCallback(
			(parameters) => setUpgradeModal({ isOpen: true, ...parameters }),
			[setUpgradeModal],
		);
		const closeStripeUpgradeModal = useCallback(
			() =>
				setUpgradeModal({
					isOpen: false,
					upgradeType: null,
					period: null,
					plan: null,
					salesVariant: null,
					iframeProps: null,
					saleSource: null,
				}),
			[setUpgradeModal],
		);
		return (
			<Component
				{...props}
				openStripeUpgradeModal={openStripeUpgradeModal}
				closeStripeUpgradeModal={closeStripeUpgradeModal}
			/>
		);
	};

export default compose(
	withRouter,
	mutation('setUpgradeModal'),
	query('stripeModal', {
		mapProps: ({ stripeModal }) => ({
			isUpgradeModalOpen: stripeModal?.upgradeModalData?.isOpen,
		}),
	}),
	query('stripe', {
		fragments: ['Prices'],
		mapProps: ({ stripePriceList }) => ({
			isBusinessModel01: stripePriceList?.experiment === PRICING_EXPERIMENT.AB_BUSINESS_MODEL_01,
		}),
	}),
	withUpgradeModal,
);
