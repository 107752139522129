import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import Notifications from '~/survio-ui/Notifications';
import Spinner from '~/survio-ui/Spinner';
import Modal from '~/survio-ui/Modal';
import { Typography, Dropdown, Checkbox, Button } from '@survio/ui';
import Features from './Feedback';
import { withSendTracking } from '~/hoc';
import mutation from '~/cache/mutation';

import styles from './style.less';

const IncreaseTeamSize = ({
	onClose,
	close,
	intl,
	getAmount,
	planInfo,
	activeService,
	increaseSeats,
	sendTracking,
}) => {
	const [sending, setSending] = useState();
	const { watch, handleSubmit, register, errors } = useForm({ defaultValues: { quantity: 1 } });
	const quantity = watch('quantity');
	const seatsYouHave = planInfo?.seatsDefault + activeService?.nextBillingSeatsCount;
	const seatsYouWant = seatsYouHave + quantity;
	const additionalSeats = seatsYouHave - planInfo?.seatsDefault;

	const handleOnSubmit = useCallback(
		({ quantity }) => {
			sendTracking(481, 'team_click_upsell_feature_seats_pay_now');
			setSending(true);
			increaseSeats({ quantity })
				.then(close)
				.then(() =>
					Notifications.add({
						type: 'success',
						content: intl.formatMessage({
							id: 'app.invitation.team-size-increased',
						}),
					}),
				)
				.then(() => setTimeout(() => window.location.reload(), 3000))
				.catch(() => setSending(false));
		},
		[close, increaseSeats, intl, sendTracking],
	);

	const { onChange, ...restRegister } = register('quantity', { valueAsNumber: true });

	return (
		<Spinner visible={sending} flex>
			<Modal.Content
				maxWidth={920}
				fullPageTo={839}
				padding={false}
				closeColor="grey"
				closeOnEsc={false}
				closeOnOutsideClick={false}
				classNameClose={styles.cross}
				className={styles.increaseTeamSize}
				close={() => {
					onClose?.();
					close();
				}}
			>
				<Modal.Body>
					<form className={styles.container} onSubmit={handleSubmit(handleOnSubmit)}>
						<div className={styles.content}>
							<div className={styles.title}>
								{intl.formatMessage({ id: 'app.multiuser.increase-team-size' })}
							</div>

							<div className={styles.flex}>
								<div className={styles.flex}>
									<Typography
										variant="textSemibold300"
										color="grey_shades_with_blue[600]"
										className={styles.additionalSeats}
									>
										{intl.formatMessage({ id: 'app.upgrade.buy-additional-seats' })}
									</Typography>

									<Dropdown
										size="sm"
										width={154}
										menuMaxHeight={200}
										items={Array.from(Array(45)).map((item, i) => ({
											id: i + 1,
											label: i + 1,
										}))}
										onChangeHookForm={onChange}
										{...restRegister}
									/>
								</div>

								<span className={styles.amount}>
									{`${getAmount(
										planInfo?.yearSeatPrice * quantity,
										planInfo?.currencyCode,
									)} / ${intl.formatMessage({ id: 'app.account.year' })}`}
								</span>
							</div>

							<div className={styles.section}>
								<div className={styles.half}>
									<Typography variant="title300" className={styles.sectionTitle}>
										{intl.formatMessage({ id: 'app.account.you-have' })}
									</Typography>

									<Typography variant="textRegular300" color="grey_shades_with_blue[500]">
										{seatsYouHave} {intl.formatMessage({ id: 'app.multiuser.seats-2' })} <br />
										{intl.formatMessage(
											{ id: 'app.upgrade.price-per-year' },
											{
												PRICE: getAmount(
													planInfo?.yearSeatPrice * additionalSeats,
													planInfo?.currencyCode,
												),
											},
										)}
									</Typography>
								</div>

								<div className={styles.half}>
									<Typography variant="title300" className={styles.sectionTitle}>
										{intl.formatMessage({ id: 'app.upgrade.you-want' })}
									</Typography>

									<Typography variant="textRegular300" color="grey_shades_with_blue[500]">
										{seatsYouWant} {intl.formatMessage({ id: 'app.multiuser.seats-2' })} <br />
										{intl.formatMessage(
											{ id: 'app.upgrade.price-per-year' },
											{
												PRICE: getAmount(
													planInfo?.yearSeatPrice * (additionalSeats + quantity),
													planInfo?.currencyCode,
												),
											},
										)}
									</Typography>
								</div>
							</div>

							<div className={styles.flex}>
								<Typography variant="textSemibold300">
									{intl.formatMessage({ id: 'app.upgrade.charge-till-next-renewal' })}
								</Typography>

								<div className={styles.totalPrice}>
									{getAmount(
										Math.ceil(
											(planInfo?.yearSeatPrice / 365) *
												quantity *
												(moment(activeService?.nextBilling.date).diff(moment(), 'days') + 1),
										),
										planInfo?.currencyCode,
									)}
								</div>
							</div>

							<div className={styles.flex}>
								<Typography variant="textRegular200" color="grey_shades_with_blue[400]">
									{intl.formatMessage(
										{ id: 'app.upgrade.full-charge-msg' },
										{
											DATE: moment(activeService?.nextBilling.date).format('l'),
											PRICE: getAmount(
												activeService?.nextBilling.amount + planInfo?.yearSeatPrice * quantity,
												planInfo?.currencyCode,
											),
										},
									)}
								</Typography>

								<Typography variant="textRegular200" color="grey_shades_with_blue[400]">
									({intl.formatMessage({ id: 'app.upgrade.excl-vat' })})
								</Typography>
							</div>

							<div className={styles.divider} />

							<Checkbox
								size="sm"
								error={errors.checkbox}
								className={styles.checkbox}
								{...register('checkbox', {
									required: intl.formatMessage({ id: 'app.multiuser.required-field' }),
								})}
							>
								{intl.formatMessage({ id: 'app.upgrade.confirmation-text' })}
							</Checkbox>

							<div className={styles.footer}>
								<Button type="submit" size="xl">
									{intl.formatMessage({ id: 'app.upgrade.pay-now' })}
								</Button>
							</div>
						</div>

						<Features />
					</form>
				</Modal.Body>
			</Modal.Content>
		</Spinner>
	);
};

IncreaseTeamSize.propTypes = {
	onClose: PropTypes.func,
	close: PropTypes.func,
	intl: PropTypes.object,
	getAmount: PropTypes.func,
	planInfo: PropTypes.object,
	activeService: PropTypes.object,
	increaseSeats: PropTypes.func,
	sendTracking: PropTypes.func,
};

export default compose(
	memo,
	injectIntl,
	mutation('increaseSeats'),
	withSendTracking,
)(IncreaseTeamSize);
