import styled from '@emotion/styled';
import { BubbleChildren } from '~/ui/components/Bubble';

export const StyledAnimationWrapper = styled.div<{ width: string; left: string }>`
	height: 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;

	position: relative;
	width: ${({ width }) => width};
	left: ${({ left }) => left};

	background: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.shadows[400]};
	outline: ${({ theme }) => `1px solid ${theme.colors.outline[10]}`};
	border-radius: 20px;
`;

export const StyledAddBar = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	padding: 26px 22px 22px; // -2px margin of all StyledQuestionGroup

	${BubbleChildren} {
		width: 100%;
	}
`;

export const StyledQuestionGroup = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	margin: 2px;

	> .title {
		height: 20px;
		margin: 4px 0 8px 8px;
		text-align: left;
		${({ theme }) => theme.typography.textMedium300};
	}

	> .items {
		display: flex;
		flex-direction: column;
	}
`;

export const StyledQuestion = styled.div`
	max-width: 216px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	padding: 8px;
	gap: 10px;
	border-radius: 8px;
	cursor: pointer;
	text-align: left;
	color: ${({ theme }) => theme.colors.grey_shades_with_blue[600]};
	${({ theme }) => theme.typography.textRegular300};

	:hover {
		background: ${({ theme }) => theme.colors.grey_shades_with_blue[25]};
		color: ${({ theme }) => theme.colors.black[100]};
	}

	.label {
		margin-top: 2px;
	}

	.icon {
		position: relative;

		&:before {
			z-index: 1;
		}

		&:after {
			z-index: 0;
			top: 2px;
			left: 2px;
		}
	}
`;
