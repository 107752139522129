import { createStore, applyMiddleware } from 'redux';

import rootReducer from '../reducers';

const createStoreWithMiddleware = () => {
	const middlewares = [];

	// if (__DEV__) {
	// 	return require('redux-devtools-extension').composeWithDevTools(applyMiddleware(...middlewares))(
	// 		createStore,
	// 	);
	// }

	return applyMiddleware(...middlewares)(createStore);
};

const configureStore = (initialState) =>
	createStoreWithMiddleware()(
		rootReducer,
		initialState,
		window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
	);

export default configureStore;
