import gql from 'graphql-tag';

export default gql`
	mutation addSurveyDashboardResponseDurationFilter(
		$input: SurveyDashboardResponseDurationFilterInput!
	) {
		addSurveyDashboardResponseDurationFilter(input: $input) {
			id
			type
			minimum
			maximum
		}
	}
`;
