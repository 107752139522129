import gql from 'graphql-tag';

export default gql`
	query user(
		$freeMonths: Int
		$extendedFirstPeriod: Boolean
		$period: PremiumServicePeriod
		$type: PremiumServiceType
		$additionalSeatsCount: Int
		$saleSource: SaleSourceInput
	) {
		user {
			getStartSubscriptionUrl(
				freeMonths: $freeMonths
				extendedFirstPeriod: $extendedFirstPeriod
				period: $period
				type: $type
				additionalSeatsCount: $additionalSeatsCount
				saleSource: $saleSource
			)
		}
	}
`;
