import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import Icon from '../Icon';
import { noop } from '../utils';

import styles from './style.less';

const Back = ({ className, text, onClick, intl }) => (
	<div className={classNames(styles.back, className)} onClick={onClick}>
		<Icon name="left-arrow" className={styles.icon} />
		{intl.formatMessage({ id: text })}
	</div>
);

Back.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	text: PropTypes.string,
};

Back.defaultProps = {
	text: 'app.common.back',
	onClick: noop,
	className: '',
};

export default injectIntl(Back);
