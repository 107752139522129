import React, { FC, forwardRef, memo, RefObject } from 'react';
import { RadioButtonProps, RadioButtonPropsBase, StyledRadio, Checkmark } from './';
import { Label } from '../Label';

export const RadioButton: FC<RadioButtonProps> = forwardRef<HTMLElement, RadioButtonPropsBase>(
	(
		{
			checked,
			className,
			children,
			invalid,
			disabled,
			withBox,
			'data-test-id': testId,
			defaultChecked,
			...props
		}: RadioButtonPropsBase,
		ref: RefObject<HTMLInputElement>,
	) => (
		<StyledRadio
			checked={checked}
			className={className}
			disabled={disabled}
			withBox={withBox}
			data-testid="ui-radio"
			data-test-id={testId}
		>
			<input
				type="radio"
				checked={checked}
				ref={ref}
				disabled={disabled}
				defaultChecked={defaultChecked}
				{...props}
			/>
			<div className="radio--background">
				<Checkmark className="radio--checkmark" disabled={disabled} invalid={invalid} />
				<Label className="radio--label" disabled={disabled} labelTypo="textMedium300" {...props}>
					{children}
				</Label>
			</div>
		</StyledRadio>
	),
);

RadioButton.displayName = 'RadioButton';

export default memo(RadioButton);
