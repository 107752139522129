import React, { ReactNode, FC, forwardRef, memo, RefAttributes, RefObject } from 'react';
import styled from '@emotion/styled';
import { ThemeType } from '../ThemeProvider/theme';
import { Typography } from '../Typography';

export type PlaceholderProps = {
	children?: ReactNode;
	className?: string;
	subtitle?: string;
	svg?: ReactNode;
	theme?: ThemeType;
	title?: string;
	width?: number | string;
};

const StyledWrapper = styled.div<PlaceholderProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 448px;
	width: ${({ width }) =>
		typeof width === 'string' ? width : typeof width === 'number' ? `${width}px` : 'auto'};
`;

const SvgWrapper = styled.div`
	margin-bottom: 16px;
`;

const Title = styled(Typography)`
	margin-bottom: 8px;
`;

export type PlaceholderWithRefProps = PlaceholderProps & RefAttributes<HTMLDivElement>;

export const Placeholder: FC<PlaceholderWithRefProps> = forwardRef<
	HTMLDivElement,
	PlaceholderProps
>(
	(
		{ className, children, title, subtitle, width, svg }: PlaceholderProps,
		ref: RefObject<HTMLDivElement>,
	) => (
		<StyledWrapper className={className} data-testid="ui-Placeholder" ref={ref} width={width}>
			<SvgWrapper>{svg}</SvgWrapper>
			{title && (
				<Title variant="textMedium600" color="grey_shades_with_blue[500]">
					{title}
				</Title>
			)}
			{subtitle && (
				<Typography variant="textRegular300_22" color="grey_shades_with_blue[300]">
					{subtitle}
				</Typography>
			)}
			{children}
		</StyledWrapper>
	),
);

Placeholder.displayName = 'Placeholder';

Placeholder.defaultProps = {
	width: '100%',
};

export default memo(Placeholder) as FC<PlaceholderWithRefProps>;
