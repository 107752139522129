import gql from 'graphql-tag';

export default gql`
	mutation copySurveyDashboard($input: CopySurveyDashboardInput!) {
		copySurveyDashboard(input: $input) {
			id
			title
			createdAt
			sharing {
				enabled
			}
		}
	}
`;
