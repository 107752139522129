import gql from 'graphql-tag';

export default gql`
	fragment isTrial on User {
		activeService {
			isTrial
			trialSource
			activeTill
			activeSince
		}
	}
`;
