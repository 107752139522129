import React, { FC, forwardRef, memo, RefObject } from 'react';
import {
	StyledMultipleSwitchItem,
	MultipleSwitchItemProps,
	MultipleSwitchItemWithRefProps,
	ItemWrapper,
} from '.';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import { Typography } from '../Typography';

const MultipleSwitchItem: FC<MultipleSwitchItemWithRefProps> = forwardRef<
	HTMLInputElement,
	MultipleSwitchItemProps
>(
	(
		{
			id,
			icon,
			active,
			tooltip,
			onChangeHandler,
			label,
			value,
			disabled,
			onChange,
			onBlur,
			name,
			flex = 'same',
		}: MultipleSwitchItemProps,
		ref: RefObject<HTMLInputElement>,
	) => (
		<ItemWrapper
			disabled={disabled}
			active={active}
			onClick={!active && !disabled ? () => onChangeHandler(id) : null}
			flex={flex}
		>
			<input
				type="radio"
				name={name}
				ref={ref}
				value={id}
				checked={value ? active : undefined}
				onChange={onChange || (() => null)}
				onBlur={onBlur}
			/>
			<StyledMultipleSwitchItem active={active} className="item" disabled={disabled}>
				<Tooltip
					content={tooltip}
					placement="top"
					description
					disabled={!tooltip || disabled}
					className="tooltip"
					wrapperClassname="tooltipWrapper"
					offset={[0, 12]}
				>
					{icon ? <Icon name={icon} /> : <Typography>{label}</Typography>}
				</Tooltip>
			</StyledMultipleSwitchItem>
		</ItemWrapper>
	),
);

MultipleSwitchItem.displayName = 'MultipleSwitchItem';

export default memo(MultipleSwitchItem) as FC<MultipleSwitchItemWithRefProps>;
