import React from 'react';
import InAppWidget, { InAppWidgetProps } from '~/components/InAppWidget';

const InAppSlideIn = ({
	onClose,
	url,
	language,
	onLoad,
}: Omit<InAppWidgetProps, 'width' | 'height'>) => (
	<InAppWidget
		onClose={onClose}
		url={url}
		language={language}
		width={460}
		height={216}
		onLoad={onLoad}
	/>
);

export default InAppSlideIn;
