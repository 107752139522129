import gql from 'graphql-tag';

// shouldShowExtendedYearlyBusinessPromo is deprecated
// TODO: Use new property experiments

export default gql`
	fragment shouldShowExtendedYearlyBusinessPromo on User {
		shouldShowExtendedYearlyBusinessPromo
	}
`;
