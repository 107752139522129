/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, memo } from 'react';
import { ReactSVG } from 'react-svg';
import { LogoProps, StyledLogo } from './';

export const Logo: FC<LogoProps> = ({ className, color, text, ...props }: LogoProps) => {
	const noText = require(`./assets/logo.svg`);
	const withText = require(`./assets/textLogo.svg`);

	return (
		<StyledLogo color={color} className={className} text={text} {...props}>
			<ReactSVG src={text ? withText : noText} className="ui-logo-wrapper" />
		</StyledLogo>
	);
};

export default memo(Logo);
