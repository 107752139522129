import React, { FC, memo, useEffect, useState, createRef, useCallback, useRef } from 'react';
import {
	BorderedContent,
	ErrorType,
	ImageUploaderProps,
	StyledImageUploader,
	Placeholder,
	Controls,
} from './';
import { Icon } from '../Icon';
import { Loader } from '../Loader';
import { Menu } from '../Menu';
import { getImgProps } from '../../.';
import imageCompression from 'browser-image-compression';
import ModernControls from './components/ModernControls';

export const ImageUploader: FC<ImageUploaderProps> = ({
	accept,
	areYouSureLabel,
	className,
	contentClassName,
	disabled,
	deleteDescription,
	deleteLabel,
	fixedSize,
	icon,
	image,
	onChange,
	onError,
	limit,
	loadingg,
	maxHeight,
	maxWidth,
	modernControls,
	onClick,
	overLimitError,
	overLimitErrorSecondary,
	primaryText,
	replaceBtnLabel,
	resetFileInputAfterChange,
	secondaryText,
	validFileTypes,
	wrongFileError,
	wrongFileErrorSecondary,
	backgroundSize,
}: ImageUploaderProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<ErrorType>(undefined);
	const [bubble, setBubble] = useState<boolean>(false);

	const mini = maxHeight < 65;
	const withButtons = isLoading || !!image;

	const [width, setWidth] = useState<number>(mini ? maxHeight : maxWidth);
	const [height, setHeight] = useState<number>(maxHeight);

	const fileRef = createRef<HTMLInputElement>();
	const uploaderRef = useRef<HTMLDivElement>(null);

	useEffect(() => setIsLoading(loadingg), [loadingg]);
	useEffect(() => (image ? setIsLoading(false) : undefined), [image]);
	useEffect(() => {
		const img = new Image();
		img.src = image;
		img.onload = () => {
			const { width, height } = getImgProps(img, maxWidth, maxHeight);
			setWidth(width);
			setHeight(height);
		};
	}, [image, maxHeight, maxWidth]);

	const handleBubble = useCallback(() => setBubble(!bubble), [bubble]);

	const validFileType = useCallback(
		(file) => {
			if (validFileTypes.length === 0) return true;

			let valid = false;
			for (let i = 0; i < validFileTypes.length; i++) {
				valid = new RegExp(validFileTypes[i], 'i').test(file.name);
				if (valid) return true;
			}
			return false;
		},
		[validFileTypes],
	);

	const handleUpload = useCallback(
		async ({
			target: {
				files: [file],
			},
		}) => {
			setError(undefined);
			setIsLoading(true);
			const controller = new AbortController();
			const options = {
				signal: controller.signal,
				maxSizeMB: 2,
				maxWidthOrHeight: Math.max(maxWidth, maxHeight),
			};

			imageCompression(file, options)
				.then(function (compressedFile) {
					const reader = new FileReader();
					reader.readAsDataURL(compressedFile);
					reader.onloadend = async () => {
						try {
							await onChange({ url: reader.result, image: compressedFile });
						} catch {
							setIsLoading(false);
						}
						if (!fixedSize) {
							const img = new Image();
							img.src = URL.createObjectURL(compressedFile);
							img.onload = function () {
								const w = img.width;
								const h = img.height;
								const ratio = w / h;
								setWidth(Math.ceil(Math.min(img.width, img.width * ratio)));
								setHeight(Math.ceil(Math.min(img.height, img.height * ratio)));
							};
						}
					};
				})
				.catch(function () {
					setIsLoading(false);
				});
		},
		[maxWidth, maxHeight, fixedSize, onChange],
	);

	const handleOnClick = useCallback(
		(e) => {
			if (disabled || isLoading) return;
			if (e.target === uploaderRef.current && image) return;
			onClick && onClick(e);
			fileRef.current && fileRef.current.click();
		},
		[disabled, isLoading, image, onClick, fileRef],
	);

	const handleOnRemove = useCallback(() => {
		onChange && onChange({ removeImage: true });
		if (mini || width !== maxWidth) setWidth(mini ? maxHeight : maxWidth);
		if (height !== maxHeight) setHeight(maxHeight);
		fileRef.current.value = '';

		setBubble(false);
	}, [onChange, width, maxWidth, mini, maxHeight, height, fileRef]);

	const handleOnInputChange = useCallback(
		(e) => {
			const {
				target: {
					files: [file],
				},
			} = e;
			if (!file) return;

			const valid = validFileType(file);

			if (!valid) {
				setError('wrongFile');
				onError && onError('wrongFile');
				image && handleOnRemove();
			} else if (!limit || file.size <= 1024 ** 2 * limit) void handleUpload(e);
			else {
				setError('overLimit');
				onError && onError('overLimit');
				image && handleOnRemove();
			}
			if (resetFileInputAfterChange) e.target.value = '';
		},
		[validFileType, limit, handleUpload, resetFileInputAfterChange, onError, image, handleOnRemove],
	);

	return (
		<Menu
			size="sm"
			disabled={!image}
			placement="bottom-start"
			width="auto"
			doNotRender={!mini}
			items={[
				{
					id: '0',
					label: replaceBtnLabel,
					renderLeft: <Icon name="resend-16" color="grey_shades_with_blue[500]" />,
					onClick: handleOnClick,
				},
				{
					id: '1',
					label: deleteLabel,
					renderLeft: <Icon name="delete-16" color="grey_shades_with_blue[500]" />,
					onClick: handleOnRemove,
				},
			]}
		>
			<StyledImageUploader
				disabled={disabled || isLoading}
				error={!!error}
				image={image}
				className={className}
				maxHeight={height}
				maxWidth={width}
				withButtons={withButtons}
				data-testid="ui-imageUploader"
				bubble={bubble}
				mini={mini}
				loadingg={isLoading || loadingg}
				ref={uploaderRef}
			>
				<div className="border">
					<BorderedContent
						className={contentClassName}
						disabled={disabled || isLoading}
						isLoading={isLoading}
						error={!!error}
						image={image}
						maxHeight={height}
						maxWidth={width}
						onClick={!image ? handleOnClick : undefined}
						backgroundSize={backgroundSize}
						mini={mini}
						// ref={uploaderRef}
					>
						{isLoading && <Loader />}
						<Placeholder
							error={error}
							icon={icon}
							mini={mini}
							disabled={disabled}
							wrongFileError={wrongFileError}
							overLimitError={overLimitError}
							primaryText={primaryText}
							wrongFileErrorSecondary={wrongFileErrorSecondary}
							overLimitErrorSecondary={overLimitErrorSecondary}
							secondaryText={secondaryText}
							image={image}
							isLoading={isLoading}
						/>
						<div className="image" />
					</BorderedContent>
				</div>
				{modernControls ? (
					<ModernControls
						mini={mini}
						withButtons={withButtons}
						handleOnClick={handleOnClick}
						disabled={disabled}
						isLoading={isLoading}
						replaceBtnLabel={replaceBtnLabel}
						areYouSureLabel={areYouSureLabel}
						handleOnRemove={handleOnRemove}
						deleteLabel={deleteLabel}
						deleteDescription={deleteDescription}
						bubble={bubble}
						handleBubble={handleBubble}
					/>
				) : (
					<Controls
						mini={mini}
						withButtons={withButtons}
						handleOnClick={handleOnClick}
						disabled={disabled}
						isLoading={isLoading}
						replaceBtnLabel={replaceBtnLabel}
						areYouSureLabel={areYouSureLabel}
						handleOnRemove={handleOnRemove}
						deleteLabel={deleteLabel}
						deleteDescription={deleteDescription}
						bubble={bubble}
						handleBubble={handleBubble}
					/>
				)}
				{/* next line - fake input which ref is used for loading files */}
				<input
					ref={fileRef}
					type="file"
					accept={accept}
					onChange={handleOnInputChange}
					style={{ display: 'none' }}
				/>
			</StyledImageUploader>
		</Menu>
	);
};

ImageUploader.defaultProps = {
	accept: '.jpg, .jpeg, .png',
	icon: 'image-filled-24',
	validFileTypes: [/\.jp(e?)g/, /\.png$/],
	backgroundSize: 'cover',
};

export default memo(ImageUploader);
