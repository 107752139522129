import { graphql } from '@apollo/client/react/hoc';
import QUERY from '~/cache/queries/builder/survey';

export default graphql(QUERY, {
	options: (ownProps) => ({
		variables: { uid: ownProps.params.uid },
	}),
	props: ({ data: { loading, refetch } }) => ({
		loading,
		refetch,
	}),
});
