import styled from '@emotion/styled';
import { ChartTileProps } from '.';

export const StyledChartTile = styled.div<ChartTileProps>`
	position: relative;
	border-radius: 16px;
	border: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
	width: ${({ width }) =>
		typeof width === 'string' ? width : typeof width === 'number' ? `${width}px` : 'auto'};
	transition: all 200ms ease-out;
	cursor: ${({ empty }) => (empty ? 'default' : 'pointer')};
	&:hover {
		box-shadow: 0 0 16px ${({ empty }) => (empty ? 'transparent' : 'rgba(0, 19, 41, 0.16)')};
		${({ empty }) => (!empty ? 'border: 1px solid transparent' : undefined)};

		.editBubble {
			opacity: ${({ empty }) => (empty ? 0 : 1)};
		}
	}
`;

export const Content = styled.div<ChartTileProps>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 32px;
	box-sizing: border-box;
	text-align: left;
`;

export const EditBubble = styled.div<ChartTileProps>`
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 16px;
	position: absolute;
	transform: translate(-50%, -50%); // IE11
	top: 50%;
	left: 50%;
	padding: 20px;
	opacity: 0;
	transition: all 200ms ease-out;
	color: ${({ theme }) => theme.colors.white};
	display: inline-flex;
	z-index: 5;

	&:hover {
		background-color: rgba(0, 0, 0, 0.9);
	}
`;

export const BottomBar = styled.div<ChartTileProps>`
	display: inline-flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	.menu {
		min-width: 192px;
	}
`;

export const Stretcher = styled.div<ChartTileProps>`
	padding-bottom: ${({ height }) =>
		typeof height === 'string' ? height : typeof height === 'number' ? `${height}px` : '100%'};
	min-height: ${({ minHeight }) =>
		typeof minHeight === 'string'
			? minHeight
			: typeof minHeight === 'number'
				? `${minHeight}px`
				: '100%'};
`;
