import React, { PureComponent } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Icon from '../Icon';
import styles from './styles.less';

class Notification extends PureComponent {
	componentDidMount() {
		const { timeout } = this.props;
		setTimeout(this.handleClose, timeout);
	}

	handleClose = () => this.props.close(this.props.id);

	render() {
		const { content, type } = this.props;
		return (
			<div
				onClick={this.handleClose}
				className={classNames(styles.notification, styles.default, styles[type])}
			>
				<div className={styles.content}>
					{content}
					<Icon className={styles.close} name="cancel-music" />
				</div>
			</div>
		);
	}
}

Notification.propTypes = {
	close: PropTypes.func.isRequired,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
	id: PropTypes.string.isRequired,
	timeout: PropTypes.string,
	type: PropTypes.oneOf(['default', 'success', 'danger', 'warning']),
};

Notification.defaultProps = {
	timeout: '3000',
	type: 'default',
};

export default Notification;
