import gql from 'graphql-tag';

export default gql`
	fragment invitation on User {
		invitation {
			id
			name
			invitedBy {
				name
				email
			}
		}
	}
`;
