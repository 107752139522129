const getAppendToElement = (appendTo, element, position = 'bottom', difference = 0) => {
	const appendToRect = appendTo.getBoundingClientRect();
	const elementRect = element.getBoundingClientRect();
	const offsetLeft = window.pageXOffset + appendToRect.left;

	switch (position) {
		case 'bottom':
			return {
				top: appendToRect.top + appendToRect.height + difference,
				left: offsetLeft + (appendToRect.width - elementRect.width) / 2,
			};
		case 'top':
			return {
				top: appendToRect.top - elementRect.height - difference,
				left: offsetLeft + (appendToRect.width - elementRect.width) / 2,
			};
		case 'left':
			return {
				top: appendToRect.top + appendToRect.height / 2 - elementRect.height / 2,
				left: offsetLeft - elementRect.width - difference,
			};
		case 'right':
			return {
				top: appendToRect.top + appendToRect.height / 2 - elementRect.height / 2,
				left: offsetLeft + appendToRect.width + difference,
			};
	}
};

export default { getAppendToElement };
