import gql from 'graphql-tag';

export default gql`
	query stripeModal {
		stripeModal @client {
			upgradeModalData {
				isOpen
				upgradeType
				period
				plan
				salesVariant
				iframeProps
				saleSource
			}
		}
	}
`;
