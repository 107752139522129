import React, { Component, memo } from 'react';
import Transition from 'react-transition-group/Transition';
import { TweenLite } from 'gsap';
import PropTypes from 'prop-types';
import styles from './MainLoader.less';
import { Typography } from 'ui';

import image from './assets/loader.gif';
import logo from './assets/logo.svg';

class MainLoader extends Component {
	static propTypes = {
		isVisible: PropTypes.bool,
		text: PropTypes.string,
	};
	static defaultProps = {
		isVisible: false,
	};

	loaderRef = React.createRef();

	handleOpen = () => {
		TweenLite.to(this.loaderRef.current, 0.4, {
			autoAlpha: 1,
		});
	};

	handleClose = () => {
		TweenLite.to(this.loaderRef.current, 0.4, {
			delay: 0.3,
			autoAlpha: 0,
		});
	};

	render() {
		const { text } = this.props;
		return (
			<Transition
				in={this.props.isVisible}
				onEnter={this.handleOpen}
				onExit={this.handleClose}
				timeout={700}
				appear
			>
				<div
					ref={this.loaderRef}
					className={styles.loader}
					style={{ backgroundImage: `url(${logo})` }}
				>
					<div className={styles.content}>
						<div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
						<Typography
							variant="textRegular250"
							color="grey_shades_with_blue[400]"
							className={styles.text}
						>
							{text}
						</Typography>
					</div>
				</div>
			</Transition>
		);
	}
}

export default memo(MainLoader);
