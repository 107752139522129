import React, { FC, memo, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Icon, Spacer } from 'ui';

export type AiLabelProps = {
	className?: string;
	children?: ReactNode;
	onClick?: () => void;
};

const StyledAiLabel = styled.div<AiLabelProps>`
	position: relative;
	border-radius: 8px;
	background: ${({ theme }) => theme.colors.primary[100]};
	padding: 6px 8px;
	display: inline-flex;
	align-items: center;
	${({ theme }) => theme.typography.textRegular300};
	color: ${({ theme }) => theme.colors.primary[285]};
	cursor: pointer;

	:hover {
		background: ${({ theme }) => theme.colors.primary[150]};
	}
`;

export const AiLabel: FC<AiLabelProps> = ({ children, ...props }: AiLabelProps) => (
	<StyledAiLabel {...props}>
		<Icon name="ai-16" color="primary[285]" />
		<Spacer width={8} />
		{children}
	</StyledAiLabel>
);

export default memo(AiLabel) as FC<AiLabelProps>;
