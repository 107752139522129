import React, { FC, memo } from 'react';
import Button from '../../Button';
import Bubble from '../../Bubble';
import { Tooltip } from '../../Tooltip';
import { ControlsProps } from '../index';
import { BubbleContent, ImageMenuStyled } from './Controls.styled';
import Typography from '../../Typography';
import { useIntl } from 'react-intl';

const ModernControls: FC<ControlsProps> = ({
	mini,
	withButtons,
	handleOnClick,
	disabled,
	isLoading,
	areYouSureLabel,
	handleOnRemove,
	deleteLabel,
	deleteDescription,
	bubble,
	handleBubble,
}: ControlsProps) => {
	const intl = useIntl();

	return !mini ? (
		<ImageMenuStyled visible={withButtons}>
			<Tooltip
				dark
				placement="top"
				description
				offset={[0, 12]}
				content={intl.formatMessage({ id: 'app.common.replace' })}
			>
				<Button
					variant="icon"
					size="sm"
					startIcon="Replace-16"
					onClick={handleOnClick}
					disabled={disabled || isLoading}
				/>
			</Tooltip>
			<Bubble
				content={
					<BubbleContent>
						<Typography
							variant="textRegular300"
							color="grey_shades_with_blue[800]"
							className="sure-label"
						>
							{areYouSureLabel}
						</Typography>
						<Button className="close-delete-button" color="red" size="sm" onClick={handleOnRemove}>
							<Typography variant="textMedium300" color="red[700]">
								{deleteLabel}
							</Typography>
						</Button>
					</BubbleContent>
				}
				contentClassName="menu-content"
				placement="top"
				visible={bubble}
				variant="light"
				withArrow={true}
				onClickOutside={handleBubble}
			>
				<Tooltip
					dark
					placement="top"
					description
					offset={[0, 12]}
					content={deleteDescription}
					disabled={disabled || isLoading}
				>
					<Button
						variant="icon"
						size="sm"
						startIcon="delete-16"
						onClick={handleBubble}
						disabled={disabled || isLoading}
					/>
				</Tooltip>
			</Bubble>
		</ImageMenuStyled>
	) : null;
};

export default memo(ModernControls);
