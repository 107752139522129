import gql from 'graphql-tag';

export default gql`
	mutation teamInvitationAccept {
		teamInvitationAccept {
			success
			message
		}
	}
`;
