import gql from 'graphql-tag';

export default gql`
	fragment Country on User {
		email
		country {
			code
			name
			language
		}
	}
`;
