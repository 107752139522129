import React, { FC, memo, useCallback } from 'react';
import { Typography } from '../Typography';
import { TableWrapper, Tr, Th, Td } from './List.styled';
import { ListProps } from './';

/**
 * ~~Original Code: Tomas Bilina©~~
 * not anymore
 */
export const List: FC<ListProps> = ({
	active,
	className,
	columns,
	dataSource,
	onClickRow,
	onDoubleClickRow,
	onHoverRow,
	onBlurRow,
	withHeader,
	padding,
	striped,
}: ListProps) => {
	const getActiveRows = useCallback(
		(id) =>
			typeof active === 'string' || typeof active === 'number'
				? active === id
				: active?.includes(id),
		[active],
	);

	const handleHoverRow = useCallback(
		(index: number) => () => {
			onHoverRow && onHoverRow(index);
		},
		[onHoverRow],
	);

	const handleBlurRow = useCallback(() => {
		onBlurRow && onBlurRow(-1);
	}, [onBlurRow]);

	return (
		<TableWrapper className={className} data-testid="ui-list">
			<table onMouseLeave={handleBlurRow}>
				{withHeader && (
					<thead>
						<tr>
							{columns.map((column) => (
								<Th
									key={column?.dataIndex}
									width={column.width}
									textAlign={column.textAlign}
									padding={padding}
								>
									<Typography variant="textSemibold200" color="grey_shades_with_blue[700]">
										{column?.title}
									</Typography>
								</Th>
							))}
						</tr>
					</thead>
				)}
				<tbody>
					{dataSource.map((row, key) => (
						<Tr
							key={key}
							onClick={onClickRow ? () => onClickRow(row) : undefined}
							onDoubleClick={() => onDoubleClickRow(row)}
							padding={padding}
							striped={striped}
							active={getActiveRows(row.id)}
							onMouseEnter={handleHoverRow(key)}
						>
							{columns.map((column) => (
								<Td
									key={column?.dataIndex}
									width={column.width}
									textAlign={column.textAlign}
									padding={padding}
									className={column?.className}
								>
									{column.render ? column.render(row) : row[column?.dataIndex]}
								</Td>
							))}
						</Tr>
					))}
				</tbody>
			</table>
		</TableWrapper>
	);
};

List.displayName = 'List';

List.defaultProps = {
	withHeader: true,
	striped: true,
};

export default memo(List);
