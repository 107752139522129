import React, { PureComponent, memo } from 'react';
import PropTypes from 'prop-types';

import MainLoader from './MainLoader';

export const MainLoaderContext = React.createContext();

export const withMainLoader = (Component) =>
	class extends PureComponent {
		static contextType = MainLoaderContext;

		componentDidMount() {
			this.hideMainLoader();
		}

		componentDidUpdate() {
			this.hideMainLoader();
		}

		hideMainLoader = () =>
			!this.props.loading &&
			this.context.isVisibleLoader &&
			setTimeout(this.context.hideMainLoader);

		render() {
			return <Component {...this.props} />;
		}
	};

class MainLoaderProvider extends PureComponent {
	static propTypes = {
		isVisible: PropTypes.bool,
		children: PropTypes.element,
	};
	static defaultProps = {
		isVisible: true,
	};

	state = {
		tryToClose: false,
		canBeClosed: true,
		isVisibleLoader: false,
	};

	handleOpen = (text) => {
		this.setState({ text, isVisibleLoader: true, canBeClosed: false });
		setTimeout(() => {
			this.setState({ canBeClosed: true }, this.handleClose);
		}, 2000);
	};
	handleClose = () => {
		if (this.state.canBeClosed && this.state.tryToClose) {
			this.setState({ isVisibleLoader: false, canBeClosed: true, tryToClose: false });
		}
	};
	handleTryClose = () => {
		if (this.state.canBeClosed) {
			this.setState({ isVisibleLoader: false, canBeClosed: true, tryToClose: false });
		} else {
			this.setState({ tryToClose: true });
		}
	};

	render() {
		const { children } = this.props;
		const { isVisibleLoader, text } = this.state;

		return (
			<MainLoaderContext.Provider
				value={{
					isVisibleLoader,
					showMainLoader: this.handleOpen,
					hideMainLoader: this.handleTryClose,
				}}
			>
				<MainLoader isVisible={isVisibleLoader} text={text} />
				{children}
			</MainLoaderContext.Provider>
		);
	}
}

export default memo(MainLoaderProvider);
