import gql from 'graphql-tag';

export default gql`
	mutation addCompletenessFilter($uid: String!, $sysid: CompletenessEnum!) {
		addCompletenessFilter(uid: $uid, sysid: $sysid) {
			id
			type
		}
	}
`;
