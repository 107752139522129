import React, { FC, memo } from 'react';
import { NoteStyled, NoteLabelStyled } from '.';

export type NoteProps = {
	className?: string;
	children: React.ReactNode;
	label?: string;
};

const Note: FC<NoteProps> = ({ children, label, className }: NoteProps) => (
	<NoteStyled className={className}>
		{label ? <NoteLabelStyled>{label}</NoteLabelStyled> : null}
		{children}
	</NoteStyled>
);

export default memo(Note);
