import gql from 'graphql-tag';

export default gql`
	fragment IntroPageActive on Survey {
		title
		content {
			introPage {
				active
			}
		}
	}
`;
