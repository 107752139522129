import gql from 'graphql-tag';

export default gql`
	query publicUrl($uid: String!) {
		survey(uid: $uid) {
			uid
			publicUrl
		}
	}
`;
