import gql from 'graphql-tag';

export default gql`
	fragment DashboardExport on User {
		fullName
		activeService {
			name
		}
	}
`;
