import styled from '@emotion/styled';

export const ProgressDotsStyled = styled.div`
	display: flex;
	gap: 20px;
`;

export const ProgressDotStyled = styled.div<{ selected?: boolean }>`
	width: ${({ selected }) => (selected ? 18 : 8)}px;
	height: 8px;
	border-radius: 20px;
	background-color: ${({ theme: { colors }, selected }) =>
		selected ? colors.primary[800] : colors.grey_shades_with_blue[200]};
	transition: all 300ms ease-in-out;
`;
