import gql from 'graphql-tag';

export default gql`
	mutation setLoginPage($input: LoginPageInput!) {
		setLoginPage(input: $input) {
			success
			message
		}
	}
`;
