import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Loader, Icon, Tooltip } from 'ui';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import { FormattedHTMLMessage } from 'react-intl';
import moment from 'moment';
import { PERIOD } from '~/constants';
import { plural } from '~/utils';
import stripe from './assets/stripe.svg';
import UpgradeSlidePanelWrapper from './UpgradeSlidePanelWrapper';

import s from './style.less';

const RightPanel = ({
	isTrial,
	actualPlan,
	intl,
	plan,
	responsePackage,
	currencyCode,
	selectedPeriod,
	planPrice,
	seats,
	loading,
	apolloClient,
	getUpdatedPrice,
	setLoading,
	nextBilling,
	onlyChildren,
}) => {
	const formatPrice = (price) => (
		<Typography variant="textMedium300">
			{!price ? '' : intl.formatNumber(price, { currency: currencyCode, style: 'currency' })}
		</Typography>
	);

	const billedPeriod = (
		<Typography
			variant="textRegular100"
			color="grey_shades_with_blue[400]"
			className={s.with8BtmMargin}
		>
			{intl.formatMessage({
				id:
					selectedPeriod === PERIOD.MONTHLY
						? 'app.common.billed-monthly'
						: 'app.common.billed-annually',
			})}
		</Typography>
	);

	const isCardValid =
		moment(nextBilling?.cardExpiration, 'MM/YYYY').endOf('month').diff(moment(), 'days') > 0;
	const isUserWithSubscription = moment(nextBilling?.date).diff(moment()) > 0;
	const responsesPrice = responsePackage?.[`${selectedPeriod?.toLowerCase()}Amount`] || 0;
	const totalPrice = planPrice + responsesPrice;
	const selectedData = useRef(null);
	const [priceData, setPriceData] = useState({
		amount: 0,
		nextBillingDate: new Date(),
		nextBillingPrice: 0,
		currencyCode: 'EUR',
		taxRate: 0,
		subAmount: 0,
		prorateDiscount: 0,
	});

	useEffect(() => {
		if (isUserWithSubscription && responsePackage?.quantity && selectedPeriod) {
			setLoading(true);
			selectedData.current = {
				quantity: Number(responsePackage?.quantity),
				period: selectedPeriod,
			};
			getUpdatedPrice(apolloClient, {
				period: selectedPeriod,
				responses: responsePackage.quantity,
			})
				.then((result) => {
					// here needs to be this check for selected period and quantity
					// otherwise there could be a race condition
					if (
						responsePackage?.quantity === selectedData?.current.quantity &&
						selectedPeriod === selectedData.current.period
					) {
						setPriceData(result);
						setLoading(false);
					}
				})
				.catch(() => setLoading(false));
		}
	}, [
		apolloClient,
		getUpdatedPrice,
		isUserWithSubscription,
		responsePackage?.quantity,
		selectedPeriod,
		setLoading,
	]);

	return (
		<UpgradeSlidePanelWrapper
			isTrial={isTrial}
			actualPlan={actualPlan}
			onlyChildren={onlyChildren}
			title={<FormattedHTMLMessage id="app.stripe.subscribe-modal-title" values={{ PLAN: plan }} />}
		>
			<div className={s.right}>
				<Loader className={cn(s.loader, loading && s.visible)} />
				<Typography
					variant="textSemibold400"
					className={s.title}
					color="grey_shades_with_blue[350]"
				>
					{intl.formatMessage({ id: 'app.stripe.plan-overview' })}
				</Typography>
				<div className={s.row}>
					<Typography variant="textMedium300">{plan}</Typography>
					{formatPrice(planPrice)}
				</div>
				{billedPeriod}
				<div className={s.row}>
					<Typography variant="textMedium300" className={s.right16margin}>
						{`${intl.formatNumber(responsePackage?.quantity)} ${intl.formatMessage({
							id: 'app.designer.modal.service-sales-2',
						})}`}
					</Typography>
					{formatPrice(responsesPrice)}
				</div>
				{billedPeriod}
				<div className={cn(s.row, s.with8BtmMargin)}>
					<Typography variant="textMedium300">
						{seats}{' '}
						{plural({
							number: seats,
							stringsArray: ['app.common.user', 'app.common.users-1', 'app.common.users-2'],
						})}
					</Typography>
					{formatPrice(0)}
				</div>
				{isUserWithSubscription && (
					<>
						{!!(priceData.prorateDiscount || responsesPrice - priceData.subAmount) && (
							<div className={cn(s.row, s.with8BtmMargin)}>
								<Typography variant="textMedium300" className={s.flex}>
									{intl.formatMessage({ id: 'app.stripe.plan-discount-item' })}{' '}
									<Tooltip
										description
										placement="top"
										content={intl.formatMessage({ id: 'app.stripe.plan-discount-info' })}
									>
										<Icon name="help-16" color="grey_shades_with_blue[300]" />
									</Tooltip>
								</Typography>
								{formatPrice(
									(priceData.prorateDiscount || responsesPrice - priceData.subAmount) * -1,
								)}
							</div>
						)}
						<div className={s.row}>
							<Typography variant="textMedium300">
								{intl.formatMessage({ id: 'app.stripe.plan-subtotal-item' })}
							</Typography>
							{formatPrice(priceData.subAmount)}
						</div>
						<div className={s.separator} />
						<div className={s.row}>
							<Typography variant="textMedium300">
								{intl.formatMessage({ id: 'app.stripe.plan-tax-item' })} ({priceData.taxRate}%)
							</Typography>
							{formatPrice(priceData.amount - priceData.subAmount)}
						</div>
					</>
				)}
				<div className={cn(s.separator, !isUserWithSubscription && s.btm16Margin)} />
				<div className={cn(s.row, isUserWithSubscription ? s.btm24Margin : s.btm4Margin)}>
					<Typography variant="textSemibold600">
						{intl.formatMessage({ id: 'app.common.total' })}
					</Typography>
					<Typography variant="textSemibold600">
						{intl.formatNumber(isUserWithSubscription ? priceData.amount : totalPrice, {
							currency: currencyCode || priceData.currencyCode,
							style: 'currency',
						})}
					</Typography>
				</div>
				{!isUserWithSubscription && (
					<Typography
						variant="textRegular100"
						color="grey_shades_with_blue[350]"
						className={cn(s.btm32Margin, s.flexEnd)}
					>
						{intl.formatMessage({ id: 'app.upgrade.excl-vat-1' })}
					</Typography>
				)}
				{isUserWithSubscription && (
					<Typography
						variant="textRegular200"
						color="grey_shades_with_blue[500]"
						className={s.btm16Margin}
					>
						<ul className={s.list}>
							<li>
								<FormattedHTMLMessage
									id={'app.stripe.plan-current-payment-info'}
									values={{
										CHARGE_ADD: intl.formatNumber(priceData.amount, {
											currency: priceData.currencyCode,
											style: 'currency',
										}),
									}}
								/>
							</li>
							<li>
								<FormattedHTMLMessage
									id={
										selectedPeriod === PERIOD.MONTHLY
											? 'app.stripe.plan-payment-info-month'
											: 'app.stripe.plan-payment-info-year'
									}
									values={{
										AMOUNT: intl.formatNumber(priceData.nextBillingPrice, {
											currency: priceData.currencyCode,
											style: 'currency',
										}),
										DATE: moment(priceData.nextBillingDate).format('ll'),
									}}
								/>
							</li>
						</ul>
					</Typography>
				)}
				<Button
					fullWidth
					size="lg"
					type="submit"
					className={cn(isUserWithSubscription && s.btm16Margin)}
				>
					{intl.formatMessage({
						id: isCardValid ? 'app.stripe.pay-now' : 'app.common.continue',
					})}
				</Button>
				{isUserWithSubscription && (
					<Typography
						variant="textRegular200"
						color="grey_shades_with_blue[500]"
						className={s.payment}
					>
						<FormattedHTMLMessage id="app.stripe.payment-method-msg" />
					</Typography>
				)}
				<Typography variant="textRegular100" className={s.poweredBy}>
					<span>Powered by </span>
					<ReactSVG src={stripe} className={s.logo} />
				</Typography>
			</div>
		</UpgradeSlidePanelWrapper>
	);
};

RightPanel.propTypes = {
	isTrial: PropTypes.bool,
	actualPlan: PropTypes.string,
	apolloClient: PropTypes.object,
	currencyCode: PropTypes.string,
	getUpdatedPrice: PropTypes.func,
	intl: PropTypes.object,
	nextBilling: PropTypes.object,
	loading: PropTypes.bool,
	plan: PropTypes.string,
	planPrice: PropTypes.number,
	register: PropTypes.object,
	responsePackage: PropTypes.object,
	seats: PropTypes.number,
	selectedPeriod: PropTypes.oneOf([PERIOD.YEARLY, PERIOD.MONTHLY]),
	setLoading: PropTypes.func,
	onlyChildren: PropTypes.bool,
};

export default memo(RightPanel);
