import gql from 'graphql-tag';

export default gql`
	query stripePriceList($countryCode: String) {
		stripePriceList(countryCode: $countryCode) {
			plans {
				name
				plan
				planName
				identifier
				responseLimit
				invitationLimit
				responseCollectionLimit
				responseCollectionPackages {
					monthlyAmount
					quantity
					yearlyAmount
				}
				yearlyAmount
				monthlyAmount
				... on StripePriceProPlan {
					seatsDefault
					seatsMax
					yearlySeatAmount
				}
			}
			currencyCode
		}
	}
`;
