import gql from 'graphql-tag';

export default gql`
	fragment chartSettings on AnalyzeChartSettings {
		id
		type
		percentage
		order
		labels
		color {
			key
			list
		}
	}
`;
