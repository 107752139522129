import React, { FC, memo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FocusBubbleProps, StyledFocusBubble, FocusBubbleWrapper } from '.';
import Menu from '../Menu';

export const FocusBubble: FC<FocusBubbleProps> = ({
	children,
	className,
	content,
	visible,
	menuWidth,
}: FocusBubbleProps) => (
	<FocusBubbleWrapper className={className}>
		<CSSTransition in={visible} unmountOnExit classNames="fade" timeout={300}>
			<Menu placement="bottom-end" content={content} visible={visible} width={menuWidth}>
				<StyledFocusBubble className="cutout" />
			</Menu>
		</CSSTransition>
		{children}
		{visible && (
			<div
				className="notClickableBackground"
				style={{
					width: window.outerWidth,
					height: window.outerHeight,
				}}
			/>
		)}
	</FocusBubbleWrapper>
);

export default memo(FocusBubble) as FC<FocusBubbleProps>;
