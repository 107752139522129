import type { IconNameType } from '../Icon/IconNamesList';
import type { AddBarTooltipPictureIds } from './AddBarTooltip/AddBarTooltip.styled';
import { ColorType } from '~/ui/components/ThemeProvider/theme';

type IntlTranslation = string; // string to be translated or translated string

type Uuid = string; // uuid generated string

type Type = 'TEXT' | 'MATRIX_SINGLE_CHOICE' | 'MATRIX_MULTI_CHOICE' | 'MATRIX_TEXT';

type SubType = 'TEXT' | 'EMAIL' | 'NUMBER' | 'DATE';

type Layout = 'ROW' | 'SELECT';

export type QuestionConfigId = {
	__typename: string;
	type?: Type;
	subtype?: SubType;
	layout?: Layout;
	isMultipleAnswer?: boolean;
};

// Change order here to change render ordering;
export const QuestionGroups = ['CHOICE', 'TEXT', 'OTHER', 'ELEMENTS'] as const;
export const QuestionGroupsTitleVisibility = [true, false, false, true] as const;
// eslint-disable-next-line prettier/prettier
export const QuestionGroupsTitleTranslations = [
	'app.common.question-types',
	'',
	'',
	'app.common.elements',
] as const;

export type QuestionConfigUi = {
	iconName: IconNameType;
	iconColor: ColorType;
	iconTextColor?: ColorType;
	title: string;
	info: string;
	questionGroup: (typeof QuestionGroups)[number];
	tooltip: {
		description: IntlTranslation;
		picture: AddBarTooltipPictureIds;
	};
};

export type Col = {
	__typename: 'MatrixItem';
	_id: Uuid;
	text: string;
	ordinal: number;
};

export type Row = {
	__typename: 'SemanticDifferentialRow' | 'TextAnswer' | 'MatrixItem';
	_id: Uuid;
	text?: IntlTranslation;
	ordinal: number;
	leftLabel?: IntlTranslation;
	rightLabel?: IntlTranslation;
};

export type Picture = {
	__typename?: 'Picture';
	h?: number;
	url?: string;
	w?: number;
};

export type Choice = {
	__typename?: 'Choice';
	_id: Uuid;
	text: IntlTranslation;
	ordinal?: number;
	open?: boolean;
	picture?: Picture | null;
	quizPoints?: number;
};

export type QuestionConfig = QuestionConfigId & {
	text: IntlTranslation;
	ui: QuestionConfigUi;
	helpText?: IntlTranslation; // optional jen kvuli TextBlock sjednoti bud optional nebo ""
	required?: boolean;
	answered?: boolean;
	picture?: string | null;
	video?: string | null;
	minimumAnswers?: number;
	maximumAnswers?: number;
	cols?: Col[];
	rows?: Row[];
	pointCount?: number;
	units?: IntlTranslation;
	leftText?: IntlTranslation;
	rightText?: IntlTranslation;
	hasPictureChoices?: boolean;
	isMultipleAnswer?: boolean;
	choices?: Choice[];
	answerLengthLimit?: number;
	endpoints?: boolean;
};
