import React, { createContext, useState } from 'react';

// type InAppContentContext = {
// 	inAppContent: any;
// 	inAppContentIsOpen: boolean;
// 	setInAppContent: (inAppContent: any, isOpen: boolean) => void;
// };

export const InAppContentContext = createContext(null);

export const InAppContentProvider = ({ children }) => {
	const [inAppContentState, setInAppContentState] = useState({
		inAppContent: null,
		inAppContentIsOpen: false,
	});

	const setInAppContent = (inAppContent, isOpen) => {
		setInAppContentState({ inAppContent, inAppContentIsOpen: isOpen });
	};

	return (
		<InAppContentContext.Provider value={{ ...inAppContentState, setInAppContent }}>
			{children}
		</InAppContentContext.Provider>
	);
};

// export default InAppContentProvider;
