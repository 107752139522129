import gql from 'graphql-tag';

export default gql`
	mutation createDemoSurvey {
		createDemoSurvey {
			title
			uid
		}
	}
`;
