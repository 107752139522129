import MultipleSwitch, { MultipleSwitch as M } from './MultipleSwitch';
import MultipleSwitchItem from './MultipleSwitchItem';
import {
	StyledMultipleSwitch,
	StyledMultipleSwitchItem,
	ItemWrapper,
} from './MultipleSwitch.styled';
import * as Types from './MultipleSwitch.d';

export {
	M as MultipleSwitch,
	StyledMultipleSwitch,
	StyledMultipleSwitchItem,
	MultipleSwitchItem,
	ItemWrapper,
};
export type MultipleSwitchProps = Types.MultipleSwitchProps;
export type MultipleSwitchWithRefProps = Types.MultipleSwitchWithRefProps;
export type MultipleSwitchItemWithRefProps = Types.MultipleSwitchItemWithRefProps;
export type MultipleSwitchItemProps = Types.MultipleSwitchItemProps;
export default MultipleSwitch;
