import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone: string) => {
	try {
		return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
	} catch (error) {
		return false;
	}
};

export const isPhoneEmpty = (phone: string) => {
	try {
		// phoneUtil.parse throws an error (The string supplied did not seem to be a phone number) on short strings
		phoneUtil.parse(phone);
		return false;
	} catch (error) {
		return true;
	}
};
