import { useEffect, useRef } from 'react';

/**
 * Hook for use previous value of a variable
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
const usePrevious = <T>(value: T): T => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export default usePrevious;
