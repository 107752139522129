import gql from 'graphql-tag';

export default gql`
	fragment InvitationConnection on Survey {
		invitationConnection {
			totalCount
			sentThisMonth
			monthlyLimit
			edges {
				node {
					id
					title
					scheduledAt {
						date
						time
						timezone
					}
					reminderAt {
						date
						time
						timezone
					}
					recipientsTotal
					recipientsDispatched
					delivers
					opens
					clicks
					submits
				}
			}
		}
	}
`;
