import merge from 'lodash.merge';

import templates from './templates';
import designer from './designer';
import builder from './builder';
import lingua from './lingua';
import stripeUpgradeModal from './stripeUpgradeModal';
// import exampleMock from './exampleMock';

export default merge(
	templates,
	designer,
	builder,
	lingua,
	stripeUpgradeModal,
	/*, exampleMock*/
);
