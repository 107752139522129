import styled from '@emotion/styled';
import { TableProps, RowProps } from '.';

export const TableWrapper = styled.div<Pick<TableProps, 'theme'>>`
	width: 100%;
	overflow: hidden;
	border-radius: 10px;
	border: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};

	table {
		width: 100%;
	}
`;

export const Cell = styled.td<RowProps & { numOfColumns?: number }>`
	${({ justifyContent }) => `text-align: ${justifyContent?.replace('flex-', '')}`};
	${({ width, numOfColumns }) =>
		width
			? `width: ${typeof width === 'string' ? width : `${width}px`}; flex: 1 0 auto`
			: `width: ${(1 / numOfColumns) * 100}%`};
	box-sizing: border-box;
	height: ${({ size }) => (size === 'xl' ? 48 : 39)}px;
	padding: 0 ${({ size }) => (size === 'xl' ? 24 : 15)}px;
	overflow: hidden;
	vertical-align: middle;

	> div {
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

export const Row = styled.tr<Pick<TableProps, 'theme'>>`
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
	}
`;

export const Header = styled(Row)<TableProps>`
	background-color: ${({ theme }) => theme.colors.grey[200]};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
`;
