import gql from 'graphql-tag';

export default gql`
	fragment QuestionPreferences on Survey {
		question(id: $id) {
			id
			results {
				preferences
				}
			}
		}
	}
`;
