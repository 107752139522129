import gql from 'graphql-tag';

export default gql`
	mutation newInvitation($input: SurveyInvitationRequest!) {
		newInvitation(input: $input) {
			id
			title
		}
	}
`;
