import gql from 'graphql-tag';

export default gql`
	mutation setMySurveysViewStyle($viewStyle: ViewStyle, $orderBy: MySurveysOrderByInput) {
		setMySurveysViewStyle(viewStyle: $viewStyle, orderBy: $orderBy) {
			success
			message
		}
	}
`;
