import React, {
	FC,
	memo,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import cn from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useOnKeyPressed, useOutsideClick } from '../../hooks';
import Button from '../Button';
import Portal from '../Portal';
import { DialogProps, StyledDialog, Window, CloseButton, DialogAnimationWrapper } from './';

export const DialogContext = React.createContext(null);

function useDialog() {
	return useContext(DialogContext);
}

const DialogWindow: FC<DialogProps> = memo(
	({
		children,
		width,
		padding,
		minHeight,
		className,
		prev,
		id,
		'data-testid': testId,
	}: DialogProps) => {
		const { setPrev } = useContext(DialogContext);
		useEffect(() => {
			setPrev(prev);
		}, [prev, setPrev]);

		return (
			<Window
				className={cn('window', className)}
				width={width}
				padding={padding}
				minHeight={minHeight}
				id={id}
				data-testid={testId}
			>
				{children}
			</Window>
		);
	},
);

export const Dialog: FC<DialogProps> & {
	Window: FC<DialogProps>;
	useDialog: () => { setSlide: (slide: string) => void; setPrev: (slide: string) => void };
} = ({
	children,
	disabled,
	onClose,
	className,
	'data-testid': testId,
	hideClose,
	disableBackdropClick,
	...props
}: DialogProps) => {
	const dialogRef = useRef(null);
	const windowWrapperRef = useRef(null);
	useOnKeyPressed(27, onClose, hideClose); // 27 is keyCode for ESC
	useOutsideClick(windowWrapperRef, onClose, hideClose || disableBackdropClick);

	const [slide, setSlide] = useState(props.slide);
	const [prev, setPrev] = useState(null);

	useLayoutEffect(() => {
		if (prev === slide) {
			dialogRef.current.classList.add('prev');
		} else {
			dialogRef.current.classList.remove('prev');
		}
		// (!) dependencies mustn't include prev
		// eslint-disable-next-line
	}, [dialogRef, slide]);

	const handleOnClose = useCallback(() => {
		onClose && onClose();
	}, [onClose]);

	return (
		<Portal>
			<DialogContext.Provider value={{ setSlide, setPrev }}>
				<DialogAnimationWrapper>
					<CSSTransition in appear classNames="fade" timeout={200}>
						<StyledDialog ref={dialogRef} data-testid={testId} className={className}>
							<CSSTransition in appear classNames="scale" timeout={200}>
								<SwitchTransition mode="in-out">
									<CSSTransition key={slide || ''} classNames="slide" timeout={{ exit: 300 }}>
										<div className="animationWrapper">
											<div style={{ position: 'relative' }} ref={windowWrapperRef}>
												{onClose && !hideClose && (
													<CloseButton className="closeButton">
														<Button
															variant="icon"
															startIcon="close-medium-24"
															size="sm"
															onClick={handleOnClose}
															disabled={disabled}
															data-test-id="close_modal_button"
														/>
													</CloseButton>
												)}
												{typeof children === 'function' ? children(slide, setSlide) : children}
											</div>
										</div>
									</CSSTransition>
								</SwitchTransition>
							</CSSTransition>
						</StyledDialog>
					</CSSTransition>
				</DialogAnimationWrapper>
			</DialogContext.Provider>
		</Portal>
	);
};
DialogWindow.displayName = 'DialogWindow';

Dialog.Window = DialogWindow;
Dialog.useDialog = useDialog;

export default Dialog;
