import gql from 'graphql-tag';

export default gql`
	fragment QuestionsBreakdown on Survey {
		questions {
			id
			... on MatrixQuestion {
				type
			}
			results {
				... on ChoicedQuestionAggregate {
					breakdown {
						count
					}
				}
				... on StarRatingQuestionAggregate {
					breakdown {
						count
					}
				}
				... on NetPromoterQuestionAggregate {
					breakdown {
						count
					}
				}
				... on MatrixQuestionAggregate {
					rowResults {
						breakdown {
							count
						}
					}
				}
				... on DividePointsQuestionAggregate {
					results {
						sum
					}
				}
				... on ImportanceQuestionAggregate {
					results: breakdown {
						averagePoints
					}
				}
			}
		}
	}
`;
