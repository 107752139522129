import React, { FC, memo } from 'react';
import { ProgressDotsStyled, ProgressDotStyled, ProgressDotsProps } from '.';

export const ProgressDots: FC<ProgressDotsProps> = ({ total, current }) => (
	<ProgressDotsStyled>
		{[...Array(total)].map((_, i) => (
			<ProgressDotStyled key={i} selected={i === current} />
		))}
	</ProgressDotsStyled>
);

export default memo(ProgressDots);
