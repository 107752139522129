import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from '../utils';

import './font/flaticon.css';

const Icon = ({ className, name, onClick, reference }) => (
	<i className={classNames(`flaticon-${name}`, className)} onClick={onClick} ref={reference} />
);

Icon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	reference: PropTypes.object,
};

Icon.defaultProps = {
	className: '',
	onClick: noop,
};

export default memo(Icon);
