import React, { FC, forwardRef, useRef, useEffect, memo, useCallback } from 'react';

import { InputProps } from './';
import TextField from '../TextField/TextField';
import { multipleRefs, restrict, setNativeInputValue } from '../../utils';
import { NumberArrowsStyled, TextFieldRight } from '../TextField/TextField.styled';
import CharCounter from '../TextField/CharCounter/CharCounter';
import { Typography } from '../../index';
import Icon from '~/ui/components/Icon';

export const Input: FC<InputProps> = forwardRef((props: InputProps, ref) => {
	const inputRef = useRef(null);
	const placeholderRef = useRef(null);
	const {
		autoSize,
		autoFocus,
		disabled,
		name,
		type,
		value,
		onBlur,
		onFocus,
		onChange,
		editable,
		placeholder,
		renderRight,
		max,
		maxLength,
		withCounter,
		select,
		size,
		restriction,
		selectOnClick,
		onClick,
		'data-testid': dataTestId,
		min,
		numberArrows,
		id,
		pattern,
	} = props;

	React.useEffect(() => {
		const input = inputRef.current;
		const setSize = () => {
			let width: number | string;

			if (autoSize) {
				width = 0;
				if (placeholder && !input.value) {
					width = `${placeholderRef.current.scrollWidth + 1}px`;
				} else {
					width = `${input.scrollWidth + 1}px`;
				}
			} else {
				width = 0;
			}

			if (input?.style) {
				input.style.width = width;
			}
		};

		Object.defineProperty(input, 'value', {
			set: function (value) {
				setNativeInputValue(input, value);
				this.textContent = value;
				setSize();
			},
		});

		setSize();

		if (autoSize) {
			input.addEventListener('input', setSize);
			return () => input.removeEventListener('input', setSize);
		} else {
			input.removeEventListener('input', setSize);
		}
	}, [autoSize, placeholder]);

	useEffect(() => {
		autoFocus && inputRef.current.focus();
		select && inputRef.current.select();
	}, [autoFocus, select, disabled, editable]);

	useEffect(() => {
		if (typeof value !== 'undefined') {
			setNativeInputValue(inputRef.current, value);
		}
	}, [value]);

	useEffect(() => {
		const input = inputRef.current;
		if (restriction) {
			const check = () => restrict(inputRef.current, restriction);
			inputRef.current.addEventListener('input', check);
			return () => input.removeEventListener('input', check);
		}
	}, [restriction]);

	const counter = withCounter ? (
		<CharCounter
			ref={inputRef}
			max={typeof max === 'string' ? parseInt(max) : max}
			value={value}
			size={size}
		/>
	) : null;

	const handleOnTextFieldClick = useCallback(() => {
		selectOnClick && inputRef.current.select();
		onClick && onClick();
	}, [onClick, selectOnClick]);

	const increaseNumber = () => {
		if (disabled) return;
		const val = inputRef.current.value;
		if (max ? val < max : true) {
			setNativeInputValue(inputRef.current, parseInt(val) + 1);
		}
	};

	const decreaseNumber = () => {
		if (disabled) return;
		const val = inputRef.current.value;
		if (min ? min < val : true) {
			setNativeInputValue(inputRef.current, parseInt(val) - 1);
		}
	};

	return (
		<TextField {...props} onClick={handleOnTextFieldClick}>
			<input
				id={id}
				maxLength={maxLength}
				name={name}
				type={type}
				// value={value}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={onChange}
				disabled={disabled}
				readOnly={!editable}
				placeholder={placeholder || ' '}
				ref={multipleRefs(inputRef, ref)}
				data-testid={dataTestId}
				min={min}
				max={max}
				pattern={pattern}
			/>
			{renderRight && (
				<TextFieldRight size={size} className="textFieldRight">
					{renderRight}
				</TextFieldRight>
			)}
			{numberArrows && (
				<NumberArrowsStyled size={size} className="numberArrows" disabled={disabled}>
					<Icon onClick={increaseNumber} name="up-16" />
					<Icon onClick={decreaseNumber} name="down-16" />
				</NumberArrowsStyled>
			)}
			{counter}
			{/* Typography for getting with od placeholder
			    - when autoSize is on and input is empty, set size od placeholder */}
			{placeholder && autoSize && (
				<Typography
					variant={
						size === 'xs' ? 'textRegular200' : size === 'sm' ? 'textRegular300' : 'textRegular400'
					}
					ref={placeholderRef}
					className="placeholderHidden"
				>
					{placeholder}
				</Typography>
			)}
		</TextField>
	);
});

Input.displayName = 'Input';

Input.defaultProps = {
	size: 'md',
	width: 200,
	type: 'text',
	editable: true,
	id: 'input-ui',
};

export default memo(Input);
