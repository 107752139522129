import gql from 'graphql-tag';

export default gql`
	query surveyOffsetConnection($perPage: Int!, $page: Int, $search: String, $workspaceId: ID) {
		surveyOffsetConnection(
			perPage: $perPage
			page: $page
			search: $search
			workspaceId: $workspaceId
		) {
			edges {
				node {
					createdAt
					lastChangedAt
					logoUrl
					note
					backgroundColor
					overlay
					title
					uid
					hasCustomBackground
					active
					previewImage
					responseCount
					workspace {
						id
						name
					}
				}
				cursor
			}
			totalCount
			pageInfo {
				startCursor
				endCursor
			}
			viewStyle
			orderBy {
				orderByKey
				orderByDirection
			}
		}
	}
`;
