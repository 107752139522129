// SOURCE: https://stackoverflow.com/a/51359101

const getQueryStringParams = (query) =>
	query
		? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
				let [key, value] = param.split('=');
				params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
				return params;
			}, {})
		: {};

export default getQueryStringParams;
