import gql from 'graphql-tag';

export default gql`
	fragment Notifications on User {
		notifications {
			id
			type
		}
	}
`;
