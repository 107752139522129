import gql from 'graphql-tag';

export default gql`
	fragment Dashboard on Survey {
		uid
		dashboard(dashboardId: $id) {
			id
			logoUrl
			title
			createdAt
			sharing {
				enabled
				url
			}
			defaultTheme {
				id
				name
				isSystem
				isLocked
				colors
			}
			themes {
				id
				name
				isSystem
				isLocked
				colors
			}
			filters {
				matches
				sourceFilters {
					id
					type
					value
				}
				datePeriodFilters {
					id
					from
					till
					periodType
				}
				durationFilters {
					id
					type
					minimum
					maximum
				}
				answersFilters {
					id
					questionId
					questionOrdinal
					answers {
						answer
						answerTextValue
						answerOption
						customAnswer
					}
				}
				completenessFilter {
					id
					type
				}
			}
			tiles {
				... on QuestionTile {
					id
					question {
						id
						text
						... on ChoiceQuestion {
							isMultipleAnswer
							hasPictureChoices
							choices {
								id
								open
								ordinal
								quizPoints
								text
								picture {
									url
									w
									h
								}
							}
						}
						... on SemanticDifferentialQuestion {
							pointCount
							rows {
								id
								leftLabel
								rightLabel
								ordinal
							}
						}
						... on MatrixQuestion {
							type
							rows {
								text
								id
							}
							cols {
								text
								id
							}
						}
						... on ImportanceQuestion {
							rows {
								id
								text
							}
						}
						... on DividePointsQuestion {
							rows {
								text
								id
								ordinal
							}
						}
						... on TextQuestion {
							subtype: type
						}
					}
					result {
						chartSettings {
							theme {
								id
								name
								isSystem
								isLocked
								colors
							}
							customLabels {
								title
								axis
								answers
								rows
								columns
							}
							labels
							type
							order
							percentage
						}
						... on ChoicedQuestionAggregate {
							respondentsTotal
							preferences
							breakdown {
								answer {
									text
									id
									ordinal
								}
								count
							}
						}
						... on StarRatingQuestionAggregate {
							respondentsTotal
							preferences
							average
							breakdown {
								answer {
									text
									id
									ordinal
								}
								count
							}
						}
						... on NetPromoterQuestionAggregate {
							preferences
							summary {
								detractors
								passive
								promoters
								score
							}
							breakdown {
								answer {
									text
									id
									ordinal
								}
								count
							}
							respondentsTotal
						}
						... on MatrixQuestionAggregate {
							respondentsTotal
							preferences
							rowResults {
								row {
									text
									id
									ordinal
								}
								breakdown {
									answer {
										text
										id
										ordinal
										value
									}
									count
								}
							}
						}
						... on DividePointsQuestionAggregate {
							respondentsTotal
							units
							preferences
							results {
								item {
									... on TextAnswer {
										text
										id
										ordinal
									}
								}
								average
								sum
							}
						}
						... on SemanticDifferentialQuestionAggregate {
							respondentsTotal
							preferences
							rowResults {
								labels {
									rightLabel
									leftLabel
								}
								breakdown {
									value
									count
								}
								average
								rowId
							}
						}
						... on ImportanceQuestionAggregate {
							respondentsTotal
							preferences
							results: breakdown {
								item {
									... on PresetTextAnswer {
										text
										importanceOrdinal: ordinal
										importanceId: id
									}
								}
								averagePoints
							}
						}
						... on TextQuestionAggregate {
							preferences
							respondentsTotal
							results {
								value
								count
							}
						}
					}
				}
			}
		}
	}
`;
