import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'ui';
import PropTypes from 'prop-types';
import { EventEmitter } from 'fbemitter';
import Switcher from '../Switcher';
import Saved from './Saved';

const SavedStatus = ({ intl, className }) => {
	const [runningMutations, setMutations] = useState([]);
	const [saved, setSaved] = useState(false);
	const [saving, setSaving] = useState(false);
	const savedTimer = useRef(null);
	const savingTimer = useRef(null);
	const prevRunningMutations = usePrevious(runningMutations);

	// Add mutation to the list listener
	const addListener = useMemo(
		() =>
			SavedStatus.emitter.addListener('add', (mutationId) => {
				if (!runningMutations.length) {
					setSaving(true);
				}
				setSaved(false);
				setMutations([...runningMutations, mutationId]);
			}),
		[runningMutations],
	);

	// Remove mutation from the list listener
	const removeListener = useMemo(
		() =>
			SavedStatus.emitter.addListener('remove', (mutationId) => {
				setMutations(() => runningMutations.filter((m) => m !== mutationId));
			}),
		[runningMutations],
	);

	// "Saving..." fade out delay
	useEffect(() => {
		if (!!prevRunningMutations?.length && !runningMutations.length) {
			savingTimer.current = setTimeout(() => {
				setSaving(false);
				setSaved(true);
			}, 1000);
		} else {
			clearTimeout(savingTimer.current);
		}
		// (!) only one dependency: runningMutations.length
		// eslint-disable-next-line
	}, [runningMutations.length]);

	// "Saved" fade out delay
	useEffect(() => {
		if (saved) {
			clearTimeout(savedTimer.current);
			savedTimer.current = setTimeout(() => {
				setSaved(false);
				clearTimeout(savedTimer.current);
			}, 4000);
		} else {
			clearTimeout(savedTimer.current);
		}
		return () => {
			clearTimeout(savedTimer.current);
		};
	}, [saved]);

	// Setting listeners
	useEffect(
		() => () => {
			addListener.remove();
			removeListener.remove();
		},
		[addListener, removeListener],
	);

	return (
		<>
			<Switcher
				componentA={<Saved intl={intl} />}
				componentB={<Saved intl={intl} finished />}
				visibleAWhen={saving}
				visibleBWhen={saved}
				className={className}
				timeout={saving ? [200, 200] : [200, 300]}
			/>
		</>
	);
};
SavedStatus.propTypes = {
	className: PropTypes.string,
	intl: PropTypes.object,
};

SavedStatus.emitter = new EventEmitter();
SavedStatus.add = (mutationId) => SavedStatus.emitter.emit('add', mutationId);
SavedStatus.remove = (mutationId) => SavedStatus.emitter.emit('remove', mutationId);

export default SavedStatus;
