import styled from '@emotion/styled';
import { TableProps, CellProps } from '.';

export const TableWrapper = styled.div<Pick<TableProps, 'theme'>>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.scrollbar {
		border: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
		border-top-width: 0;
		border-radius: 0 0 10px 10px;
		width: 100%;
	}
`;

export const Cell = styled.div<CellProps>`
	display: flex;
	align-items: center;
	${({ justifyContent }) => `justify-content: ${justifyContent}`};
	${({ width, numOfColumns }) =>
		width
			? `width: ${typeof width === 'string' ? width : `${width}px`}; flex: 1 0 auto`
			: `width: ${(1 / numOfColumns) * 100}%`};
	box-sizing: border-box;
	padding: ${({ size }) => (size === 'xl' ? 13 : 9)}px ${({ size }) => (size === 'xl' ? 24 : 15)}px;
	overflow: hidden;

	// commented code => for one row text with three dots and tooltip "Text text t..."

	> div {
		//overflow: hidden;
		white-space: pre-wrap;
	}

	> span {
		//overflow: hidden;
		> div {
			//text-overflow: ellipsis;
			//overflow: hidden;
			//max-height: 100%;
			//white-space: nowrap;
		}
	}

	.tooltip-children {
		//overflow: hidden;
		> div {
			//text-overflow: ellipsis;
			//overflow: hidden;
			//max-height: 100%;
			//white-space: nowrap;
		}
	}
`;

export const Row = styled.div<Pick<TableProps, 'theme'>>`
	display: inline-flex;
	width: 100%;
	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
	}
`;

export const Header = styled(Row)<TableProps>`
	border: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
	border-radius: 10px 10px 0 0;
	background-color: ${({ theme }) => theme.colors.grey[200]};
`;
