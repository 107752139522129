import {
	avatarColorSets,
	AvatarColorSetType,
	ColorType,
	ThemeType,
} from '../components/ThemeProvider/theme';
import chroma from 'chroma-js';

const getThemeColor = (id: ColorType, theme: ThemeType): string => {
	const result = new Function('obj', 'return obj.colors.' + id + ';');
	return result(theme);
};

const generateColorByUUID = (uuid: string): AvatarColorSetType => {
	if (!uuid) {
		return undefined;
	}
	const characters = 'ec2flpu4zj3bndis1y8oah76r0xtgvw9q5km';
	const lastFourCharactersUUID = Array.from(uuid.toLowerCase().split('-').toString().slice(-4));
	let result = 0;
	for (let i = 0; i < characters.length - 1; i++) {
		result += characters.indexOf(lastFourCharactersUUID[i]);
	}
	const generatedColor = Math.abs(result % avatarColorSets.length);
	return avatarColorSets[generatedColor];
};

const hexToRGBA = (hex: string, alpha: number): string => {
	const r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	if (alpha) {
		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	} else {
		return 'rgb(' + r + ', ' + g + ', ' + b + ')';
	}
};

const isDark = (hex: string): boolean => hex && chroma(hex).get('lab.l') < 70;

const isHexColor = (hex: string): boolean => /^#([0-9A-F]{3}){1,2}$/i.test(hex);

const normalizeHexColor = (color: string) => {
	const code = color.replace(/^#/, '');

	return code.length === 3
		? `#${code.charAt(0)}${code.charAt(0)}${code.charAt(1)}${code.charAt(1)}${code.charAt(
				2,
			)}${code.charAt(2)}`
		: `#${code}`;
};

const addAlphaToHex = (color: string, alpha: number) => {
	const normalized = normalizeHexColor(color);
	const r = parseInt(normalized.substring(1, 3), 16);
	const g = parseInt(normalized.substring(3, 5), 16);
	const b = parseInt(normalized.substring(5, 7), 16);

	const finalAlpha = Math.round(alpha * 255);

	return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
		.toString(16)
		.padStart(2, '0')}${finalAlpha.toString(16).padStart(2, '0')}`;
};

export {
	getThemeColor,
	hexToRGBA,
	generateColorByUUID,
	isDark,
	isHexColor,
	normalizeHexColor,
	addAlphaToHex,
};
