import gql from 'graphql-tag';

export default gql`
	query inAppEmails($id: Int) {
		user(id: $id) {
			inAppEmails {
				id
				subject
				body
			}
		}
	}
`;
