import gql from 'graphql-tag';

const query = (children, fragments) => gql`
	query categories($language: String!) {
		categories(language: $language) @client {
			${children}
		}
	}
	${fragments}
`;

export default query;
