import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import Portal from '../Portal';
import { animate } from '../utils';

class OverlayPortal extends React.PureComponent {
	static propTypes = {
		children: PropTypes.any.isRequired,
		show: PropTypes.bool.isRequired,
		className: PropTypes.string,
	};

	handleOnEnter = (node) => {
		document.body.classList.add('modal-open');
		animate(node, 'from', 0.3, { opacity: '0' });
	};

	handleOnExit = (node) => {
		animate(node, 'to', 0.3, {
			opacity: '0',
			onComplete: () => document.body.classList.remove('modal-open'),
		});
	};

	render() {
		const { children, show, className } = this.props;

		return (
			<Transition
				in={show}
				timeout={show ? 300 : 0}
				mountOnEnter
				unmountOnExit
				onEnter={this.handleOnEnter}
				onExit={this.handleOnExit}
			>
				<Portal containerID="modalContainer">
					<div id="overlay" className={className || ''}>
						{children}
					</div>
				</Portal>
			</Transition>
		);
	}
}

export default OverlayPortal;
