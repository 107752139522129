import moment from 'moment';
import Cookies from 'js-cookie';
import { AUTH } from '~/constants';

const isCookieAuth = () => !!Cookies.get('jwt_hp');

const setToken = (token) => localStorage.setItem('jwt', token);

const getToken = () => (isCookieAuth() ? Cookies.get('jwt_hp') : localStorage.getItem('jwt'));

const removeToken = () => {
	Cookies.remove('jwt_hp', { domain: window.env.DOMAIN });
	localStorage.removeItem('jwt');
};

const isTokenValid = (token) => token?.split('.')?.length === (isCookieAuth() ? 2 : 3);

const getTokenData = (token) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch {
		return {};
	}
};

// not in use
const refreshToken = async (token) => {
	const { iat } = getTokenData(token);
	const isTokenOneDayOld = moment(moment.unix(iat).toDate()).add(1, 'days').isBefore();

	if (!isTokenOneDayOld) {
		return false;
	}

	try {
		const body = new FormData();
		body.append('token', token);

		const { token: newToken, ok } = await (
			await fetch(`${AUTH}/jwt/refresh_token`, {
				method: 'POST',
				body,
			})
		).json();

		if (ok) setToken(newToken);
		else removeToken();

		return ok;
	} catch {
		removeToken();
		return false;
	}
};

// not in use
const validateToken = async (token) => {
	try {
		const body = new FormData();
		body.append('token', token);

		const { ok } = await (
			await fetch(`${AUTH}/jwt/validate_token`, {
				method: 'POST',
				body,
			})
		).json();

		if (!ok) removeToken();

		return ok;
	} catch {
		removeToken();
		return false;
	}
};

export default {
	setToken,
	getToken,
	removeToken,
	isTokenValid,
	getTokenData,
	refreshToken,
	validateToken,
	isCookieAuth,
};
