import React, { memo } from 'react';
import { StyledPulser, PulserProps } from '.';

const Pulser = ({ children, ...rest }: PulserProps) => (
	<StyledPulser {...rest}>{children}</StyledPulser>
);

Pulser.defaultProps = {
	active: true,
	translateCss: ['-50%', '50%'],
};

export default memo(Pulser);
