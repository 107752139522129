import gql from 'graphql-tag';

export default gql`
	fragment Workspaces on User {
		workspaces {
			id
			name
			surveyCount
		}
	}
`;
