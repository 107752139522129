import React, { FC, memo } from 'react';
import { CategoryButtonStyled, CategoryButtonProps } from '.';
import { Icon } from 'ui';

const CategoryButton: FC<CategoryButtonProps> = ({ label, active, onClick, disabled }) => (
	<CategoryButtonStyled
		active={active}
		onClick={!disabled ? onClick : undefined}
		disabled={disabled}
	>
		{label}
		<Icon name="arrow-right-16" className="arrow" />
	</CategoryButtonStyled>
);

export default memo(CategoryButton);
