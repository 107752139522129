import gql from 'graphql-tag';

export default gql`
	mutation setQuestionAnalyticsPreferences($uid: String!, $question: Int!, $value: Json!) {
		setQuestionAnalyticsPreferences(input: { uid: $uid, question: $question, value: $value }) {
			success
			message
		}
	}
`;
