import gql from 'graphql-tag';

export default gql`
	query filters($uid: String!) {
		filters(uid: $uid) {
			matches
			sourceFilters {
				id
				type
				value
			}
			datePeriodFilters {
				id
				from
				till
			}
			durationFilters {
				id
				type
				minimum
				maximum
			}
			answersFilters {
				id
				questionId
				questionOrdinal
				answers {
					answer
					answerTextValue
					answerOption
					customAnswer
				}
			}
			completenessFilter {
				id
				type
			}
		}
	}
`;
