import gql from 'graphql-tag';

export default gql`
	fragment LoginPageSettings on User {
		team {
			id
			loginPage {
				settings
			}
		}
	}
`;
