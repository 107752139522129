import React from 'react';
import {
	ApolloClient,
	InMemoryCache,
	ApolloLink,
	defaultDataIdFromObject,
	split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { v4 as uuid } from 'uuid';
import DebounceLink from 'apollo-link-debounce';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { FormattedMessage } from 'react-intl';

import auth from '~/auth';
import resolvers from '~/resolvers';
import Notifications from '~/components/Notifications';
import { API, LOGIN_URL_SUFFIX } from '~/constants';
import SavedStatus from '~/components/App/components/Header/SavedStatus';
import { UTM_PREFIX } from './constants';

const createApolloClient = (result) => {
	const possibleTypes = {};

	result.data.__schema.types.forEach((result) => {
		if (result.possibleTypes) {
			possibleTypes[result.name] = result.possibleTypes.map((subtype) => subtype.name);
		}
	});

	const cache = new InMemoryCache({
		possibleTypes,
		typePolicies: {
			User: {
				fields: {
					activeService: {
						merge: true,
					},
				},
			},
			Survey: {
				fields: {
					invitationConnection: {
						merge: true,
					},
					settings: {
						merge: true,
					},
					content: {
						merge: true,
					},
					theme: {
						merge: true,
					},
					integrations: {
						merge: true,
					},
					responseConnection: {
						merge: true,
					},
				},
			},
			SurveyContent: {
				fields: {
					thanksPage: {
						merge: true,
					},
					introPage: {
						merge: true,
					},
				},
			},
			DesignerTheme: {
				fields: {
					footer: {
						merge: true,
					},
					question: {
						merge: true,
					},
				},
			},
			Team: {
				fields: {
					loginPage: {
						merge: true,
					},
				},
			},
			Query: {
				fields: {
					user: {
						merge: true,
					},
					survey: {
						merge: true,
					},
					...Object.fromEntries(
						Object.entries(resolvers.defaults).map(([key, value]) => [
							key,
							{
								merge: true,
								read: (existingValue) => ({
									...value,
									...existingValue,
								}),
							},
						]),
					),
				},
			},
		},
		dataIdFromObject: (object) => {
			switch (object.__typename) {
				case 'Survey':
					return `${object.__typename}:${object.uid}`;
				case 'Video':
					return null;
				case 'TextAnswer':
					return null;
				case 'SurveyTemplateCategory':
					return null;
				default:
					return defaultDataIdFromObject(object);
			}
		},
	});

	const authLink = setContext((_, { headers }) => {
		const token = auth.getToken();
		const isTokenValid = auth.isTokenValid(token);

		if (!isTokenValid) {
			auth.removeToken();
		}

		return {
			headers: {
				...headers,
				...(auth.isCookieAuth() ? {} : { authorization: `Bearer ${token}` }),
			},
		};
	});

	const errorLink = onError(({ networkError, operation }) => {
		if (networkError?.statusCode === 401) {
			const { language } = auth.getTokenData(auth.getToken());
			const lang = language || window.navigator.language.slice(0, 2);
			const appUrl = new URL(location.href);
			const appParams = new URLSearchParams(appUrl.search);

			auth.removeToken();
			window.localStorage.removeItem('proformaWarning');

			const newUrl = new URL(
				['skoda.survio.com', 'stage-skoda.survio.com'].includes(location.host)
					? `${location.origin}/login`
					: `${window.env.HOME_URL}/${lang}/${LOGIN_URL_SUFFIX[lang]}`,
			);

			for (const key of appParams.keys()) {
				if (key.startsWith(UTM_PREFIX)) {
					newUrl.searchParams.set(key, appParams.get(key));
					appUrl.searchParams.delete(key);
				}
			}
			newUrl.searchParams.set('redirect', appUrl.href);
			window.location.replace(newUrl.href);
		} else {
			if (operation.operationName === 'stripeUpgrade') {
				return;
			}
			Notifications.add({
				variant: 'danger',
				children: <FormattedMessage id="app.common.internal-server-error" />,
			});
		}
	});

	const httpLink = createUploadLink({
		uri: `${API}/app`,
		credentials: auth.isCookieAuth() ? 'include' : 'omit',
	});

	const linkWithStatus = new ApolloLink((operation, forward) => {
		const id = uuid();
		SavedStatus.add(id);

		return forward(operation).map((response) => {
			SavedStatus.remove(id);
			return response;
		});
	}).concat(httpLink);

	const noBatchLink = split(
		({ query }) => {
			const { kind, operation } = getMainDefinition(query);
			return kind === 'OperationDefinition' && operation === 'subscription';
		},
		linkWithStatus, // false
		linkWithStatus,
	);

	const batchLink = new BatchHttpLink({
		uri: `${API}/batch`,
		credentials: auth.isCookieAuth() ? 'include' : 'omit',
	});

	const link = ApolloLink.from([
		new DebounceLink(0),
		authLink,
		errorLink,
		split(
			({ query, getContext }) => {
				const { operation } = getMainDefinition(query);
				return getContext().noBatch || operation !== 'query';
			},
			noBatchLink, // true
			batchLink, // false
		),
	]);

	return new ApolloClient({
		link,
		cache,
		...resolvers,
	});
};

export default createApolloClient;
