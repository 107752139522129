import React, { FC, memo } from 'react';

import { TextFieldProps } from './';
import { TextFieldBase, TextFieldError, TextFieldLeft, TextFieldStyled } from './TextField.styled';
import { Label } from '../Label';

const TextField: FC<TextFieldProps> = ({
	width,
	className,
	disabled,
	label,
	size,
	error,
	editable,
	children,
	renderLeft,
	borderColor,
	counter,
	withCounter,
	renderRight,
	dark,
	onClick,
	...props
}: TextFieldProps) => (
	<TextFieldStyled tabIndex={-1} width={width} className={className} onClick={onClick}>
		<Label disabled={disabled} className="input-label" {...props}>
			{label}
		</Label>
		<TextFieldBase
			size={size}
			disabled={disabled}
			error={error}
			editable={editable}
			borderColor={borderColor}
			withCounter={withCounter}
			renderRight={renderRight}
			dark={dark}
		>
			<div className="children-wrapper">
				{children}
				{renderLeft && (
					<TextFieldLeft
						size={size}
						disabled={disabled}
						error={error}
						editable={editable}
						className="textFieldLeft"
					>
						{renderLeft}
					</TextFieldLeft>
				)}
			</div>
		</TextFieldBase>
		{counter}
		{typeof error === 'string' && !disabled && (
			<TextFieldError withCounter={withCounter} counter={counter} error={error}>
				{error || ' '}
			</TextFieldError>
		)}
	</TextFieldStyled>
);

export default memo(TextField);
