import query from '~/cache/query';

export default query('chartSettings', {
	options: ({ id }) => ({
		fetchPolicy: 'network-only',
		variables: { question: id },
		context: { noBatch: true },
	}),
	mapProps: ({ chartSettings }) => {
		return {
			settings: { ...chartSettings },
		};
	},
});
