import gql from 'graphql-tag';

export default gql`
	fragment QuestionsIndividual on Survey {
		title
		questions {
			... on QuestionInterface {
				id
				text
				helpText
				required
				ordinal
				edited
				number
				answered
				picture {
					url
				}
				video {
					url
					thumb
				}
			}
			... on ChoiceQuestion {
				hasPictureChoices
				layout
				isMultipleAnswer
				minimumAnswers
				maximumAnswers
				layout
				isQuizItem
				choices {
					id
					text
					open
					ordinal
					quizPoints
					picture {
						url
					}
				}
			}
			... on MatrixQuestion {
				type
				minimumAnswers
				maximumAnswers
				cols {
					id
					text
					ordinal
				}
				rows {
					id
					text
					ordinal
				}
			}
			... on ImportanceQuestion {
				rows {
					text
					id
					ordinal
				}
			}
			... on RatingQuestion {
				pointCount
			}
			... on NetPromoterQuestion {
				leftText
				rightText
			}
			... on SemanticDifferentialQuestion {
				pointCount
				rows {
					id
					leftLabel
					rightLabel
					ordinal
				}
			}
			... on TextQuestion {
				subtype: type
				answerLengthLimit
			}
			... on DividePointsQuestion {
				rows {
					text
					id
					ordinal
				}
				pointCount
				units
			}
		}
	}
`;
