import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { FastField, Formik } from 'formik';
import { compose } from 'redux';
import { withApollo } from '@apollo/client/react/hoc';
import { injectIntl } from 'react-intl';
import withRouter from '~/hoc/withRouter';

import { Typography, Dropdown, Button, Modal as ModalNew, Icon as IconNew } from '@survio/ui';
import Notifications from '~/survio-ui/Notifications';
import TeamSizeAdjustmentModal from '~/pages/Members/components/TeamSizeAdjustmentModal';
import Modal from '~/survio-ui/Modal';
import Spinner from '~/survio-ui/Spinner';
import Radio from '~/survio-ui/Radio';
import Icon from '~/survio-ui/Icon';
import Checkbox from '~/survio-ui/Checkbox';
import Feedback from './Feedback';
import { includesPlan, isProPlan } from '~/utils';
import { PAGES, PLANS } from '~/constants';
import getStartSubscriptionUrl from '~/cache/queries/getStartSubscriptionUrl';
import query from '~/cache/query';
import mutation from '~/cache/mutation';

import styles from './style.less';

const UpgradePlan = ({
	loading,
	data,
	activeService,
	upgradeService,
	close,
	history,
	client,
	intl,
	validate,
	getAmount,
	planInfo,
}) => {
	const [modal, setModal] = useState();
	const [sending, setSending] = useState();
	const isMedium = includesPlan([PLANS.MEDIUM], data.type);
	const isCardValid =
		moment(activeService?.nextBilling?.cardExpiration, 'MM/YYYY')
			.endOf('month')
			.diff(moment(), 'days') > 0;

	const openModal = useCallback(() => setModal(true), []);

	const submit = useCallback(
		async ({ period, additionalSeatsCount }) => {
			if (isCardValid) {
				setSending(true);
				upgradeService({
					period,
					type: data.type.toUpperCase(),
					additionalSeatsCount: Number(additionalSeatsCount),
					saleSource: data.saleSource,
				})
					.then(() => new Promise((resolve) => setTimeout(resolve, 3000)))
					.then(close)
					// .then(() => history.push(PAGES.dashboard.path))
					.then(() =>
						Notifications.add({
							type: 'success',
							content: intl.formatMessage({
								id: 'app.upgrade.notification-subscription-edited',
							}),
						}),
					)
					.then(() => setTimeout(() => (window.location = PAGES.dashboard.path), 3000))
					.catch(() => setSending(false));
			} else {
				try {
					const result = await client.query({
						query: getStartSubscriptionUrl,
						variables: {
							period,
							type: data.type.toUpperCase(),
							additionalSeatsCount: Number(additionalSeatsCount),
							saleSource: data.saleSource,
						},
					});

					if (result.data?.user.getStartSubscriptionUrl) {
						window.location.href = result.data?.user.getStartSubscriptionUrl;
					}
				} catch {
					console.error('Error while getting start subscription url');
				}
			}
		},
		[client, close, data.saleSource, data.type, intl, isCardValid, upgradeService],
	);

	return (
		<Formik
			initialValues={{
				period: data.selectedPeriod || data.period || 'YEARLY',
				checkbox: false,
				additionalSeatsCount: '0',
			}}
			onSubmit={submit}
		>
			{({ handleSubmit, values }) => (
				<>
					{modal && (
						<ModalNew slide="upgradePRO" close={close} className={styles.modal}>
							{(slide) => {
								switch (slide) {
									case 'upgradePRO':
										return (
											<TeamSizeAdjustmentModal
												upgrade
												prev={false}
												close={close}
												planType={data.type}
												seatsDefault={planInfo?.seatsDefault}
												seatsCount={planInfo?.seatsDefault + Number(values.additionalSeatsCount)}
											/>
										);
								}
							}}
						</ModalNew>
					)}

					<Spinner visible={loading || sending} flex>
						<Modal.Content
							close={close}
							maxWidth={920}
							fullPageTo={839}
							padding={false}
							closeColor="grey"
							closeOnEsc={false}
							closeOnOutsideClick={false}
							classNameClose={styles.cross}
						>
							<Modal.Body>
								<div className={styles.container}>
									<div className={styles.content}>
										<div className={styles.title}>
											{intl.formatMessage(
												{ id: 'app.upgrade.upgrade-to' },
												{
													PLAN: intl.formatMessage(
														{
															id: isProPlan(data.type)
																? 'app.multiuser.plan-types-PRO'
																: 'app.multiuser.plan-types-basic',
														},
														{ PLAN_TYPE: data.type?.toUpperCase() },
													),
												},
											)}
										</div>
										<div className={styles.subtitle}>
											{intl.formatMessage({ id: 'app.upgrade.choose-subscription' })}
										</div>

										<div className={styles.form}>
											{!['YEARLY', 'TWO_YEARS'].includes(data.period) && (
												<label className={classNames(styles.line, styles.flex)}>
													<FastField
														name="period"
														id="MONTHLY"
														render={({ field }) => (
															<span
																className={classNames(
																	styles.name,
																	field.value === 'MONTHLY' && styles.purple,
																)}
															>
																<Radio
																	{...field}
																	value="MONTHLY"
																	checked={field.value === 'MONTHLY'}
																	color="filters_primary"
																	className={styles.radio}
																/>
																{intl.formatMessage({ id: 'app.upgrade.monthly-subscription' })}
															</span>
														)}
													/>

													<span className={styles.amount}>
														{`${getAmount(
															planInfo?.monthlyAmount,
															planInfo?.currencyCode,
														)} / ${intl.formatMessage({ id: 'app.account.month' })}`}
													</span>
												</label>
											)}

											{data.period !== 'TWO_YEARS' && (
												<label className={classNames(styles.line, styles.flex)}>
													<span className={styles.column}>
														<FastField
															name="period"
															id="YEARLY"
															render={({ field }) => (
																<span
																	className={classNames(
																		styles.name,
																		field.value === 'YEARLY' && styles.purple,
																	)}
																>
																	<Radio
																		{...field}
																		value="YEARLY"
																		checked={field.value === 'YEARLY'}
																		color="filters_primary"
																		className={styles.radio}
																	/>
																	{intl.formatMessage({ id: 'app.upgrade.yearly-subscription' })}
																</span>
															)}
														/>

														{data.period !== 'YEARLY' && (
															<div className={styles.label}>
																{intl.formatMessage(
																	{ id: 'app.upgrade.save-months' },
																	{
																		MONTHS: Math.floor(
																			(12 * planInfo?.monthlyAmount - planInfo?.yearlyAmount) /
																				planInfo?.monthlyAmount,
																		),
																	},
																)}
															</div>
														)}
													</span>

													<span className={styles.amount}>
														{data.period !== 'YEARLY'
															? `${getAmount(
																	planInfo?.yearlyAmount / 12,
																	planInfo?.currencyCode,
																)} / ${intl.formatMessage({ id: 'app.account.month' })}`
															: `${getAmount(
																	planInfo?.yearlyAmount,
																	planInfo?.currencyCode,
																)} / ${intl.formatMessage({ id: 'app.account.year' })}`}
													</span>
												</label>
											)}

											{!activeService?.nextBillingSeatsCount && (
												<label className={classNames(styles.line, styles.flex)}>
													<span className={styles.column}>
														<FastField
															name="period"
															id="TWO_YEARS"
															render={({ field }) => (
																<span
																	className={classNames(
																		styles.name,
																		field.value === 'TWO_YEARS' && styles.purple,
																	)}
																>
																	<Radio
																		{...field}
																		value="TWO_YEARS"
																		checked={field.value === 'TWO_YEARS'}
																		color="filters_primary"
																		className={styles.radio}
																	/>
																	{intl.formatMessage({ id: 'app.upgrade.two-years-subscription' })}
																</span>
															)}
														/>

														{data.period !== 'TWO_YEARS' && (
															<div className={styles.label}>
																{isProPlan(data.type)
																	? `${intl.formatMessage({
																			id: 'app.pricelist.save',
																		})} ${Math.floor(
																			((2 * planInfo?.yearlyAmount) / planInfo?.twoYearAmount - 1) *
																				100,
																		)}%`
																	: intl.formatMessage(
																			{ id: 'app.upgrade.save-months' },
																			{
																				MONTHS:
																					data.period !== 'YEARLY'
																						? Math.floor(
																								(24 * planInfo?.monthlyAmount -
																									planInfo?.twoYearAmount) /
																									planInfo?.monthlyAmount,
																							)
																						: Math.floor(
																								(2 * planInfo?.yearlyAmount -
																									planInfo?.twoYearAmount) /
																									(planInfo?.yearlyAmount / 12),
																							),
																			},
																		)}
															</div>
														)}
													</span>
													<span className={styles.amount}>
														{data.period === 'MONTHLY'
															? `${getAmount(
																	planInfo?.twoYearAmount / 24,
																	planInfo?.currencyCode,
																)} / ${intl.formatMessage({ id: 'app.account.month' })}`
															: `${getAmount(
																	planInfo?.twoYearAmount / 2,
																	planInfo?.currencyCode,
																)} / ${intl.formatMessage({ id: 'app.account.year' })}`}
													</span>
												</label>
											)}
										</div>

										{isMedium && (
											<div className={styles.flex}>
												<div className={styles.flex}>
													<Typography variant="textSemibold300" color="grey_shades_with_blue[600]">
														{intl.formatMessage({ id: 'app.multiuser.team-size' })}
													</Typography>
													<IconNew
														name="help-16"
														color="grey_shades_with_blue[600]"
														className={styles.help}
													/>
													<FastField
														name="additionalSeatsCount"
														render={({ field, form }) => (
															<Dropdown
																{...field}
																onChange={(value) =>
																	form.setFieldValue('additionalSeatsCount', value)
																}
																size="sm"
																width={73}
																menuMaxHeight={200}
																items={Array.from(
																	Array(planInfo?.seatsMax - planInfo?.seatsDefault + 1),
																).map((item, i) => ({
																	id: `${i}`,
																	label: `${i + planInfo?.seatsDefault}`,
																}))}
															/>
														)}
													/>
												</div>
												<Typography variant="title300" color="grey_shades_with_blue[600]">
													{`+ ${getAmount(
														(planInfo?.yearSeatPrice ?? 0) * values.additionalSeatsCount,
														planInfo?.currencyCode,
													)} / ${intl.formatMessage({ id: 'app.account.year' })}`}
												</Typography>
											</div>
										)}

										<div className={styles.separator} />

										<div className={classNames(styles.total, styles.flex)}>
											<span>
												{intl.formatMessage({ id: 'app.upgrade.total' })}
												<span className={styles.vat}>
													{` (${intl.formatMessage({ id: 'app.upgrade.excl-vat' })})`}
												</span>
											</span>

											<span className={styles.sum}>
												{(() => {
													switch (values.period) {
														case 'MONTHLY':
															return `${getAmount(
																planInfo?.monthlyAmount,
																planInfo?.currencyCode,
															)} ${intl.formatMessage({
																id: 'app.upgrade.for-one-month',
															})}`;
														case 'YEARLY':
															return `${getAmount(
																planInfo?.yearlyAmount +
																	(planInfo?.yearSeatPrice ?? 0) * values.additionalSeatsCount,
																planInfo?.currencyCode,
															)} ${
																typeof data.period === 'undefined' || data.period === 'MONTHLY'
																	? intl
																			.formatMessage({
																				id: 'app.upgrade.for-months-2',
																			})
																			.replace('%s', 12)
																	: intl.formatMessage({
																			id: 'app.upgrade.for-one-year',
																		})
															}`;
														case 'TWO_YEARS':
															return `${getAmount(
																planInfo?.twoYearAmount +
																	2 * (planInfo?.yearSeatPrice ?? 0) * values.additionalSeatsCount,
																planInfo?.currencyCode,
															)} ${
																typeof data.period === 'undefined' || data.period === 'MONTHLY'
																	? intl
																			.formatMessage({
																				id: 'app.upgrade.for-months-2',
																			})
																			.replace('%s', 24)
																	: intl
																			.formatMessage({
																				id: 'app.upgrade.for-years',
																			})
																			.replace('%s', 2)
															}`;
													}
												})()}
											</span>
										</div>

										{isCardValid && (
											<div>
												<FastField
													name="checkbox"
													validate={validate}
													render={({ field, form: { errors, touched } }) => (
														<Checkbox
															{...field}
															color="filters_primary"
															className={styles.checkbox}
															checked={field.value}
															errors={errors.checkbox && touched.checkbox}
															label={intl.formatMessage({ id: 'app.upgrade.confirmation-text' })}
														/>
													)}
												/>
											</div>
										)}

										<div className={styles.footer}>
											{isProPlan(data.type) && (
												<Button
													size="xl"
													color="ghost"
													className={styles.button}
													onClick={openModal}
												>
													{intl.formatMessage({ id: 'app.upgrade.pay-by-invoice' })}
												</Button>
											)}

											<Button size="xl" startIcon="payment-24" onClick={handleSubmit}>
												{isCardValid
													? `${intl.formatMessage({
															id: 'app.upgrade.pay-now',
														})}
												(
												${(() => {
													switch (values.period) {
														case 'MONTHLY':
															return getAmount(planInfo?.monthlyAmount, planInfo?.currencyCode);
														case 'YEARLY':
															return getAmount(planInfo?.yearlyAmount, planInfo?.currencyCode);
														case 'TWO_YEARS':
															return getAmount(planInfo?.twoYearAmount, planInfo?.currencyCode);
													}
												})()}
												)
											`
													: intl.formatMessage({
															id: 'app.common.continue',
														})}
											</Button>
										</div>

										<div className={styles.security}>
											<Icon className={styles.icon} name="security" />
											{intl.formatMessage({ id: 'app.upgrade.secure-payment' })}
										</div>
									</div>

									<Feedback />
								</div>
							</Modal.Body>
						</Modal.Content>
					</Spinner>
				</>
			)}
		</Formik>
	);
};

UpgradePlan.propTypes = {
	loading: PropTypes.bool,
	data: PropTypes.object,
	activeService: PropTypes.object,
	upgradeService: PropTypes.func,
	history: PropTypes.func,
	client: PropTypes.object,
	intl: PropTypes.object,
	close: PropTypes.func,
	validate: PropTypes.func,
	getAmount: PropTypes.func,
	planInfo: PropTypes.object,
};

export default compose(
	memo,
	injectIntl,
	withRouter,
	withApollo,
	query('account', {
		fragments: ['Team', 'ActiveService'],
		mapProps: ({ user }) => ({
			activeService: user?.activeService,
		}),
	}),
	mutation('upgradeService'),
)(UpgradePlan);
