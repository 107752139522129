import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './style.less';

export const SpinnerContext = React.createContext();

class Spinner extends PureComponent {
	state = {};

	hide = () => this.setState({ visible: false });

	show = () => this.setState({ visible: true });

	render() {
		const {
			absolute,
			children,
			color,
			block,
			flex,
			fullpage,
			inline,
			background,
			mode,
			style,
			styleChildren,
			hiddenChildren,
			opacity,
		} = this.props;

		const visible = this.props.visible || this.state.visible;

		return (
			<div
				className={classNames(
					styles.component,
					visible && styles.visible,
					block && styles.block,
					flex && styles.flex,
					absolute && styles.absolute,
					fullpage && styles.fullpage,
					inline && styles.inline,
					background && styles.background,
				)}
				style={style}
			>
				{visible && (
					<div
						className={classNames(styles.container, color && styles[color], mode && styles[mode])}
						style={{ background }}
					>
						<div className={styles.spinner}>
							<div
								className={styles.spin}
								style={{ borderColor: `${color} transparent transparent transparent` }}
							/>
							<div
								className={styles.spin}
								style={{ borderColor: `${color} transparent transparent transparent` }}
							/>
							<div
								className={styles.spin}
								style={{ borderColor: `${color} transparent transparent transparent` }}
							/>
						</div>
					</div>
				)}
				<div
					className={classNames(styles.children, hiddenChildren && styles.hiddenChildren)}
					style={{ ...styleChildren, opacity: visible ? opacity : undefined }}
				>
					<SpinnerContext.Provider
						value={{
							visible,
							show: this.show,
							hide: this.hide,
						}}
					>
						{children}
					</SpinnerContext.Provider>
				</div>
			</div>
		);
	}
}

Spinner.propTypes = {
	absolute: PropTypes.bool,
	background: PropTypes.bool,
	block: PropTypes.bool,
	color: PropTypes.oneOf(['', 'white']),
	flex: PropTypes.bool,
	fullpage: PropTypes.bool,
	hiddenChildren: PropTypes.bool,
	inline: PropTypes.bool,
	mode: PropTypes.oneOf(['dark', 'light']),
	style: PropTypes.object,
	styleChildren: PropTypes.object,
	visible: PropTypes.bool,
};
Spinner.defaultProps = {
	visible: false,
	// position: absolute
	absolute: false,
	// diplay: block
	block: false,
	// flexbox
	flex: false,
	// full page (fixed & flexbox) loader
	fullpage: false,
	// inline without children props
	inline: false,
	// body background
	background: false,
	// children opacity 0
	hiddenChildren: false,
	// inline styles
	style: {},
	// children inline styles
	styleChildren: {},
};
export default Spinner;
