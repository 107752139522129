import styled from '@emotion/styled';

import { ProgressBarProps } from './';
import { getThemeColor } from '../../utils';

export const StyledBar = styled.div<Pick<ProgressBarProps, 'theme'>>`
	width: 100%;
	overflow: hidden;
	border-radius: 30px;
	background: ${({ theme }) => theme.colors.grey_shades_with_blue[100]};
`;

export const StyledIndicator = styled.div<ProgressBarProps>`
	height: ${({ height }) => height}px;
	min-width: ${({ height }) => height}px;
	max-width: 100%;
	border-radius: 30px;
	width: ${({ value, max }) => `${100 / (max / value)}%`};
	background: ${({ value, max, theme, overLimitColor, color }) =>
		value >= max ? getThemeColor(overLimitColor, theme) : getThemeColor(color, theme)};
	transition: width 200ms;
`;
