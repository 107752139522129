import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Spacer, Status, Typography } from 'ui';
import { FormattedHTMLMessage } from 'react-intl';
import { PERIOD, PLANS_NEW, UPGRADE_MODAL_TYPES, PLANS_24 } from '~/constants';
import s from './style.less';
import { PLANS_OLD } from '~/constants/plans';
import UpgradeSlidePanelWrapper from './UpgradeSlidePanelWrapper';
import ChooseUserQuantity from '~/components/UpgradeModalStripe/ChooseUserQuantity';

const LeftPanel = ({
	plan,
	intl,
	responsePackages = [],
	register,
	sale,
	actualPlan,
	actualPeriod,
	type,
	period,
	actualLimit,
	isTrial,
	stripePricingPlan,
	currencyCode,
	activePro,
	stripePriceList,
	isPro23A,
}) => {
	const getRadioButtons = () =>
		responsePackages.map((p) => (
			<RadioButton
				withBox
				className={s.radio}
				key={p.quantity}
				{...register('responses')}
				value={p.quantity}
			>
				<Typography variant="textSemibold300" color="black[100]">
					{intl.formatNumber(p.quantity)}
				</Typography>{' '}
				{intl.formatMessage({ id: 'app.designer.modal.service-sales-2' })}
			</RadioButton>
		));

	return (
		<UpgradeSlidePanelWrapper
			isTrial={isTrial}
			actualPlan={actualPlan}
			className={s.left}
			title={
				<FormattedHTMLMessage
					id={
						type === UPGRADE_MODAL_TYPES.INCREASE_RESPONSES
							? 'app.stripe.increase-response-limit-title'
							: 'app.stripe.subscribe-modal-title'
					}
					values={{ PLAN: isPro23A ? 'PRO' : plan, FORMER_LIMIT: actualLimit }}
				/>
			}
		>
			{period !== PERIOD.YEARLY &&
				type !== UPGRADE_MODAL_TYPES.INCREASE_RESPONSES &&
				(actualPlan === PLANS_OLD.FREE ||
					actualPlan === PLANS_24.FREE ||
					actualPlan === PLANS_NEW.FREE_01 ||
					actualPeriod === 1 ||
					period === PERIOD.MONTHLY ||
					isTrial) && (
					<div className={s.subscription}>
						<Typography variant="textSemibold300" className={s.sectionTitle}>
							{intl.formatMessage({ id: 'app.upgrade.choose-subscription-length' })}
						</Typography>
						<RadioButton
							withBox
							className={s.radio}
							name="period"
							{...register('period')}
							value={PERIOD.MONTHLY}
						>
							{intl.formatMessage({ id: 'app.stripe.monthly-subscription' })}
							<Spacer />
							<Typography variant="textMedium300">
								{`${intl.formatNumber(stripePricingPlan?.monthlyAmount, {
									currency: currencyCode,
									style: 'currency',
									minimumFractionDigits: 0,
									maximumFractionDigits: 0,
								})} / ${intl.formatMessage({
									id: 'app.account.month',
								})}`}
							</Typography>
						</RadioButton>
						<RadioButton
							withBox
							className={s.radio}
							name="period"
							{...register('period')}
							value={PERIOD.YEARLY}
						>
							{intl.formatMessage({ id: 'app.stripe.annual-subscription' })}
							<Spacer width={8} />
							<Status state="Save">{`${intl.formatMessage({
								id: 'app.pricelist.save',
							})} ${sale}%`}</Status>
							<Spacer />
							{period !== PERIOD.YEARLY && (
								<Typography variant="textMedium300">
									{`${intl.formatNumber(stripePricingPlan?.yearlyAmount / 12, {
										currency: currencyCode,
										style: 'currency',
										minimumFractionDigits: 0,
										maximumFractionDigits: 0,
									})} / ${intl.formatMessage({
										id: 'app.account.month',
									})}`}
								</Typography>
							)}
						</RadioButton>
					</div>
				)}
			{isPro23A && (
				<ChooseUserQuantity
					intl={intl}
					period={period}
					register={register}
					sale={sale}
					stripePricingPlan={stripePricingPlan}
					currencyCode={currencyCode}
					activePro={activePro}
					stripePriceList={stripePriceList}
				/>
			)}
			{type !== UPGRADE_MODAL_TYPES.INCREASE_RESPONSES && responsePackages.length > 0 && (
				<Typography variant="textMedium400" className={s.subtitle}>
					{intl.formatMessage({ id: 'app.stripe.response-volume' })}
				</Typography>
			)}
			{getRadioButtons()}
		</UpgradeSlidePanelWrapper>
	);
};

LeftPanel.propTypes = {
	actualLimit: PropTypes.number,
	actualPeriod: PropTypes.number,
	actualPlan: PropTypes.string,
	intl: PropTypes.object,
	isTrial: PropTypes.bool,
	period: PropTypes.oneOf([PERIOD.MONTHLY, PERIOD.YEARLY]),
	plan: PropTypes.string,
	register: PropTypes.object,
	responsePackages: PropTypes.array,
	sale: PropTypes.number,
	type: PropTypes.oneOf([UPGRADE_MODAL_TYPES.UPGRADE_PLAN, UPGRADE_MODAL_TYPES.INCREASE_RESPONSES]),
	stripePricingPlan: PropTypes.object,
	currencyCode: PropTypes.string,
	activePro: PropTypes.string,
	stripePriceList: PropTypes.object,
	isPro23A: PropTypes.string,
};

export default memo(LeftPanel);
