const defaultEnv = {
	LANGUAGES_BASE_URL: process.env.LANGUAGES_BASE_URL,
	TEMPLATES_BASE_URL: process.env.TEMPLATES_BASE_URL,
	GRAPHQL_BASE_URL: process.env.GRAPHQL_BASE_URL,
	MEDIA: process.env.MEDIA,
	AUTH: process.env.AUTH,
	API: process.env.API,
	HOME_URL: process.env.HOME_URL,
	URL: process.env.URL,
	SMARTLOOK: process.env.SMARTLOOK,
	GOOGLE_ANALYTICS: process.env.GOOGLE_ANALYTICS,
	MATOMO_URL: process.env.MATOMO_URL,
	SENTRY_DSN: process.env.SENTRY_DSN,
	DOMAIN: process.env.DOMAIN,
	ZAPIER_URL: process.env.ZAPIER_URL,
	APP_ENV: process.env.APP_ENV,
	CDN: process.env.CDN,
	WEBSITE_URL: process.env.WEBSITE_URL,
	WIZARD_TEMPLATES_URL: process.env.WIZARD_TEMPLATES_URL,
	OPTIMIZE_TEST_1: process.env.OPTIMIZE_TEST_1,
	OPTIMIZE_TEST_2: process.env.OPTIMIZE_TEST_2,
	OPTIMIZE_TEST_WIZARD: process.env.OPTIMIZE_TEST_WIZARD,
	STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
	MKT_SALES_ENABLED: process.env.MKT_SALES_ENABLED,
};

window.env = window.env || {};
for (const key in window.env) {
	if (window.env[key] === `\${${key}}`) {
		window.env[key] = defaultEnv[key];
	}
}
