import React from 'react';
import { EMPTY_INDIVIDUAL } from '../constants';

import Text from '~/survio-ui/Text';

const text = {
	cols: ({ id, text }) => [
		{
			dataIndex: `${id}`,
			// intlID: 'app.results.individual-questioncolumn',
			title: () => <Text maxLine={2}>{text}</Text>,
			questionId: id,
			width: 270,
			render: (row) => <Text width={225}>{row[id]}</Text>,
		},
	],
	rows: ({ answer, cols }) =>
		answer && answer.questionId
			? {
					[answer.questionId]: answer.answer.text ? answer.answer.text : EMPTY_INDIVIDUAL,
			  }
			: {
					[cols[0].dataIndex]: EMPTY_INDIVIDUAL,
			  },
};
export default text;
