import React, { memo } from 'react';
import { Typography } from '@survio/ui';
import PropTypes from 'prop-types';

import Feature from './Feature';

import style from '../styles.less';

const Features = ({ features, intl, price, isEnterprise }) => (
	<div className={style.features}>
		<Typography variant="title400" className={style.featuresTitle}>
			{intl.formatMessage({ id: 'app.designer.modal.service-sales-title' })}
		</Typography>
		<div className={style.wrapper}>
			{features.map((feature, i) => (
				<Feature feature={feature} key={i} intl={intl} />
			))}
		</div>
		{!isEnterprise && (
			<span className={style.price}>
				<Typography
					variant="textRegular400"
					className={style.price}
					color="grey_shades_with_blue[500]"
				>
					{intl.formatMessage({ id: 'app.designer.modal.service-sales-fromprice' })}
				</Typography>{' '}
				<Typography variant="title500" className={style.price}>
					{price}
				</Typography>{' '}
				<Typography
					variant="textRegular400"
					className={style.price}
					color="grey_shades_with_blue[500]"
				>
					{` / ${intl.formatMessage({ id: 'app.designer.modal.service-sales-16' })}`}
				</Typography>
			</span>
		)}
	</div>
);

Features.propTypes = {
	features: PropTypes.array,
	intl: PropTypes.object,
	isEnterprise: PropTypes.bool,
	price: PropTypes.string,
};

export default memo(Features);
