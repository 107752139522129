export default {
	defaults: {
		// appStrings: {},
	},
	resolvers: {
		Query: {
			appStrings: async (_, { appLang }) => {
				const response = await fetch(
					`${window.env.LANGUAGES_BASE_URL}/languages/survey-languages-${appLang || 'en'}.json`,
					{ cache: 'no-store' },
				);
				const {
					data: { appStrings },
				} = await response.json();

				return appStrings;
			},
		},
	},
};
