import gql from 'graphql-tag';

export default gql`
	mutation setDashboardSharing($input: DashboardSharingInput!) {
		setDashboardSharing(input: $input) {
			enabled
			url
			__typename
		}
	}
`;
