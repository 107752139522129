// eslint-disable-next-line

import { Ref } from 'react';

const multipleRefs = <T extends HTMLElement>(...refs: Ref<T>[]): Ref<T> => {
	// why it accepts any booleans?
	const filteredRefs = refs.filter(Boolean);
	if (!filteredRefs.length) return null;
	if (filteredRefs.length === 1) return filteredRefs[0];
	return (inst) => {
		for (const ref of filteredRefs) {
			if (typeof ref === 'function') {
				ref(inst);
			} else if (typeof ref === 'object') {
				// ref.current is read only
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				ref.current = inst;
			}
		}
	};
};

export default multipleRefs;
