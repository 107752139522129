import gql from 'graphql-tag';

export default gql`
	fragment UpgradePlanData on User {
		activeService {
			name
			identifier
			responseCollectionLimit
			period
			subscriptionReference
		}
	}
`;
