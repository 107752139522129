import React from 'react';
import { QuestionConfig } from '../types';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import {
	StyledTooltipContent,
	StyledPicture,
	StyledTitle,
	StyledDescription,
} from './AddBarTooltip.styled';
import { QuestionTypesOnly } from '~/pages/Builder23_1/components/QuestionDialog/types';

export type AddBarTooltipProps = {
	questionConfig: QuestionConfig | QuestionTypesOnly;
};

export const isQuestionConfig = (
	question: QuestionTypesOnly | QuestionConfig,
): question is QuestionConfig => (question as QuestionConfig).ui !== undefined;

const AddBarTooltip = ({ questionConfig }: AddBarTooltipProps) => {
	const t = useIntl();

	return isQuestionConfig(questionConfig) ? (
		<StyledTooltipContent>
			<StyledPicture picture={questionConfig?.ui?.tooltip.picture}></StyledPicture>
			<StyledTitle>{t.formatMessage({ id: questionConfig?.ui?.title })}</StyledTitle>
			<StyledDescription>
				<FormattedHTMLMessage id={questionConfig?.ui?.tooltip.description} />
				{/*{t.formatMessage({ id: questionConfig?.ui?.tooltip.description })}*/}
			</StyledDescription>
		</StyledTooltipContent>
	) : null;
};

export default AddBarTooltip;
